import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AllergyModal from './AllergyModal';

const AllergyTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'allergy',
            header: 'Allergy',
            meta: {
                headerProps: { className: 'pe-1 col-lg-8 text-start text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.allergy + "," + original.is_active + "," + original.allergy_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [allergy, setAllergy] = useState([]);
    const [error, setError] = useState(true);

    // const [showModal, setShowModal] = useState(false);

    // const handleEditAllergyModal = (allergyValue, isActive, allergyId) => {
    //     localStorage.setItem("allergyValue", allergyValue);
    //     localStorage.setItem("allergyId", allergyId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchAllergyDetails() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-patient-allergies", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const presAllergiesData = data.data

            if (data.status) {
                presAllergiesData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            allergy: value.allergy,
                            allergy_id: value.id,
                            is_active: value.is_active
                            // updated_by: value.updated_by,
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });
                if(results.length > 0){
                    setAllergy(results);
                    setError(true)
                }else{
                    setError(false)
                }
            }
            else {
                toast.info("No Allergies found in this list");
            }
        }

        fetchAllergyDetails();

        async function refreshAllergyDetails() {
            fetchAllergyDetails();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshAllergyDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: allergy,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                {error ? <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle "//text-center
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                /> : <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '50px' }}>
                <p className="text-danger fw-bold mb-0">There is no data available in the table</p>
            </div>}
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <AllergyModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default AllergyTable;
