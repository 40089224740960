import React, { useEffect, useState } from 'react'
import UserProfileHeader from './UserProfileHeader'
import axios from 'axios';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import UserProfileBody from './UserProfileBody';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';

const UserProfileMain = () => {
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {
        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/users/profile", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        setUserData(data)
    }

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'User-Profile', href: '', isActive: true }
    ];
    return (
        <>
            {userData != null ? <>
                <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />
                <UserProfileHeader data={userData} />
                <UserProfileBody data={userData} />
            </> : <ShimmerUI />}
        </>
    )
}

export default UserProfileMain