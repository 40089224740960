import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import moment from 'moment';

const DeliveryModal = ({ show, setShow, deliverydate, cycleid, selectedDate }) => {

    const [deliverydateoptions, setDeliveryDateOptions] = useState([]);
    const [deliverytimeoptions, setDeliveryTimeOptions] = useState([]);
    const [deliverytypeoptions, setDeliveryTypeOptions] = useState([]);
    const [modalDeliveryDate, setDeliveryDate] = useState('');
    const [modalDeliveryType, setDeliveryType] = useState("");
    const [modalDeliveryTime, setDeliveryTime] = useState("");
    const [modalDeliveryReason, setDeliveryReason] = useState("");

    useEffect(() => {

        console.log("deliverydate " + deliverydate);

        let results = []
        setDeliveryDate(selectedDate);
        deliverydate.forEach(function (singledate, index) {
            results.push({
                key: moment(singledate, 'YYYY-MM-DD').format('MM/DD/YY') , value: singledate
            })
        });

        setDeliveryDateOptions([
            { key: 'Select Date', value: '' },
            ...results
        ]);

        setDeliveryTimeOptions([
            { key: '03:00PM-08:00PM', value: '03:00PM-08:00PM' },
        ]);

        setDeliveryTypeOptions([
            { key: 'Select Delivery Type', value: '' },
            { key: 'Bridgewell Driver', value: 'Bridgewell Driver' },
            { key: 'Waltham', value: 'Waltham' },
            { key: 'Fitchburg', value: 'Fitchburg' },
            { key: 'Pickup Waltham', value: 'Pickup Waltham' },
            { key: 'Pickup Fitchburg', value: 'Pickup Fitchburg' },
            { key: 'UPS', value: 'UPS' },
            { key: 'Destin', value: 'Destin' },
        ]);

    }, [deliverydate]);

    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let deliveryUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-delivery-date`;

        let deliveryData =
        {
            "cycle_id": cycleid,
            "delivery_date": modalDeliveryDate,
            "delivery_time": modalDeliveryTime,
            "delivery_date_update_reason": modalDeliveryReason,
        };

        console.log("deliveryData " + JSON.stringify(deliveryData));

        const { data } = await axios.post(deliveryUrl, deliveryData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Delivery Date has been set successfully");
            setShow(false);
        }
        else {
            toast.error("Error occured while setting Delivery Date");
        }

    }


    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Set Delivery
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form >
                    <Row>
                        {/* {localStorage.getItem("roleId") < 4 && ( */}
                        <Form.Group>
                            <Form.Label>Delivery Date</Form.Label>
                            <Form.Select
                                name="deliverydate"
                                size="sm"
                                onChange={(e) => { setDeliveryDate(e.target.value) }}
                            >
                                {deliverydateoptions.map((option) => {
                                    var isSelected = false
                                    if(selectedDate == option.key){
                                        isSelected = 'selected'
                                    }
                                    return (
                                        <option key={option.key} value={option.value} selected = {isSelected}>
                                            {option.key}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row>
                        {/* {localStorage.getItem("roleId") < 4 && ( */}
                        <Form.Group>
                            <Form.Label>Delivery Time</Form.Label>
                            <Form.Select
                                name="deliverytime"
                                size="sm"
                                disabled
                                onChange={(e) => { setDeliveryType(e.target.value) }}
                            >
                                {deliverytimeoptions.map((option) => {
                                    return (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                        {/* )} */}
                    </Row>
                    <Row>
                        {localStorage.getItem("roleId") < 4 && (
                            <Form.Group>
                                <Form.Label>Delivery Type</Form.Label>
                                <Form.Select
                                    name="deliverytype"
                                    size="sm"
                                    onChange={(e) => { setDeliveryTime(e.target.value) }}
                                >
                                    {deliverytypeoptions.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        )}
                    </Row>
                    <Row>
                        {localStorage.getItem("roleId") < 4 && <Form.Group className="mb-3">
                            <Form.Label>Enter Reason</Form.Label>
                            <Form.Control
                                name="deliveryreason"
                                type="text"
                                size="sm"
                                onChange={(e) => { setDeliveryReason(e.target.value) }}
                            />
                        </Form.Group>}
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    name="btnpatientmodalsubmit"
                                    className="mt-4 w-50"
                                    onClick={handleSave}>
                                    Save
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

DeliveryModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default DeliveryModal;

