export const ACCESS_CONTROL = {
    "peltask": 1,
    "mym": 2,
    "medsheets" : 1,
    "medsheets_advanced": 2,
    "treament": 2,
    "currentmeds": 1,
    "loaform": 2,
    "hcporder": 2,
    "editprescription" : 2
}