import { Card, Col, Form, Row, Button, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import MaskedInput from 'react-text-mask';
import axios from "axios";
import moment from "moment";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import PatientModal from './PatientModal';
import paths, { rootPaths } from '../../../routes/paths';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import DemoFileUpload from 'components/orderdetail/DemoFileUpload';

const AddEditPatient = (props) => {

    const [checked, setChecked] = useState(false);
    const [facilityoptions, setFacilityOptions] = useState([]);
    const [genderoptions, setGenderOptions] = useState([]);
    const [dobdate, setDobDate] = useState(null);
    const [admitdate, setAdmitDate] = useState(null);
    const [physicalexamdate, setPhysicalexamDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const formRef = useRef(null);
    const [errors, setErrors] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    //const location = useLocation();
    //const locationData = location.state;
    const [allergyvalue, setAllergyValue] = useState("");
    const [allergyOptions, setAllergyOptions] = React.useState([]);
    const [efsnovalue, setEfsNoValue] = useState("");
    const [efsnoOptions, setEfsnoOptions] = React.useState([{ key: "Select Option", value: "" }]);
    const [insprtyvalue, setInsPrtyValue] = useState("");
    const [selectedAllergies, setSelectedAllergies] = useState([]);
    const [insprtyOptions, setInsprtyOptions] = React.useState([{}]);
    const [loading, setLoading] = useState(false);

    const initialFormData = {
        facilityname: '',
        firstname: '',
        lastname: '',
        dob: '',
        gender: '',
        entrydate: '',
        physicalexam: '',
        ssno: '',
        allergies: [],
        medsheetnote: '',
        efsinfo: '',
        insgrpno: '',
        insno: '',
        inspriority: '',
        is_program: true,
        is_medicate: false
    };
    const [allergy, setAllergy] = useState([])
    const [formData, setFormData] = useState(initialFormData);

    const navigate = useNavigate();

    const handlePatientModal = (event) => {
        //console.log("PatientFormName " + event.target.id)
        localStorage.setItem("patientFormName", event.target.id);
        setShowModal(true);
    };

    useEffect(() => {
        async function populatePatientData() {

            let results = JSON.parse(localStorage.getItem("facilitiesData"));

            let tokenStr = localStorage.getItem("accessToken");
            const getAllItems = {
                "allergy": {}
            }
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/prescriptions/fetch-multiple`, getAllItems, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                const allergyDropdownOptions = res?.data?.output?.data?.allergy.map((item) => ({
                    value: item.id,
                    label: item.allergy,
                }));
                setAllergy(allergyDropdownOptions)

                if (props.patientid && props.data?.allergies) {
                    const patientAllergyIds = props.data.allergies.split(",").map(id => id.trim()); // Convert "1,1198" to ["1", "1198"]
    
                    const preSelectedAllergies = allergyDropdownOptions.filter(opt => patientAllergyIds.includes(opt?.value?.toString()));
    
                    setSelectedAllergies(preSelectedAllergies);
                    setFormData((prev) => ({ ...prev, allergies: preSelectedAllergies.map(a => a.value) }));
                }
            })
            //console.log("Facility Points " + results);

            /***************DROPDOWN*****************/

            setFacilityOptions([
                { key: 'Select a facility', value: '' },
                ...results
            ]);

            setGenderOptions([
                {
                    key: 'Select a gender', value: ''
                },
                {
                    key: 'Male', value: 1
                },
                {
                    key: 'Female', value: 2
                },
                {
                    key: 'Others', value: 3,
                }
            ])

            setInsprtyOptions([
                {
                    key: 'Select Priority', value: ''
                },
                {
                    key: 'High', value: 0
                },
                {
                    key: 'Low', value: 1
                },
                {
                    key: 'Medium', value: 2,
                }
            ])

            /**********************EDIT PATIENT*****************************/

            if (props.patientid !== undefined) {
                let singlePatient = props.data;
                setFormData({
                    facilityname: singlePatient.facility_id,
                    firstname: singlePatient.first_name,
                    lastname: singlePatient.last_name,
                    gender: singlePatient.gender,
                    ssno: singlePatient.social_security_number,
                    medsheetnote: singlePatient.medsheet_note,
                    insgrpno: singlePatient.insurance_group_no,
                    insno: singlePatient.insurance_no,
                    is_program: singlePatient.not_capable_selfmeditating ? false : true,
                    is_medicate: singlePatient.medication_independent_status ? false : true,
                    inspriority: singlePatient.insurance_priority,
                    allergies : singlePatient.allergies.split(',')
                });
                setDobDate(singlePatient.dob != null ? moment(singlePatient.dob, 'YYYY-MM-DD').format('MM/DD/YY') : null);
                setAdmitDate(singlePatient.admit_date != null ? moment(singlePatient.admit_date, 'YYYY-MM-DD').format('MM/DD/YY') : null);
                setPhysicalexamDate(singlePatient.physical_exam_date != null ? moment(singlePatient.physical_exam_date, 'YYYY-MM-DD').format('MM/DD/YY') : null);

                if (singlePatient.allergies != "" && singlePatient.allergies != null) {
                    if (singlePatient.allergies.includes(",")) {
                        let allergiesArray = singlePatient.allergies.split(',')
                        for (var i = 0; i < allergiesArray.length; i++) {
                            setAllergyOptions([...allergyOptions, { key: allergiesArray[i], value: allergiesArray[i] }])
                        }
                    }
                    else {
                        setAllergyOptions([...allergyOptions, { key: singlePatient.allergies, value: singlePatient.allergies }])
                    }
                }
                if (singlePatient.insurance_efs_information != "" && singlePatient.insurance_efs_information != null) {
                    if (singlePatient.insurance_efs_information.includes(",")) {
                        let efsArray = singlePatient.insurance_efs_information.split(',')
                        for (var i = 0; i < efsArray.length; i++) {
                            setEfsnoOptions([...efsnoOptions, { key: efsArray[i], value: efsArray[i] }])
                        }
                    }
                    else {
                        setEfsnoOptions([...efsnoOptions, { key: singlePatient.insurance_efs_information, value: singlePatient.insurance_efs_information }])
                    }
                }
            };
        }
        // Trigger the fetch
        populatePatientData();
    }, []);

    const validateForm = () => {
        let validationErrors = {};
        if (!formData.allergies) validationErrors.allergies = "Allergy is required";
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    // Handler
    const handlePatientSubmit = async e => {

        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        //console.log("i am also getting called" + e.target.name);

        if (e.target.name == "patientmainform") {

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsPatientUrl = props.patientid !== undefined ? `${process.env.REACT_APP_SERVER_URL}` + "/patients/update-patient" : `${process.env.REACT_APP_SERVER_URL}` + "/patients/add-patient";

            let allergyManual = [];
            let efsManual = [];
            let insurancePriorityManual = [];

            allergyOptions.map((allergyoption) => {

                if (allergyoption.value != "") {
                    allergyManual.push(allergyoption.value);
                }
            });

            efsnoOptions.map((efsoption) => {
                if (efsoption.value != "") {
                    efsManual.push(efsoption.value);
                }
            });

            insprtyOptions.map((insprtyoption) => {

                if (insprtyoption.value != "") {
                    insurancePriorityManual.push(insprtyoption.value);
                }
            });

            let tempGender = "";
            if (formData.gender == "Male") {
                tempGender = 1
            }
            else if (formData.gender == "Female") {
                tempGender = 2
            }
            else (formData.gender == "Others")
            {
                tempGender = 3
            }



            //TODO need to merge it in one and do it for other post request
            let pelmedsPatientData = props.patientid !== undefined ?
                {
                    id: props.patientid,
                    group_id: localStorage.getItem("groupId"),
                    facility_id: formData.facilityname,
                    first_name: formData.firstname,
                    last_name: formData.lastname,
                    dob: moment(dobdate).format('YYYY-MM-DD'),//formData.dob,                    
                    gender: tempGender,//temp fix
                    admit_date: moment(admitdate).format('YYYY-MM-DD'),//formData.entrydate,
                    physical_exam_date: moment(physicalexamdate).format('YYYY-MM-DD'),//formData.physicalexam,
                    ss_number: formData.ssno,
                    allergies: formData.allergies,//localStorage.getItem("allergyValue"),//formData.allergy,
                    medsheet_note: formData.medsheetnote,
                    insurance_efs_information: formData.efsinfo,
                    insurance_group_no: formData.insgrpno,
                    insurance_no: formData.insno,
                    insurance_priority: 0,//formData.inspriority,
                    //is_program: formData.is_program,
                    is_active: formData.is_program ? 1 : 0,
                    not_capable_selfmeditating: formData.is_medicate ? 1 : 0,
                    allergy_manual: allergyManual,
                    efs_manual: efsManual,
                    inspr_manual: insurancePriorityManual,
                }
                :
                {
                    group_id: localStorage.getItem("groupId"),
                    facility_id: formData.facilityname,
                    first_name: formData.firstname,
                    last_name: formData.lastname,
                    dob: moment(dobdate).format('YYYY-MM-DD'),//formData.dob,
                    gender: tempGender,//temp fix
                    admit_date: moment(admitdate).format('YYYY-MM-DD'),//formData.entrydate,
                    physical_exam_date: moment(physicalexamdate).format('YYYY-MM-DD'),//formData.physicalexam,
                    ss_number: formData.ssno,
                    allergies: formData.allergies,//formData.allergy,
                    medsheet_note: formData.medsheetnote,
                    insurance_efs_information: formData.efsinfo,
                    insurance_group_no: formData.insgrpno,
                    insurance_no: formData.insno,
                    insurance_priority: 0,//formData.inspriority,
                    //is_program: formData.is_program,
                    is_active: formData.is_program ? 1 : 0,
                    not_capable_selfmeditating: formData.is_medicate ? 1 : 0,
                    allergy_manual: allergyManual,
                    efs_manual: efsManual,
                    inspr_manual: insurancePriorityManual,
                };

            console.log("pelmedsPatientData ", (pelmedsPatientData));
            const formDataToSubmit = new FormData();
            Object.keys(pelmedsPatientData).forEach(key => {
                formDataToSubmit.append(key, pelmedsPatientData[key]);
            });
            if(uploadedFiles.length > 0){
                formDataToSubmit.append("patient_pic", uploadedFiles[0], uploadedFiles[0].name);
            }


            try {
                const { data } = await axios.post(pelmedsPatientUrl, formDataToSubmit, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
          
                if (data.status && props.patientid === undefined) {
                  toast.success("Patient Details added successfully");
                  navigate(paths.viewPatient);
                } else if (data.status && props.patientid !== undefined) {
                  toast.success("Patient Details updated successfully");
                  navigate(paths.viewPatient);
                } else {
                  setLoading(false);
                  toast.info("Error occurred during adding/updating Patient Details");
                }
              } catch (error) {
                toast.error("Failed to save patient data.");
              } finally {
                setLoading(false);  // End loading
              }

            //TODO REMOVE BELOW AS ITS NOT REQUIRED AND ITS REFERENCES
            //if (locationData == null) {
            //setFormData(initialFormData);
            //formRef.current.reset();
            //}
            //console.log("DataOutput " + JSON.stringify(data));
        }
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };



    // Handler function for file upload

    const handleFileChange = (files) => {
        setUploadedFiles(files);

        console.log('Selected file:', files);
    };


    const handlePatientModalValue = (modalvalue) => {
        //console.log("i m in handlepopup" + modalvalue);
        let formName = localStorage.getItem("patientFormName");

        if (formName == "btnaddallergy") {
            let isDuplicate = handleDrpDuplicates(allergyOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate)
                setAllergyOptions([...allergyOptions, { key: modalvalue, value: modalvalue }])
        }
        else if (formName == "btnaddins") {
            let isDuplicate = handleDrpDuplicates(insprtyOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate)
                setInsprtyOptions([...insprtyOptions, { key: modalvalue, value: modalvalue }])
        }
        else if (formName == "btnaddefs") {
            let isDuplicate = handleDrpDuplicates(efsnoOptions, modalvalue);
            if (modalvalue != "" && !isDuplicate)
                setEfsnoOptions([...efsnoOptions, { key: modalvalue, value: modalvalue }])
        }
    };

    function handleDrpDuplicates(drpdwnOptions, modalvalue) {

        let isDuplicate = false;

        drpdwnOptions.map((singleoption) => {
            //console.log(singleoption.key + " && " + modalvalue);
            if (singleoption.key.toLowerCase() == modalvalue.toLowerCase()) {
                isDuplicate = true;
            }
        })

        return isDuplicate;
    }

    const handleCheckBoxChange = () => {
        setChecked(!checked);
    };

    const handleAllergyFieldChange = (e) => {
        //console.log("Target " + Array.from(e.target.selectedOptions, option => option.value));
        setAllergyValue(Array.from(e.target.selectedOptions, option => option.value));
    };

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Add-Patient', href: paths.addPatient, isActive: true }
    ];

    return (
        <>
            {props.patientid ? [] : <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />}
            <Form ref={formRef} onSubmit={handlePatientSubmit} name="patientmainform">
                <Card className="mb-3">
                    <Card.Header as="h6" className="bg-body-tertiary">
                        {props.patientid !== undefined ? "Edit" : "Add"} Patient
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">Facility</Form.Label>
                                    <Form.Select
                                        name="facilityname"
                                        onChange={handleFieldChange}
                                        size="sm"
                                        disabled = {props.data.from_ips == 1}>
                                        {facilityoptions.map((option) => {
                                            var isFacilitySelected = false;
                                            if (props.patientid !== undefined) {
                                                if (option.value == props.facilityid) {
                                                    isFacilitySelected = "selected";
                                                }
                                            }
                                            return (
                                                <option key={option.value} value={option.value} selected={isFacilitySelected}>
                                                    {option.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">First Name</Form.Label>
                                    <Form.Control
                                        placeholder='First Name'
                                        value={formData.firstname}
                                        name="firstname"
                                        onChange={handleFieldChange}
                                        type="text"
                                        size="sm"
                                        disabled = {props.data.from_ips == 1}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">Last Name</Form.Label>
                                    <Form.Control
                                        placeholder='Last Name'
                                        value={formData.lastname}
                                        name="lastname"
                                        onChange={handleFieldChange}
                                        type="text"
                                        size="sm"
                                        disabled = {props.data.from_ips == 1}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">Date Of Birth</Form.Label>
                                    <DatePicker
                                        name="dob" 
                                        disabled = {props.data.from_ips == 1} 
                                        className="form-control"
                                        placeholderText="mm/dd/yy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        selected={dobdate ? moment(dobdate).format("MM/DD/YY") : null}
                                        onChange={(dobdate) =>
                                            setDobDate(moment(dobdate).format("MM/DD/YY"))
                                        }
                                        dateFormat="MM/dd/yy"
                                        maxDate={new Date()} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">Gender</Form.Label>
                                    <Form.Select
                                        name="gender"
                                        onChange={handleFieldChange}
                                        size="sm"
                                        disabled = {props.data.from_ips == 1}
                                    >
                                        {genderoptions.map((option) => {
                                            var isGenderSelected = false;
                                            if (props.patientid !== undefined) {
                                                if (option.value == formData.gender) {
                                                    isGenderSelected = "selected";
                                                }
                                            }
                                            return (
                                                <option key={option.value} value={option.value} selected={isGenderSelected}>
                                                    {option.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Entry Date</Form.Label>
                                    {/* <DatePicker 
                                        name="entrydate" 
                                        selected={admitdate} 
                                        onChange={(admitdate) => setAdmitDate(admitdate)}
                                        className='form-control' 
                                        placeholderText="Select Admit Date" 
                                        maxDate={new Date()} /> */}

                                    <DatePicker
                                        placeholderText="Select Admit Date" 
                                        className="form-control"
                                        name="entrydate" 

                                        selected={admitdate ? moment(admitdate).format("MM/DD/YY") : null}
                                        onChange={(admitdate) =>
                                            setAdmitDate(moment(admitdate).format("MM/DD/YY"))
                                        }
                                        dateFormat="MM/dd/yy"
                                       // maxDate={new Date()} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Physical Exam</Form.Label>
                                    {/* <DatePicker name="physicalexam" 
                                        selected={physicalexamdate} 
                                        onChange={(physicalexamdate) => setPhysicalexamDate(physicalexamdate)}
                                            
                                        className='form-control' 
                                        placeholderText="Select Physical Exam Date" 
                                        maxDate={new Date()} 
                                    /> */}
                                
                                    <DatePicker
                                        name="physicalexam" 
                                        placeholderText="Select Physical Exam Date"
                                        className="form-control"

                                        selected={physicalexamdate ? moment(physicalexamdate).format("MM/DD/YY") : null}
                                        onChange={(physicalexamdate) =>
                                            setPhysicalexamDate(moment(physicalexamdate).format("MM/DD/YY"))
                                        }
                                        dateFormat="MM/dd/yy"
                                        //maxDate={new Date()} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label>SSNo</Form.Label>
                                    <MaskedInput
                                        mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control"
                                        guide={false}
                                        placeholder="XXX-XX-XXXX"
                                        onChange={handleFieldChange}
                                        size="sm"
                                        name="ssno"
                                        value={formData.ssno}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label className="required">{"Allergy (Select Allergy)"} </Form.Label>
                                        <Select
                                            isMulti
                                            options={allergy}
                                            value={selectedAllergies}
                                            onChange={(selectedOptions) => {
                                                setSelectedAllergies(selectedOptions);
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    allergies: selectedOptions.map(option => option.value) // Store only IDs
                                                }));
                                            }}
                                            placeholder="Select Allergies"
                                            classNamePrefix="react-select"
                                            className={errors.allergies ? 'is-invalid' : ''}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.allergies}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label>MedSheet Note</Form.Label>
                                    <Form.Control
                                        placeholder='MedSheet Note'
                                        value={formData.medsheetnote}
                                        name="medsheetnote"
                                        onChange={handleFieldChange}
                                        as="textarea"
                                        rows={3}
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance/EFS Information</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select
                                            name="efsinfo"
                                            onChange={handleFieldChange}
                                        >
                                            {efsnoOptions.map((efsoption) => {
                                                var isEfsNoSelected = false;
                                                if (props.patientid !== undefined) {
                                                    if (efsoption.value == formData.efsno) {
                                                        isEfsNoSelected = "selected";
                                                    }
                                                }
                                                return (
                                                    <option key={efsoption.value} value={efsoption.value} selected={isEfsNoSelected}>
                                                        {efsoption.key}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Button variant="outline-secondary" id="btnaddefs" onClick={handlePatientModal}>
                                            +
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Group Number</Form.Label>
                                    <Form.Control
                                        placeholder='Insurance Group Number'
                                        value={formData.insgrpno}
                                        name="insgrpno"
                                        onChange={handleFieldChange}
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Number</Form.Label>
                                    <Form.Control
                                        placeholder='Insurance Number'
                                        value={formData.insno}
                                        name="insno"
                                        onChange={handleFieldChange}
                                        type="text"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Insurance Priority</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select
                                            name="inspriority"
                                            onChange={handleFieldChange}
                                        >
                                            {insprtyOptions.map((inspriorityoption) => {
                                                var isInsrPrioritySelected = false;
                                                if (props.patientid !== undefined) {
                                                    if (inspriorityoption.value == formData.inspriority) {
                                                        isInsrPrioritySelected = "selected";
                                                    }
                                                }
                                                return (
                                                    <option key={inspriorityoption.value} value={inspriorityoption.value} selected={isInsrPrioritySelected}>
                                                        {inspriorityoption.key}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Button variant="outline-secondary" id="btnaddins" onClick={handlePatientModal}>
                                            +
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <DemoFileUpload onFilesChange={handleFileChange} textOfDoc={'Individual Image change'}/>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        type='switch'
                                        name="is_program"
                                        id='is_program'
                                        checked={
                                            formData.is_program
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_program: e.target.checked
                                            })
                                        }
                                        label='Is Individual currently in this program ?(Check if YES)'
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        type='switch'
                                        name="is_medicate"
                                        id='is_medicate'
                                        checked={
                                            formData.is_medicate
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_medicate: e.target.checked
                                            })
                                        }
                                        label='Assessed & Able to Self Medicate?(Check if YES)'
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col>
                                <Form.Group>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-100 mt-4"
                                        name="btnpatientsubmit"
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : props.patientid !== undefined ? "Update" : "Save"}
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Card.Body>
                </Card>
                <PatientModal show={showModal} setShow={setShowModal} handlePatientModalValue={handlePatientModalValue} />
            </Form >
        </>
    );
};

AddEditPatient.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

AddEditPatient.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default AddEditPatient;
