import React, { useEffect, useState } from 'react';
import { useAppContext } from 'providers/AppProvider';
import { Container, Row, Col, Form, Card, Badge } from 'react-bootstrap';
import axios from 'axios';
import Select from "react-select";
import PatientCard from './PatientCard';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import { socket } from '../../socket';
import SetMastersDetails from 'components/common/SetMastersDetails';
import RemoveMasters from 'components/common/RemoveMasters';

const PatientView = () => {
  const [columns, setColumns] = useState([
    { key: '2', title: 'Intake', count: 0 },
    { key: '3', title: 'Data Entry', count: 0 },
    { key: '4', title: 'PV1', count: 0 },
    { key: '5', title: 'Filling', count: 0 },
    { key: '6', title: 'PV2', count: 0 },
    { key: '7', title: 'Manifest', count: 0 },
  ]);
  const [cardData, setCardData] = useState(null);
  const [filteredCardData, setFilteredCardData] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState({ key: 'All Facilities', value: '' });
  
  let results = JSON.parse(localStorage.getItem("facilitiesData"));
  const [options] = useState([
    { key: 'All Facilities', value: '' },
    ...results
  ]);

  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);

    function onConnect() {
      console.log('Connected to server');
    }

    function onDisconnect() {
      console.log('Disconnected from server');
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    const tokenStr = localStorage.getItem("accessToken");
    socket.emit('getPatientViewData', { tokenStr });

    socket.on('patientDataUpdated', async () => {
      console.log('Patient data updated via socket.');
      await getPatientViewData();
    });

    getPatientViewData();
    SetMastersDetails();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.emit('clearAllOrders', { tokenStr: tokenStr });
      setConfig('isFluid', false);
      setConfig('isNavbarVerticalCollapsed', false);
      RemoveMasters();
    };
  }, []);

  async function getPatientViewData() {
    let tokenStr = localStorage.getItem("accessToken");
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/orders/patient-dashboard`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    const dataObj = data.orders;
    const updatedColumns = columns.map((column) => {
      let totalOrders = 0;
      if (dataObj[column.key]) {
        Object.keys(dataObj[column.key]).forEach((patientId) => {
          const patient = dataObj[column.key][patientId];
          if (patient.orders) {
            totalOrders += patient.orders.length;
          }
        });
      }
      return { ...column, count: totalOrders };
    });

    setColumns(updatedColumns);
    setCardData(dataObj);
    setFilteredCardData(dataObj);
  }

  const handleFacilityFieldChange = (selectedOption) => {
    setSelectedFacility(selectedOption.value);

    if (selectedOption.value) {
      let filteredData = {};
      Object.keys(cardData).forEach((statusKey) => {
        let filteredPatients = Object.keys(cardData[statusKey])
          .filter(patientId => cardData[statusKey][patientId].facility_id === selectedOption.value)
          .reduce((obj, patientId) => {
            obj[patientId] = cardData[statusKey][patientId];
            return obj;
          }, {});

        if (Object.keys(filteredPatients).length > 0) {
          filteredData[statusKey] = filteredPatients;
        }
      });

      setFilteredCardData(filteredData);
    } else {
      setFilteredCardData(cardData);
    }
  };

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Patient', href: '#', isActive: true },
    { name: 'Patient Dashboard', href: "#", isActive: true }
  ];

  return (
    <>
      {cardData ? (
        <>
                
          <Row className="m-00">
            <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
            <Card className="p-3 pt-3 pb-2 mb-4">
              <Row className='mb-3'>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Facility</Form.Label>
                    <Select
                      name="facilityname"
                      closeMenuOnSelect={true}
                      placeholder='Select Facility'
                      classNamePrefix="react-select"
                      options={options}
                      value={options.find(opt => opt.value === selectedFacility) || selectedFacility}
                      getOptionLabel={option => option.key}
                      getOptionValue={option => option.value}
                      onChange={handleFacilityFieldChange}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: "#ced4da",
                          backgroundColor: "white",
                          height: "calc(1.5em + 0.75rem + 2px)",
                          minHeight: "38px",
                          boxShadow: "none",
                          "&:hover": { borderColor: "#80bdff" }
                        }),
                        option: (provided, { isFocused, isSelected }) => ({
                          ...provided,
                          backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                          color: isSelected ? "#212529 !important" : "#212529 !important",
                          cursor: "pointer",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#212529",
                        }),
                      }}
                    />
                    </Form.Group>
                  </Col>
              </Row>
            </Card>
            {Object.keys(filteredCardData).length > 0 ? (
              columns.map((column) => (
                <Col key={column.key} id={column.key} xs={2} className="dayCols1 px-1">
                  <Card>
                    <Card.Header className="dateCol text-white card-header text-center p-2">
                      {column.title} <Badge bg="success">{column.count}</Badge>
                    </Card.Header>
                    <Card.Body className='p-0'>
                      {filteredCardData[column.key] &&
                        Object.keys(filteredCardData[column.key]).map((patientId) => {
                          const patient = filteredCardData[column.key][patientId];
                          return <PatientCard key={patientId} patient={patient} />
                        })}
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <div className="text-center py-4">
                <h5 className="text-muted">No Data Available</h5>
              </div>
            )}
          </Row>
        </>
      ) : (
        <ShimmerUI />
      )}
    </>
  );
};

export default PatientView;