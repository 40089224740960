import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
import routes, { peltaskRoutes, peltaskAdminRoutes, pharmacyAdminRoutes, reportRoutes, Rout4MeRoutes, orderHistoryRoutes } from 'routes/siteMaps';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/21.jpeg';
import Flex from 'components/common/Flex';

const Login = () => {

  //cleanupactivity todo better way to right this
  localStorage.clear();

  if (routes.includes(peltaskRoutes)) {
    const index = routes.indexOf(peltaskRoutes);
    routes.splice(index, 1);
  }

  if (routes.includes(peltaskAdminRoutes)) {
    const index = routes.indexOf(peltaskAdminRoutes);
    routes.splice(index, 1);
  }

  if (routes.includes(pharmacyAdminRoutes)) {
    const index = routes.indexOf(pharmacyAdminRoutes);
    routes.splice(index, 1);
  }

  if(routes.includes(Rout4MeRoutes)){
    const index = routes.indexOf(Rout4MeRoutes);
    routes.splice(index, 1);
  }

  if(routes.includes(reportRoutes)){
    const index = routes.indexOf(reportRoutes);
    routes.splice(index, 1);
  }

  if(routes.includes(orderHistoryRoutes)){
    const index = routes.indexOf(orderHistoryRoutes);
    routes.splice(index, 1);
  }


  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: 'center bottom', style: { aspectRatio: '960/1080' } }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Login</h3>
        {/*<p className="mb-0 fs-10">
          <span className="fw-semibold">New User? </span>
          <Link to={paths.splitRegister}>Create account</Link>
        </p>*/}
      </Flex>
      <LoginForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Login;
