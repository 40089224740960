import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customerDetailsData';
import { Link } from 'react-router-dom';
import IconItem from 'components/common/icon/IconItem';
import { toast } from 'react-toastify';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import paths from 'routes/paths';

const columns = [
    {
        accessorKey: 'hcp',
        header: 'HCP',
        meta: {
            headerProps: { className: 'pe-1 text-900' }
        }
    },
    {
        accessorKey: 'speciality',
        header: 'Speciality',
        meta: {
            headerProps: { className: 'pe-1 text-900' }
        }
    },
    {
        accessorKey: 'address',
        header: 'Address',
        meta: {
            headerProps: { className: 'pe-1 text-900' }
        }
    },
    {
        accessorKey: 'city',
        header: 'City',
        meta: {
            headerProps: { className: 'pe-1 text-900' }
        }
    },
    {
        accessorKey: 'officephone',
        header: 'Office Phone',
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    // {
    //     accessorKey: 'updateby',
    //     header: 'Updated By',
    //     meta: {
    //         headerProps: { className: 'text-900' }
    //     }
    // },
    // {
    //     accessorKey: 'updatedate',
    //     header: 'Updated Date',
    //     meta: {
    //         headerProps: { className: 'text-900' }
    //     }
    // },
    {
        accessorKey: 'Action',
        header: '',
        meta: {
            headerProps: {
                className: 'text-900'
            },
            cellProps: {
                className: 'align-items-center'
            }
        },
        enableSorting: false,
        cell: ({ row: { original } }) => {
            //Todo Temp Setup Till we get single doctor API Call
            return (<Link to={{ pathname: paths.addEditHcp }} state={{
                doc_id: original.doc_id,
                firstname: original.firstname,
                lastname: original.lastname,
                address: original.address,
                city: original.city,
                state: original.state,
                zip: original.zip,
                gender: original.gender,
                phone: original.officephone,
                fax: original.fax,
                speciality: original.speciality
            }} ><IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                /></Link>)
        }
    }
];

const HcpTable = (props) => {

    const [hcpData, setHcpData] = useState([]);
    const [error, setError] = useState(true);
    useEffect(() => {

        async function fetchDoctorsData() {

            let doctorSpeciality = [];
            let tokenStr = localStorage.getItem("accessToken");

            await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctor-speciality", { headers: { 'Authorization': `Bearer ${tokenStr}` } })
                .then((res) => {

                    const results = [];
                    let objDocSpecialityData = res.data.data;

                    //console.log("HSIB " + JSON.stringify(res.data));

                    objDocSpecialityData.forEach((value) => {

                        doctorSpeciality.push({
                            key: value.id,
                            value: value.speciality,
                        });
                    });

                })

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctors", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const doctorsData = data.data

            //console.log("doctorSpeciality " + JSON.stringify(doctorSpeciality));

            if (data.status) {

                doctorsData.forEach((value) => {
                    //Todo change it active column once its available
                    if (value.gender != null) {
                        if (props.active_status == value.gender.toLowerCase()) {
                            results.push({
                                doc_id: value.id,
                                hcp: value.firstname + " " + value.lastname,
                                firstname: value.firstname,
                                lastname: value.lastname,
                                state: value.state,
                                zip: value.zip,
                                speciality: value.doctors_speciality_id != null ? doctorSpeciality.get(value.doctors_speciality_id) : "",
                                gender: value.gender,
                                fax: value.fax,
                                address: value.address,
                                city: value.city,
                                officephone: value.phone
                                // updateby: value.updated_by,
                                // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                            });
                        }
                    }
                });

                if(results.length > 0){
                    setHcpData(results);
                    setError(true)
                }else{
                    setError(false)
                }
            }
            else {
                toast.info("No Doctors found in this list");
            }
        }

        fetchDoctorsData();
    }, []);

    const table = useAdvanceTable({
        data: hcpData,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <AdvanceTableProvider {...table}>
            <Row >
                <Col>
                    <AdvanceTableSearchBox placeholder="Search..." />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                {error ? <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle "//text-center
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                /> : <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '50px' }}>
                <p className="text-danger fw-bold mb-0">There is no data available in the table</p>
            </div>}
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Col>
            </Row>
        </AdvanceTableProvider>
    );
};

export default HcpTable;
