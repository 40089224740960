import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Col, Form, Row, Button } from 'react-bootstrap';

const cycleCheckListItems = [
  {
    id: 1,
    label: 'Must Approve census first',
    icon: 'check'
  },
  {
    id: 2,
    label: 'Please select medchanges',
    icon: 'check'
  },
  { id: 3, label: 'Pick date and time for next delivery', icon: 'check' },
  {
    id: 4,
    label: (
      <>
        For any changes 10 days prior to cycle, please call
        {' '}<strong>
          <a href="tel:781-966-2700" className="text-dark">
            781-966-2700
          </a>
        </strong>
      </>
    ),
    icon: 'check'
  }
];

const CycleChecklist = ({ bodyClassName }) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Cycle Checklist"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body
        as={Flex}
        direction="column"
        alignItems="between"
        className="h-100"
      >
        <ul className="fa-ul m-0 ps-x1">
          {cycleCheckListItems.map(item => (
            <li key={item.id}>
              <h6 className="text-700">
                <FontAwesomeIcon
                  icon={item.icon}
                  className="fa-li text-success"
                />
                {item.label}
              </h6>
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

CycleChecklist.propTypes = {
  bodyClassName: PropTypes.string
};

export default CycleChecklist;