import { Col, Form, Modal, Row, Container } from 'react-bootstrap';
import React from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const PrescriptionInfoModal = ({ show, setShow }) => {
    const location = useLocation();
    const data = location.state || {};

    return (
        <Modal show={show}
            onHide={() => setShow(false)}
            size="md"
            centered>
            <Modal.Header closeButton className="bg-light">
                <Modal.Title as="h5" className="fw-bold text-primary">
                    More Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-white">
                <Container fluid>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Patient Name:</Col>
                        <Col xs={6}>{data.patientName || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Drug:</Col>
                        <Col xs={6}>{data.drug || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Rx Number:</Col>
                        <Col xs={6}>{data.rxid || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Doctor:</Col>
                        <Col xs={6}>{data.doctorName || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Delivered:</Col>
                        <Col xs={6}>{data.delivery_date || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Next Refill:</Col>
                        <Col xs={6}>{data.next_refill_date || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Prescribed Date:</Col>
                        <Col xs={6}>{moment(data.prescribe_date).format('MM/DD/YY') || "N/A"}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Original Refill:</Col>
                        <Col xs={6}>N/A</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Stop Date:</Col>
                        <Col xs={6}>N/A</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6} className="fw-bold">Direction:</Col>
                        <Col xs={6}>{data.direction || "N/A"}</Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default PrescriptionInfoModal;
