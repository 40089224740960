import React, { useState } from 'react';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import paths from 'routes/paths';



const ViewReportTable = (props) => {
    //const [facility] = useState(facilityStaffData);
    const [viewreport, setViewReport] = useState([]);
    const [reportflag, showReportFlag] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [unverifiedCount, setUnverifiedCount] = useState(0);
    const [pendingFetch, setPendingFetch] = useState(false);
    const navigate = useNavigate();
    const columns = [
        // {
        //     accessorKey: 'id',
        //     header: 'ReportID',
        //     meta: {
        //         headerProps: { className: 'pe-1 text-900' }
        //     }
        // },
        {
            accessorKey: 'reporttype',
            header: 'Report Type',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'reportformat',
            header: 'Report Format',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'reportdate',
            header: 'Report For',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'generatedon',
            header: 'Generated on',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'reportlocation',
            header: 'Report Location',
            meta: {
                headerProps: { className: 'text-900' }
            },
            cell: ({ row: { original } }) => {

                return <div>{reportflag && (<a href={original.reportlocation} target="_blank" rel="noreferrer" ><button className="btn btn-primary">
                    📄 Download Report
                </button></a>)}</div>
            }
        }
    ];

    // useEffect(() => {
    //     // Trigger the fetch
    //     getReports();
    // }, [props.report_type, props.trigger]);
    useEffect(() => {
        if (props.report_type && props.trigger) {
            checkUnverifiedOrdersBeforeFetching();
        }
    }, [props.report_type, props.trigger]);
    
    const checkUnverifiedOrdersBeforeFetching = () => {
        const unverified = props?.data?.prescriptions?.filter(p => p.is_verified == 0) || [];
        if (unverified.length > 0) {
            setUnverifiedCount(unverified.length);
            setShowConfirmModal(true);
            setPendingFetch(true);
        } else {
            getReports();
        }
    };

    const handleUnverifiedView = () => {
        navigate(paths.orderHistoryFacility);
    }
    
    async function getReports() {

        console.log("I m in table" + props.report_type);

        let tokenStr = localStorage.getItem("accessToken");
        let inputReportDetails = {
            "report_type": props.report_type,
            "patient_id": localStorage.getItem("patientId"),
            "facility_id": localStorage.getItem("facilityId")
        };

        //console.log("Input Report Details " + JSON.stringify(inputReportDetails));

        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/reports/get-reports", inputReportDetails, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let results = []

        let objData = data.reportData;

        objData.forEach((value) => {
            results.push({
                id: value.id,
                reporttype: value.report_type,
                reportformat: value.report_format,
                reportdate: moment(value.report_date, 'YYYY-MM-DD').format('MMM yyyy'),
                reportlocation: value.report_url,
                generatedon : moment(value.created, 'YYYY-MM-DD').format('MM/DD/YY'),
            });
        });

        //console.log("Report Details " + data.reportData + results.length);
        results.sort((a, b) => b.id - a.id);
        if (results.length == 0) {
            // results.push({
            //     id: "",
            //     reporttype: "",
            //     reportformat: "No Data Found",
            //     reportdate: "",
            //     reportlocation: ""
            // });
            props.setter(0);
            showReportFlag(false);
        }
        else {
            props.setter(0);
            showReportFlag(true);
        }

        setViewReport(results);
    }

    const table = useAdvanceTable({
        data: viewreport,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <>
            {showConfirmModal && (
                <div className="modal d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Unverified Orders</h5>
                            </div>
                            <div className="modal-body">
                                <p>There are {unverifiedCount} unverified orders, do you want to continue?</p>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button className="btn btn-secondary" onClick={() => handleUnverifiedView()}>
                                    Unverified Orders
                                </button>
                                <div>
                                    <button
                                        className="btn btn-primary me-2"
                                        onClick={() => {
                                            setShowConfirmModal(false);
                                            setPendingFetch(false);
                                            getReports();
                                            props.setter(0);
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            props.setter(0);
                                            setShowConfirmModal(false);
                                            setPendingFetch(false);
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        {viewreport.length > 0 ? <AdvanceTableProvider {...table}>
            <AdvanceTable
                headerClassName="bg-200 text-nowrap align-middle text-center"
                rowClassName="text-nowrap align-middle text-center"
                tableProps={{
                    size: 'lg',
                    striped: true,
                    className: 'fs-10 mb-0'
                }}
            />
            <div className="mt-3">
                <AdvanceTableFooter
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
            </div>
        </AdvanceTableProvider> : <p className="text-danger text-center fw-bold">No data available in table</p>}
        </>
    );
};

export default ViewReportTable;
