import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import MedSheetForm from './MedSheetForm';
import CurrentMed from './CurrentMed';
import Treatment from './Treatment';
import LoaForm from './LoaForm';
import HcpOrder from './HcpOrder';
import ViewReportTable from './ViewReportTable';
// import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';
import * as accessControlObj from '../authentication/accessControlMap'


const ReportTypeIndex = ({data}) => {
    const [options, setOptions] = useState([]);
    const [reportType, setReportType] = useState("medsheet");
    const [trigger, setTrigger] = useState(0);
    useEffect(() => {
        populateReportTypes();
    }, []);

    async function populateReportTypes() {

        var localStorageAccessControl = localStorage.getItem("accessControl");

        console.log("accessControlObj " , accessControlObj.ACCESS_CONTROL["medsheets"], localStorageAccessControl.includes(2));

        var reportOptions = [{ key: 'MedSheet', value: 'medsheet' }];
        
        if(localStorageAccessControl.includes(accessControlObj.ACCESS_CONTROL["treament"])) {
            reportOptions.push({ key: 'Treatment', value: 'treatment' });
        }
        if(localStorageAccessControl.includes(accessControlObj.ACCESS_CONTROL["currentmeds"])) {
            reportOptions.push({ key: 'Current Meds', value: 'currentmeds' });
        }
        // if(localStorageAccessControl.includes(accessControlObj.ACCESS_CONTROL["loaform"])) {
        //     reportOptions.push({ key: 'LOA form', value: 'loaform' });
        // }
        if(localStorageAccessControl.includes(accessControlObj.ACCESS_CONTROL["hcporder"])) {
            reportOptions.push({ key: 'HCP Order', value: 'hcporder' });
        }

        setOptions([
            ...reportOptions
        ])

        accessControlObj.ACCESS_CONTROL["medsheets"]

    }

    const callAnotherChildMethod = () => {
        console.log("I m in parent " + reportType);
        setTrigger((trigger) => trigger + 1);
    };

    const handleFieldChange = e => {
        //console.log(e.target.key + "," + e.target.value);
        setReportType(e.target.value);
    };
    //console.log({accessControlObj.ACCESS_CONTROL["medsheets"]});
    return (
        <div>
            <Row className='mb-3 justify-content-between'>
                <Col xs="12">
                    <Form.Group>
                        <Form.Label className="required">Report Type</Form.Label>
                        <Form.Select
                            name="reporttype"
                            onChange={handleFieldChange}
                            size="sm">
                            {options.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.key}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mb-3 justify-content-between'>
                <Col xs="12" className="mb-1 mt-1">
                    {reportType == "medsheet" &&
                        <MedSheetForm onCallParent={callAnotherChildMethod} />
                    }
                    {reportType == "currentmeds" &&
                        <CurrentMed onCallParent={callAnotherChildMethod} />
                    }
                    {reportType == "treatment" &&
                        <Treatment onCallParent={callAnotherChildMethod} data = {data} />
                    }
                    {reportType == "hcporder" &&
                        <HcpOrder onCallParent={callAnotherChildMethod} data = {data}/>
                    }
                </Col>
            </Row>
            <Row className='mb-3 justify-content-between'>
                    {reportType == "medsheet" &&
                        <ViewReportTable report_type={reportType} trigger={trigger} setter = {setTrigger} data = {data} />
                    }
                
            </Row>
        </div>
    );
};

export default ReportTypeIndex;