import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import DemoFileUpload from 'components/orderdetail/DemoFileUpload';

const DrugsNoteModal = ({ show, setShow, docType, patientId, cycleDetailId }) => {
    const [docNote, setDocNote] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState("");

    const handleSave = async (e) => {
        e.preventDefault();
    
        // **Validation Rules**
        if (docType == 3 && !docNote.trim()) {
            setError("Note is required for adding a hold.");
            return;
        }
        if (docType != 3 && !docNote.trim() && (!selectedFile || selectedFile.length === 0)) {
            setError("Either a note or a document is required.");
            return;
        }
    
        setError(""); // Clear error if validation passes
    
        let tokenStr = localStorage.getItem("accessToken");
        let drugsGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-prescriptions`;
    
        // **Build drugsGridData**
        let drugsGridData = [
            {
                "cycle_detail_id": cycleDetailId,
                "change_note": docNote
            }
        ];
    
        let finalDrugsData = {
            "cycle_patient_id": patientId,
            "prescriptions": drugsGridData
        };
        let formData = new FormData();
    
        // Append JSON fields
        formData.append("cycle_patient_id", finalDrugsData.cycle_patient_id);
        formData.append("prescriptions", JSON.stringify(finalDrugsData.prescriptions));
    
        // Append files if available
        if (docType !== 3 && selectedFile && selectedFile.length > 0) {
            selectedFile.forEach((file) => {
                formData.append('change_document', file, file.name);
            });
        }
    
        try {
            const { data } = await axios.post(drugsGridUrl, formData, {
                headers: {
                    "Authorization": `Bearer ${tokenStr}`}
            });
    
            if (data.status) {
                toast.success("Drug details added successfully.");
                setShow(false);
            } else {
                toast.error("Error occurred while saving drug details.");
            }
        } catch (error) {
            toast.error("Failed to save drug details.");
        }
    };
    

    const handleFileChange = (files) => {
        setSelectedFile(files);
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="h5">
                    {docType == 1 && "D/C Document"}
                    {docType == 2 && "Label Change Document"}
                    {docType == 3 && "Add Hold Note"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary">
                <Row>
                    <Col xs="12">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Add Note</Form.Label>
                            <Form.Control
                                name="docnote"
                                value={docNote}
                                onChange={(e) => setDocNote(e.target.value)}
                                as="textarea"
                                rows={3}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* Show validation message */}
                {error && <p className="text-danger">{error}</p>}

                {/* Only show file upload for docType !== 3 */}
                {docType != 3 && (
                    <>
                        <Row>
                            <Col className="d-flex align-items-center my-4">
                                <div className="flex-grow-1 border-top"></div>
                                <Form.Label className="mx-3 text-muted fw-bold">OR</Form.Label>
                                <div className="flex-grow-1 border-top"></div>
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col>
                                <Form.Group controlId="formFile" className="mb-3">
                                    {/* <Form.Label>Document Upload</Form.Label>
                                    <Form.Control type="file" onChange={handleFileChange} /> */}
                                    <DemoFileUpload onFilesChange={handleFileChange} textOfDoc="Required Document" errorFlag={false} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                )}

                <Row>
                    <Col xs="8"></Col>
                    <Col xs="4">
                        <Button color="primary" className="w-100" onClick={handleSave}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

DrugsNoteModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    docType: PropTypes.number.isRequired,
    patientId: PropTypes.string.isRequired,
    cycleDetailId: PropTypes.string.isRequired
};

export default DrugsNoteModal;
