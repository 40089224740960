import React, { useEffect, useState } from 'react';
import KanbanHeader from './KanbanHeader';
import KanbanContainer from './KanbanContainer';
import { useAppContext } from 'providers/AppProvider';
import KanbanProvider from 'providers/KanbanProvider';
import { Card, Col, Form, Nav, Row, Tab, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import axios from "axios";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, fa } from '@fortawesome/free-solid-svg-icons';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { socket } from '../../../socket';
import { useNavigate, useParams } from 'react-router-dom'
import siteMaps from 'routes/siteMaps';
import paths from '../../../routes/paths';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import { Link } from 'react-router-dom';
import ColorStatusLegend from 'components/common/ColorStatusLegend';
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useErrorBoundary } from 'react-error-boundary';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';

const CycleView = () => {

  var tempArray = [];
  var tempCount = 0;
  const navigate = useNavigate();
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();
  const [cycleIsFluid] = useState(isFluid);
  const [cycleIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);
  const [pageNo, setpageNo] = useState(1);
  const [cycleData, setCycleData] = useState([]);
  const [filteredCycleData, setFilteredCycleData] = useState([]);
  const [cycleDataIdwise, setcycleDataIdwise] = useState([]);
  const [cycleDates, setCycleDates] = useState([]);
  const [cycleLoaderids, setCycleLoaderids] = useState([]);
  const { id } = useParams();
  const pageNumber = Number(id) || 1;
  const groupList = JSON.parse(localStorage.getItem("groupsData")) || [];
  const storeList = [
    { key: 'WALTHAM', value: 1 },
    { key: 'FITCHBURG', value: 4 },
    { key: 'BROCKTON', value: 5 }
  ]
  const [groupOptions] = useState([{ key: "Select Group", value: "" }, ...groupList]);
  const [storeOptions] = useState([{ key: "Select Store", value: "" }, ...storeList]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedGroupValue, setSelectedGroupValue] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedStoreValue, setSelectedStoreValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false); 
  const startOfWeek = moment().startOf('isoWeek');
  const dates = [];
  for (let i = 0; i < 4; i++) {
    dates.push(startOfWeek.clone().add(i, 'weeks').format('MMMM DD'));
  }


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = async (e) => {
    const { deliverydate, headerid, checktype } = e.target.dataset;
    const { checked } = e.target;

    document.getElementById("cycleViewCard" + headerid).classList.add('loader');

    //console.log('handleCheckboxChange', "cycleViewCard" + headerid);

    let tokenStr = localStorage.getItem("accessToken");
    let data = { cycle_id: headerid };

    switch (checktype) {
      case 'refill':
        data.refill = checked ? 1 : 0;
        break;
      case 'billed':
        data.billed = checked ? 1 : 0;
        break;
      case 'tcgrx':
        data.tcgrx_fill = checked ? 1 : 0;
        break;
      case 'manual':
        data.manual_fill = checked ? 1 : 0;
        break;
      case 'pv2':
        data.pv2_status = checked ? 1 : 0;
        break;
      case 'manifest':
        data.manifest = checked ? 1 : 0;
        break;
      default:
        break;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle`, data, {
        headers: { 'Authorization': `Bearer ${tokenStr}` }
      }).then((response) => {
        //console.log('response', response);
        if (response.data.status) {
          //console.log('Cycle data updated successfully');
          setTimeout(() => {
            document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
          }, 1000);
        }
      });


      // setcycleDataIdwise(prevData => ({
      //   ...prevData,
      //   [headerid]: {
      //     ...prevData[headerid],
      //     [checktype]: checked ? 1 : 0
      //   }
      // }));
    } catch (error) {
      console.error('Error updating cycle data:', error);
      document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
    }
  };

  const handleAcknowledgeChange = async (e) => {
    const { deliverydate, headerid, checktype } = e.target.dataset;
    const { checked } = e.target;

    document.getElementById("cycleViewCard" + headerid).classList.add('loader');

    //console.log('handleAcknowledgeChange', "cycleViewCard" + headerid, e.target, checked);

    let tokenStr = localStorage.getItem("accessToken");
    let data = { cycle_id: headerid };
    data.need_acknowledgement = 1;

    if (checked) {
      data.need_acknowledgement = 0;
    }

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/cycles/update-need-acknowledgement`, data, {
        headers: { 'Authorization': `Bearer ${tokenStr}` }
      }).then((response) => {
        //console.log('response', response);
        if (response.data.status) {
          //console.log('Cycle data updated successfully');
          setTimeout(() => {
            document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
          }, 1000);
        }
      });
    } catch (error) {
      console.error('Error updating cycle data:', error);
      document.getElementById("cycleViewCard" + headerid).classList.remove('loader');
    }
  };

  useEffect(() => {
    tempArray.push(++tempCount + ' - 1 ' + moment().format('YYYY-MM-DD HH:mm:ss'));
    let tokenStr = localStorage.getItem("accessToken");
    // set pageNo to 1 on initial load
    setpageNo(1);

    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);


    function onConnect() {
      // setIsConnected(true);
    }

    function onDisconnect() {
      console.log('Disconnected from server');
      // setIsConnected(false);
    }

    // function onFooEvent(value) {
    //   console.log('getCycleViewData getCycleViewData ');
    //   socket.emit('clearAllCycles', { tokenStr:tokenStr });
    //   // setFooEvents(previous => [...previous, value]);
    // }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    // socket.on('getCycleViewData', onFooEvent);

    socket.emit('getCycleViewData', { tokenStr: tokenStr, pageNo: 1 });

    socket.on('cycleDataUpdated', async (data) => {
      tempArray.push(++tempCount + ' - 2 ' + moment().format('YYYY-MM-DD HH:mm:ss'));
      // console.log('cycleDataUpdated', data, cycleLoaderids);
      await getCycleViewData(pageNo);
      // console.log('cycleDataUpdated...done', cycleLoaderids);

      // Object.keys(cycleData).map(date => {
      //   cycleData[date].map(item => {
      //     setTimeout(() => {
      //       document.getElementById("cycleViewCard"+item.id).classList.remove('loader');
      //     },500);

      //   });
      // });
    });


    // call getCycleViewData with pageNo
    getCycleViewData(pageNo);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      // socket.off('foo', onFooEvent);

      console.log('Disconnected from server');

      socket.emit('clearAllCycles', { tokenStr: tokenStr });

      setConfig('isFluid', false);
      setConfig('isNavbarVerticalCollapsed', false);
    };
  }, [pageNumber]);

  async function getCycleViewData(pageNo) {
    let tokenStr = localStorage.getItem("accessToken");
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/cycles/cycle-dashboard/" + pageNumber, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
  
      var temp = {};
      var dateCount = {};
      Object.keys(data.cycleView).map(date => {
        if (!dateCount.hasOwnProperty(date)) {
          dateCount[date] = {
            total: 0,
            pv2Count: 0
          };
        }
  
        data.cycleView[date].map(item => {
          dateCount[date].total++;
          if (item.pv2_status != 0) {
            dateCount[date].pv2Count++;
          }
          item.bgColor = 'bg-orange';
          item.fgColorHeader = 'text-white';
          if (item.census == 1) {
            item.bgColor = 'bg-yellow';
            item.fgColorHeader = 'text-dark';
          }
          if (item.refill == 1) {
            item.bgColor = 'bg-darkgreen';
          }
          if (item.billed_status == 1) {
            item.bgColor = 'bg-peach';
          }
          if (item.tcgrx_fill == 1) {//Robot
            item.bgColor = 'bg-blue';
          }
          if (item.manual_fill == 1) {
            item.bgColor = 'bg-purple';
          }
          if (item.pv2_status == 1) {
            item.bgColor = 'bg-green';
          }
          if (item.manifest == 1) {
            item.bgColor = 'bg-brown';
          }
          temp[item.id] = item;
          if (selectedGroup && item.group_id !== selectedGroup) {
            setIsError(true); 
            return;
          }
          if (selectedStore && item.store_id !== selectedStore) {
            setIsError(true); 
            return;
          }
        });
      });

      if (data && data.cycleView) {
        setCycleDates(dateCount);
        setcycleDataIdwise(temp);
        setCycleData(data.cycleView);
        setFilteredCycleData(data.cycleView);
      } else {
        setIsError(true); 
      }
    } catch (error) {
      console.error('Error fetching cycle data:', error);
      // setIsError(true);
      navigate('/error')
    } finally {
      setIsLoading(false);
    }
  }

  const processData = () => {
    for (const date in cycleData) {
      if (cycleData.hasOwnProperty(date)) {
        const arrayForDate = cycleData[date];
        // Process each array item
        arrayForDate.forEach(item => {
          //console.log(`Processing item for date ${date}:`, item);
        });
      }
    }
  };

  useEffect(() => {
    //console.log('tempArray', tempArray);
    if (Object.keys(cycleData).length > 0) {
      // processData();
    }
  }, [cycleData]);

  const handleCardBodyClick = (facilityid) => {
    //console.log("cardbody" + facilityid);
    //navigate(paths.cycleDelivery(facilityid), { state: { facilityId: facilityid } });
    navigate(paths.cycleDelivery(facilityid));
  }

  const handleGroupChange = (e) => {
    const selectedGroupId = e.value;
    setSelectedGroup(selectedGroupId);
    setSelectedGroupValue(e)
    if(selectedGroupId != ''){
      const filteredData = Object.keys(cycleData).reduce((result, date) => {
        const filteredByGroup = cycleData[date].filter(item => item.group_id === selectedGroupId);
        if (filteredByGroup.length > 0) {
          result[date] = filteredByGroup;
        }
        return result;
      }, {});
      if(Object.keys(filteredData).length === 0){
        setIsError(true); 
      }else{
        setIsError(false); 
      }
      setFilteredCycleData(filteredData);
    }else{
      setIsError(false);
      setFilteredCycleData(cycleData);
    }
};
const handleStoreChange = (e) => {
  const selectedGroupId = e.value;
  setSelectedStore(selectedGroupId);
  setSelectedStoreValue(e)
  if(selectedGroupId != ''){
    const filteredData = Object.keys(cycleData).reduce((result, date) => {
      const filteredByGroup = cycleData[date].filter(item => item.store_id === selectedGroupId);
      if (filteredByGroup.length > 0) {
        result[date] = filteredByGroup;
      }
      return result;
    }, {});
    if(Object.keys(filteredData).length === 0){
      setIsError(true); 
    }else{
      setIsError(false); 
    }
    setFilteredCycleData(filteredData);
  }else{
    setIsError(false);
    setFilteredCycleData(cycleData);
  }
};

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Cycle-View', href: paths.cycleView, isActive: true }
  ];
  const cycleView = paths.cycleView

  const handlePageChange = (newPage) => {
    setSelectedGroup('')
    setSelectedGroupValue('')
    if (newPage > 0) {
      navigate(incrementLastNumber(cycleView, newPage - 1))
    }
  };

  function incrementLastNumber(inputString, increment) {
    const parts = inputString.split("/");
    const lastPart = parts[parts.length - 1];

    if (!isNaN(lastPart)) {
      const newLastPart = parseInt(lastPart) + increment;
      parts[parts.length - 1] = newLastPart.toString();
      return parts.join("/");
    } else {
      return inputString;
    }
  }

  return (
    <>
      <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
      <Card className="p-3 pt-3 pb-4 mb-4">
        <div className="filters-section rounded position-relative">
        <Row className='mb-3'>
          <Col md={4} className="d-flex align-items-center">
            {dates.map((date, index) => (
              <React.Fragment key={index}>
                <Link to={{ pathname: incrementLastNumber(cycleView, index) }} className="text-decoration-none fw-bold">
                  {date}
                </Link>
                {index < dates.length - 1 && <span className="mx-2">/</span>}
              </React.Fragment>
            ))}
          </Col>
          <Col md={8}>
            <ColorStatusLegend />
          </Col>
        </Row>
        <Row>
            <Col md={4}>
            <Form.Group>
              <Form.Label>Store</Form.Label>
                <Select
                  name="store"
                  closeMenuOnSelect={true}
                  placeholder='Select store'
                  classNamePrefix="react-select"
                  options={storeOptions}
                  value = {selectedStoreValue}
                  getOptionLabel={option => option.key}
                  getOptionValue={option => option.value}
                  onChange={(e) => handleStoreChange(e)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#ced4da",
                      boxShadow: "none",
                      "&:hover": { borderColor: "#80bdff" },
                    }),
                    option: (provided, { isFocused, isSelected }) => ({
                      ...provided,
                      backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                      color: isSelected ? "#212529 !important" : "#212529 !important",
                      cursor: "pointer",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#212529",
                    }),
                  }}
                />
                </Form.Group>
            </Col>
            <Col md={4}>
            <Form.Group>
              <Form.Label>Group</Form.Label>
                <Select
                  name="group"
                  closeMenuOnSelect={true}
                  placeholder='Select Group'
                  classNamePrefix="react-select"
                  options={groupOptions}
                  value = {selectedGroupValue}
                  getOptionLabel={option => option.key}
                  getOptionValue={option => option.value}
                  onChange={(e) => handleGroupChange(e)}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#ced4da",
                      boxShadow: "none",
                      "&:hover": { borderColor: "#80bdff" },
                    }),
                    option: (provided, { isFocused, isSelected }) => ({
                      ...provided,
                      backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                      color: isSelected ? "#212529 !important" : "#212529 !important",
                      cursor: "pointer",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#212529",
                    }),
                  }}
                />
                </Form.Group>
            </Col>
        </Row>
        </div>
      </Card>
      {isError ? (
        <Alert variant="info" className="text-center fw-bold">
          No Data found.
        </Alert>
      ) : (
        <Row className='m-0'>
        {Object.keys(filteredCycleData).map(date => (
          <Col xs={2} className='dayCols px-1'>
            <Card>
              <Card.Header className='dateCol text-white card-header text-center p-2'>
                {moment(date, 'YYYY-MM-DD').format('MM/DD/YY')} [{cycleDates[date]?.total}]
                <br />
                PV2 [{cycleDates[date]?.pv2Count}]
              </Card.Header>
              <Card.Body className='px-1 cycleViewCardBody' >
                {filteredCycleData[date]?.map((item, index) => (
                  <>
                    {item.need_acknowledgement == 0 && <Card className='cycleViewCard my-2' id={"cycleViewCard" + item.id} name={"cycleViewCard" + item.id} role="button" >
                      <Card.Header className={item.bgColor + ' px-3 py-1 ' + item.fgColorHeader}>
                        {item.facility_name}
                      </Card.Header>
                      <Card.Body className={item.bgColor + '-body text-dark px-3 py-1'}  >

                        <Row>
                          <Col xs="6 " onClick={() => handleCardBodyClick(item.facility_id)}>
                            {item.need_refill > 0 && <OverlayTrigger
                              overlay={
                                <Tooltip style={{ position: 'fixed' }} >
                                  {item.need_refill_drugs.map((drug, index) => (
                                    <div key={index}>{drug} </div>
                                  ))}
                                </Tooltip>
                              }
                            >
                              <u class='refill-tooltip fw-bold'> Need Refill: {item.need_refill}</u>
                            </OverlayTrigger>}

                            {item.need_refill == 0 &&
                              <span> Need Refill: {item.need_refill}</span>
                            }

                          </Col>
                          <Col xs="6" className='text-end'>
                            On Hold: {item.on_hold}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            Robot: DOSIS
                          </Col>
                          <Col xs="6" className='text-end'>
                            {
                              item.total_changed_prescriptions > 0 &&
                              <span>Cycle:&nbsp;
                                {item.updated_changed_prescriptions}
                                &nbsp;out of&nbsp;
                                {item.total_changed_prescriptions}
                              </span>
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            Delivery Time: {item.delivery_time}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            Cycle: {moment(item.cycle_start_date, 'YYYY-MM-DD').format('MM/DD/YY')} to {moment(item.cycle_end_date, 'YYYY-MM-DD').format('MM/DD/YY')}
                          </Col>
                        </Row>
                        <Row className="cycle-chks">
                          <Col xs="auto" className='col1'>
                            <div class="form-check-inline me-0">
                              {item.census == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)}
                              {item.census == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)}
                              &nbsp;Census
                            </div>
                          </Col>

                          <Col xs="auto" className='col2'>

                            <div class="form-check-inline me-0">
                              {/* {item.liaison == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)} Liaison
                              {item.liaison == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)} Liaison */}

                              {/* <FontAwesomeIcon className="text-disable" icon="times-circle" /> Liaison */}
                              <FontAwesomeIcon className="text-success" icon="check-circle" /> Liaison
                            </div>

                          </Col>
                        </Row>
                        <Row className="cycle-chks">
                          <Col xs="auto" className='col1'>
                            <Form.Group class="form-check-inline">
                              {/* <Form.Check
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="refill"
                                    name={"is_refill"+ item.id}
                                    id={"is_refill"+ item.id}
                                    checked={cycleDataIdwise[item.id].refill == 1? true : false}
                                    label='Refill'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                              <input
                                type='checkbox'
                                className='form-check-input'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="refill"
                                name={"is_refill" + item.id}
                                id={"is_refill" + item.id}
                                checked={item.refill}
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                              <label title="" for={"is_refill" + item.id} class="form-check-label">&nbsp;Refill</label>
                            </Form.Group>
                          </Col>
                          <Col xs="auto" className='col2'>
                            <Form.Group class="form-check-inline">

                              <input
                                type='checkbox'
                                className='form-check-input'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="billed"
                                name={"chkBilled" + item.id}
                                id={"chkBilled" + item.id}
                                checked={item.billed_status}
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                              <label title="" for={"chkBilled" + item.id} class="form-check-label">&nbsp;Billed</label>

                              {/* <Form.Check                                
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="billed"
                                    name={"chkBilled"+ item.id}
                                    id={"chkBilled"+ item.id}
                                    value={item.billed_status}
                                    checked={cycleDataIdwise[item.id].billed_status == 1? true : false}
                                    label='Billed'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                            </Form.Group>
                          </Col>

                          <Col xs="auto" >

                            <Form.Group class="form-check-inline">
                              <input
                                type='checkbox'
                                className='form-check-input'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="tcgrx"
                                name={"chkTcgrx" + item.id}
                                id={"chkTcgrx" + item.id}
                                checked={item.tcgrx_fill}
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                              <label title="" for={"chkTcgrx" + item.id} class="form-check-label">&nbsp;Robot</label>



                              {/* <Form.Check                                
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="tcgrx"
                                    name={"chkTCGRx+"+ item.id}
                                    id={"chkTCGRx+"+ item.id}
                                    value={item.tcgrx_fill}
                                    checked={cycleDataIdwise[item.id].tcgrx_fill == 1? true : false}
                                    label=' Robot'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                            </Form.Group>

                          </Col>

                          <Col xs="auto" className='col1'>



                            <Form.Group class="form-check-inline">
                              <Form.Check
                                type='checkbox'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="manual"
                                name={"chkManual+" + item.id}
                                id={"chkManual+" + item.id}
                                value={item.manual_fill}
                                checked={item.manual_fill}
                                label=' Manual'
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                            </Form.Group>


                          </Col>

                          <Col xs="auto" className='col2' >


                            <Form.Group class="form-check-inline">
                              <Form.Check
                                type='checkbox'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="pv2"
                                name={"chkPV2+" + item.id}
                                id={"chkPV2+" + item.id}
                                value={item.pv2_status}
                                checked={item.pv2_status}
                                label="PV2"
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                            </Form.Group>

                          </Col>

                          <Col xs="auto"  >

                            <Form.Group class="form-check-inline">
                              <Form.Check
                                type='checkbox'
                                data-deliverydate={date}
                                data-headerid={item.id}
                                data-checktype="manifest"
                                name={"chkManifest+" + item.id}
                                id={"chkManifest+" + item.id}
                                value={item.manifest}
                                checked={item.manifest}
                                label=' Manifest'
                                onChange={(e) => {
                                  handleCheckboxChange(e);
                                }}
                              />
                            </Form.Group>


                          </Col>

                        </Row>

                      </Card.Body>
                    </Card>}

                    <div key={index}>
                      {/* {JSON.stringify(item)} Render your item properties here */}
                    </div>
                  </>
                ))}
              </Card.Body>
            </Card>
          </Col>
        ))}
        <Col xs={2} className='px-1'>
          <Card className='h-100'>
            <Card.Header className='dateCol text-white card-header text-center p-2'>
              Updates
              <br />
              &nbsp;
            </Card.Header>
            <Card.Body className='p-1'>

              {Object.keys(filteredCycleData).map(date => (
                <>
                  {filteredCycleData[date].map((item, index) => (
                    <>
                      {item.need_acknowledgement == 1 && <Card className='cycleViewCard my-2' id={"cycleViewCard" + item.id}>
                        <Card.Header className={item.bgColor + ' px-3 py-1 ' + item.fgColorHeader}>
                          {item.facility_name}
                        </Card.Header>
                        <Card.Body className={item.bgColor + '-body text-dark px-3 py-1'} >

                          <Row>
                            <Col xs="6 ">
                              {item.need_refill > 0 && <OverlayTrigger
                                overlay={
                                  <Tooltip style={{ position: 'fixed' }} >
                                    {item.need_refill_drugs.map((drug, index) => (
                                      <div key={index}>{drug} </div>
                                    ))}
                                  </Tooltip>
                                }
                              >
                                <u class='refill-tooltip fw-bold'> Need Refill: {item.need_refill}</u>
                              </OverlayTrigger>}

                              {item.need_refill == 0 &&
                                <span> Need Refill: {item.need_refill}</span>
                              }

                            </Col>
                            <Col xs="6" className='text-end'>
                              On Hold: {item.on_hold}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6">
                              Robot: DOSIS
                            </Col>
                            <Col xs="6" className='text-end'>
                              {
                                item.total_changed_prescriptions > 0 &&
                                <span>Cycle:&nbsp;
                                  {item.updated_changed_prescriptions}
                                  &nbsp;out of&nbsp;
                                  {item.total_changed_prescriptions}
                                </span>
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              Delivery Time: {item.delivery_time}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12">
                              Cycle: {moment(item.cycle_start_date, 'YYYY-MM-DD').format('MM/DD/YY')} to {moment(item.cycle_end_date, 'YYYY-MM-DD').format('MM/DD/YY')}
                            </Col>
                          </Row>
                          <Row className="cycle-chks">
                            <Col xs="auto" className='col1'>
                              <div class="form-check-inline me-0">
                                {item.census == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)}
                                {item.census == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)}
                                &nbsp;Census
                              </div>
                            </Col>

                            <Col xs="auto" className='col2'>

                              <div class="form-check-inline me-0">
                                {/* {item.liaison == 0 && (<FontAwesomeIcon className="text-disable" icon="times-circle" />)} Liaison
                              {item.liaison == 1 && (<FontAwesomeIcon className="text-success" icon="check-circle" />)} Liaison */}

                                {/* <FontAwesomeIcon className="text-disable" icon="times-circle" /> Liaison */}
                                <FontAwesomeIcon className="text-success" icon="check-circle" /> Liaison
                              </div>

                            </Col>
                          </Row>
                          <Row className="cycle-chks">
                            <Col xs="auto" className=''>
                              <Form.Group class="form-check-inline">
                                {/* <Form.Check
                                    type='checkbox'
                                    data-deliverydate={date}
                                    data-headerid={item.id}
                                    data-checktype="refill"
                                    name={"is_refill"+ item.id}
                                    id={"is_refill"+ item.id}
                                    checked={cycleDataIdwise[item.id].refill == 1? true : false}
                                    label='Refill'
                                    onChange={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                /> */}
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  data-deliverydate={date}
                                  data-headerid={item.id}
                                  data-checktype="acknowledged"
                                  name={"is_acknowledged" + item.id}
                                  id={"is_acknowledged" + item.id}
                                  onChange={(e) => {
                                    handleAcknowledgeChange(e);
                                  }}
                                />
                                <label title="" for={"is_acknowledged" + item.id} class="form-check-label">&nbsp;Acknowledged</label>
                              </Form.Group>
                            </Col>









                          </Row>

                        </Card.Body>
                      </Card>}

                    </>
                  ))}
                </>
              ))}




            </Card.Body>
          </Card>
        </Col>
      </Row>
      )}
      <div className="d-flex justify-content-between mt-4">
        <Button
          variant="primary"
          disabled={pageNumber === 1}
          onClick={() => handlePageChange(pageNumber - 1)}
        >
          Previous Page
        </Button>
        <Button
          variant="primary"
          disabled={pageNumber === 4}
          onClick={() => handlePageChange(pageNumber + 1)}
        >
          Next Page
        </Button>
      </div>
    </>
  );
};

export default CycleView;