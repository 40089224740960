import React from 'react';
import { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import axios from "axios";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FacilityBasicInformation from './FacilityBasicInformation';
import { useNavigate, useLocation } from "react-router-dom";
import FacilityFooter from './FacilityFooter';
import FacilityMedsInfo from './FacilityMedsInfo';
import FacilityWorkInfo from './FacilityWorkInfo';
import { toast } from 'react-toastify';
import paths, { rootPaths } from '../../../../routes/paths';
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';
import MaskedInput from 'react-text-mask';
import FormValidations from 'components/FormValidations/FormValidations';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import FormatPhoneNumber from 'components/common/FormatPhoneNumber';

const AddFacilityStaff = (props) => {

  let initialFormData = {
    fname: '',
    lname: '',
    mobile: '',
    zip: '',
    city: null,
    state: '',
    address: '',
    address2: '',
    email: '',
    designation: '',
    fax: '',
    providername: '',
    username: '',
    role: '',
    facility: [],
    password: '',
    cnfpassword: '',
    is_active : 0,
    group: localStorage.getItem("groupId")
  };
  const locationData = props.dataObj
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [stateoptions, setStateOptions] = useState([]);
  const [roleoptions, setRoleOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [programDisableGroup, setProgramDisabledGroup] = useState(false);
  const [programDisableFacility, setProgramDisabledFacility] = useState(false);
  const [facilityvalue, setFacilityValue] = useState("");
  const [programOptions, setProgramOptions] = useState([]);
  const [usernameAvailable, setUsernameAvailable] = useState(null);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(null);
  const [errors, setErrors] = useState({});
  const currentRoleId = JSON.parse(localStorage.getItem("roleId"));
  let results = JSON.parse(localStorage.getItem("facilitiesData"));
  let groupResults = JSON.parse(localStorage.getItem("groupsData")) ? JSON.parse(localStorage.getItem("groupsData")) : [];

  useEffect(() => {

    
    function fetchProviderData() {
   
      
      setStateOptions([
        { key: 'Select a state', value: '' },
        ...mapYourMedsConstant.STATE_VALUES
      ])

      setGroupOptions([
        { key: 'Select a group', value: '' },
        ...groupResults
      ])

      const allRoles = [
        { key: "Pharmacy Admin", value: 2 },
        { key: "Pharmacy Staff", value: 3 },
        { key: "Group Admin", value: 4 },
        { key: "Facility Staff", value: 5 }
      ];

      const roleSlices = {
        2: allRoles, // All roles for Pharmacy Admin
        3: allRoles.slice(1), // Exclude Pharmacy Admin
        4: allRoles.slice(2)  // Exclude Pharmacy Admin and Pharmacy Staff
      };

      const roleList = roleSlices[currentRoleId] || allRoles;

      setRoleOptions([
        { key: 'Select a Role', value: '' },
        ...roleList
      ]);
      if (locationData != null) {
        let value = locationData;
        setFormData({
          fname: value.first_name,
          lname: value.last_name,
          mobile: FormatPhoneNumber(value.phone) ,
          zip: value.zip,
          city: value.city,
          state: value.state,
          address: value.address,
          address2: value.address2,
          email: value.email,
          designation: '',
          fax: value.fax,
          providername: '',
          username: value.username,
          role: value.role_id,
          facility: value.facility_id,
          password: value.password,
          cnfpassword: value.password,
          group: value.group_id,
          is_active : value.is_active
        });
      }

      const initialGroupId = locationData?.group_id || null;
      let filteredPrograms = [];
      if (initialGroupId) {
        filteredPrograms = results.filter(
          (program) => program.group_id === initialGroupId
        );
      }
      const programVar = filteredPrograms.length > 0 ? filteredPrograms : results
      setProgramOptions(programVar);

      if (locationData?.facilities && programVar.length > 0) {
        // Filter programOptions to match facility IDs from locationData
        const preselectedFacilities = programVar.filter(option => 
            locationData.facilities.includes(option.value)
        );
        setSelectedFacilities(Array.from(preselectedFacilities, option => option.value));
    }
    }
    // Trigger the fetch
    fetchProviderData();
    handleRoleFieldChange({ target: { value: locationData?.role_id } }, true);
  }, []);

  useEffect(() => {
    if (formData.username.trim() === '') {
      setUsernameAvailable(null);
      return;
    }
    const timeout = setTimeout(() => {
      checkUsernameAvailability(formData.username);
    }, 500);
    return () => clearTimeout(timeout);
  }, [formData.username]);

  useEffect(() => {
    if (formData.email.trim() === '') {
      setEmailAvailable(null);
      return;
    }
    const timeout = setTimeout(() => checkEmailAvailability(formData.email), 500);
    return () => clearTimeout(timeout);
  }, [formData.email]);


  const checkEmailAvailability = async (email) => {
    if (!email.trim()) {
      setEmailAvailable(null);
      setErrors((prev) => ({ ...prev, email: 'Email is required.' }));
      return;
    }

    setCheckingEmail(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/check-email`,
        { email }
      );
      setEmailAvailable(response.data.status);
      setErrors((prev) => ({
        ...prev,
        email: (response.data.status || locationData != null) ? '' : 'This email is already registered.'
      }));
    } catch (error) {
      setEmailAvailable(null);
      setErrors((prev) => ({ ...prev, email: 'Error checking email. Try again.' }));
    }
    setCheckingEmail(false);
  };

  const checkUsernameAvailability = async (username) => {
    if (!username.trim()) {
      setUsernameAvailable(null);
      setErrors((prev) => ({ ...prev, username: 'Username is required.' }));
      return;
    }

    setCheckingUsername(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/check-username`,
        { username }
      );
      setUsernameAvailable(response.data.status);
      setErrors((prev) => ({
        ...prev,
        username: (response.data.status || locationData != null) ? '' : 'This username is already taken.'
      }));
    } catch (error) {
      setUsernameAvailable(null);
      setErrors((prev) => ({ ...prev, username: 'Error checking username. Try again.' }));
    }
    setCheckingUsername(false);
  };

  const handleGroupChange = (event) => {
    const selectedGroupId = parseInt(event.target.value);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    const filteredPrograms = results.filter((program) => program.group_id === selectedGroupId);
    setProgramOptions(filteredPrograms);
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!validate()) return;
    let facilityStaffData = {
        "user_id": locationData?.id || '',
        "first_name": formData.fname,
        "last_name": formData.lname,
        "email": formData.email,
        "phone": formData.mobile?.replace(/\D/g, '') || '',
        "city": formData.city || '',
        "role_id": formData.role || '',
        "group_id": formData.group || (localStorage.getItem("groupId") || ""),
        "state": formData.state,
        "zip": formData.zip,
        "address": formData.address,
        "address2": formData.address2,
        "username": formData.username,
        "password": formData.password,
        // "facilities": facilityvalue || [],
        "facilities": selectedFacilities || [],
        "is_active" : formData.is_active == true ? 1 : 0
      }

    let tokenStr = localStorage.getItem("accessToken");

    console.log("userData " + JSON.stringify(facilityStaffData));

    let facilityStaffUrl = locationData != null ? `${process.env.REACT_APP_SERVER_URL}/users/update` : `${process.env.REACT_APP_SERVER_URL}/users/create`;

    const { data } = await axios.post(facilityStaffUrl, facilityStaffData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

    console.log("data " + JSON.stringify(data));

    if (data.status) {
      toast.success(data.message);
      navigate(paths.viewFacility);
    }
    else {
      toast.error(data.message);
    }

    // fetch(`${process.env.REACT_APP_SERVER_URL}` + "/users/create",
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenStr}` },
    //     body: userData
    //   })
    //   .then(response => response.json())
    //   .then(function (data) {
    //     const items = data;
    //     console.log("AddFacility " + items.message);
    //     if (items.message == "User created successfully") {
    //       toast.success('User added successfully', {
    //         theme: 'colored'
    //       });
    //       navigate(paths.viewFacility);
    //     }
    //     else {
    //       toast.error('Unable to submit User', {
    //         theme: 'colored'
    //       });
    //     }
    //   })

  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    if (name === 'username' && value.trim() === '') {
      setUsernameAvailable(null);
    }
    if (name === 'email' && value.trim() === '') {
      setEmailAvailable(null);
    }
  };

  const handleRoleFieldChange = (e, flag) => {
    //console.log("tager " + e.target.value)
    if (e.target.value == "5") {
      setProgramDisabledFacility(false);
      setProgramDisabledGroup(false);
    } else if (e.target.value == "4") {
      setProgramDisabledFacility(true);
      setProgramDisabledGroup(false);
    } else if (e.target.value != undefined) {
      setProgramDisabledFacility(true);
      setProgramDisabledGroup(true);
    } else if (locationData == null) {
      setProgramDisabledFacility(true);
      setProgramDisabledGroup(true);
    }

      if(Number(e.target.value) < 5){
        setSelectedFacilities([])
      }

    if (!flag) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleFacilityFieldChange = (e) => {
    //console.log("Target " + Array.from(e.target.selectedOptions, option => option.value));
    // setFacilityValue(Array.from(e.target.selectedOptions, option => option.value));
    setSelectedFacilities(Array.from(e.target.selectedOptions, option => option.value));
  };

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Add-User-Profile', href: paths.addFacility, isActive: true }
  ];

  const validate = () => {
    const newErrors = {};
    if (!formData.fname) {
      newErrors.fname = 'First Name is required.'
    } else if (!FormValidations(formData.fname, 1)) {
      newErrors.fname = 'Only letters are allowed';
    }
    if (!formData.lname) {
      newErrors.lname = 'Last Name is required.';
    } else if (!FormValidations(formData.lname, 1)) {
      newErrors.lname = 'Only letters are allowed';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required.';
    } else if (!FormValidations(formData.password, 7, 6)) {
      newErrors.password = 'Password should be atleast 6 characters';
    }
    if (!formData.cnfpassword) {
      newErrors.cnfpassword = 'Confirm Password is required.';
    } else if (formData.cnfpassword !== formData.password) {
      newErrors.cnfpassword = 'Passwrord must Match';
    }
    if (!formData.role) newErrors.role = 'Role is required.';
    if (!formData.zip) {
      newErrors.zip = 'Zip code is required.';
    } else if (!FormValidations(formData.zip, 3)) {
      newErrors.zip = 'Invalid ZIP code format.';
    }
    if (formData.mobile && !FormValidations(formData.mobile, 6, 10)) {
      newErrors.mobile = 'Invalid mobile number format.';
    }
    if (formData.city && !FormValidations(formData.city, 1)) {
      newErrors.city = 'City should be in letters';
    }
    if (!formData.username) {
      newErrors.username = 'Username is required.';
    } else if (!FormValidations(formData.username, 2)) {
      newErrors.username = 'Invalid username format.';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!FormValidations(formData.email, 5)) {
      newErrors.email = 'Invalid email format.';
    }

    if (formData.role == "5") { 
      if (!formData.group) {
        newErrors.group = 'Group is required for Facility Staff.';
      }
      if (!selectedFacilities || selectedFacilities.length === 0) {
        newErrors.facility = 'At least one Facility must be selected for Facility Staff.';
      }
    } else if (formData.role == "4") {
      if (!formData.group) {
        newErrors.group = 'Group is required for Facility Admin.';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      {locationData ? [] : <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />}
      <Form onSubmit={onSubmit} name="facilityform">
        {locationData != null ?
          <Row className="g-3">
            <Col>
              <Form.Check
                type='switch'
                name="is_active"
                id='is_active'
                className="fs-5"
                checked={
                  formData.is_active
                }
                onChange={e =>
                  setFormData({
                    ...formData,
                    is_active: e.target.checked
                  })
                }
                label='Is Active'
              />
            </Col>
            <Col lg={12}>
              <div className="mb-2 border" style={{ borderColor: '#f2f2f3' }}>
                <Card.Header as="h6">
                  Personal Information
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">First Name</Form.Label>
                        <Form.Control
                          placeholder='First Name'
                          value={formData.fname}
                          name="fname"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.fname}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fname}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">Last Name</Form.Label>
                        <Form.Control
                          placeholder='Last Name'
                          value={formData.lname}
                          name="lname"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.lname}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lname}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Mobile</Form.Label>
                        <MaskedInput
                          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          className={`form-control form-control-sm ${errors.mobile ? 'is-invalid' : ''
                            }`}
                          guide={false}
                          placeholder="(XXX) XXX-XXXX"
                          onChange={handleFieldChange}
                          size="sm"
                          name="mobile"
                          value={formData.mobile}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                          {errors.mobile}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className='required'>Email</Form.Label>
                        <Form.Control
                          placeholder='Email'
                          value={formData.email}
                          name="email"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.email}
                          disabled={locationData != null}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          placeholder='Address'
                          value={formData.address}
                          name="address"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Address2</Form.Label>
                        <Form.Control
                          placeholder='Address2'
                          value={formData.address2}
                          name="address2"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          placeholder='City'
                          Value={formData.city}
                          name="city"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          name="state"
                          onChange={handleRoleFieldChange}
                          size="sm"
                        //disabled={watch("role") == 4 ? true : false}
                        >
                          {stateoptions.map((stateoption) => {
                            let isStateSelected = false;
                            if (locationData != null) {
                              if (stateoption.value == formData.state) {
                                isStateSelected = "selected";
                              }
                            }
                            return (
                              <option key={stateoption.value} value={stateoption.value} selected={isStateSelected}>
                                {stateoption.key}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label className="required">Zip</Form.Label>
                        <Form.Control
                          placeholder="Zip"
                          value={formData.zip}
                          name="zip"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.zip}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.zip}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="gx-2 gy-3 mb-2">

                  </Row>
                </Card.Body>
              </div>
            </Col>
            <Col xs={12}>
              <div className="mb-2 border" style={{ borderColor: '#f2f2f3' }}>
                <Card.Header as="h6">
                  Access Information
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="12">
                      <Form.Group>
                        <Form.Label className="required">User Name</Form.Label>
                        <Form.Control
                          placeholder='User Name'
                          value={formData.username}
                          name="username"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          disabled={locationData != null}
                          isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-3 gy-3 mb-2">
                    <Col md={6} className="d-flex flex-column h-100">
                      <Form.Group className='mb-3 flex-grow-1'>
                        <Form.Label className="required">Role</Form.Label>
                        <Form.Select
                          name="role"
                          onChange={handleRoleFieldChange}
                          size="sm"
                          isInvalid={!!errors.role}
                        >
                          {roleoptions.map((roleoption) => {
                            let isRoleSelected = false;
                            if (locationData != null) {
                              if (roleoption.value == formData.role) {
                                isRoleSelected = "selected";
                              }
                            }
                            return (
                              <option key={roleoption.value} value={roleoption.value} selected={isRoleSelected}>
                                {roleoption.key}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.role}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {JSON.parse(localStorage.getItem("roleId")) < 4 ? <Form.Group className="flex-grow-1">
                        <Form.Label>Group</Form.Label>
                        <Form.Select
                          name="group"
                          onChange={handleGroupChange}
                          size="sm"
                          disabled={programDisableGroup}
                          isInvalid={!!errors.group}
                        >
                          {groupOptions.map((groupoption) => {
                            let isRoleSelected = false;
                            if (locationData != null) {
                              if (groupoption.value == formData.group) {
                                isRoleSelected = "selected";
                              }
                            }
                            return (<option key={groupoption.value} value={groupoption.value} selected={isRoleSelected}>
                              {groupoption.key}
                            </option>)
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.group}</Form.Control.Feedback>
                      </Form.Group> : []}
                    </Col>
                    <Col md="6" className="d-flex align-items-stretch">
                      <Form.Group className="w-100">
                        <Form.Label>Program (Press Ctrl to select multiple facilities)</Form.Label>
                        <Form.Select
                          multiple
                          disabled={programDisableFacility}
                          name="facility"
                          onChange={handleFacilityFieldChange}
                          value={selectedFacilities}
                          size="sm"
                          isInvalid={!!errors.facility}
                          style={{ height: '105px' }}
                        >
                          {programOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.facility}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">Password</Form.Label>
                        <Form.Control
                          placeholder='Password'
                          value={formData.password}
                          name="password"
                          onChange={handleFieldChange}
                          type="password"
                          size="sm"
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">Confirm Password</Form.Label>
                        <Form.Control
                          placeholder='Confirm Password'
                          value={formData.cnfpassword}
                          name="cnfpassword"
                          onChange={handleFieldChange}
                          type="password"
                          size="sm"
                          isInvalid={!!errors.cnfpassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cnfpassword}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="flex-between-center">
                <Col></Col>
                <Col xs="auto" >
                  <Button variant="primary"
                    type="submit"
                  >
                    {locationData != null ? mapYourMedsConstant.UPDATE_ACTION : mapYourMedsConstant.SAVE_ACTION}
                  </Button>
                </Col>
                <Col>
                  <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
              </Row>
            </Col>
          </Row>
          :
          <Card>
            <Row className="g-3 p-3">
            <Col lg={12}>
              <div className="mb-2 border" style={{ borderColor: '#f2f2f3' }}>
                <Card.Header as="h6">
                  Personal Information
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">First Name</Form.Label>
                        <Form.Control
                          placeholder='First Name'
                          value={formData.fname}
                          name="fname"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.fname}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fname}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">Last Name</Form.Label>
                        <Form.Control
                          placeholder='Last Name'
                          value={formData.lname}
                          name="lname"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.lname}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lname}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Mobile</Form.Label>
                        <MaskedInput
                          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          className={`form-control form-control-sm ${errors.mobile ? 'is-invalid' : ''
                            }`}
                          guide={false}
                          placeholder="(XXX) XXX-XXXX"
                          onChange={handleFieldChange}
                          size="sm"
                          name="mobile"
                          value={formData.mobile}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                          {errors.mobile}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group>
                          <Form.Label className='required'>Email</Form.Label>
                          <Form.Control
                            name="email"
                            value={formData.email}
                            onChange={handleFieldChange}
                            isInvalid={!!errors.email || emailAvailable === false}
                          />
                          {checkingEmail && <small className="text-primary">Checking...</small>}
                          {!checkingEmail && emailAvailable === true && <small className="text-success">Email is available ✅</small>}
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          placeholder='Address'
                          value={formData.address}
                          name="address"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>Address2</Form.Label>
                        <Form.Control
                          placeholder='Address2'
                          value={formData.address2}
                          name="address2"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3 mb-2">
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          placeholder='City'
                          Value={formData.city}
                          name="city"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Select
                          name="state"
                          onChange={handleRoleFieldChange}
                          size="sm"
                        //disabled={watch("role") == 4 ? true : false}
                        >
                          {stateoptions.map((stateoption) => {
                            let isStateSelected = false;
                            if (locationData != null) {
                              if (stateoption.value == formData.state) {
                                isStateSelected = "selected";
                              }
                            }
                            return (
                              <option key={stateoption.value} value={stateoption.value} selected={isStateSelected}>
                                {stateoption.key}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label className="required">Zip</Form.Label>
                        <Form.Control
                          placeholder="Zip"
                          value={formData.zip}
                          name="zip"
                          onChange={handleFieldChange}
                          type="text"
                          size="sm"
                          isInvalid={!!errors.zip}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.zip}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="gx-2 gy-3 mb-2">

                  </Row>
                </Card.Body>
              </div>
            </Col>
            <Col xs={12}>
              <div className="mb-2 border" style={{ borderColor: '#f2f2f3' }}>
                <Card.Header as="h6">
                  Access Information
                </Card.Header>
                <Card.Body>
                  <Row className="gx-2 gy-3 mb-2">
                      <Col md="12">
                        <Form.Group>
                          <Form.Label className="required">User Name</Form.Label>
                          <Form.Control
                            name="username"
                            value={formData.username}
                            onChange={handleFieldChange}
                            isInvalid={!!errors.username || usernameAvailable === false}
                          />
                          {checkingUsername && <small className="text-primary">Checking...</small>}
                          {usernameAvailable && <small className="text-success">Username is available ✅</small>}
                          <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="gx-3 gy-3 mb-2">
                    <Col md={6} className="d-flex flex-column h-100">
                      <Form.Group className='mb-3 flex-grow-1'>
                        <Form.Label className="required">Role</Form.Label>
                        <Form.Select
                          name="role"
                          onChange={handleRoleFieldChange}
                          size="sm"
                          isInvalid={!!errors.role}
                        >
                          {roleoptions.map((roleoption) => {
                            let isRoleSelected = false;
                            if (locationData != null) {
                              if (roleoption.value == formData.role) {
                                isRoleSelected = "selected";
                              }
                            }
                            return (
                              <option key={roleoption.value} value={roleoption.value} selected={isRoleSelected}>
                                {roleoption.key}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.role}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {JSON.parse(localStorage.getItem("roleId")) < 4 ? <Form.Group className="flex-grow-1">
                        <Form.Label>Group</Form.Label>
                        <Form.Select
                          name="group"
                          onChange={handleGroupChange}
                          size="sm"
                          disabled={programDisableGroup}
                          isInvalid={!!errors.group}
                        >
                          {groupOptions.map((groupoption) => {
                            let isRoleSelected = false;
                            if (locationData != null) {
                              if (groupoption.value == formData.group) {
                                isRoleSelected = "selected";
                              }
                            }
                            return (<option key={groupoption.value} value={groupoption.value} selected={isRoleSelected}>
                              {groupoption.key}
                            </option>)
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.group}</Form.Control.Feedback>
                      </Form.Group> : []}
                    </Col>
                    <Col md="6" className="d-flex align-items-stretch">
                      <Form.Group className="w-100">
                        <Form.Label>Program (Press Ctrl to select multiple facilities)</Form.Label>
                        <Form.Select
                          multiple
                          disabled={programDisableFacility}
                          name="facility"
                          value={selectedFacilities}
                          onChange={handleFacilityFieldChange}
                          size="sm"
                          isInvalid={!!errors.facility}
                          style={{ height: '105px' }}
                        >
                          {programOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.facility}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="gx-2 gy-3">
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">Password</Form.Label>
                        <Form.Control
                          placeholder='Password'
                          value={formData.password}
                          name="password"
                          onChange={handleFieldChange}
                          type="password"
                          size="sm"
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label className="required">Confirm Password</Form.Label>
                        <Form.Control
                          placeholder='Confirm Password'
                          value={formData.cnfpassword}
                          name="cnfpassword"
                          onChange={handleFieldChange}
                          type="password"
                          size="sm"
                          isInvalid={!!errors.cnfpassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.cnfpassword}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="flex-between-center">
                <Col></Col>
                <Col xs="auto" >
                  <Button variant="primary"
                    type="submit"
                  >
                    {locationData != null ? mapYourMedsConstant.UPDATE_ACTION : mapYourMedsConstant.SAVE_ACTION}
                  </Button>
                </Col>
                <Col>
                  <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
              </Row>
            </Col>
          </Row>
          </Card>}
      </Form>
    </>
  );
};

export default AddFacilityStaff;
