import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import avatar from 'assets/img/team/avatar.png';
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const ViewFacilityStaffProfileHeader = (props) => {
const data = props.data
const groupData = JSON.parse(localStorage.getItem("groupsData"))?.filter((value) => value.value == data.group_id)

    return (
        <Card>
            <Card.Header className="pt-3 mb-2">
                <h4>
                    {data?.username}
                </h4>
            </Card.Header>
            <Card.Body className="py-0">
                <Row className='mb-3'>
                    <Col lg={4} className="ps-2 ps-lg-3 me-0 ms-auto">
                        {data.facility_id ? <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Facility Name:  </h6>
                            </div>
                        </Flex> : []}
                        {groupData ? <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Group Name: {groupData[0]?.key} </h6>
                            </div>
                        </Flex> : []}
                    </Col>

                    <Row className="col-12 mx-0 w-100 avatar-profile-sec">
                        <Avatar
                            size="auto"
                            className="avatar-profile col-1 mx-0 px-0"
                            src={avatar}
                            mediaClass="img-thumbnail shadow-sm"
                        />
                    </Row>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ViewFacilityStaffProfileHeader