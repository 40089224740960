import React from 'react';

import { Card, Col, Form, Row, Button, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import paths from 'routes/paths';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import { toast } from 'react-toastify';
import MedicalRecordModal from './MedicalRecordModal';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';

const AddEditMedicalRecord = () => {
    const location = useLocation();
    const locationData = location?.state;
    const dataObj = locationData?.dataObj;
    const flagsObj = locationData?.flagsObj;
    let initialFormData = {
        facilityname: '',
        individual: '',
        category: '',
        description: flagsObj?.description || '',
        callhcp: '',
        splins: '',
        orderid: '',
        hcp: '',
        startdate: '',
        stopdate: '',
        frequency: '',
        rsn: '',
        medgrp: '',
        is_medsheet: Number(flagsObj?.mar_flag) == 1 ? true : false,
        is_treatment: Number(flagsObj?.tar_flag) == 1 ? true : false,
        is_physpres: Number(flagsObj?.po_flag) == 1 ? true : false,
        is_prnmedication: false,
        is_active: true,
        time1: '0',
        time2: '0',
        time3: '0',
        time4: '0',
        time5: '0',
        time6: '0',
        time7: '0',
        time8: '0'
    };

    const [formData, setFormData] = useState(initialFormData);

    const [checked, setChecked] = useState(false);
    const [edittimeclicked, setEditTimeClicked] = useState(false);

    const [startdate, setStartDate] = useState(null);
    const [stopdate, setStopDate] = useState(null);

    const [time1options, setTime1] = useState([]);
    const [time2options, setTime2] = useState([]);
    const [time3options, setTime3] = useState([]);
    const [time4options, setTime4] = useState([]);
    const [time5options, setTime5] = useState([]);
    const [time6options, setTime6] = useState([]);
    const [time7options, setTime7] = useState([]);
    const [time8options, setTime8] = useState([]);

    const [facilityoptions, setFacilityOptions] = useState([]);
    const [patientoptions, setPatientOptions] = useState([]);
    const [hcpoptions, setHcpOptions] = useState([]);
    const [splInsOptions, setSplinsOptions] = React.useState([]);
    const [freqOptions, setFreqOptions] = React.useState([]);
    const [presOptions, setPresOptions] = React.useState([]);
    const [medgroupOptions, setMedGroupOptions] = React.useState([]);
    const [medcategoryOptions, setMedCategoryOptions] = React.useState([]);
    const [errors , setErrors] = useState({});

     
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");
        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        //console.log("locationData.medrecord_id " + locationData.medrecord_id);

        setFacilityOptions([
            { key: 'Select a facility', value: '' },
            ...results
        ]);


        let getAllItems = locationData.medrecord_id != null ?
            {
                prescription: {
                    id: locationData.medrecord_id
                },
                frequency: {},
                special_instruction: {},
                medgroup: {},
                prescription_hour: {}
            } :
            {
                frequency: {},
                special_instruction: {},
                medgroup: {},
                prescription_hour: {}
            };

        await axios.get(`${process.env.REACT_APP_SERVER_URL}/patients/list-medical-record-category`, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then(async (res) => {

                let objMedicalRecordCategoryData = res.data.data;

                let medCategoryResults = [];

                objMedicalRecordCategoryData.forEach((singleRec) => {
                    medCategoryResults.push({
                        key: singleRec.code,
                        value: singleRec.id + '|' + singleRec.mar_flag + '|' + singleRec.tar_flag + '|' + singleRec.po_flag + '|' + singleRec.is_active,
                    });
                });

                setMedCategoryOptions([
                    { key: 'Select Medical Record Category', value: '' },
                    ...medCategoryResults
                ]);


            });

        /**********************ORDERID***********************/

        let prescriptionResults = JSON.parse(localStorage.getItem("prescriptionObjData"));
        //console.log("prescriptionResults" + JSON.stringify(prescriptionResults));

        let presResults = [];

        prescriptionResults.forEach((value) => {
            presResults.push({
                key: value.id + '-' + value.drug,
                value: value.id,
            });
        });

        setPresOptions([
            { key: 'Select Prescription', value: '' },
            ...presResults
        ]);

        /**********************ORDERID***********************/

        await axios.post(`${process.env.REACT_APP_SERVER_URL}/prescriptions/fetch-multiple`, getAllItems, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then(async (res) => {

                /**********************FREQUENCY*****************************/

                let objFreqData = res.data.output.data["frequency"];
                let freqResults = [];

                objFreqData.forEach((value) => {
                    if (value.name != null && value.name != "") {
                        freqResults.push({
                            key: value.name,
                            value: value.id,
                        });
                    }
                });

                setFreqOptions([
                    { key: 'Select Frequency', value: '' },
                    ...freqResults
                ]);

                /**********************INSTRUCTIONS*****************************/

                let objInsData = res.data.output.data["special_instruction"];
                let insResults = [];

                objInsData.forEach((value) => {

                    if (value.instruction != null && value.instruction != "") {
                        insResults.push({
                            key: value.instruction,
                            value: value.id,
                        });
                    }
                });

                setSplinsOptions([
                    { key: 'Select Special Instruction', value: '' },
                    ...insResults
                ]);

                /**********************MED GROUP*****************************/

                let objMedgroupData = res.data.output.data["medgroup"];
                let medgroupResults = [];

                objMedgroupData.forEach((value) => {

                    if (value.name != null && value.name != "") {
                        medgroupResults.push({
                            key: value.name,
                            value: value.id,
                        });
                    }
                });

                setMedGroupOptions([
                    { key: 'Select MedGroup', value: '' },
                    ...medgroupResults
                ]);

                /**********************TIME*****************************/

                let objHrData = res.data.output.data["prescription_hour"];
                let hrResults = [];

                objHrData.forEach((value) => {

                    if (value.time != null && value.time != "") {
                        hrResults.push({
                            key: value.time,
                            value: value.id,
                        });
                    }
                });

                setTime1([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime2([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime3([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime4([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime5([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime6([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime7([{ key: 'Select Time', value: '' }, ...hrResults]);
                setTime8([{ key: 'Select Time', value: '' }, ...hrResults]);


                /**********************EDIT MEDICAL RECORD*****************************/

                if (locationData.medrecord_id != null) {

                    await axios.get(`${process.env.REACT_APP_SERVER_URL}/patients/get-medical-record/` + locationData.medrecord_id, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
                        .then((res) => {

                            let objMedicalRecordData = res.data.data;

                            //console.log("singleMedicalRecord " + JSON.stringify(res.data.data));

                            objMedicalRecordData.forEach((singleMedicalRecord) => {
                                fetchHcpData(singleMedicalRecord.facility_id);
                                if(singleMedicalRecord.medication_identifier == "P"){
                                    setChecked(true);
                                }else{
                                    setChecked(false);
                                }
                                setFormData({
                                    facilityname: singleMedicalRecord.facility_id,
                                    individual: singleMedicalRecord.patient_id,
                                    hcp: singleMedicalRecord.doctor_id,
                                    category: singleMedicalRecord.medical_record_category_id,
                                    callhcp: singleMedicalRecord.other_note,
                                    medgrp: singleMedicalRecord.med_group_id,
                                    description: singleMedicalRecord.description,
                                    rsn: singleMedicalRecord.reason,
                                    splins: singleMedicalRecord.special_instruction_id,
                                    frequency: singleMedicalRecord.frequency_id,
                                    orderid: singleMedicalRecord.prescription_id,
                                    is_medsheet: Number(singleMedicalRecord.mar_flag) == 1 ? true : false,
                                    is_treatment: Number(singleMedicalRecord.tar_flag) == 1 ? true : false,
                                    is_physpres: Number(singleMedicalRecord.po_order) == 1 ? true : false,
                                    is_active: Number(singleMedicalRecord.is_active) == 1 ? true : false,
                                    is_prnmedication: singleMedicalRecord.medication_identifier == "P" ? true : false,
                                    time1: singleMedicalRecord.time1,
                                    time2: singleMedicalRecord.time2,
                                    time3: singleMedicalRecord.time3,
                                    time4: singleMedicalRecord.time4,
                                    time5: singleMedicalRecord.time5,
                                    time6: singleMedicalRecord.time6,
                                    time7: singleMedicalRecord.time7,
                                    time8: singleMedicalRecord.time8,
                                });




                                setStartDate(singleMedicalRecord.start_date != null ? moment(singleMedicalRecord.start_date, 'MM/DD/YYYY').format('MM/DD/YY') : null);

                                setStopDate(singleMedicalRecord.stop_date != null ? moment(singleMedicalRecord.stop_date, 'MM/DD/YYYY').format('MM/DD/YY') : null); 

                            });

                        });
                };

            });
        fetchHcpData(locationData.facilityid);

    }

    async function fetchHcpData(facilityid) {

        let tokenStr = localStorage.getItem("accessToken");
        //TODO confirm with SHANKS whether listing is based on facility id or not
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/doctors/list-doctors", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let results = []

        //console.log("Doctor Data Status " + data.status);
        let objPatientData = data.data;

        objPatientData.forEach((value) => {
            results.push({
                key: value.firstname + ", " + value.lastname,
                value: value.id
            });
        });

        setHcpOptions([
            ...results
        ])

    }

    const handleMedicalRecordModal = (event) => {
        //localStorage.setItem("medicalRecordFormName", event.target.id);
        setShowModal(true);
    };

    const validateForm = () => {
        let newErrors = {};
        if(locationData.medrecord_id != null){
            if (!formData.facilityname) newErrors.facilityname = "Facility is required";
            if (!formData.individual) newErrors.individual = "Individual is required";
            if (!formData.orderid) newErrors.orderid = "Prescription is required";
            if (!formData.frequency) newErrors.frequency = "Frequency is required";
        }
        if (!formData.category) newErrors.category = "Category is required";
        if (!formData.description) newErrors.description = "Description is required";
        if (!startdate) newErrors.startdate = "Start Date is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();

        if (!validateForm()) return;

        if (e.target.name == "medicalrecordform") {

            //console.log("locationData.medrecord_id" + locationData.medrecord_id);

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsMedicalRecordUrl = locationData.medrecord_id != null ? `${process.env.REACT_APP_SERVER_URL}/patients/update-medical-record` : `${process.env.REACT_APP_SERVER_URL}/patients/add-medical-record`;

            let splInsManual = [];

            splInsOptions.map((splinsoption) => {

                if (splinsoption.value == "-999") {
                    splInsManual.push(splinsoption.key);
                }
            });
            
            let pelmedsMedicalRecordData = locationData.medrecord_id != null ?
                {
                    id : locationData.medrecord_id,
                    group_id: localStorage.getItem("groupId"),
                    facility_id: locationData.facilityid,
                    patient_id: locationData.patientid,
                    description: formData.description,
                   // start_date: startdate != null ? moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                   // stop_date: stopdate != null ? moment(stopdate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                    start_date: startdate != null ? moment(startdate).format('YYYY-MM-DD') : null,
                    stop_date: stopdate != null ? moment(stopdate).format('YYYY-MM-DD') : null,
                    medical_record_category_id: formData.category || "",
                    doctor_id: formData.hcp,
                    frequency_id: formData.frequency,
                    prescription_id: formData.orderid,
                    special_instruction_id: formData.splins,
                    // special_instruction_manual: splInsManual.toString(), showing unknown from server
                    // remarks: formData.callhcp,  showing unknown from server
                    // medgroup_id: formData.medgrp,  showing unknown from server
                    reason: formData.rsn,
                    medication_identifier: formData.is_prnmedication ? "P" : "", //Todo why MedType is P
                    mar_flag: formData.is_medsheet ? 1 : 0,
                    tar_flag: formData.is_treatment ? 1 : 0,
                    is_active: formData.is_active ? 1 : 0,
                    po_order: formData.is_physpres ? 1 : 0,
                    time1: !formData.is_prnmedication ? formData.time1 : '0',
                    time2: !formData.is_prnmedication ? formData.time2 : '0',
                    time3: !formData.is_prnmedication ? formData.time3 : '0',
                    time4: !formData.is_prnmedication ? formData.time4 : '0',
                    time5: !formData.is_prnmedication ? formData.time5 : '0',
                    time6: !formData.is_prnmedication ? formData.time6 : '0',
                    time7: !formData.is_prnmedication ? formData.time7 : '0',
                    time8: !formData.is_prnmedication ? formData.time8 : '0'
                } :
                {
                    group_id: localStorage.getItem("groupId"),
                    facility_id: locationData.facilityid,
                    patient_id: locationData.patientid,
                    doctor_id: formData.hcp,
                    description: formData.description,
                    frequency_id: formData.frequency,
                    prescription_id: formData.orderid,
                    //start_date: startdate != null ? moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                    //stop_date: stopdate != null ? moment(stopdate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                    start_date: startdate != null ? moment(startdate).format('YYYY-MM-DD') : null,
                    stop_date: stopdate != null ? moment(stopdate).format('YYYY-MM-DD') : null,
                    special_instruction_id: formData.splins,
                    special_instruction_manual: splInsManual.toString(),
                    remarks: formData.callhcp,
                    medgroup_id: formData.medgrp,
                    reason: formData.rsn,
                    medication_identifier: formData.is_prnmedication ? "P" : "", //Todo why MedType is P
                    mar_flag: formData.is_medsheet ? 1 : 0,
                    tar_flag: formData.is_treatment ? 1 : 0,
                    is_active: formData.is_active ? 1 : 0,
                    po_order: formData.is_physpres ? 1 : 0,
                    time1: !formData.is_prnmedication ? formData.time1 : '0',
                    time2: !formData.is_prnmedication ? formData.time2 : '0',
                    time3: !formData.is_prnmedication ? formData.time3 : '0',
                    time4: !formData.is_prnmedication ? formData.time4 : '0',
                    time5: !formData.is_prnmedication ? formData.time5 : '0',
                    time6: !formData.is_prnmedication ? formData.time6 : '0',
                    time7: !formData.is_prnmedication ? formData.time7 : '0',
                    time8: !formData.is_prnmedication ? formData.time8 : '0',
                    medical_record_category_id: formData.category || ""
                }

            const { data } = await axios.post(pelmedsMedicalRecordUrl, pelmedsMedicalRecordData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const patientDetails = paths.patientDetails;
            if (data.status && locationData.medrecord_id == null) {
                toast.success("Medical record Details added successfully");
                navigate(`${patientDetails}/${localStorage.getItem("patientId")}`);
            }
            else if (data.status && locationData.medrecord_id != null) {
                toast.success("Medical record Details updated successfully");
                navigate(`${patientDetails}/${localStorage.getItem("patientId")}`);
            }
            else {
                toast.info("Error while adding Medical record Details");
            }

            //if (locationData == null) {
            //    setFormData(initialFormData);
            //    formRef.current.reset();
            //}
            //console.log("DataOutput " + JSON.stringify(data));

        }
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleMedRecordFieldChange = e => {

        //console.log(e.target.value);        
        
        formData.is_medsheet = e.target.value.split("|")[1] == 0 ? false : true;
        formData.is_treatment = e.target.value.split("|")[2] == 0 ? false : true;
        formData.is_physpres = e.target.value.split("|")[3] == 0 ? false : true;
        const value = e.target.value?.split('|')[0];
        setFormData({
            ...formData,
            [e.target.name]: value
        });

    }

    const handlePrnMedication = (e) => {
        setChecked(!checked);
        setFormData({
            ...formData,
            is_prnmedication: e.target.checked
        });
    };

    const handleMoreTime = (e) => {
        //if (!edittimeclicked)
        setEditTimeClicked(true);
    };

    const handleMedicalRecordModalValue = (modalvalue) => {

        let isDuplicate = handleDrpDuplicates(splInsOptions, modalvalue);
        if (modalvalue != "" && !isDuplicate) {
            let updatedInsOptions = splInsOptions.filter((singleoption) => singleoption.value != -999);
            updatedInsOptions.push({ key: modalvalue, value: -999 });
            setSplinsOptions(updatedInsOptions);
        }

    };

    function handleDrpDuplicates(drpdwnOptions, modalvalue) {

        let isDuplicate = false;

        drpdwnOptions.map((singleoption) => {
            //console.log(singleoption.key + " && " + modalvalue);
            if (singleoption.key.toLowerCase() == modalvalue.toLowerCase()) {
                isDuplicate = true;
            }
        })

        return isDuplicate;
    }

    const validateStopDate = stopdate => {
        if (moment(stopdate) > moment(startdate)) {
            setStopDate(stopdate)
        } else {
            toast.info("Stop date should be greater than Start Date");
        }
    }

    const breadcrumbItems = locationData.medrecord_id != null ? [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Patient', href: paths.viewPatient, isActive: false },
        { name: 'Patient-Profile', href: `${paths.patientDetails}/${locationData.patientid}`, isActive: false },
        { name: 'Edit-Medicalrecord', href: paths.editPrescription, isActive: true }

    ] : [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Patient', href: paths.viewPatient, isActive: false },
        { name: 'Patient-Profile', href: `${paths.patientDetails}/${locationData.patientid}`, isActive: false },
        { name: 'Add-Medicalrecord', href: paths.addPrescription, isActive: true }
    ]

    return (
        <>
        <CommonBreadCrumb breadcrumbItems = {breadcrumbItems}/>
        <Form onSubmit={handleSubmit} name="medicalrecordform">
            <Card className="mb-3">
                <Card.Header as="h6" className="bg-body-tertiary">
                    {locationData.medrecord_id != null ? "Edit" : "Add"} Medical Record
                </Card.Header>
                <Card.Body>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label className='required'>Facility</Form.Label>
                                <Form.Select
                                    name="facilityname"
                                    onChange={handleFieldChange}
                                    size="sm"
                                    disabled="disabled"
                                >
                                    {facilityoptions.map((option) => {
                                        let isFacilitySelected = false;
                                        if (option.value == locationData.facilityid) {
                                            isFacilitySelected = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isFacilitySelected} >
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className='required'>Category</Form.Label>
                                <Form.Select
                                    name="category"
                                    onChange={handleMedRecordFieldChange}
                                    size="sm"
                                    isInvalid = {!!errors.category}
                                >
                                    {medcategoryOptions.map((option) => {
                                        let isMedRecordCategorySelected = false;
                                        if (Number(option?.value?.split('|')[0]) === dataObj?.category_id) {
                                            isMedRecordCategorySelected = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isMedRecordCategorySelected} >
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" >{errors.category}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label className='required'>Individual</Form.Label>
                                <Form.Control
                                    name="individual"
                                    onChange={handleFieldChange}
                                    size="sm"
                                    value={localStorage.getItem("patientName")}
                                    disabled="disabled">
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>HCP</Form.Label>
                                <Form.Select
                                    name="hcp"
                                    onChange={handleFieldChange}
                                    size="sm">
                                    <option key="Select Hcp" value="">Choose Facility to Select HCP</option>
                                    {hcpoptions.map((option) => {
                                        var isHcpSelected = false;
                                        if (locationData.medrecord_id != null) {
                                            if (option.value == formData.hcp) {
                                                isHcpSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isHcpSelected}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Frequency</Form.Label>
                                <Form.Select
                                    name="frequency"
                                    onChange={handleFieldChange}
                                >
                                    {freqOptions.map((freqoption) => {
                                        var isFreqSelected = false;
                                        if (locationData.medrecord_id != null) {
                                            if (freqoption.value == formData.frequency) {
                                                isFreqSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={freqoption.value} value={freqoption.value} selected={isFreqSelected}>
                                                {freqoption.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Prescription</Form.Label>
                                <Form.Select
                                    name="orderid"
                                    onChange={handleFieldChange}
                                >
                                    {presOptions.map((presoption) => {
                                        var isPresSelected = false;
                                        if (locationData.medrecord_id != null) {
                                            if (presoption.value == formData.orderid) {
                                                isPresSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={presoption.value} value={presoption.value} selected={isPresSelected}>
                                                {presoption.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Reason</Form.Label>
                                <Form.Control
                                    placeholder='Reason'
                                    value={formData.rsn}
                                    name="rsn"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label className='required'>Start</Form.Label>
                                {/* <DatePicker name="startdate" selected={startdate} onChange={(startdate) => setStartDate(startdate)}
                                    className={`form-control form-control-sm ${errors.startdate ? 'is-invalid' : ''}`} placeholderText="Select Start Date" /> */}

                                <DatePicker 
                                    name="startdate" 
                                    selected={startdate ? moment(startdate).format("MM/DD/YY") : null} 
                                    onChange={(startdate) => setStartDate(startdate)}
                                    dateFormat="MM/dd/yy"
                                    className={`form-control form-control-sm ${errors.startdate ? 'is-invalid' : ''}`} placeholderText="Select Start Date" />


                                {errors.startdate && <div className="invalid-feedback mt-1 display-block">{errors.startdate}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Stop</Form.Label>
                                {/* <DatePicker name="stopdate" selected={stopdate} onChange={validateStopDate}
                                    className='form-control' placeholderText="Select Stop Date" /> */}

                                <DatePicker 
                                    name="stopdate" 
                                    selected={stopdate ? moment(stopdate).format("MM/DD/YY") : null} 
                                    onChange={validateStopDate}
                                    className='form-control' 
                                    placeholderText="Select Stop Date" 
                                    dateFormat="MM/dd/yy"
                                    minDate={new Date()} 
                                    />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Special Instruction</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select
                                        name="splins"
                                        onChange={handleFieldChange}
                                    >
                                        {splInsOptions.map((splinsoption) => {
                                            var isSplInsSelected = false;
                                            if (locationData.medrecord_id != null) {
                                                if (splinsoption.value == formData.splins) {
                                                    isSplInsSelected = "selected";
                                                }
                                            }
                                            return (
                                                <option key={splinsoption.value} value={splinsoption.value} selected={isSplInsSelected}>
                                                    {splinsoption.key}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Button variant="outline-secondary" id="btnsplins" onClick={handleMedicalRecordModal}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Med Group</Form.Label>
                                <Form.Select
                                    name="medgrp"
                                    onChange={handleFieldChange}
                                >
                                    {medgroupOptions.map((medgrpoption) => {
                                        var isMedGroupSelected = false;
                                        if (locationData.medrecord_id != null) {
                                            if (medgrpoption.value == formData.medgrp) {
                                                isMedGroupSelected = "selected";
                                            }
                                        }
                                        return (
                                            <option key={medgrpoption.value} value={medgrpoption.value} selected={isMedGroupSelected}>
                                                {medgrpoption.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Call HCP If</Form.Label>
                                <Form.Control
                                    placeholder='Call HCP If'
                                    value={formData.callhcp}
                                    name="callhcp"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className='required'>Description</Form.Label>
                                <Form.Control
                                    placeholder='Description'
                                    value={formData.description}
                                    name="description"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                    isInvalid = {!!errors.description}
                                />
                                <Form.Control.Feedback type="invalid" >{errors.description}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_medsheet"
                                id='is_medsheet'
                                checked={
                                    formData.is_medsheet
                                }
                                onChange={(e) => {
                                    if (formData.is_medsheet && !e.target.checked) {
                                        if (!window.confirm("Are you sure you want to turn off Medsheet?")) {
                                            return;
                                        }
                                    }
                                    setFormData({
                                        ...formData,
                                        is_medsheet: e.target.checked,
                                    });
                                }}
                                label='Medsheet'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_treatment"
                                id='is_treatment'
                                checked={
                                    formData.is_treatment
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_treatment: e.target.checked
                                    })
                                }
                                label='Treatment'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_physpres"
                                id='is_physpres'
                                checked={
                                    formData.is_physpres
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_physpres: e.target.checked
                                    })
                                }
                                label='Physician Prescription'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_active"
                                id='is_active'
                                checked={
                                    formData.is_active
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_active: e.target.checked
                                    })
                                }
                                label='Active'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {!checked && (
                                <Form.Group>
                                    <Form.Label>Hour</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Select
                                                name="time1"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time1options.map((time1option) => {
                                                    var isTime1Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time1option.value == formData.time1) {
                                                            isTime1Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time1option.value} value={time1option.value} selected={isTime1Selected}>
                                                            {time1option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time2"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time2options.map((time2option) => {
                                                    var isTime2Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time2option.value == formData.time2) {
                                                            isTime2Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time2option.value} value={time2option.value} selected={isTime2Selected}>
                                                            {time2option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time3"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time3options.map((time3option) => {
                                                    var isTime3Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time3option.value == formData.time3) {
                                                            isTime3Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time3option.value} value={time3option.value} selected={isTime3Selected}>
                                                            {time3option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                name="time4"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time4options.map((time4option) => {
                                                    var isTime4Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time4option.value == formData.time4) {
                                                            isTime4Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time4option.value} value={time4option.value} selected={isTime4Selected}>
                                                            {time4option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-secondary" id="btnmoretime" onClick={handleMoreTime}>
                                                +
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        </Col>
                        <Col xs="2">
                            <Form.Label></Form.Label>
                            <Form.Check
                                type='switch'
                                name="is_prnmedication"
                                id='is_prnmedication'
                                checked={
                                    formData.is_prnmedication
                                }
                                onChange={handlePrnMedication}
                                label='Check this Box For PRN Medications'
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {edittimeclicked && (
                                <Form.Group>
                                    <Row>
                                        <Col xs="2">
                                            <Form.Select
                                                name="time5"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time5options.map((time5option) => {
                                                    var isTime5Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time5option.value == formData.time5) {
                                                            isTime5Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time5option.value} value={time5option.value} selected={isTime5Selected}>
                                                            {time5option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="2">
                                            <Form.Select
                                                name="time6"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time6options.map((time6option) => {
                                                    var isTime6Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time6option.value == formData.time6) {
                                                            isTime6Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time6option.value} value={time6option.value} selected={isTime6Selected}>
                                                            {time6option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="2">
                                            <Form.Select
                                                name="time7"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time7options.map((time7option) => {
                                                    var isTime7Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time7option.value == formData.time7) {
                                                            isTime7Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time7option.value} value={time7option.value} selected={isTime7Selected}>
                                                            {time7option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xs="2">
                                            <Form.Select
                                                name="time8"
                                                size="xs"
                                                onChange={handleFieldChange}
                                            >
                                                {time8options.map((time8option) => {
                                                    var isTime8Selected = false;
                                                    if (locationData.medrecord_id != null) {
                                                        if (time8option.value == formData.time8) {
                                                            isTime8Selected = "selected";
                                                        }
                                                    }
                                                    return (
                                                        <option key={time8option.value} value={time8option.value} selected={isTime8Selected}>
                                                            {time8option.key}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="w-100 mt-4">
                                    {locationData.medrecord_id != null ? "Update" : "Save"}
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                </Card.Body>
            </Card>
            <MedicalRecordModal show={showModal} setShow={setShowModal} handleMedicalRecordModalValue={handleMedicalRecordModalValue} />
        </Form >
        </>
    );
};

AddEditMedicalRecord.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

AddEditMedicalRecord.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default AddEditMedicalRecord;
