import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ViewFacilityStaffProfileHeader from './ViewFacilityStaffProfileHeader';
import ViewFacilityStaffProfileBody from './ViewFacilityStaffProfileBody';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';

const ViewFacilityStaffProfile = () => {
  const { id } = useParams()
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    fetchData(id)
  }, [])

  async function fetchData(id) {
    let tokenStr = localStorage.getItem("accessToken");
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/users/get-user/" + id, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    setProfileData(data)

  }

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'User Management ', href: paths.viewFacility, isActive: false },
    { name: ' User Profile', href: paths.viewfacilitystaffprofile, isActive: true }
  ];
  return (
    <>
    {profileData  ?   <>
      <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />
      <ViewFacilityStaffProfileHeader data={profileData} />
      <ViewFacilityStaffProfileBody data={profileData} />
    </> : <ShimmerUI></ShimmerUI>}
    </>
    
  )
}

export default ViewFacilityStaffProfile