import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const Avatar = ({
  size,
  rounded,
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact,
  icon,
  flag
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const avatarClasses = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');

  const getAvatar = () => {
    if (src) {
      return <img className={mediaClasses} src={src} alt="avatar" onClick={() => setShowPopup(true)} />;
    }
    
    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`} onClick={() => setShowPopup(true)}>
          <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <div className={`avatar-name ${mediaClasses}`} onClick={() => setShowPopup(true)}>
          <FontAwesomeIcon icon={icon} />
        </div>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`} onClick={() => setShowPopup(true)}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className={avatarClasses}>{getAvatar()}</div>
      {(showPopup && flag) && (
        <div className="avatar-popup-overlay" onClick={() => setShowPopup(false)}>
          <div className="avatar-popup-content">
            <img src={src} alt="Enlarged Avatar" />
          </div>
        </div>
      )}
    </>
  );
};

export const AvatarGroup = ({ avatars, dense, className, maxDisplay = 3 }) => {
  return (
    <div
      className={classNames(className, 'avatar-group', {
        'avatar-group-dense': dense
      })}
    >
      {avatars.slice(0, maxDisplay).map((avatar, index) => (
        <Avatar key={index} size={avatar.size || "xl"} src={avatar.src} name={avatar.name} />
      ))}
      {avatars.length > maxDisplay && (
        <div className="extra-avatars">
          +{avatars.length - maxDisplay}
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  isExact: false
};

AvatarGroup.propTypes = {
  avatars: PropTypes.array.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool,
  maxDisplay: PropTypes.number
};

export default Avatar;
