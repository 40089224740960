

const SetMastersDetails = () => {
    let objData = JSON.parse(localStorage.getItem("masters"))
    let orderTypesResult = objData.order_types.reduce((obj, item) => {
        obj[item.id] = item.order_type;
        return obj;
    }, {});

    let orderStatusResult = objData.order_status.reduce((obj, item) => {
        obj[item.id] = item.order_status;
        return obj;
    }, {});

    let orderStatusResultFacility = objData.order_status.reduce((obj, item) => {
        obj[item.id] = item.order_status_for_client;
        return obj;
    }, {});

    let orderStatusResultInternal = objData.order_status.map(item => ({
        key: item.order_status,
        value: item.id
    }));
    let deliveryTypes = objData.delivery_locations.reduce((obj, item) => {
        obj[item.id] = item.delivery_location;
        return obj;
    }, {});
    localStorage.setItem("order_status_internal", JSON.stringify(orderStatusResultInternal))
    localStorage.setItem("order_status", JSON.stringify(orderStatusResult))
    localStorage.setItem("order_types", JSON.stringify(orderTypesResult))
    localStorage.setItem("delivery_types", JSON.stringify(deliveryTypes))
    localStorage.setItem("order_status_facility", JSON.stringify(orderStatusResultFacility))
}

export default SetMastersDetails
