import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderDetailModalParent from 'components/orderdetail/OrderDetailModalParent';
import AddOrderModal from 'components/patient/view-patient/AddOrderModal';
import moment from 'moment';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const MedCard = (props) => {
    const [showModal, setShowModal] = useState(false);
    const data = props.data;
    const [original,setOriginal] = useState({
        patient: data.patient_lastname + ", " + data.patient_firstname,
        ordertype: data.order_type,
        deliverydatetime: data.delivery_date != null ? moment(data.delivery_date).format('MM/DD/YY') : null,
        refillrequest: data.refill_request,
        createdby: data.created_by_username,
        createddate: data.created != null ? moment(data.created).format('MM/DD/YY - hh:mm A') : null,
        orderstatus: data.order_status,
        rxnumber: data.pharmacy_order_id,
        qty: data.drug_qty,
        drug: data.drug,
        orderid: data.id,
        notesObj: data.notes,
        internalNotesObj : data.internal_notes,
        documents : data.documents,
        is_billing_issue: data.is_billing_issue,
        is_loansupplies: data.is_loansupplies,
        is_pa: data.is_pa,
        parentId: data.patient_id,
        patientModalData : data.patient
    })
    
    const uniqueId = `collapseDetails-${Number(original.parentId)}-${Number(original.orderid)}`;
    const triggerModal = () => {
        setShowModal(true);
    }
    return (
        <>
            <OrderDetailModalParent show={showModal} filteredData={original} setRefresh={setOriginal} handleClose={() => setShowModal(false)} />
            <div className="card rounded-0 mb-1">
                <div
                    className='card-header py-0 px-1 border'
                    variant="link"
                >
                    <a
                        data-bs-toggle="collapse"
                        data-bs-target={`#${uniqueId}`}
                        aria-expanded="false"
                        aria-controls={uniqueId}
                    >
                       {original.drug ? original.drug : "New Patient"}
                    </a>


                    <Link className="float-end" state={{ original }}>
                        <FontAwesomeIcon className="text-disable" icon="edit" onClick={triggerModal} />
                    </Link>
                </div>
                    <div className="collapse card-body p-0" id={uniqueId}>
                        <div className="px-1 py-1" style={{ fontSize: "12px", color: "#555", lineHeight: "1" }}>
                            <p style={{ margin: "5px 0" }}>{original.time}</p>
                            <p style={{ margin: "5px 0" }}>{'Morning/Evening'}</p>
                            <p style={{ margin: "5px 0" }}>{original.createddate}</p>
                            <p style={{ margin: "5px 0" }}>{original.createdby}</p>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default MedCard