import React, { useEffect, useState } from 'react';
import PatientDetailsHeader from './PatientDetailsHeader';
import PatientInfo from './PatientInfo';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PatientShimmer from '../../ShimmerUI/PatientShimmer';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';

const PatientDetails = () => {
    const [data, setData] = useState([]);
    const [name, setName] = useState("");
    const [flag, setFlag] = useState(false);
    const { id } = useParams();
    //Todo Need to remove and fetch it from PatientID API Call
    //console.log("patientName" + data.name + data.facilityid + data.facility + data.patientid);

    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {
        let tokenStr = localStorage.getItem("accessToken");

        await axios.get(`${process.env.REACT_APP_SERVER_URL}/patients/get-patient/` + id, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                //console.log("Res " + JSON.stringify());
                setFlag(true)
                setData(res.data.patients.data);
                setName(`${res.data.patients.data.last_name},${res.data.patients.data.first_name}`)
                localStorage.setItem("patientName", `${res.data.patients.data.last_name},${res.data.patients.data.first_name}`);
                localStorage.setItem("patientId", id);
                localStorage.setItem("facilityId", res.data.patients.data.facility_id);   
            })
    }

    const breadcrumbItems = [
            { name: 'Dashboard', href: '/main', isActive: false },
            { name: 'Patient', href: paths.viewPatient, isActive: false},
            { name: 'Patient-Profile', href: paths.patientDetailsWithId, isActive: true}
        ];

    return (
        <>
            {flag ? <>
            <CommonBreadCrumb breadcrumbItems = {breadcrumbItems}/>
                <PatientDetailsHeader patient_name={name} paramsObj={data} />
                <br /><br />
                <PatientInfo patient_id={data.id} username={name} facilityid={data.facility_id} paramsObj={data} />
            </> : <PatientShimmer />}
        </>
    );
};

export default PatientDetails;
