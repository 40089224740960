import React from 'react';

import { Card, Col, Form, Row, Button, InputGroup } from 'react-bootstrap';
import Select from "react-select";
import PropTypes from 'prop-types';
import paths from 'routes/paths';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from "react-datepicker";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Flex from 'components/common/Flex';
import moment from "moment";
import { toast } from 'react-toastify';
import CycleStatusGrid from './CycleStatusGrid';
import * as mapYourMedsConstant from '../../common/mapYourMedsConstant';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';

const CycleStatus = ({ bodyClassName }) => {

    const [startdate, setStartDate] = useState(moment().subtract(2, 'days').toDate());
    const [enddate, setEndDate] = useState(moment().toDate());
    const [options, setOptions] = useState([{ key: 'Select a program', value: '' }]);
    const [grpoptions, setGrpOptions] = useState([]);
    const [facilityobj, setFacilityObj] = useState([]);
    const [censustypeoptions, setCensusTypeOptions] = useState([]);
    const [censusstatusdata, setCensusStatusData] = useState({
        startdate: moment().subtract(2, 'days').toDate(),
        enddate: moment().toDate()
    });
    const [groupdata, setGroupData] = useState();
    const [selectedFacility, setSelectedFacility] = useState('');
    const [selectedCensus, setSelectedCensus] = useState('');
    const location = useLocation();
    const data = location.state;

    let initialFormData = {
        group: '',
        facility: '',
        censustype: '',
        startdate: '',
        enddate: ''
    };

    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        populateData();
    }, []);

    const handleFilterChange = (filterType, value) => {
        if (filterType === "facility") {
            setSelectedFacility(value);
        } else if (filterType === "census") {
            setSelectedCensus(value);
        }
    };

    async function populateData() {

        //let tokenStr = localStorage.getItem("accessToken");

        //set group details

        //set groupid
        // let groupResults = JSON.parse(localStorage.getItem("groupData"));
        // setGrpOptions([
        //     { key: 'Select a group', value: '' },
        //     ...groupResults
        // ]);

        //Todo Need to fix this based on role and group
        //console.log("facilitiesData " + localStorage.getItem("facilitiesData"));
        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        setOptions([
            { key: 'Select a program', value: '' },
            ...results
        ]);
        // const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/get-all-facility`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        // setGroupData(data.data);

        //set census types
        let censusresults = [
            // {
            //     key: "All",
            //     value: mapYourMedsConstant.CENSUS_STATUS_ALL
            // },
            {
                key: "Completed",
                value: mapYourMedsConstant.CENSUS_STATUS_COMPLETED
            },
            {
                key: "Pending",
                value: mapYourMedsConstant.CENSUS_STATUS_PENDING
            }

        ]

        setCensusTypeOptions([
            { key: 'Select a census', value: '' },
            ...censusresults
        ]);

    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            facility: e.value
        });

        

        console.log("formData" , formData);


        // setFormData({
        //     ...formData,
        //     [e.target.name]: e.target.value
        // });

    };

    const handleGroupFieldChange = e => {

        let groupdetails = groupdata[e.target.value];

        let facilityresults = [];

        groupdetails["facilities"].map((option) => {
            facilityresults.push({
                key: option.facility_name,
                value: option.id
            });
        });

        setOptions([
            { key: 'Select a program', value: '' },
            ...facilityresults
        ]);

        setFacilityObj(facilityresults);

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        let cycleStatusData = {
            startdate: moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            enddate: moment(enddate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        };

        setCensusStatusData(cycleStatusData);

        console.log("cycleStatusFORM " + JSON.stringify(cycleStatusData));
    }

    const handleClear = () => {
        // setStartDate(moment().subtract(2, 'days').toDate());
        // setEndDate(moment().toDate());
        setSelectedFacility('');
        setSelectedCensus('');
        // setCensusStatusData({
        //     startdate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
        //     enddate: moment().format('YYYY-MM-DD'),
        //     facility: '',
        //     censustype: ''
        // });
    };

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Cycle-Status', href: paths.cycleStatus, isActive: true }
    ];

    return (
        <>
            <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
            <Card className="p-3">
                <div className="border rounded p-3 pb-4 mb-4">
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="required">Start Date</Form.Label>
                                <DatePicker
                                    selected={startdate}
                                    onChange={(date) => setStartDate(date)}
                                    className='form-control form-control-sm'
                                    placeholderText="Select Start Date"
                                    dateFormat="MM/dd/yy"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label className="required">End Date</Form.Label>
                                <DatePicker
                                    selected={enddate}
                                    onChange={(date) => setEndDate(date)}
                                    className='form-control form-control-sm'
                                    placeholderText="Select End Date"
                                    dateFormat="MM/dd/yy"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="d-flex align-items-end">
                            <Button variant="primary" type="submit" onClick={handleSubmit} className="me-2">Search</Button>
                        </Col>
                    </Row>
                </div>
                <div className="filters-section border rounded pt-4  p-3 position-relative mb-4">
                    <div className="filter-heading">
                        Filters
                    </div>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Facility</Form.Label>
                                <Select
                                    name="facility"
                                    closeMenuOnSelect={true}
                                    options={options}
                                    placeholder="Select Facility"
                                    classNamePrefix="react-select"
                                    getOptionLabel={(option) => option.key}
                                    getOptionValue={(option) => option.value}
                                    value={options.find(opt => opt.value === selectedFacility) || null}
                                    onChange={(e) => handleFilterChange("facility", e.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Census Type</Form.Label>
                                <Form.Select
                                    name="censustype"
                                    size="sm"
                                    value={selectedCensus}
                                    onChange={(e) => handleFilterChange("census", e.target.value)}
                                >
                                    {censustypeoptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <CycleStatusGrid
                        cyclestatusdata={censusstatusdata}
                        selectedFacility={selectedFacility}
                        selectedCensus={selectedCensus}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

export default CycleStatus;