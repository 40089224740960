import useAdvanceTable from 'hooks/useAdvanceTable';
import React, { useEffect, useState } from 'react'
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Card, Col, Row, Form } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const communicationmethodOptionsData = [
    {
        "key": "Email",
        "value": 1
    },
    {
        "key": "Fax",
        "value": 2
    },
    {
        "key": "SMS",
        "value": 3
    }
]
const columns = [
    {
        accessorKey: 'facilityname',
        header: 'Faciity',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            return <Link to={{ pathname: paths.editfaciliitylookupform }} state={{ obj: original }} >
                {original.facility_name}</Link>
        }
    },
    {
        accessorKey: 'email',
        header: 'Email',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            return original.is_email ? (<FontAwesomeIcon icon="check" className="ms-1 text-success " />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
    {
        accessorKey: 'fax',
        header: 'Fax',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            return original.is_fax ? (<FontAwesomeIcon icon="check" className="ms-1 text-success " />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
    {
        accessorKey: 'sms',
        header: 'SMS',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            return original.is_sms ? (<FontAwesomeIcon icon="check" className="ms-1 text-success " />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
    {
        accessorKey: 'liaison',
        header: 'Liaison',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            return original.is_liaison ? (<FontAwesomeIcon icon="check" className="ms-1 text-success " />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
    {
        accessorKey: 'cycle_leed_day',
        header: 'Lead Days',
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'delivery_time',
        header: 'Delivery Time',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        }
    }
];



const FacilityTableView = (props) => {
    const { id } = props
    const [communicationOption, setCommunicationOptions] = useState([]);
    const [group, setGroup] = useState([]);

    useEffect(() => {
        fetchFacilityData("")
    }, [])

    async function fetchFacilityData(props) {
        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/list-facility/` + id, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let objData = data.data
        setCommunicationOptions([
            { key: 'Select', value: '' },
            ...communicationmethodOptionsData
        ])
        let filteredFacility;
        if (props == "1") {
            filteredFacility = objData.filter(facility => facility.is_email === 1);
            setGroup(filteredFacility);
        }
        else if (props == "2") {
            filteredFacility = objData.filter(facility => facility.is_fax === 2);
            setGroup(filteredFacility);
        }
        else if (props == "3") {
            filteredFacility = objData.filter(facility => facility.is_fax === 3);
            setGroup(filteredFacility);
        } else {
            setGroup(objData);
        }
    }

    const handleFieldChange = e => {
        fetchFacilityData(e.target.value)
    };

    const table = useAdvanceTable({
        data: group,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <AdvanceTableProvider {...table}>
            <Card className="mb-3">
                <Card.Body className="p-3">
                    <Row className='mt-3 justify-content-between'>
                        <Col xs="4" className="mb-2">
                            <Form.Group>
                                <Form.Label>Communication Method</Form.Label>
                                <Form.Select
                                    name="communicationmethod"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {communicationOption?.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs="4" className="mt-4">
                            <AdvanceTableSearchBox placeholder="Search..." />
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-1 mt-1">
                            <AdvanceTable
                                headerClassName="bg-200 text-nowrap align-middle text-center"
                                rowClassName="text-nowrap align-middle text-center"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs-10 mb-0 overflow-hidden'
                                }}
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default FacilityTableView