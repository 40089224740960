import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import moment from 'moment';
import { prescriptionType } from './prescriptionType.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefillDiscontinuedModal from './RefillDiscontinuedModal.js';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ACCESS_CONTROL } from 'components/authentication/accessControlMap.js';
import AdvanceTableCurrentPresc from 'components/common/advance-table/AdvanceTableCurrentPresc.js';
import IframeContainerDrug from 'components/navbar/top/IframeContainerDrug.js';



const CurrentPrescription = (props) => {
    //const [facility] = useState(facilityStaffData);
    const [currentPrescription, setCurrentPrescription] = useState([]);
    const [prescriptionTypeOptions, setprescriptionTypeOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDrugId, setSelectedDrugId] = useState(null);
    const [iframeKey, setIframeKey] = useState(0);
    const data = props?.data;
    let localStorageAccessControl = localStorage.getItem("accessControl");
    if (localStorageAccessControl === null) {
        localStorageAccessControl = [];
    } else {
        localStorageAccessControl = localStorageAccessControl.split(",");
    }

    const columns = [
        {
            accessorKey: 'orderid',
            header: 'Id',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                const editPrescription = paths.editPrescription
                return (localStorageAccessControl.includes(String(ACCESS_CONTROL["editprescription"])) ? <Link to={{ pathname: `${editPrescription}/${original.orderid}` }} state={{
                    from_ips: data.from_ips, orderid: original.orderid, patientid: original.patientid, facilityid: original.facility_id
                }} >{original.orderid}</Link> : <span>
                    {original.orderid}
                </span>)
            }
        },
        {
            accessorKey: 'drug',
            header: 'Drug',
            meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => (
                <div>
                    <span
                        className="text-primary cursor-pointer text-decoration-underline" 
                        role="button"
                        onClick={() => handleDrugClick(original.orderid)}
                    >
                        {original.drug}
                    </span>
                    {(selectedDrugId === original.orderid) && (
                        <IframeContainerDrug
                            key={iframeKey}
                            id={selectedDrugId}
                        />
                    )}
                </div>
            )
        },          
        {
            accessorKey: 'generic',
            header: 'Generic',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'dose',
            header: 'Dose',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        // {
        //     accessorKey: 'frequency',
        //     header: 'Frequency',
        //     meta: {
        //         headerProps: { className: 'text-900 text-start' },
        //         cellProps: { className: 'text-start' }
        //     }
        // },
        {
            accessorKey: 'start',
            header: 'Start',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        // {
        //     accessorKey: '',
        //     header: 'Disc Order',
        //     meta: {
        //         headerProps: { className: 'text-900 text-start' },
        //         cellProps: { className: 'text-start' }
        //     },
        //     cell: ({ row: { original } }) => {
        //         return <div className="p-2" onClick={() => { localStorage.setItem("gridFormName", "lnkdisc," + original.orderid); setShowModal(true) }}>
        //             <FontAwesomeIcon icon="plus" className="me-1 fs-11" /></div>
        //     }
        // },
        // {
        //     accessorKey: 'refilldate',
        //     header: 'Refill Due',
        //     meta: {
        //         headerProps: { className: 'text-900 text-start' },
        //         cellProps: { className: 'text-start' }
        //     }
        // },
        // {
        //     accessorKey: '',
        //     header: 'Refill',
        //     meta: {
        //         headerProps: { className: 'text-900 text-start' },
        //         cellProps: { className: 'text-start' }
        //     },
        //     cell: ({ row: { original } }) => {
        //         return <div className="p-2" onClick={() => { localStorage.setItem("gridFormName", "lnkrefill," + original.orderid); setShowModal(true) }}>
        //             <FontAwesomeIcon icon="plus" className="me-1 fs-11" /></div>
        //     }
        // }
    ];

    const handleDrugClick = (drugId) => {
        setSelectedDrugId(drugId);
        setIframeKey(prev => prev + 1);
    };
      
    useEffect(() => {
        async function fetchAllPrescriptionData() {
            setSelectedDrugId(null);
            //console.log("props.patientid current " + props.patientid);

            setprescriptionTypeOptions([
                ...prescriptionType
            ]);

            let results = []

            //console.log("Prescription Data Status " + data.prescriptions.status);
            let objData = data.prescriptions;
            let groupId = "";

            //console.log("prescriptionObjData" + JSON.stringify(objData));

            localStorage.setItem("prescriptionObjData", JSON.stringify(objData));

            filterPrescriptionData(objData);
        }

        // Trigger the fetch
        fetchAllPrescriptionData();
    }, []);

    const table = useAdvanceTable({
        data: currentPrescription,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    const handleFieldChange = e => {
        //console.log(e.target.value);
        let prescriptionResults = JSON.parse(localStorage.getItem("prescriptionObjData"));
        let filtered_prescription = "";
        if (e.target.value == "") {
            filtered_prescription = prescriptionResults;
        }
        else if (e.target.value == "current")
            filtered_prescription = prescriptionResults.filter(element => element.discontinued_date == null)
        else if (e.target.value == "discontinued")
            filtered_prescription = prescriptionResults.filter(element => element.discontinued_date != null)
        // else if (e.target.value == "refill")
        //     filtered_prescription = prescriptionResults.filter(element => element.refill_date != null)
        else if (e.target.value == "unverified")
            filtered_prescription = prescriptionResults.filter(element => (element.is_verified == 0 && element.discontinued_date == null))

        filterPrescriptionData(filtered_prescription);
    }

    function filterPrescriptionData(filtered_prescription) {
        setSelectedDrugId(null);
        let results = [];
        filtered_prescription.forEach((value) => {
            let stop_date = value.stop_date != null ? moment(value.stop_date, 'YYYY-MM-DD').format('MM/DD/YY') : null;
            let pharmacy_expire_date = value.pharmacy_expire_date != null ? moment(value.pharmacy_expire_date, 'YYYY-MM-DD').format('MM/DD/YY') : null;
            let start_date = value.start_date != null ? moment(value.start_date, 'YYYY-MM-DD').format('MM/DD/YY') : null;

            results.push({
                orderid: value.id,
                hcp: value.doctor_lastname + ", " + value.doctor_firstname,
                drug: value.drug + ' ' + value.strength_value + ' ' + value.strength,
                generic: value.generic,
                amount: value.amount,
                dose: value.days_supply,
                frequency: value.no_of_refill,
                route: '',
                start: start_date,
                stop: stop_date,
                expiry: pharmacy_expire_date,
                facility_id: value.facility_id,
                patient_id: value.patient_id,
                ndc: value.drug_ndc,
                refilldate: value.refill_date != null ? moment(value.refill_date, 'YYYY-MM-DDThh:mm:ss').format('MM/DD/YY') : null,
                drug_form: value.drug_form,
                brand_drug: value.brand_drug,
                generic: value.generic,
                patient_name: props.username,
                earlytime: value.time_1,
                exceedtime: value.time_2,
                medstime: value.time_3,
                hourtime1: value.time_4,
                hourtime2: value.time_5,
                hourtime3: value.time_6,
                hourtime4: value.time_7,
                isvital: value.has_vitalsigns == null ? 0 : 1,
                patientid: props.patientid,
                is_verified: value.is_verified,
                discontinued_date: value.discontinued_date
            });
        });
        setCurrentPrescription(results);

    }

    return (
        <div>
            <Row className="align-items-end mb-3">
                <Col md={3}>
                    <Form.Group>
                        <Form.Label>Prescription Type</Form.Label>
                        <Form.Select
                            name="prescriptiontype"
                            onChange={handleFieldChange}
                            size="sm"
                        >
                            {prescriptionTypeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={6} className="d-flex flex-column">
                    <Form.Label className="mb-1">Legend</Form.Label>
                    <div className="d-flex flex-wrap align-items-center gap-4">
                        <div className="d-flex align-items-center gap-1">
                            <span
                                className="rounded"
                                style={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#ffaeae',
                                    display: 'inline-block',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <small className="text-muted">Discontinued</small>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                            <span
                                className="rounded"
                                style={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#f7ba002b',
                                    display: 'inline-block',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <small className="text-muted">Unverified</small>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <span className="legend-color-box" style={{ backgroundColor: '#fffff' }}></span>
                            <span className="fs-11 text-muted">Active</span>
                        </div>
                    </div>
                </Col>
                {currentPrescription.length > 0 && (
                    <Col md={3} className="d-flex justify-content-end align-items-end">
                        <AdvanceTableProvider {...table}>
                            <AdvanceTableSearchBox placeholder="Search..." />
                        </AdvanceTableProvider>
                    </Col>
                )}
            </Row>
            {currentPrescription.length > 0 ? (
                <AdvanceTableProvider {...table}>
                    <Row>
                        <Col>
                            <AdvanceTableCurrentPresc
                                headerClassName="bg-200 text-nowrap align-middle text-center"
                                rowClassName="text-nowrap align-middle text-center"
                                tableProps={{
                                    size: 'lg',
                                    striped: true,
                                    className: 'fs-10 mb-0'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
                        </Col>
                    </Row>
                </AdvanceTableProvider>
            ) : (
                <p className="text-danger text-center fw-bold">No data available in table</p>
            )}
        </div>
    );
};

export default CurrentPrescription;