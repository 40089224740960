import React, { useState, useEffect } from "react";
import axios from "axios";

const IframeContainer = ({id}) => {
    const [iframeSrc, setIframeSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchHtmlContent = async () => {
        let tokenStr = localStorage.getItem("accessToken");
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/cycles/get-cycle-status-report/" + id, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const blob = new Blob([response.data], { type: "text/html" });
            const blobUrl = URL.createObjectURL(blob);
            setIframeSrc(blobUrl);
        } catch (err) {
            setError("Failed to print content.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHtmlContent();
    }, []);

    return (
        <>
            {loading && <div className="text-center">Loading...</div>}
            {error && <div className="text-danger text-center">{error}</div>}
            <div style={{
                height: 0,
                overflow: 'hidden',
                width: 0
            }}>
                {iframeSrc && (
                    <iframe
                        src={iframeSrc}
                        style={{ width: "100%", height: "500px", border: "none" }}
                        title="Preview Content"
                    />
                )}
            </div>
        </>
    );
};

export default IframeContainer;
