import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Card, Col, Form, Row, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const ResetPasswordModal = ({ show, setShow }) => {

    const initialFormData = {
        password: '',
        cnfpassword: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleModalSubmit = async e => {

        if (formData.password == formData.cnfpassword) {
            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsPasswordUrl = `${process.env.REACT_APP_SERVER_URL}` + "/users/update-password";

            let pelmedsPasswordData =
            {
                user_id: localStorage.getItem("editUserId"),
                password: formData.password,
                confirm_password: formData.cnfpassword
            };

            console.log("pelmedsPasswordData " + JSON.stringify(pelmedsPasswordData));

            const { data } = await axios.post(pelmedsPasswordUrl, pelmedsPasswordData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            console.log("Data Status" + JSON.stringify(data));

            if (data.status) {
                toast.success("Password has been updated successfully");
            }
            else {
                toast.error("Error found while updating password");
            }

            setFormData({
                password: '',
                cnfpassword: ''
            })

            setShow(false);
        }
        else {
            toast.error("Password and Confirm Password has to be same");
        }
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Modal
            show={show}
            onHide={() =>{
                setFormData(initialFormData);
                setShow(false)
            }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Reset Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                {/* <Form onSubmit={handleModalSubmit}> */}
                <Row className="mb-2">
                    <Col md="6">
                        <Form.Group>
                            <Form.Label className="required">New Password</Form.Label>
                            <Form.Control
                                placeholder='Password'
                                value={formData.password}
                                name="password"
                                onChange={handleFieldChange}
                                type="password"
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md="6">
                        <Form.Group>
                            <Form.Label className="required">Confirm Password</Form.Label>
                            <Form.Control
                                placeholder='Confirm Password'
                                value={formData.cnfpassword}
                                name="cnfpassword"
                                onChange={handleFieldChange}
                                type="password"
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Form.Group>
                            <Button
                                type="submit"
                                color="primary"
                                className="w-100 mt-2"
                                name="btnresetpass"
                                size="sm"
                                onClick={handleModalSubmit}
                                disabled={!formData.password || !formData.cnfpassword}>
                                Edit Password
                            </Button>
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                    </Col>
                </Row>
                {/* </Form> */}
            </Modal.Body>
        </Modal>
    );
};

ResetPasswordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default ResetPasswordModal;
