import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import moment from 'moment';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DataTransferRoute = () => {
  const initialData = {
    startTime: '',
    cutOffTime: '',
    startDate: ''
  };
  const [formData, setFormData] = useState(initialData);
  const [columnData, setColumnData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const columns = [
    {
      accessorKey: 'route',
      header: 'Route',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'first_name',
      header: 'First Name',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'last_name',
      header: 'Last Name',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'group',
      header: 'Group',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'del_type',
      header: 'Del Type',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'planned_date',
      header: 'Planned Date',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'driver',
      header: 'Driver',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'eta',
      header: 'ETA',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'delivery_date',
      header: 'Delivery Date',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'details',
      header: 'Details',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    const payload = {
      start_date: `${String(startDate.getMonth() + 1).padStart(2, '0')}/${String(
        startDate.getDate()
      ).padStart(2, '0')}/${startDate.getFullYear()}`,
      start_time: formData.startTime || '00:00',
      cutoff_time: formData.cutOffTime || '23:59'
      // routes: selectedOptions, // Include selected routes for filtering
    };
    const tokenStr = localStorage.getItem('accessToken');
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/route4me/order-search/`,
      payload,
      { headers: { Authorization: `Bearer ${tokenStr}` } }
    );
    const results = data.data.map((iterator) => ({
      route: iterator.route,
      first_name: iterator.first_name,
      last_name: iterator.last_name,
      group: iterator.alias,
      phone: iterator.phone,
      del_type: iterator.del_type,
      planned_date: iterator.tobe_delivered_datetime
        ? moment(iterator.tobe_delivered_datetime).format('MM/DD/YY')
        : null,
      eta: iterator.service_time,
      delivery_date: iterator.delivery_date,
    }));
    setColumnData(results);
    setFilteredData(results);
  };

  const handleExportCSV = () => {
    console.log('Export to CSV clicked');
  };

  const handleSendToRoute4Me = () => {
    console.log('Send to Route4Me clicked');
  };

  const handleUploadSignature = () => {
    console.log('Upload Signature to IPS clicked');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function fetchData() {
    let tokenStr = localStorage.getItem('accessToken');
    const payload = {
      start_date: `${String(startDate.getMonth() + 1).padStart(2, '0')}/${String(
        startDate.getDate()
      ).padStart(2, '0')}/${startDate.getFullYear()}`,
      start_time: '00:00',
      cutoff_time: '23:59'
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/route4me/order-search/`,
      payload,
      { headers: { Authorization: `Bearer ${tokenStr}` } }
    );

    const results = data.data.map((iterator) => ({
      route: iterator.route,
      first_name: iterator.first_name,
      last_name: iterator.last_name,
      group: iterator.alias,
      phone: iterator.phone,
      del_type: iterator.del_type,
      planned_date: iterator.tobe_delivered_datetime
        ? moment(iterator.tobe_delivered_datetime).format('MM/DD/YY')
        : null,
      eta: iterator.service_time,
      delivery_date: iterator.delivery_date
    }));

    setColumnData(results);
    setFilteredData(results);
  }

  const handleOptionChange = (e) => {
    const { value, checked } = e.target;

    const updatedOptions = checked
      ? [...selectedOptions, value]
      : selectedOptions.filter((option) => option !== value);

    setSelectedOptions(updatedOptions);
    if (updatedOptions.length > 0) {
      const filtered = columnData.filter((item) =>
        updatedOptions.includes(item.route)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(columnData);
    }
  };

  const table = useAdvanceTable({
    data: filteredData,
    columns,
    selection: false,
    sortable: true,
    pagination: true
  });

  return (
    <>
      <Card className="p-4">
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                type="time"
                name="startTime"
                value={formData.startTime || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Cutoff Time</Form.Label>
              <Form.Control
                type="time"
                name="cutOffTime"
                value={formData.cutOffTime || ''}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              {['FTCH1', 'WTM1', 'WTM2', 'WTMB', 'BRCK', 'OTHER'].map((option) => (
                <Form.Check
                  key={option}
                  inline
                  label={option}
                  value={option}
                  onChange={handleOptionChange}
                />
              ))}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex gap-2">
            <Button variant="primary" onClick={handleSearch}>
              Search
            </Button>
            <Button variant="info" onClick={handleExportCSV}>
              Export to CSV
            </Button>
            <Button variant="secondary" onClick={handleSendToRoute4Me}>
              Send to Route4Me
            </Button>
            <Button variant="success" onClick={handleUploadSignature}>
              Upload Signature to IPS
            </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row className="mt-3">
          <AdvanceTableProvider {...table}>
            <Row>
              <Col xs="12" className="mb-1 mt-1">
                <AdvanceTable
                  headerClassName="bg-200 text-nowrap align-middle text-center"
                  rowClassName="text-nowrap align-middle text-center"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Card.Footer>
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
              </Card.Footer>
            </Row>
          </AdvanceTableProvider>
        </Row>
      </Card>
    </>
  );
};

export default DataTransferRoute;
