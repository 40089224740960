import axios from "axios";
import DemoFileUpload from "components/orderdetail/DemoFileUpload";
import moment from "moment";
import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const RefillNoModal = ({ show, handleClose, orderData, onRefillSuccess }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [holdUntil, setHoldUntil] = useState(null);
  const [note, setNote] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUpload, setFileUploadFlag] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
    setUploadedFiles([]);
    setHoldUntil(null);
    setErrors({});
  };

  const handleFileChange = (files) => {
    setUploadedFiles(files);
    setFileUploadFlag(false);
  };

  const validateForm = () => {
    let validationErrors = {};

    if (!selectedReason) {
      validationErrors.selectedReason = "Reason is required.";
    }

    if (selectedReason === "2" && !holdUntil) {
      validationErrors.holdUntil = "Hold Until date is required.";
    }

    if ((selectedReason === "3" || selectedReason === "1") && uploadedFiles.length === 0) {
      validationErrors.uploadedFiles = "Document is required.";
      setFileUploadFlag(true);
    }

    if (!note.trim()) {
      validationErrors.note = "Note is required.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    let tokenStr = localStorage.getItem("accessToken");

    const payload = {
      reminder_status: 2,
      id: orderData?.orderId,
      change_type: selectedReason,
      hold_till: selectedReason === "2" ? moment(holdUntil).format("MM/DD/YYYY") : null,
      change_note: note,
    };

    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value !== null) formData.append(key, value);
    });

    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append("change_document", file, file.name);
      });
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/orders/update-refill-reminder/`,
        formData,
        { headers: { Authorization: `Bearer ${tokenStr}` } }
      );

      if (response.status) {
        toast.success("Refill request saved successfully!");

        // Remove patient from parent table
        if (onRefillSuccess) {
          onRefillSuccess(orderData?.orderId);
        }

        handleClose();
      } else {
        toast.error("Failed to save refill request.");
      }
    } catch (error) {
      toast.error("Error submitting request. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setSelectedReason("");
    setHoldUntil(null);
    setNote("");
    setUploadedFiles([]);
    setFileUploadFlag(false);
    setErrors({});
    setIsSubmitting(false);
  };

  return (
    <Modal show={show} onHide={() => { resetForm(); handleClose(); }} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-danger text-center fw-bold">
          Reason for not refilling the prescription
        </p>

        <Form onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Col xs={12}>
              <Form.Label>Patient Name</Form.Label>
              <Form.Control type="text" value={orderData?.patient} readOnly />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={12}>
              <Form.Label>Drug</Form.Label>
              <Form.Control type="text" value={orderData?.drug} readOnly />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={12}>
              <Form.Label>RX</Form.Label>
              <Form.Control type="text" value={orderData?.rx} readOnly />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col xs={12}>
              <Form.Label>Select Reason</Form.Label>
              <Form.Select
                value={selectedReason}
                onChange={handleReasonChange}
                isInvalid={!!errors.selectedReason}
              >
                <option value="">Select Reason</option>
                <option value="1">Other</option>
                <option value="2">Hold</option>
                <option value="3">DC</option>
              </Form.Select>
              {errors.selectedReason && <div className="text-danger mt-1">{errors.selectedReason}</div>}
            </Col>
          </Row>

          {/* Show File Upload for DC and Other */}
          {(selectedReason === "3" || selectedReason === "1") && (
            <>
              <Row className="mb-2">
                <Col xs={12}>
                  <Form.Label>Document</Form.Label>
                  <DemoFileUpload onFilesChange={handleFileChange} textOfDoc="Required Document" errorFlag={fileUpload} />
                  {errors.uploadedFiles && <div className="text-danger mt-1">{errors.uploadedFiles}</div>}
                </Col>
              </Row>
              <p className="text-danger fw-bold small">
                * Please attach {selectedReason === "3" ? "DC Order" : "Document"}.
              </p>
            </>
          )}

          {/* Show Date Picker for Hold */}
          {selectedReason === "2" && (
            <Row className="mb-2">
              <Col xs={12}>
                <Form.Label>Hold Until</Form.Label>
                <DatePicker
                  selected={holdUntil}
                  onChange={(date) => setHoldUntil(date)}
                  className="form-control"
                  dateFormat="MM/dd/yy"
                  placeholderText="Select Date"
                  minDate={new Date()}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
                  isInvalid={!!errors.holdUntil}
                />
                {errors.holdUntil && <div className="text-danger mt-1">{errors.holdUntil}</div>}
              </Col>
            </Row>
          )}

          <Row className="mb-2">
            <Col xs={12}>
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                isInvalid={!!errors.note}
              />
              {errors.note && <div className="text-danger mt-1">{errors.note}</div>}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={6}>
              <Button variant="primary" type="submit" className="w-100" disabled={isSubmitting}>
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Col>
            <Col xs={6}>
              <Button variant="secondary" onClick={() => { resetForm(); handleClose(); }} className="w-100" disabled={isSubmitting}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RefillNoModal;
