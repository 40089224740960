import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import OrderDetailModal from './OrderDetailModal';
import Select from "react-select";
import OrderDetailModalParent from './OrderDetailModalParent';
import moment from 'moment';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import { useAppContext } from 'providers/AppProvider';
import SetMastersDetails from 'components/common/SetMastersDetails';
import Breadcrumb from 'components/doc-components/Breadcrumb';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import DatePicker from 'react-datepicker';
import RemoveMasters from 'components/common/RemoveMasters';

const OrderDetails = () => {
  const [refreshOrder, setRefreshOrder] = useState(false);
  const [facility, setFacility] = useState(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]); // Data from the API
  const [filteredData, setFilteredData] = useState([]); // Filtered data to display
  const [errorMessage, setErrorMessage] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').format('MM/DD/YYYY'),
    endDate: moment().endOf('day').format('MM/DD/YYYY'),
    patient: '',
    rxNumber: '',
    category: '',
    initiated: ''
  });
  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();
  const isFirstRender = useRef(true);
  const orderStatus = localStorage.getItem("order_status")
  ? JSON.parse(localStorage.getItem("order_status"))
  : {}; 
  const facilityList = JSON.parse(localStorage.getItem("facilitiesData"));
  const [options] = useState(facilityList);

  let facilityObj = facilityList.reduce((obj, item) => {
    obj[item.value] = item.key;
    return obj;
  }, {})

  const columns = [
    {
      accessorKey: 'patient',
      header: 'Patient',
      meta: {
        headerProps: { className: 'text-900 text-start px-2' },
        cellProps: { className: 'text-start px-2' }
      }
    },
    {
      accessorKey: 'facility',
      header: 'Facility',
      meta: {
        headerProps: { className: 'text-900 text-start px-2' },
        cellProps: { className: 'text-start px-2' }
      }
    },
    {
      accessorKey: 'ordertypetable',
      header: 'Order Type',
      meta: {
        headerProps: { className: 'text-900 text-center px-2' },
        cellProps: { className: 'text-center px-2' }
      }
    },
    {
      accessorKey: 'deliverydatetime',
      header: 'Delivery-Date',
      meta: {
        headerProps: { className: 'text-900 text-center px-2' },
        cellProps: { className: 'text-center fw-bold px-2' }
      }
    },
    {
      accessorKey: 'orderstatus',
      header: 'Order Status',
      meta: {
        headerProps: { className: 'text-900 text-center px-2' },
        cellProps: { className: 'text-center px-2' }
      },
      cell: ({ row: { original } }) => {
        const orderStatus = JSON.parse(localStorage.getItem("order_status"));
        return (
          <Link onClick={() => { setShow(true) }} state={{ original }}>
            {orderStatus[original.orderstatus]}
          </Link>
        )
      }
    },
    // {
    //   accessorKey: 'reason',
    //   header: 'Reason',
    //   meta: {
    //     headerProps: { className: 'text-900 text-start' },
    //     cellProps: { className: 'text-start' }
    //   }
    // },
    // {
    //   accessorKey: 'refillrequest',
    //   header: 'Refill Request',
    //   meta: {
    //     headerProps: { className: 'text-900 text-start' },
    //     cellProps: { className: 'text-start' }
    //   }
    // },
    {
      accessorKey: 'createddate',
      header: 'Created Date',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    // {
    //   accessorKey: 'moredetails',
    //   header: 'More Details',
    //   meta: {
    //     headerProps: { className: 'text-900 text-start' },
    //   },
    //   cell: () => {
    //     return (
    //       <FontAwesomeIcon icon="file" className="me-1 fs-11" />
    //     )
    //   }
    // },
  ]

  const fetchTable = async () => {
    setIsLoading(true);
    setApiError(false);

    let sendingObj = {
      from_date: filters.startDate,
      to_date: filters.endDate
    }
    let tokenStr = localStorage.getItem("accessToken");
    try{
    const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/list-orders/", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    let orderType = JSON.parse(localStorage.getItem("order_types"));
    let objData = data.orders
    let results = []
    objData.map((iterator) => {
      results.push({
        patient: iterator.patient_lastname + ", " + iterator.patient_firstname,
        facility: facilityObj[iterator.facility_id],
        facilityid: iterator.facility_id,
        ordertype: iterator.order_type,
        ordertypetable: iterator.order_type == 9 ? "New Patient" : orderType[iterator.order_type],
        deliverydatetime: iterator.delivery_date != null ? moment(iterator.delivery_date).format('MM/DD/YY') : null,
        reason: iterator.discarded_reason,
        refillrequest: iterator.refill_request,
        createdby: iterator.created_by_username,
        createddate: iterator.created != null ? moment(iterator.created).format('MM/DD/YY - hh:mm A') : null,
        orderstatus: iterator.order_status,
        rxnumber: iterator.pharmacy_order_id,
        qty: iterator.drug_qty,
        drug: iterator.drug,
        orderid: iterator.id,
        notesObj: iterator.notes,
        documents : iterator.documents,
        internalNotesObj: iterator.internal_notes,
        is_billing_issue: iterator.is_billing_issue,
        is_loansupplies: iterator.is_loansupplies,
        is_pa: iterator.is_pa,
        flag: true,
        patientModalData: iterator.patient
      })
    })
    setData(results);
    setFilteredData(results);
  } catch (error) {
    console.error('Error fetching data:', error);
    setApiError(true);
    toast.error("Failed to fetch order details.");
  } finally {
    setIsLoading(false);
  }
  }

  const searchByDate = async () => {
    try {
      fetchTable()
    } catch (error) {
      console.error('Error fetching data by date:', error);
      toast.error('Failed to fetch data by date');
    }
  };

  const table = useAdvanceTable({
    data: filteredData,
    columns,
    selection: false,
    sortable: true,
    pagination: true
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    filterLocalData(filters);
  }, [filters]);

  useEffect(() => {
    setConfig('isNavbarVerticalCollapsed', true);
    fetchData();

    return () => {
      setConfig('isNavbarVerticalCollapsed', false);
      RemoveMasters();
    }
  }, [refreshOrder]);



  const fetchData = async () => {
    try {
      SetMastersDetails();
      fetchTable();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters({
      ...filters,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleFacilityInputChange = (selectedOption) => {
    setFacility(selectedOption);
    setFilters({
      ...filters,
      "facility_ID": selectedOption ? selectedOption.value : ''
    });
  }

  async function handleSearch(e) {
    e.preventDefault();
    if (filters.startDate && filters.endDate) {
      await searchByDate();
    }
  };


  const filterLocalData = (filters) => {
    const filtered = data.filter((item) => {
      return (
        (!filters.patient || item.patient.toLowerCase().includes(filters.patient.toLowerCase())) &&
        (!filters.rxNumber || item.rxnumber === Number(filters.rxNumber)) &&
        (!filters.category || item.orderstatus === Number(filters.category)) &&
        (!filters.facility_ID || item.facilityid === filters.facility_ID) &&
        (!filters.initiated || item.initiated === filters.initiated)
      );
    });
    if (filtered.length) {
      setFacility(null);
      setFilteredData(filtered);
    }
  };

  const validateExpiryDate = date => {
    if (moment(date) > moment(filters.startDate)) {
      handleInputChange({ target: { name: 'endDate', value: moment(date).format("MM/DD/YYYY") } })
    } else {
      toast.error("End date should be greater than Start Date");
    }
  }

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Order', href: '#', isActive: true },
    { name: 'Order List', href: "#", isActive: true }
  ];

  return (
    <>

      <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
      <OrderDetailModalParent show={show} filteredData={filteredData} setRefresh={setFilteredData} handleClose={() => setShow(false)} setOrder={setRefreshOrder} />
      <Card className="p-3">
        <h4 className="mb-3">Order List</h4>
        <div className="border rounded p-3 pb-4 mb-4">
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Start Date:</Form.Label>
                <DatePicker
                  placeholderText="mm/dd/yy"
                  className="form-control"
                  selected={filters.startDate ? moment(filters.startDate).format("MM/DD/YYYY") : null}
                  onChange={(date) =>
                    handleInputChange({ target: { name: 'startDate', value: moment(date).format("MM/DD/YYYY") } })
                  }
                  dateFormat="MM/dd/yy"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>End Date:</Form.Label>
                <DatePicker
                  placeholderText="mm/dd/yy"
                  className="form-control"
                  selected={filters.endDate ? moment(filters.endDate).format("MM/DD/YYYY") : null}
                  onChange={(date) =>
                    validateExpiryDate(date)
                  }
                  dateFormat="MM/dd/yy"
                />
              </Form.Group>
            </Col>
            <Col md={4} className="d-flex align-items-end">
              <Button variant="primary" onClick={handleSearch} className="me-2">
                Search
              </Button>
            </Col>
          </Row>
        </div>
        <div className="filters-section border rounded pt-4  p-3 position-relative mb-4">
          <div className="filter-heading">
            Filters
          </div>
          <Row className="mb-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label>Patient:</Form.Label>
                <Form.Control
                  type="text"
                  name="patient"
                  placeholder="Search By Patient"
                  value={filters.patient}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Rx Number:</Form.Label>
                <Form.Control
                  type="text"
                  name="rxNumber"
                  placeholder="Search By Rx Number"
                  value={filters.rxNumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Initiated:</Form.Label>
                <Form.Select
                  name="initiated"
                  value={filters.initiated}
                  onChange={handleInputChange}
                >
                  <option value="">All</option>
                  <option value="Pharmacy">Pharmacy</option>
                  <option value="Facility">Facility</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Category:</Form.Label>
                <Form.Select
                  name="category"
                  value={filters.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  {Object.entries(orderStatus).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Facility:</Form.Label>
                <Select
                  name="facility"
                  closeMenuOnSelect={true}
                  placeholder="Select Facility"
                  classNamePrefix="react-select"
                  options={options}
                  getOptionLabel={(option) => option.key}
                  getOptionValue={(option) => option.value}
                  onChange={handleFacilityInputChange}
                  value={facility}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Card>
      <br></br>
      {isLoading ? (
        <ShimmerUI />
      ) : apiError ? (
        <Alert variant="danger" className="text-center fw-bold">
          Failed to fetch order details. Please try again later.
        </Alert>
      ) : filteredData.length === 0 ? (
        <Alert variant="info" className="text-center fw-bold">
          No order details found for the selected date range.
        </Alert>
      ) : (
        <Card className="p-3">
          <AdvanceTableProvider {...table}>
            <Row>
              <Col xs="12">
                <AdvanceTable
                  headerClassName="bg-200 text-nowrap align-middle text-center"
                  rowClassName="text-nowrap align-middle text-center"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Card.Footer>
                <div className="mt-3">
                  <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
                </div>
              </Card.Footer>
            </Row>
          </AdvanceTableProvider>
        </Card>
      )}
    </>
  );
};

export default OrderDetails;
