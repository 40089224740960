import React, { useEffect , useState} from 'react'
import FacilityLookUpHeader from './FacilityLookUpHeader'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import EditFacilityLookup from './EditFacilityLookup';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';

const FacilityListLookUp = () => {
  const [profileData, setProfileData] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    fetchFacilities(id)
  }, [])

  async function fetchFacilities(id) {
    let tokenStr = localStorage.getItem("accessToken");
    
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/group-profile/` + id, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
      .then((res) => {
        setProfileData(res.data.data[0]);
      }); 
  }

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Groups&Facility', href: paths.faciliityLookUp, isActive: false },
    { name: 'Group-Profile', href: `${paths.facilitylistlookUp}/${id}`, isActive: true }
];
  return (
    <>
      {profileData != null ?
        <>
          <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />
          <FacilityLookUpHeader data={profileData} />
          <br></br>
          <EditFacilityLookup id={id} />
        </> : <ShimmerUI />}
    </>
  )
}

export default FacilityListLookUp