import React from 'react'

const RemoveMasters = () => {
    localStorage.removeItem("order_status_internal")
    localStorage.removeItem("order_status")
    localStorage.removeItem("order_types")
    localStorage.removeItem("delivery_types")
    localStorage.removeItem("order_status_facility")
}

export default RemoveMasters