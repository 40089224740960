import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Table, Pagination } from 'react-bootstrap';

const DeliveryDriverSetup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    ipsId: '',
  });

  const [searchQuery, setSearchQuery] = useState('');

  const sampleData = [
    { firstName: 'Abner', lastName: 'Lebrun', email: 'alebrun@scriptpac.com', ipsId: '6042', status: 'Active' },
    { firstName: 'Abraham', lastName: 'acevedo', email: 'aacevedo@scriptpac.com', ipsId: '3016', status: 'Active' },
    { firstName: 'AGA', lastName: 'CHOJNOWSA', email: 'achojnowska@scriptpac.com', ipsId: '495', status: 'Active' },
    { firstName: 'ALDO', lastName: 'dallaku', email: 'adallaku@scriptpac.com', ipsId: '2914', status: 'Active' },
  ];

  const [data, setData] = useState(sampleData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEdit = (row) => {
    setFormData({
      firstName: row.firstName,
      lastName: row.lastName,
      email: row.email,
      ipsId: row.ipsId,
    });
  };

  const handleFormSubmit = () => {
    console.log('Form submitted:', formData);
    setData((prevData) =>
      prevData.map((item) =>
        item.ipsId === formData.ipsId ? { ...item, ...formData } : item
      )
    );
    handleCancel();
  };

  const handleCancel = () => {
    setFormData({ firstName: '', lastName: '', email: '', ipsId: '' });
  };

  return (
    <div className="p-4">
      <h4>Delivery Driver</h4>
      <Card className="mb-4 p-3">
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>First Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Last Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>IPS Internal ID *</Form.Label>
              <Form.Control
                type="text"
                placeholder="IPS Internal ID"
                name="ipsId"
                value={formData.ipsId}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button variant="primary" onClick={handleFormSubmit}>
              Save
            </Button>{' '}
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Card>

      <Card className="p-3">
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Show</Form.Label>
              <Form.Select>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} className="text-end">
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
        </Row>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>IPS Internal ID</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.ipsId}</td>
                <td>{item.status}</td>
                <td>
                  <Button variant="link" onClick={() => handleEdit(item)}>
                    Edit
                  </Button>{' '}
                  |{' '}
                  <Button
                    variant="link"
                    onClick={() =>
                      setData((prevData) =>
                        prevData.map((row) =>
                          row.ipsId === item.ipsId
                            ? { ...row, status: row.status === 'Active' ? 'Inactive' : 'Active' }
                            : row
                        )
                      )
                    }
                  >
                    {item.status === 'Active' ? 'Inactive' : 'Active'}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination>
          <Pagination.Prev />
          <Pagination.Item>1</Pagination.Item>
          <Pagination.Item>2</Pagination.Item>
          <Pagination.Item>3</Pagination.Item>
          <Pagination.Next />
        </Pagination>
      </Card>
    </div>
  );
};

export default DeliveryDriverSetup;
