import React, { useState } from 'react';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from "moment";
import { Link } from 'react-router-dom';


const MedicalRecordTablePrescription = (props) => {
    const [formFlags, setFormFlags] = useState(null);
    const columns = [
        {
            accessorKey: 'medrecord_id',
            header: 'PrescriptionID',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            },
            cell: ({ row: { original } }) => { 
                return <Link to={{ pathname: paths.editMedicalRecord }} state={{
                    dataObj : original,
                    flagsObj : formFlags,
                    medrecord_id: original.medrecord_id, patientid: original.patientid, facilityid: original.facilityid
                }} >{original.medrecord_id}</Link>
            }
        },
        {
            accessorKey: 'category',
            header: 'Category',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'start',
            header: 'Start Date',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'stop',
            header: 'Stop Date',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'medsheet',
            header: 'Medsheet',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'treatment',
            header: 'Treatment',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'physician_order',
            header: 'Physician Order',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'frequency',
            header: 'Frequency',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'time1',
            header: 'Time 1',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'time2',
            header: 'Time 2',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'time3',
            header: 'Time 3',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'time4',
            header: 'Time 4',
            meta: {
                headerProps: { className: 'text-900' }
            }
        }
    ];
    //const [facility] = useState(facilityStaffData);
    const [medicalrecordPrescription, setMedicalRecordPrescription] = useState([]);

    useEffect(() => {

        var medRecCat = {};

        async function fetchMedicalRecordCategoryData() {

            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-medical-record-category", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = []

            console.log("Medical Record Category Table Status " , data);
            let objData = data.data;
            setFormFlags(objData[0])
            objData.forEach((value) => {
                console.log("Medical Record Category Table Value " , value);
                medRecCat[value.id] = value.code;
            });

        }

        medRecCat["6"] = "Some BP Stuff";


        async function fetchMedicalRecordData() {

            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-medical-record/" + props.patientid, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = []

            //console.log("Medical Record Table Status " + data.status);
            let objData = data.data;
            let groupId = "";

            objData.forEach((value) => {
                //groupId = value.group_id;
                results.push({
                    medrecord_id: value.id,
                    category_id : value.medical_record_category_id,
                    category: medRecCat.hasOwnProperty(value.medical_record_category_id)? medRecCat[value.medical_record_category_id] : "-",  
                    // category : value.medical_record_category_desc || '-',
                    start: value.start_date != null ? moment(value.start_date, 'MM/DD/YYYY').format('MM/DD/YY') : null,
                    stop: value.stop_date != null ? moment(value.stop_date, 'MM/DD/YYYY').format('MM/DD/YY') : null,
                    description: value.description,
                    patientid: props.patientid,
                    facilityid: props.facilityid,
                    medsheet: value.mar_flag ? "Y" : "N",
                    treatment: value.tar_flag ? "Y" : "N",
                    physician_order: value.po_order ? "Y" : "N",
                    //physician_order: value.po_order ? value.po_order : "-",
                    frequency: value.frequency_name,
                    time1: value.time1,
                    time2: value.time2,
                    time3: value.time3,
                    time4: value.time4,
                    verifiedby: value.verified_by,
                    verified: value.verified_date
                });
            });

            //localStorage.setItem("groupId", groupId);

            setMedicalRecordPrescription(results);
        }

        // Trigger the fetch
        fetchMedicalRecordCategoryData();
        fetchMedicalRecordData();
    }, []);

    const table = useAdvanceTable({
        data: medicalrecordPrescription,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <>
        {medicalrecordPrescription.length > 0 ?<AdvanceTableProvider {...table}>
            <AdvanceTable
                headerClassName="bg-200 text-nowrap align-middle text-center"
                rowClassName="text-nowrap align-middle text-center"
                tableProps={{
                    size: 'lg',
                    striped: true,
                    className: 'fs-10 mb-0'
                }}
            />
            <div className="mt-3">
                <AdvanceTableFooter
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
            </div>
        </AdvanceTableProvider> : <p className="text-danger text-center fw-bold">No data available in table</p>}
        </>
    );
};

export default MedicalRecordTablePrescription;
