import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import IconItem from 'components/common/icon/IconItem';
import AddNotesModal from './AddNotesModal';
import DrugsModal from './DrugsModal';
import { toast } from 'react-toastify';
import IframeContainer from 'components/navbar/top/IframeContainer';

const CensusGrid = (props) => {
    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [drugsData, setDrugsData] = useState([]);
    const [medChecked, setMedCheck] = useState(false);
    const [skipCycle, setSkipCycle] = useState(false);
    const [tableGridData, setTableGridData] = useState([]);
    const [showIframe, setShowIframe] = useState(false);
    const [iframeKey, setIframeKey] = useState(0);
    const [patientDetails, setPatientDetails] = useState({});

    const updatePatientDetails = (updatedPatientDetails) => {
        setPatientDetails(updatedPatientDetails);
    };
        
    const columns = [
        {
          accessorKey: 'patient',
          header: 'Patient',
          meta: {
            headerProps: { className: 'text-900 text-start px-2' },
            cellProps: { className: 'text-start px-2' }
          }
        },
        {
          accessorKey: 'sign_off',
          header: '  Status',
          meta: {
            headerProps: { className: 'text-900 text-start px-2' },
            cellProps: { className: 'text-start px-2' }
          },
          cell: ({ row: { original } }) => {
            return (
              <Form.Group>
                <Form.Select
                  name={`census_status${original.index}`}
                  value={original.census_status}
                  onChange={(e) => handleRowData(e.target.value, "census_status", original.index)}
                  size="sm"
                >
                  <option value="0">Pending</option>
                  <option value="1">Active</option>
                  <option value="2">Discharged</option>
                  <option value="3">Deceased</option>
                  <option value="4">MLOA</option>
                </Form.Select>
              </Form.Group>
            );
          }
        },
        {
          accessorKey: 'status',
          header: 'D/C Label Change or Split Packs',
          meta: {
            headerProps: { className: 'text-900 text-start px-2' },
            cellProps: { className: 'text-start px-2' }
          },
          cell: ({ row: { original } }) => (
            <Row>
              <Col xs="4">
                <Form.Check
                  type="radio"
                  name={`pack${original.index}`}
                  id={`is_yes${original.index}`}
                  label="Yes"
                  checked={original.sign_off === 2}
                  onChange={() => handleRowData(2, "sign_off", original.index)}
                  onClick={(e) => handleEditDrugsData(e, original.refilldrugsdata, original.patient, original.index, original.patientid)}
                />
              </Col>
              <Col xs="4">
                <Form.Check
                  type="radio"
                  name={`pack${original.index}`}
                  id={`is_no${original.index}`}
                  label="No"
                  checked={original.sign_off === 1}
                  onChange={() => handleRowData(1, "sign_off", original.index)}
                />
              </Col>
            </Row>
          )
        },
        {
          accessorKey: 'facility_notes',
          header: 'Facility Note',
          meta: {
            headerProps: { className: 'text-900 text-start px-2' },
            cellProps: { className: 'text-start px-2' }
          },
          cell: ({ row: { original } }) => truncateText(original.facility_notes)
        },
        {
          accessorKey: 'pharmacy_notes',
          header: 'Pharmacy Note',
          meta: {
            headerProps: { className: 'text-900 text-start px-2' },
            cellProps: { className: 'text-start px-2' }
          },
          cell: ({ row: { original } }) => truncateText(original.pharmacy_notes)
        },
        {
          accessorKey: 'action',
          header: 'Notes',
          meta: {
            headerProps: { className: 'text-900 text-center px-2' },
            cellProps: { className: 'text-center px-2' }
          },
          cell: ({ row: { original } }) => (
            <IconItem
              tag="button"
              icon={['far', 'edit']}
              size="sm"
              onClick={() => handleAddNotes(original.patient, original.patientid)}
              className="btn text-center rounded-3 me-2 fs-11 align-items-center"
            />
          )
        }
      ];
      

    const [showModal, setShowModal] = useState(false);
    const [showDrugsModal, setShowDrugsModal] = useState(false);
    const [refill, setRefill] = useState([]);

    const truncateText = (text, length = 30) => {
        return text && text.length > length ? text.substring(0, length) + "..." : text;
    };

    const handleAddNotes = (pname, pid) => {
        setPatientName(pname);
        setPatientId(pid);
        setShowModal(true);
    };

    // function handleEditDrugsData(e, drugsdata, pname, index, pid) {
    //     if (e.target.checked) {
    //         handleRowData(0, "sign_off", index);
    //         setPatientId(pid);
    //         setPatientName(pname);
    //         setDrugsData(drugsdata);
    //         setShowDrugsModal(true);
    //     }
    // }

    function handleEditDrugsData(e, drugsdata, pname, index, pid) {
        if (e.target.checked) {
            setPatientId(pid);
            setPatientName(pname);
            setDrugsData(drugsdata);
            setShowDrugsModal(true);
    
            setRefill((prevRefill) => {
                let newRefill = [...prevRefill];
                if (newRefill[index].sign_off !== 2) { // Prevent toggling off
                    newRefill[index] = { ...newRefill[index], sign_off: 2 };
                }
                return newRefill;
            });
        }
    }


    const handleRadioChecked = (e) => {
        if (e.target.checked) {
            setMedCheck(true);
        } else {
            setMedCheck(false);
        }
    }

    // const handleSkipMedsChecked = (e) => {

    //     let isChecked = e.target.checked;
    //     setSkipCycle(isChecked);
    //     let updatedRefill = refill.map((item) => ({
    //         ...item,
    //         sign_off: isChecked ? 1 : item.sign_off // Set all to "No" (1)
    //     }));

    //     setRefill(updatedRefill);
        

    // }

    const handleSkipMedsChecked = (e) => {
        setSkipCycle(e.target.checked);
        setRefill((prevRefill) =>
            prevRefill.map((item) => ({ ...item, sign_off: e.target.checked ? 1 : item.sign_off }))
        );
    };

    useEffect(() => {
        if (props.patientdetails_obj && Object.keys(props.patientdetails_obj).length > 0) {
            setPatientDetails(props.patientdetails_obj);
        }
    }, [props.patientdetails_obj]);

    useEffect(() => {
        if (Object.keys(patientDetails).length > 0) {
            fetchPatientData();
        }
    }, [patientDetails]);


    const fetchPatientData = () => {
        let results = [];
        let ctr = 0;

        for (let patient in patientDetails) {

            let patientlistObj = patientDetails[patient];
            let refillObj = [];
            let cyclepatientid = "";

            refillObj.push(patientlistObj["prescriptions"].will_be_filled)


            refillObj.forEach(function (arrayItem) {
                for (let singleitem in arrayItem) {
                    cyclepatientid = arrayItem[singleitem].cycle_patient_id;
                }
            });

            // console.log("patientlistObj" , patientlistObj);
            // console.log("refillObj" , refillObj);

            results.push({
                index: ctr++,
                patient: patientlistObj["patient_first"] + " " + patientlistObj["patient_last"],
                refilldrugsdata: refillObj,
                patientid: patientlistObj.patient_id,
                sign_off: patientlistObj["sign_off"],
                census_status: patientlistObj["census_status"],
                facility_notes: patientlistObj["facility_notes"],
                pharmacy_notes: patientlistObj["pharmacy_notes"]
            })

        }

        //console.log("results " + results);
        setRefill(results);
        //setTableGridData(results);
        // localStorage.setItem("censusgridresults", JSON.stringify(results));

        //console.log("tableGridData" + tableGridData);
    };

    const handleAllActive = () => {
        setRefill((prevRefill) => 
            prevRefill.map((item) => ({
                ...item,
                census_status: 1,
            }))
        );
        setSkipCycle(false);
    };

    // const handleRowData = (gridControlValue, gridControlHeader, gridControlIndex) => {
    //     // let tempArrData = JSON.parse(localStorage.getItem("censusgridresults"));//tableGridData;
    //     // //console.log("tableOpData ip" + JSON.stringify(tempArrData));

    //     // tempArrData.forEach(function (arrayItem, index) {
    //     //     if (index == gridControlIndex) {
    //     //         //console.log("index" + JSON.stringify(arrayItem) + gridControlValue);
    //     //         arrayItem[gridControlHeader] = gridControlValue;

    //     //     }
    //     // });

    //     // //setTableGridData(tempArrData);
    //     // localStorage.setItem("censusgridresults", JSON.stringify(tempArrData));


    //     let tempArrData = [...refill];
    //     tempArrData[index][field] = value;
    //     setRefill(tempArrData);
    //     localStorage.setItem("censusgridresults", JSON.stringify(tempArrData));
    //     //console.log("tableOpData Op" + JSON.stringify(tempArrData));

    // }

    const handleRowData = (value, field, index) => {
        setRefill((prevRefill) => {
            let newRefill = [...prevRefill];
            newRefill[index] = { ...newRefill[index], [field]: value };
            // localStorage.setItem("censusgridresults", JSON.stringify(newRefill));
            return newRefill;
        });
    };

    const handleSave = async (e) => {
        e.preventDefault();
        let tokenStr = localStorage.getItem("accessToken");
        let censusGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-patients`;

        const censusGridData = refill.map(({ patientid, sign_off, census_status }) => ({
            cycle_patient_id: patientid,
            sign_off,
            census_status
        }));

        try {
            const { data } = await axios.post(censusGridUrl, censusGridData, {
                headers: { 'Authorization': `Bearer ${tokenStr}` }
            });

            if (data.status) {
                toast.success("Census Details added successfully");
                // Update UI to reflect changes
                const updatedDetails = { ...patientDetails };
                censusGridData.forEach(({ cycle_patient_id, sign_off, census_status }) => {
                    if (updatedDetails[cycle_patient_id]) {
                        updatedDetails[cycle_patient_id] = {
                            ...updatedDetails[cycle_patient_id],
                            sign_off,
                            census_status
                        };
                    }
                });
                updatePatientDetails(updatedDetails);
            } else {
                toast.error("Error occurred while updating Census Details");
            }
        } catch (error) {
            toast.error("Failed to update census details.");
        }
    };

    const handleIframeToggle = () => {
        setIframeKey(iframeKey + 1);
        setShowIframe(true);
      };

    const table = useAdvanceTable({
        data: refill,
        columns,
        selection: false,
        sortable: false,
        pagination: true
    });

    return (
        <>
            <AdvanceTableProvider {...table}>
                <Row className="mt-3 d-flex align-items-center justify-content-between">
                    <Col xs="3" className="mb-3">
                        <AdvanceTableSearchBox placeholder="Search..." />
                    </Col>
                    <Col xs="3">
                        <Form.Check
                            type="checkbox"
                            name="skipcycle"
                            id="is_skipcycle"
                            label="Skip Cycle med changes for all clients?"
                            checked={skipCycle}
                            onChange={handleSkipMedsChecked}
                        />
                    </Col>
                    <Col xs="2">
                        <Button color="primary" className="w-100" onClick={handleAllActive}>
                            All Active
                        </Button>
                    </Col>
                    <Col xs="2">
                        <Button color="primary" className="w-100" disabled={props.flag} onClick={handleSave}>
                            Save
                        </Button>
                    </Col>
                    <Col xs="2">
                        <div>
                            <Button color="primary" className="w-100" onClick={handleIframeToggle}>
                                Print
                            </Button>
                            {showIframe && <IframeContainer key={iframeKey} id={props?.cycleid} />}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="mb-1 mt-1">
                        <AdvanceTable
                            headerClassName="bg-200 text-nowrap align-middle text-center"
                            rowClassName="text-nowrap align-middle text-center"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs-10 mb-0 overflow-hidden'
                            }}
                        />
                    </Col>
                </Row>
                <Card.Footer>
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </Card.Footer>
            </AdvanceTableProvider>
           {showModal && <AddNotesModal show={showModal} setShow={setShowModal} patientname={patientName} patientid={patientId} notesby="Facility" dataObj={patientDetails} updateDataObj={updatePatientDetails}/>}
            {showDrugsModal && <DrugsModal show={showDrugsModal} setShow={setShowDrugsModal} patientname={patientName} patientid={patientId} drugsdata={drugsData} />}
        </>
    );
};

export default CensusGrid;
	