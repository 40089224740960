import React from 'react'
import { Breadcrumb } from 'react-bootstrap'

const CommonBreadCrumb = (props) => {
const breadcrumbItems = props.breadcrumbItems
    return (
        <Breadcrumb className="breadcrumb px-3 py-2 bg-light rounded mb-2">
            {breadcrumbItems.map((item, index) => (
                <React.Fragment key={index}>
                    <Breadcrumb.Item
                        href={item.href}
                        active={item.isActive}
                        className={item.isActive ? 'text-secondary' : 'text-primary fw-bold'}
                    >
                        {item.name}
                    </Breadcrumb.Item>
                    {index < breadcrumbItems.length - 1 && (
                        <span className="mx-1 text-secondary">{'>'}</span>
                    )}
                </React.Fragment>
            ))}
        </Breadcrumb>
    )
}

export default CommonBreadCrumb