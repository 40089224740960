import React, { useState, useEffect } from 'react';
//import { useLocation, useParams } from 'react-router-dom';
import { Card, Col, Form, Row, Button, Modal} from 'react-bootstrap';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import HoldRxGrid from './HoldRxGrid';
import AddNotesModal from './AddNotesModal';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';

const IndexHold = ({ bodyClassName }) => {


    let initialFormData = {
        group: '',
        facility: '',
        startdate: '',
        enddate: '',
        holdreason: '',
        holdstatus: '', 
        holdtype: '',
        holdsearch: ''
    };

    const [options, setOptions] = useState([{ key: 'Select Facility', value: '' }]);
    
    const [startdate, setStartDate] = useState(moment().subtract('7','days'));
    const [enddate, setEndDate] = useState(moment().add('7','days'));

    
    const [formData, setFormData] = useState(initialFormData);
    const [holdData, setHoldData] = useState([]);
    const [reasonOptions, setReasonsOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [facilityobj, setFacilityObj] = useState({});

    const [holdId, setHoldId] = useState("");
    const [holdCalledNote, setHoldCalledNote] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [getReasonObj, setReasonObj] = useState([]);
    const [getFacilitiesObj, setFacilitiesObj] = useState([]);
    const [holdreasonsresults, setReasonsResults] = useState([{key:"TEST",value:"123"}]);
    const [isLoading, setIsLoading] = useState(true);
    const facilityResultsNew = JSON.parse(localStorage.getItem("facilitiesData"));

    const columns = [
        {
            accessorKey: 'facility',
            header: 'Facility',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'delivery_cycle_date',
            header: 'Delivery/Cycle Date',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'patient',
            header: 'Patient',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'rx_id',
            header: 'Rx',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
          accessorKey: 'drug',
          header: 'Drug',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          }
        },
        {
          accessorKey: 'reason',
          header: 'Reason',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          }
        },
        {
          accessorKey: 'status',
          header: 'Status',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: '' }
          },
          cell: ({ row: { original } }) => {
            return <>
                 <Form.Select
                     name={"hold_id_" + original.hold_id}
                     id={"hold_id_" + original.hold_id}

                     onChange={(e) => { handleHoldStatus(e, original.hold_id, original.rx_id) }}
                     value={original.get_hold_status}
                    >
                        
                        <option value="1" >Hold</option>
                        <option value="0">Unhold</option>
                </Form.Select>                      
              </>
          }
        },
        {
          accessorKey: 'hold_type',
          header: 'Hold Type',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          }
        },
        /*{
          accessorKey: 'done_unhold',
          header: 'Done/Unhold',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          },
          cell: ({ row: { original } }) => {
            return <Form.Check
                type='checkbox'
                name={"hold_id_" + original.hold_id}
                id={"hold_id_" + original.hold_id}
                size="md"
                defaultChecked= {original.status == 'Hold' ? "true" : '' }
                onChange={(e) => { handleHoldStatus(e, original.hold_id, original.rx_id) }}
            />
             }
        },*/
        {
          accessorKey: 'hold_by_date',
          header: 'Hold By/Date	',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          }
        },
        {
          accessorKey: 'called_date_reason',
          header: 'Called Date / Reason',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          }
        },
        {
          accessorKey: 'action',
          header: 'Edit',
          meta: {
              headerProps: { className: 'text-900 text-start' },
              cellProps: { className: 'text-start' }
          },
          cell: ({ row: { original } }) => {
              return <IconItem
                  tag="button"
                  icon={['far', 'edit']}
                  size="sm"
                  onClick={() => handleAddNotes(original.hold_id, original.get_called_reason)}
                  className="btn rounded-3 me-2 fs-11 align-items-center"
              />
          }
        }
      ];

    
    const handleAddNotes = (hid, called_note) => {
        setHoldId(hid);
        setHoldCalledNote(called_note);
        setShowModal(true);
       // console.log(called_note);
    };
    const handleHoldStatus= async (e, hid, rxid) => {

        let is_hold_status = e.target.value;

        let tokenStr = localStorage.getItem("accessToken");
        let holdStatusUrl = `${process.env.REACT_APP_SERVER_URL}/drugs/update-hold-status/`;

        let holdStatusParams = {
            "hold_id": hid,
            "is_hold" : is_hold_status       
        };

        //console.log("Hold Status Data " + JSON.stringify(holdStatusParams));

        const { data } = await axios.post(holdStatusUrl, holdStatusParams, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        //console.log(data.status);

        if (data.status) {
            if (is_hold_status == 0) {
                toast.success('Rx Id: '+rxid+' drug successfully unhold');
            }
            else {
                toast.success('Rx Id: '+rxid+' are drug successfully hold');
            }

            
            for (let index = 0; index < holdData.length; index++) {
                const element = holdData[index];
                
                if(element.hold_id == hid) {
                    element.get_hold_status = is_hold_status;
                   // console.log(element);
                }
            }
           // console.log("holdData", holdData);
            setHoldData([...holdData])
        }
        else {
            toast.error("Getting some server error, please try again");
        }
    }

    useEffect(() => {

        async function populateData() {
            let tokenStr = localStorage.getItem("accessToken");
            const reasonsResults = [];

            try {
                const getreasonsdata = await axios.get(`${process.env.REACT_APP_SERVER_URL}/drugs/list-hold-reasons/`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            
                const getReasonsList = getreasonsdata.data.hold_reasons['data'];
                //console.log(getReasonsList);
                if (getReasonsList.length > 0) {       
                    getReasonsList.forEach((val) => {    
                        if (val.is_active == 1) {
                            reasonsResults.push({
                                key: val.reason,
                                value: val.id
                            });
                        }
                    });
                
                    setReasonsOptions([
                        { key: 'Select Reason', value: '' },
                        ...reasonsResults
                    ]);
    
                    setReasonsResults(reasonsResults);
                    console.log("reasonsResults in index ::: ", reasonsResults)
    
                    var reasonObj = {};
                    reasonsResults.forEach(function (reasonItem) {
                        reasonObj[reasonItem.value] = reasonItem.key
                    });
                    setReasonObj(reasonObj);
                }

                const groupList = JSON.parse(localStorage.getItem("groupsData"));
               
                setOptions([
                    { key: 'All facility', value: '' },
                    ...facilityResultsNew
                ])
                let temp = {};
                const groupResults = groupList.map((group) => {
                    const facilitiesForGroup = facilityResultsNew.filter(
                        (facility) => facility.group_id === group.value
                    );

                    temp[group.value] = facilitiesForGroup.map((facility) => ({
                        name: facility.key,
                        id: facility.value,
                    }));

                    return { key: group.key, value: group.value };
                });

                setGroupOptions([{ key: "Select Group", value: "" }, ...groupResults]);
                setFacilityObj(temp);
                const facilitiesMap = facilityResultsNew.reduce((acc, facility) => {
                    acc[facility.value] = facility.key;
                    return acc;
                }, {});
                setFacilitiesObj(facilitiesMap);
        
            
    
                let holddates = {
                    "start_date": startdate.format('YYYY-MM-DD'),
                    "end_date": enddate.format('YYYY-MM-DD')
                }
    
                const holdGridResults = [];
                const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/drugs/list-hold-drug/`, holddates, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    
            
                const holdListData = data.drug_hold['data'];
                //console.log(holdListData.length);
                if (holdListData.length > 0) {       
                    holdListData.forEach((singleData) => {         
                        holdGridResults.push({
                            hold_id: singleData.id,
                            facility: facilitiesObj[singleData.facility_id],
                            delivery_cycle_date: moment(singleData.due_date, 'YYYY-MM-DD').format('MM/DD/YY'),
                            patient: singleData.patient_lastname+', '+singleData.patient_firstname,
                            rx_id: singleData.pharmacy_order_id,
                            drug: singleData.drug,
                            reason: reasonObj[singleData.holdreason_id],
                            get_hold_status: singleData.is_hold,
                            //status: singleData.is_hold == 0 ? 'Unhold' : 'Hold',
                            hold_type: singleData.is_cycle == 1 ? 'Cycle' : 'Daily',
                            hold_by_date: singleData.is_hold == 1 ?  singleData.hold_by_username+' '+moment(singleData.hold_date, 'YYYY-MM-DD').format('MM/DD/YY') : '-',
                            called_date_reason: singleData.is_called == 1 ? singleData.called_by_username+' '+moment(singleData.called_date, 'YYYY-MM-DD').format('MM/DD/YY')+' '+singleData.called_note : '-',
                            get_called_reason: singleData.called_note != '' ? singleData.called_note : ''
                            
                        });
                        //console.log(holdGridResults);
                        setHoldData(holdGridResults);
                    });
                }


            } catch (error) {
                
            } finally {
                setIsLoading(false);
            }
            
        }
        populateData();
    }, []);

    


    const handleFacilityChange = e => {
        formData.facility = e.value
        setFormData(formData);
    };
    const handleGroupChange = e => {
        if (e.value != "") {
            var groupFacilityResults = [];
            facilityobj[e.value].forEach((group) => {
                groupFacilityResults.push({
                    key: group.name,
                    value: group.id,
                });
            });
            setOptions([
                { key: 'Select Facility', value: '' },
                ...groupFacilityResults
            ]);
            formData.group = e.value
            setFormData(formData);
        }else{
            setOptions([
                { key: 'Select Facility', value: '' },
                ...facilityResultsNew
            ]);
        }
    };
    const handleReasonChange = e => {
        formData.holdreason = e.value
        setFormData(formData);
    };
    const handleHoldStatusGrid = e => {
        formData.holdstatus = e.target.value
        setFormData(formData);
    };
    const handleHoldType = e => {
        formData.holdtype = e.target.value
        setFormData(formData);
    };
    const handleHoldSearch = e => {
        formData.holdsearch = e.target.value
        setFormData(formData);
    };

    const handleSubmit = async e => { 
        e.preventDefault();

        let handleHoldData = {
            group: formData.group,
            facility: formData.facility,
            startdate: moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            enddate: moment(enddate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            holdreason: formData.holdreason,
            holdstatus: formData.holdstatus,
            holdtype: formData.holdtype,
            holdsearch: formData.holdsearch,
        };
        
       
       // console.log(handleHoldData);
        let filteredHoldDates = {
            "start_date": moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            "end_date": moment(enddate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        }
       
       // console.log(handleHoldData);
 
        let tokenStr = localStorage.getItem("accessToken");
        const holdGridResults = [];
        let filteredHolds = [];
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/drugs/list-hold-drug/`, filteredHoldDates, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
     
        const holdListData = data.drug_hold['data'];
        if (holdListData.length > 0) { 
            holdListData.forEach((singleData) => {
                var is_push = true;
                if(handleHoldData.group) {
                    is_push = false;
                    if(handleHoldData.group == singleData.group_id){
                        is_push = true;
                    }
                }                
                if(handleHoldData.facility) {
                    is_push = false;
                    if(handleHoldData.facility == singleData.facility_id){
                        is_push = true;
                    }
                }
                if(handleHoldData.holdreason) {
                    is_push = false;
                    if(handleHoldData.holdreason == singleData.holdreason_id){
                        is_push = true;
                    }
                }
                if(handleHoldData.holdstatus) {
                    is_push = false;
                    if(handleHoldData.holdstatus == singleData.is_hold){
                        is_push = true;
                    }
                }
                if(handleHoldData.holdtype) {
                    is_push = false;
                    if(handleHoldData.holdtype == singleData.is_cycle){
                        is_push = true;
                    }
                }

                /*if(handleHoldData.holdsearch) {
                    is_push = false;
                    handleHoldData.holdsearch = handleHoldData.holdsearch.toLocaleLowerCase()

                    var patient_name = (singleData.patient_lastname+' '+singleData.patient_firstname).toLocaleLowerCase();

                    var facility_name = getFacilitiesObj[singleData.facility_id].toLocaleLowerCase();
                    if(patient_name.search(handleHoldData.holdsearch) >= 0){
                        is_push = true;
                    }

                    if(facility_name.search(handleHoldData.holdsearch) >= 0){
                        is_push = true;
                    }
                }*/


                if(is_push) {
                    holdGridResults.push({
                        hold_id: singleData.id,
                        facility: getFacilitiesObj[singleData.facility_id],
                        delivery_cycle_date: moment(singleData.due_date, 'YYYY-MM-DD').format('MM/DD/YY'),
                        patient: singleData.patient_lastname+', '+singleData.patient_firstname,
                        rx_id: singleData.pharmacy_order_id,
                        drug: singleData.drug,
                        reason: getReasonObj[singleData.holdreason_id],
                        get_hold_status: singleData.is_hold,
                        status: singleData.is_hold == 0 ? 'Unhold' : 'Hold',
                        hold_type: singleData.is_cycle == 1 ? 'Cycle' : 'Daily',
                        hold_by_date: singleData.is_hold == 1 ?  singleData.hold_by_username+' '+moment(singleData.hold_date, 'YYYY-MM-DD').format('MM/DD/YY') : '-',
                        called_date_reason: singleData.is_called == 1 ? singleData.called_by_username+' '+moment(singleData.called_date, 'YYYY-MM-DD').format('MM/DD/YY')+' '+singleData.called_note : '-',
                        get_called_reason: singleData.called_note != '' ? singleData.called_note : ''
                        
                    });
                }



                setHoldData(holdGridResults);
            });
        }
        else {
            setHoldData('');
        }
    }

    
  const table = useAdvanceTable({
    data: holdData,
    columns,
    selection: false,
    sortable: true,
    pagination: true
  });

  if(isLoading) {
    return <>...</>
  }

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Order-Hold', href: paths.indexHold, isActive: true }
];

  return (
    <>
    <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />
    <HoldRxGrid holdreasonsdata={holdreasonsresults} />
    <Card>
      <Card.Body
          className={classNames(bodyClassName, 'h-100')}
          as={Flex}
          direction="column"
          alignItems="between"
      >
        <Form onSubmit={handleSubmit} name="holdsearchform" className="mb-5">
        
          <Row>
            <Col className="col-3">
                  <Form.Group>
                      <Form.Label>Group</Form.Label>
                      <Select
                          name="group"
                          closeMenuOnSelect={true}
                          placeholder='Select Group'                                    
                          classNamePrefix="react-select"  
                          options={groupOptions}
                          getOptionLabel={option => option.key}
                          getOptionValue={option => option.value}
                          onChange={(e) => handleGroupChange(e)}
                      />
                  </Form.Group>
              </Col>  
              <Col className="col-3">
                  <Form.Group>
                      <Form.Label>Facility</Form.Label>
                        <Select
                            name="facility"
                            closeMenuOnSelect={true}
                            placeholder='Select Facility'                                    
                            classNamePrefix="react-select"
                            options={options}
                            getOptionLabel={option => option.key}
                            getOptionValue={option => option.value}
                            onChange={(e) => handleFacilityChange(e)}
                        />
                  </Form.Group>
              </Col>
              <Col className="col-3">
                  <Form.Group>
                      <Form.Label className="required">Start Date</Form.Label>
                      <DatePicker name="startdate" selected={startdate.toDate()} 
                        onChange={(date) => {setStartDate(moment(date))}}
                        className='form-control form-control-sm' placeholderText="Select Start Date" />
                  </Form.Group>
              </Col>
              <Col className="col-3">
                  <Form.Group>
                      <Form.Label className="required">End Date</Form.Label>
                      <DatePicker name="enddate" selected={enddate.toDate()}  
                        onChange={(date) => setEndDate(moment(date))}
                        className='form-control form-control-sm' placeholderText="Select End Date" />
                  </Form.Group>
              </Col>
              <Col className="col-3">
                  <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                          name="holdstatus"
                          size="sm"                          
                          onChange={handleHoldStatusGrid}
                      >
                        <option value="" selected>All</option>
                        <option value="1" >Hold</option>
                        <option value="0">Unhold</option>
                      </Form.Select>
                  </Form.Group>
              </Col>
              <Col className="col-3">
                  <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                          name="holdtype"
                          size="sm"
                          onChange={handleHoldType}
                      >
                        <option value="" selected>All</option>
                        <option value="1">Cycle</option>
                        <option value="0">Daily</option>
                      </Form.Select>
                  </Form.Group>
              </Col>
              <Col className="col-3">
                  <Form.Group>
                      <Form.Label>Reason</Form.Label>
                     <Select
                            name="holdreason"
                            closeMenuOnSelect={true}
                            placeholder='Select Reason'                                    
                            classNamePrefix="react-select"
                            options={reasonOptions}
                            getOptionLabel={option => option.key}
                            getOptionValue={option => option.value}
                            onChange={(e) => handleReasonChange(e)}
                        />
                  </Form.Group>
              </Col>
              <Col className="col-3">
                <Form.Group>
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                      name="holdsearch"
                      type="search"
                      placeholder="Search..."
                      size="sm"
                      aria-label="Search"
                      className="rounded search-input"
                      onChange={handleHoldSearch}
                    />
                  </Form.Group>
              </Col>
          </Row>
          <Row>
              <Col></Col>
              <Col>
                  <Form.Group>
                      <Button
                          type="submit"
                          color="primary"
                          className="w-75 mt-3"
                          disabled={!enddate || !startdate}>
                          Search
                      </Button>
                  </Form.Group>
              </Col>
              <Col></Col>
          </Row>
        </Form>
        <AdvanceTableProvider {...table}>                       
                  <Row>
                      <Col></Col>
                      <Col></Col>
                  </Row>
                  <Row>
                      <Col xs="12" className="mb-1 mt-1">
                          <AdvanceTable
                              headerClassName="bg-200 align-middle text-center"//text-nowrap 
                              rowClassName="align-middle text-center"
                              tableProps={{
                                  size: 'sm',
                                  striped: true,
                                  className: 'fs-10 mb-0 overflow-hidden'
                              }}
                          />
                      </Col>
                  </Row>
              <Card.Footer>
                  <div className="mt-3">
                      <AdvanceTableFooter
                          rowInfo
                          navButtons
                          rowsPerPageSelection
                      />
                  </div>
              </Card.Footer>
              <AddNotesModal show={showModal} setShow={setShowModal} holdid={holdId} called_note={holdCalledNote} />
        </AdvanceTableProvider>
      </Card.Body>
      </Card>

    
    </> 
  );



};

export default IndexHold;