import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import MedSheetForm from './MedSheetForm';
import CurrentMed from './CurrentMed';
import Treatment from './Treatment';
import LoaForm from './LoaForm';
import HcpOrder from './HcpOrder';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';
import Select from "react-select";


const FacilityReportIndex = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {

        populateFacilityDetails();

    }, []);

    async function populateFacilityDetails() {

        //let tokenStr = localStorage.getItem("accessToken");
        //let facilityName = "";

        let facilityResults = JSON.parse(localStorage.getItem("facilitiesData"));
        //console.log("Facility Points " + results);

        setOptions([
            { key: 'Select a facility', value: '' },
            ...facilityResults
        ])

    }

    const handleFieldChange = e => {
        //console.log(e.target.key + "," + e.target.value);
        localStorage.setItem("reportFacilityId", e?.value);
    };

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: props.formname, href: '', isActive: true }
    ];

    return (
        <>
            <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
            <Card className="mb-3">
                <Card.Header as="h6" className="bg-body-tertiary">
                    {props.formname}
                </Card.Header>
                <Card.Body className="p-3">
                    <Row className='justify-content-between'>
                        <Col xs="12">
                            <Form.Group>
                                <Form.Label className="required">Facility</Form.Label>
                                <Select
                                    name="facilityname"
                                    closeMenuOnSelect={true}
                                    placeholder='Select Facility'
                                    classNamePrefix="react-select"
                                    options={options}
                                    getOptionLabel={option => option.key}
                                    getOptionValue={option => option.value}
                                    onChange={handleFieldChange}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: "#ced4da",
                                            boxShadow: "none",
                                            "&:hover": { borderColor: "#80bdff" },
                                        }),
                                        option: (provided, { isFocused, isSelected }) => ({
                                            ...provided,
                                            backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                            color: isSelected ? "#212529 !important" : "#212529 !important",
                                            cursor: "pointer",
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#212529",
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-1 mt-1">
                            {props.formname == "Medsheet" &&
                                <MedSheetForm formname = 'Medsheet'/>
                            }
                            {props.formname == "Currentmeds" &&
                                <CurrentMed />
                            }
                            {props.formname == "Treatment" &&
                                <Treatment />
                            }
                            {props.formname == "Loaform" &&
                                <LoaForm />
                            }
                            {props.formname == "Hcporder" &&
                                <HcpOrder />
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default FacilityReportIndex;