import React, { useState } from 'react';
import { Nav, Card, Col, Form, Row, Tab, Tabs, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import NineDotMenu from './NineDotMenu';
import { Dropdown } from 'react-bootstrap';
import ThemeControlDropdown from './ThemeControlDropdown';
import paths from 'routes/paths';
import team3 from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import NeedHelpForm from 'components/common/NeedHelpForm';

const TopNavRightSideNavItem = () => {

  const [show, setShow] = useState(false)
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <Link to={"#"}  onClick={() => (setShow(true))}>Contact Us</Link> */}
      <NeedHelpForm show= {show} handleClose= {setShow} />
      <ThemeControlDropdown />
      <Dropdown as="div" align="end">
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="link"
          className="nav-link dropdown-toggle d-flex align-items-center pe-1">
          <Avatar src={team3} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className='dropdown-caret dropdown-menu-card dropdown-menu-end'>
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item as={Link} to={paths.UserProfile} >View Profile</Dropdown.Item>
            <Dropdown.Item as={Link} to={paths.defaultSetting} >Group Setting</Dropdown.Item>
            <Dropdown.Item as={Link} to={paths.pelMedsLogin} className="text-danger">
              Log Out
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <div>{localStorage.getItem("username")}</div>
    </Nav >
  );
};

export default TopNavRightSideNavItem;
