const FormValidations = (props , type, number) => {
if(type == 1) return /^[A-Za-z\s]+$/.test(props);// first name/last name only letters allowed
if(type == 2) return /^[^@-]+$/.test(props);// username atleast single special character is required
if(type == 3) return /^\d{5}(-\d{4})?$/.test(props);// use for zipcode validation
if(type == 4) return /^(\+1|1)?[-.\s]?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/.test(props)// use for phone/fax number validation
if(type == 5) return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(props);// use for Email Validations
if(type == 6) {                                         //used for number based validations
    const digitsOnly = props?.replace(/\D/g, '');
  if (digitsOnly?.length === number) {
    return true;
  }
  return false; 
};
if(type == 7) {                                         //used for Password Length Validations
if (props?.length >= number) {
  return true;
}
return false; 
};
}

export default FormValidations