import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Card } from 'react-bootstrap';
import Select from "react-select";
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrderDetailModal = ({ dataObj, setRefreshOrder, handleClose }) => {
    const data = dataObj;
    let orderStatusInternal = JSON.parse(localStorage.getItem("order_status_internal"));
    const [formData, setFormData] = useState(data || {});
    const [orderStatusList] = useState(orderStatusInternal);
    let orderType = JSON.parse(localStorage.getItem("order_types"))
    const orderTypeOptions = Object.keys(orderType).map((key) => ({
        value: key,
        label: orderType[key]
    }));
    let deliveryType = JSON.parse(localStorage.getItem("delivery_types"))
    const deliveryTypeOptions = Object.keys(deliveryType).map((key) => ({
        value: key,
        label: deliveryType[key]
    }));
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        let sendingObj = {
            order_id: formData.orderid,
            rx_number: formData.rxnumber,
            quantity: formData.qty,
            delivery_date: formData.deliverydatetime,
            order_type: orderTypeOptions.find((type) => Number(type.value) === Number(formData.ordertype)).value,
            delivery_type: deliveryTypeOptions.find((type) => Number(type.value) === Number(formData.deliveryOn || "1")).value,
            delivery_time: formData.deliverBy,
            is_pa: formData.is_pa,
            is_loansupplies: formData.is_loansupplies,
            note: "",
            is_billing_issue: formData.is_billing_issue,
            order_status: orderStatusList.find((type) => type.value === parseInt(formData.orderstatus)).value,
        }
        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/update-order/", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.output.status) {
            toast.success(data.output.message);
            if (setRefreshOrder) {
                setRefreshOrder(prev => !prev);
            }
            handleClose(false);
        } else {
            toast.error(data.output.message);
        }

    };


    return (
        <Card className='mt-2'>
            <Card.Body>
                <Form>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Delivery On</Form.Label>
                                <Form.Select
                                    name="deliveryOn"
                                    value={formData.deliveryOn || '1'}
                                    onChange={handleInputChange}
                                >
                                    {deliveryTypeOptions?.map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Order Type</Form.Label>
                                <Form.Select
                                    name="ordertype"
                                    value={formData.ordertype || '1'}
                                    onChange={handleInputChange}
                                >
                                    {orderTypeOptions.map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Delivery Date</Form.Label>
                                <DatePicker
                                    placeholderText="mm/dd/yy"
                                    className="form-control"

                                    selected={formData.deliverydatetime ? moment(formData.deliverydatetime).format("MM/DD/YYYY") : null}
                                    onChange={(date) =>
                                        handleInputChange({ target: { name: 'deliverydatetime', value: moment(date).format("MM/DD/YYYY") } })
                                    }
                                    dateFormat="MM/dd/yy"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Deliver By</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="deliverBy"
                                    value={formData.deliverBy || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Drug</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="drug"
                                    value={formData.drug || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Rx Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="rxnumber"
                                    value={formData.rxnumber || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>Qty</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="qty"
                                    value={formData.qty || ''}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Order Status</Form.Label>
                                <Form.Select
                                    name="orderstatus"
                                    onChange={handleInputChange}
                                    defaultValue={formData.orderstatus}
                                >
                                    {orderStatusList.map((order) => {
                                        return (
                                            <option key={order.value} value={order.value}>
                                                {order.key}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col md={8}>
                        <div className="d-flex align-items-center">
                            <Form.Check
                                type="switch"
                                name="is_pa"
                                id="is_pa"
                                checked={formData.is_pa}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        is_pa: e.target.checked ? 1 : 0,
                                    })
                                }
                                label="PA"
                                className="me-3"
                            />
                            <Form.Check
                                type="switch"
                                name="is_loansupplies"
                                id="is_loansupplies"
                                checked={formData.is_loansupplies}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        is_loansupplies: e.target.checked ? 1 : 0,
                                    })
                                }
                                label="Loan Supplies"
                                className="me-3"
                            />
                            <Form.Check
                                type="switch"
                                name="is_billing_issue"
                                id="is_billing_issue"
                                checked={formData.is_billing_issue}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        is_billing_issue: e.target.checked ? 1 : 0,
                                    })
                                }
                                label="Billing Issue"
                            />
                        </div>
                    </Col>
                    <Col md={4} className="text-end">
                        <Button variant="success" className="text-white" onClick={handleSubmit}>
                            Update
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
            {dataObj?.documents && dataObj.documents.length > 0 && (
                <div className="mt-3">
                    <h5>Documents:</h5>
                    <div className="document-list">
                        {dataObj.documents.map((document, index) => (
                            <div key={index} className="document-item mb-2">
                                <a
                                    href={document.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="d-flex align-items-center text-decoration-none"
                                >
                                    {document.document_name.toLowerCase().endsWith('.jpg') ||
                                        document.document_name.toLowerCase().endsWith('.png') ||
                                        document.document_name.toLowerCase().endsWith('.jpeg') ? (
                                        <img
                                            src={document.url}
                                            alt={document.document_name}
                                            className="document-thumbnail me-2"
                                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon="file-alt" className="me-2" style={{ fontSize: '20px' }} />
                                    )}
                                    <span>{document.document_name}</span>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Card >
    );
};

export default OrderDetailModal;
