import React, { useEffect, useRef, useState } from "react";
import { Form, Row, Col, Button, Tab, Nav, Container, Card, Toast } from "react-bootstrap";
import axios from "axios";
import Flex from "components/common/Flex";
import CommonBreadCrumb from "components/commonbreadcrumb/CommonBreadCrumb";
import paths from "routes/paths";
import { toast } from "react-toastify";
import DemoFileUpload from "components/orderdetail/DemoFileUpload";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Select from "react-select";
import * as mapYourMedsConstant from '../../common/mapYourMedsConstant'
import FormValidations from "components/FormValidations/FormValidations";

const NavItem = ({ item, classNameCustom }) => {
    return (
        console.log(classNameCustom),
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-3 px-1 cursor-pointer`}
                eventKey={item.toLowerCase().replaceAll(" ", "-")}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const AddPatient = () => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeKey, setActiveKey] = useState("client-information");
    const [navItems] = useState(['Client-Information', 'Insurance-Information']);
    const [errors, setErrors] = useState({})
    const formRef = useRef(null);
    const [imageUpload, setImageUploadFlag] = useState(false);
    const [stateoptions] = useState([{ key: 'Select a state', value: '' },
    ...mapYourMedsConstant.STATE_VALUES]);
    const [fileUpload, setFileUploadFlag] = useState(false);
    const [groupOptions, setGroupOptions] = useState([]);
    const [facilityobj, setFacilityObj] = useState({});
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        startOfCare: "",
        dob: "",
        gender: "",
        allergies: "",
        ssn: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        mdName: "",
        mdPhone: "",
        diagnosis: "",
        medicalCondition: "",
        note: "",
        insuranceName: "",
        pcn: "",
        groupNumber: "",
        bin: "",
        idNumber: "",
        medicareNumber: "",
        paymentMethod: "",
        responsibleParty: "",
        billingCity: "",
        billingZip: "",
        billingPhone: "",
        cardName: "",
        expirationDate: "",
        cardNumber: "",
        securityCode: "",
        streetAddress: "",
        billingState: "",
        email: "",
        group: "",
        facility: "",
        cell: "",
        document: null,
        is_createOrder: 0
    });
    let groupList = JSON.parse(localStorage.getItem("groupsData")) ? JSON.parse(localStorage.getItem("groupsData")) : [];
    const facilityResults = JSON.parse(localStorage.getItem("facilitiesData"));
    const facilityResultsNew = JSON.parse(localStorage.getItem("facilitiesData"));
    const [options, setOptions] = useState(facilityResultsNew);
    const [selectedGroup, setSelectedGroup] = useState(groupList[0]);
    const facilitiesForGroup = selectedGroup
        ? facilityResults.filter(facility => facility.group_id === selectedGroup.value)
        : facilityResults[0];
    const [selectedFacility, setSelectedFacility] = useState(facilitiesForGroup);
    const currentRoleId = JSON.parse(localStorage.getItem("roleId"));
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [imageUploadedFiles, setImageUploadedFiles] = useState([]);
    const [allergy, setAllergy] = useState([]);

    useEffect(() => {
        setGroupAndFacility()
    }, [])

    async function setGroupAndFacility() {
        if (Number(localStorage.getItem("roleId")) < 4) {
            let temp = {};
            const groupResults = groupList.map((group) => {
                const facilitiesForGroup = facilityResults.filter(
                    (facility) => facility.group_id === group.value
                );

                temp[group.value] = facilitiesForGroup.map((facility) => ({
                    name: facility.key,
                    id: facility.value,
                }));

                return { key: group.key, value: group.value };
            });
            setGroupOptions([{ key: "Select Group", value: "" }, ...groupResults]);
            setFacilityObj(temp);
        }
        const getAllItems = {
            "allergy": {}
        }
        let tokenStr = localStorage.getItem("accessToken");
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/prescriptions/fetch-multiple`, getAllItems, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                const allergyDropdownOptions = res?.data?.output?.data?.allergy.map((item) => ({
                    value: item.id,
                    label: item.allergy,
                }));
                setAllergy(allergyDropdownOptions)
            })
    }

    const handleFileChange = (files) => {
        setUploadedFiles(files);
    };

    const handleImageChange = (files) => {
        setImageUploadedFiles(files)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === "group") {
            const filtered = facilityData.filter((facility) => facility.group_id === parseInt(value));
            setFilteredFacilities(filtered);
            setFormData((prev) => ({ ...prev, facility: "" }));
        }
    };

    const handleGroupChange = (selectedGroup) => {
        setSelectedGroup(selectedGroup);
        const groupFacilityResults = facilityobj[selectedGroup.value]?.map((facility) => ({
            key: facility.name,
            value: facility.id,
        })) || [];
        setOptions([{ key: "Select a facility", value: "" }, ...groupFacilityResults]);
        setSelectedFacility({ key: "Select a facility", value: "" });
    };

    async function handleOptionSelect(selectedOption) {
        setSelectedFacility(selectedOption);
        if (Object.keys(facilityobj).length > 0) {
            const groupFacilityResults = facilityobj[selectedGroup.value]?.map((facility) => ({
                key: facility.name,
                value: facility.id,
            })) || [];
            setOptions([{ key: "Select a facility", value: "" }, ...groupFacilityResults]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;

        setIsSubmitting(true);
        var submissionData = new FormData();
        // Object.entries(formData).forEach(([key, value]) => {
        //     if (value !== null && value !== undefined) {
        //         if (key === "document" && value instanceof File) {
        //             submissionData.append(key, value);
        //         } else {
        //             submissionData.append(key, value.toString());
        //         }
        //     }
        // });

        // submissionData.append(uploadedFiles);

        var submittedData = {
            create_order: formData.is_createOrder ? 1 : 0,
            group_id: JSON.parse(localStorage.getItem("groupId")) || selectedGroup.value,
            facility_id: selectedFacility.value,
            first_name: formData.firstName || "",
            last_name: formData.lastName || "",
            dob: moment(formData.dob).format("MM/DD/YYYY") || "",
            gender: formData.gender || "",
            admit_date: moment(formData.startOfCare).format("MM/DD/YYYY") || "",
            physical_exam_date: "",
            state: formData.state || "",
            city: formData.city || "",
            zip: formData.zip || "",
            ss_number: formData.ssn || "",
            allergies: formData.allergies || "",
            medsheet_note: formData.note || "",
            insurance_info: formData.insuranceName || "",
            insurance_group: formData.groupNumber || "",
            insurance_no: formData.idNumber || "",
            insurance_priority: "",
            is_active: "",
        };
        console.log(submittedData)
        Object.keys(submittedData).forEach(key => {
            submissionData.append(key, submittedData[key]);
        });
        if (formData.is_createOrder && uploadedFiles.length > 0) {
            uploadedFiles.forEach(file => {
                submissionData.append("documents", file, file.name);
            });
        }
        if (imageUploadedFiles.length > 0) {
            submissionData.append("patient_pic", imageUploadedFiles[0], imageUploadedFiles[0].name);
        }
        console.log("Submission Data:", submissionData);
        let tokenStr = localStorage.getItem("accessToken");
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/patients/add-patient`,
                submissionData,
                { headers: { 'Authorization': `Bearer ${tokenStr}` } }
            );

            if (data.status) {
                toast.success("Patient Details added successfully");
                navigate(paths.viewPatient);
            } else {
                toast.error("Error submitting the form. Please try again.");
            }
        } catch (error) {
            toast.error("Submission failed. Please check your input and try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Add-Patient', href: paths.addPatient, isActive: true }
    ];

    const validateForm = (tab) => {
        let validationErrors = {};
        if (tab === "client-information") {
            if (!formData.firstName.trim()) validationErrors.firstName = "First Name is required";
            if (!formData.lastName.trim()) validationErrors.lastName = "Last Name is required";
            if (!formData.dob) validationErrors.dob = "Date of Birth is required";
            if (!formData.startOfCare) validationErrors.startOfCare = "Admit Date is required";
            if (!formData.gender) validationErrors.gender = "Gender is required";
            if (!formData.allergies) validationErrors.allergies = "Allergies are required";
            if (formData.zip.trim() && !FormValidations(formData.zip, 3)) {
                validationErrors.zip = "Zip Code format is not correct";
            }
            if (!selectedGroup?.value && currentRoleId < 4) validationErrors.group = "Group selection is required";
            if (!selectedFacility.value) validationErrors.facility = "Facility selection is required";
            if (formData.dob && moment(formData.dob).isAfter(moment())) {
                validationErrors.dob = "DOB cannot be in the future";
            }
            // if (imageUploadedFiles.length == 0) {
            //     setImageUploadFlag(true)
            //     validationErrors.document = "uploading Image is required";
            // } else {
            //     setImageUploadFlag(false)
            // }
            // if (formData.is_createOrder) {
            //     if (uploadedFiles.length == 0) {
            //         setFileUploadFlag(true);
            //         validationErrors.file = "uploading File is required";
            //     } else {
            //         setFileUploadFlag(false);
            //     }
            // }
        }

        if (tab === "insurance-information") {
            if (!formData.insuranceName.trim()) validationErrors.insuranceName = "Insurance Name is required";
            if (!formData.bin.trim()) validationErrors.bin = "BIN is required";
            if (!formData.pcn.trim()) validationErrors.pcn = "PCN is required";
            if (!formData.idNumber.trim()) validationErrors.idNumber = "ID Number is required";

            // if (formData.medicareNumber && !/^\d+$/.test(formData.medicareNumber)) {
            //     validationErrors.medicareNumber = "Medicare Number must be numeric";
            // }
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleNext = async (e) => {
        e.preventDefault()
        if (!validateForm(activeKey)) {
            if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            return;
        }
        setImageUploadFlag(false);
        setFileUploadFlag(false);
        setActiveKey("insurance-information");
    }

    return (
        <>
            <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
            <Card ref={formRef}>
                <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                    <Card.Header
                        as={Flex}
                        justifyContent="between"
                        alignItems="center"
                        className="p-0 border-bottom patientInfoTabs"
                    >
                        <Nav
                            as="ul"
                            className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                        >
                            {navItems.map(item => (
                                <NavItem classNameCustom="col text-center" item={item} key={item} />
                            ))}
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        <Row className="g-1">
                            <Col xxl={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="client-information">
                                        <Form>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Check
                                                        type='switch'
                                                        name="is_createOrder"
                                                        id='is_createOrder'
                                                        className="fs-5"
                                                        checked={
                                                            formData.is_createOrder
                                                        }
                                                        onChange={e =>
                                                            setFormData({
                                                                ...formData,
                                                                is_createOrder: e.target.checked
                                                            })
                                                        }
                                                        label='Add patient to system'
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {formData.is_createOrder ? <Col md={12} className="mb-2">
                                                    <DemoFileUpload onFilesChange={handleFileChange} textOfDoc={'Required Document'} errorFlag={fileUpload} />
                                                    <Form.Control.Feedback type="invalid" >{errors.file}</Form.Control.Feedback>
                                                </Col> : []}
                                            </Row>
                                            <Row className="mb-3">
                                                {currentRoleId < 4 ? <Col xs="6" className="mb-0">
                                                    <Form.Group>
                                                        <Form.Label className="required">Group</Form.Label>
                                                        <Select
                                                            name="group"
                                                            closeMenuOnSelect={true}
                                                            placeholder='Select Group'
                                                            classNamePrefix="react-select"
                                                            className={`${errors.group ? 'is-invalid' : ''}`}
                                                            options={groupOptions}
                                                            value={selectedGroup}
                                                            getOptionLabel={option => option.key}
                                                            getOptionValue={option => option.value}
                                                            onChange={(e) => handleGroupChange(e)}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderColor: "#ced4da",
                                                                    boxShadow: "none",
                                                                    "&:hover": { borderColor: "#80bdff" },
                                                                }),
                                                                option: (provided, { isFocused, isSelected }) => ({
                                                                    ...provided,
                                                                    backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                                                    color: isSelected ? "#212529 !important" : "#212529 !important",
                                                                    cursor: "pointer",
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: "#212529",
                                                                }),
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid" >{errors.group}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col> : []}
                                                <Col xs="6" className="mb-0">
                                                    <Form.Group>
                                                        <Form.Label className="required">Facility</Form.Label>
                                                        <Select
                                                            name="facility"
                                                            closeMenuOnSelect={true}
                                                            placeholder='Select Facility'
                                                            classNamePrefix="react-select"
                                                            className={`${errors.facility ? 'is-invalid' : ''}`}
                                                            options={options}
                                                            value={selectedFacility}
                                                            getOptionLabel={option => option.key}
                                                            getOptionValue={option => option.value}
                                                            onChange={(e) => handleOptionSelect(e)}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderColor: "#ced4da",
                                                                    boxShadow: "none",
                                                                    "&:hover": { borderColor: "#80bdff" },
                                                                }),
                                                                option: (provided, { isFocused, isSelected }) => ({
                                                                    ...provided,
                                                                    backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                                                    color: isSelected ? "#212529 !important" : "#212529 !important",
                                                                    cursor: "pointer",
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: "#212529",
                                                                }),
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid" >{errors.facility}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="firstName">
                                                        <Form.Label>First Name<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            value={formData.firstName}
                                                            isInvalid={!!errors.firstName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="lastName">
                                                        <Form.Label>Last Name<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.lastName}
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid" >{errors.lastName}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="startOfCare">
                                                        <Form.Label>Start Of Care</Form.Label>
                                                        <DatePicker
                                                            placeholderText="mm/dd/yy"
                                                            selected={formData.startOfCare ? moment(formData.startOfCare).format("MM/DD/YYYY") : null}
                                                            onChange={(date) =>
                                                                handleChange({ target: { name: 'startOfCare', value: moment(date).format("MM/DD/YY") } })
                                                            }
                                                            dateFormat="MM/dd/yy"
                                                            className={`form-control ${errors.startOfCare ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.startOfCare && <div className="invalid-feedback mt-1 display-block">{errors.startOfCare}</div>}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="mrNumber">
                                                        <Form.Label>MR#</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="mrNumber"
                                                            placeholder="MR"
                                                            value={formData.mrNumber}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label className="required">DOB</Form.Label>
                                                        <DatePicker
                                                            selected={formData.dob ? moment(formData.dob).format("MM/DD/YY") : null}
                                                            onChange={(date) =>
                                                                handleChange({ target: { name: 'dob', value: moment(date).format("MM/DD/YYYY") } })
                                                            }
                                                            dateFormat="MM/dd/yy"
                                                            placeholderText="mm/dd/yy"
                                                            showYearDropdown
                                                            minDate={moment().subtract(100, "years").toDate()}
                                                            maxDate={moment().toDate()}
                                                            scrollableYearDropdown
                                                            yearDropdownItemNumber={100}
                                                            className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                                        />
                                                        {errors.dob && <div className="invalid-feedback mt-1 display-block">{errors.dob}</div>}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="gender">
                                                        <Form.Label>Gender<span className="text-danger">*</span></Form.Label>
                                                        <Form.Select
                                                            name="gender"
                                                            value={formData.gender}
                                                            isInvalid={!!errors.gender}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <option value="">Select Gender</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                            <option value="3">Others</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid" >{errors.gender}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="allergies">
                                                        <Form.Label className="required">Allergies</Form.Label>
                                                        <Select
                                                            isMulti
                                                            options={allergy}
                                                            placeholder="Select Allergies"
                                                            classNamePrefix="react-select"
                                                            className={errors.allergies ? 'is-invalid' : ''}
                                                            onChange={(selectedOptions) => {
                                                                setFormData((prev) => ({
                                                                    ...prev,
                                                                    allergies: selectedOptions.map(option => option.value)
                                                                }));
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.allergies}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="diagnosis">
                                                        <Form.Label>Diagnosis</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="diagnosis"
                                                            placeholder="Diagnosis"
                                                            value={formData.diagnosis}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="medicalCondition">
                                                        <Form.Label>Medical Condition</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="medicalCondition"
                                                            placeholder="Medical Condition"
                                                            value={formData.medicalCondition}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="note">
                                                        <Form.Label>Note</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="note"
                                                            placeholder="Note"
                                                            value={formData.note}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="address1">
                                                        <Form.Label>Address 1</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="address1"
                                                            placeholder="Address 1"
                                                            value={formData.address1}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="address2">
                                                        <Form.Label>Address 2</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="address2"
                                                            placeholder="Address 2"
                                                            value={formData.address2}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={4}>
                                                    <Form.Group controlId="city">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="city"
                                                            placeholder="City"
                                                            value={formData.city}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId="state">
                                                        <Form.Label >State</Form.Label>
                                                        <Form.Select
                                                            name="state"
                                                            value={formData.state}
                                                            onChange={handleChange}
                                                        >
                                                            {stateoptions.map((stateoption) => {
                                                                return (
                                                                    <option key={stateoption.value} value={stateoption.value}>
                                                                        {stateoption.key}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId="zip">
                                                        <Form.Label>Zip</Form.Label>
                                                        <Form.Control
                                                            className={`form-control form-control-sm ${errors.zip ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            name="zip"
                                                            placeholder="Zip"
                                                            value={formData.zip}
                                                            onChange={handleChange}
                                                            onInvalid={!!errors.zip}
                                                        />
                                                        <Form.Control.Feedback type="invalid" >{errors.zip}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="mdName">
                                                        <Form.Label>MD Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="mdName"
                                                            placeholder="MD Name"
                                                            value={formData.mdName}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="mdPhone">
                                                        <Form.Label>MD Phone</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="mdPhone"
                                                            placeholder="MD Phone"
                                                            value={formData.mdPhone}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <DemoFileUpload onFilesChange={handleImageChange} textOfDoc={'Individual Image'} errorFlag={imageUpload} />
                                                        <Form.Control.Feedback type="invalid" >{errors.document}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Button
                                                        variant="primary"
                                                        onClick={handleNext}
                                                    >
                                                        Next
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="insurance-information">
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="insuranceName">
                                                        <Form.Label>Insurance Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="insuranceName"
                                                            placeholder="Insurance Name"
                                                            value={formData.insuranceName}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="bin">
                                                        <Form.Label>BIN</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="bin"
                                                            placeholder="BIN"
                                                            value={formData.bin}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="pcn">
                                                        <Form.Label>PCN</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="pcn"
                                                            placeholder="PCN"
                                                            value={formData.pcn}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="idNumber">
                                                        <Form.Label>ID #</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="idNumber"
                                                            placeholder="ID"
                                                            value={formData.idNumber}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group controlId="groupNumber">
                                                        <Form.Label>Group #</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="groupNumber"
                                                            placeholder="Group #"
                                                            value={formData.groupNumber}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group controlId="medicareNumber">
                                                        <Form.Label>Medicare Number (if Applicable)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="medicareNumber"
                                                            placeholder="Medicare Number"
                                                            value={formData.medicareNumber}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-start">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => setActiveKey("client-information")}
                                                    >
                                                        Back
                                                    </Button>
                                                </Col>
                                                <Col className="d-flex justify-content-end">
                                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? "Submitting..." : "Submit"}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Card.Body>
                </Tab.Container>
            </Card >
        </>
    );
};

export default AddPatient;
