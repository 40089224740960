import useAdvanceTable from 'hooks/useAdvanceTable';
import React, { useEffect, useState } from 'react'
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Card, Col, Row, Form } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { Link, useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';

const columns = [
    {
        accessorKey: 'groupname',
        header: 'Group Name',
        meta: {
            headerProps: { className: 'text-900 text-start' },
            cellProps: { className: 'text-start' }
        },
        cell: ({ row: { original } }) => {
            const facilitylistlookUp = paths.facilitylistlookUp;
            return <Link to={{ pathname: `${facilitylistlookUp}/${original.id}` }} state={{
                name: original.groupname
            }} >
                {original.groupname}</Link>
        }
    },
    {
        accessorKey: 'peltask',
        header: 'Peltask',
        meta: {
            headerProps: { className: 'text-900' },
        },
        cell: ({ row: { original } }) => {
            return original.peltask ? (<FontAwesomeIcon icon="check" className="ms-1 text-success" />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
    {
        accessorKey: 'mapyourmeds',
        header: 'MAP YOUR MEDS',
        meta: {
            headerProps: { className: 'text-900' },
        },
        cell: ({ row: { original } }) => {
            return original.mapyourmeds ? (<FontAwesomeIcon icon="check" className="ms-1 text-success" />) : (<FontAwesomeIcon icon="check" className="ms-1  opacity-50" />)
        }
    },
    {
        accessorKey: 'pelpak',
        header: 'Pelpak',
        meta: {
            headerProps: { className: 'text-900' },
        },
        cell: ({ row: { original } }) => {
            return original.pelpak ? (<FontAwesomeIcon icon="check" className="ms-1 text-success" />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
    {
        accessorKey: 'deviceapprove',
        header: 'Device Approve',
        meta: {
            headerProps: { className: 'text-900' },
        },
        cell: ({ row: { original } }) => {
            return original.pelpak ? (original.deviceapprove ? (<FontAwesomeIcon icon="check" className="ms-1 text-success" />) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)) : (<FontAwesomeIcon icon="check" className="ms-1 opacity-50" />)
        }
    },
];



const FacilityLookUp = () => {
    const [group, setGroup] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        fetchFacilityData()
    }, [])


    async function fetchFacilityData() {
        try {
            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/get-all-groups`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = [];
            let objData = data?.data;

            Object.values(objData).forEach(value => results.push({
                id: value.id,
                groupname: value.name,
                peltask: value.is_peltask_active,
                mapyourmeds: value.is_mym_active,
                pelpak: value.is_mobile_app_active,
                deviceapprove: value.enable_device_approve
            }));
            setGroup(results);
        } catch (error) {
            navigate("/error")
        }
    }

    const table = useAdvanceTable({
        data: group,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Groups&Facility', href: paths.faciliityLookUp, isActive: true }
    ];

    return (
        <>
            {group.length > 0 ? 
            <>
            <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
            <AdvanceTableProvider {...table}>
                <Card className="mb-3">
                    <Card.Body className="p-3">
                        <Row className='mt-3 justify-content-between'>
                            <Col xs="4" className="mt-4">
                                <AdvanceTableSearchBox placeholder="Search..." />
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-1 mt-3">
                                <AdvanceTable
                                    headerClassName="bg-200 text-nowrap align-middle text-center"
                                    rowClassName="text-nowrap align-middle text-center"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div className="mt-3">
                            <AdvanceTableFooter
                                rowInfo
                                navButtons
                                rowsPerPageSelection
                            />
                        </div>
                    </Card.Footer>
                </Card>
            </AdvanceTableProvider>
            </> : <ShimmerUI/>
            }
        </>
    )
}

export default FacilityLookUp