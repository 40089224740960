import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Card } from 'react-bootstrap';
import Select from "react-select";
import { toast } from 'react-toastify';

const AddPatientModal = ({ dataObj, handleClose, setRefreshOrder }) => {
    const propsObj = dataObj
    const data = dataObj.patientModalData;
    const tokenStr = localStorage.getItem("accessToken");
    const handleSubmit = async () => {
        let sendingObj = {
            order_id: propsObj.orderid,
        }
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/create-ips-patient/", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.status) {
            toast.success(data.message);
            if(setRefreshOrder){
                setRefreshOrder(prev => !prev);
            }
            handleClose(false);
        } else {
            toast.error(data.message);
        }
    };

    const handleDiscardSubmit = async () => {
        let sendingObj = {
            order_id: propsObj.orderid,
            order_status: 8,
        }
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/update-order/", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.output.status) {
            toast.success(data.output.message);
            if(setRefreshOrder){
                setRefreshOrder(prev => !prev);
            }
            handleClose(false);
        } else {
            toast.error(data.output.message);
        }
    }

    const handleIntakeSubmit = async () => {
        let sendingObj = {
            order_id: propsObj.orderid,
            order_status: 2,
        }
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/update-order/", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.output.status) {
            toast.success(data.output.message);
            if(setRefreshOrder){
                setRefreshOrder(prev => !prev);
            }
            handleClose(false);
        } else {
            toast.error(data.output.message);
        }
    }

    const renderField = (label, value) => (
        <Col md={4} className="mb-3">
            <strong>{label}:</strong> <span>{value || "N/A"}</span>
        </Col>
    );

    return (
        <Card className="mt-2 shadow-sm border-0">
            <Card.Body>
                <Row>
                    {renderField("First Name", data.first_name)}
                    {renderField("Last Name", data.last_name)}
                    {renderField("Date of Birth", data.dob ? data.dob.split("T")[0] : "N/A")}
                </Row>
                <Row>
                    {renderField("Facility Name", data.facility_name)}
                    {renderField("Group Name", data.group_name)}
                    {renderField("Address", `${data.address || ""} ${data.address2 || ""}`.trim())}
                </Row>
                <Row>
                    {renderField("Gender", data.gender === 1 ? "Male" : "Female")}
                    {renderField("Allergies", data.allergies)}
                    {renderField("Medsheet Note", data.medsheet_note)}
                </Row>
                <Row>
                    {renderField("City", data.city)}
                    {renderField("State", data.state)}
                    {renderField("ZIP", data.zip)}
                </Row>
                <Row>
                    {renderField("Mobile", data.phone)}
                    {renderField("Fax", data.fax)}
                    {renderField("Language", data.primary_language)}
                </Row>
                <Row>
                    {renderField("Ethnicity", data.ethnicity)}
                    {renderField("Admit Date", data.admit_date)}
                    {renderField("SSN", data.social_security_number)}
                </Row>
                <Row>
                    {renderField("EFS", data.insurance_efs_information)}
                    {renderField("Insurance Group Number", data.insurance_group_no)}
                    {renderField("Insurance Number", data.insurance_no)}
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col md={12} className="text-end">
                        <Button
                            variant="primary"
                            className="mb-2"
                            style={{ minWidth: '150px', fontSize: '16px' }}
                            onClick={handleSubmit}>
                            Upload to SRX
                        </Button>
                        <div>
                            {propsObj.orderstatus != 2 ? <Button
                                variant="secondary"
                                className="me-2"
                                onClick={handleIntakeSubmit}>
                                Move to Intake
                            </Button> : []}
                            <Button
                                variant="secondary"
                                onClick={handleDiscardSubmit}>
                                Discard
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default AddPatientModal;
