import axios from 'axios';
import DemoFileUpload from 'components/orderdetail/DemoFileUpload';
import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Form, InputGroup, Modal, Row, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddOrderModal = ({ show, setShow }) => {
  const [drugOptions, setDrugOptions] = useState([]);
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  const [newDrug, setNewDrug] = useState("");
  const [note, setNote] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [editableQuantities, setEditableQuantities] = useState({});
  const [docFlag, setDocFlag] = useState(false);
  const [orderTypeValue, setOrderTypeValue] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  let orderTypeVal = JSON.parse(localStorage.getItem("order_types"));
  const orderType = [
    { value: "", label: "Select Order Type" },
    ...Object.keys(orderTypeVal)
      .filter(key => orderTypeVal[key] !== "Refill")
      .map((key) => ({
        value: parseInt(key, 10),
        label: orderTypeVal[key]
      }))
  ];

  const location = useLocation();
  const state = location?.state?.data;
  let initalData = {
    patient_id: state?.patientid,
    delivery_date: '',
    order_type: "",
    note: '',
    order_document: {},
    prescriptions: []
  };
  const [formData, setFormData] = useState(initalData);

  useEffect(() => {
    if (show && location.state?.triggeredBy === "someAction") {
      fetchData();
      if (show) {
        const dates = getNextWorkingDays();
        setDeliveryDates(dates);
        if (dates.length > 0) {
          setSelectedDate(dates[0].value);
        }
      }
    }
  }, [show, location?.state]);

  const getNextWorkingDays = () => {
    const workingDays = [];
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    while (workingDays.length < 5) {
      const day = currentDate.getDay();
      if (day !== 0 && day !== 6) {
        let label = "";
        if (workingDays.length === 0) label = `Tomorrow (${formatDate(currentDate)})`;
        else label = `${currentDate.toLocaleDateString('en-US', { weekday: 'long' })} (${formatDate(currentDate)})`;

        workingDays.push({
          label,
          value: `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return workingDays;
  };


  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    });
  };


  const handleQtyChange = (drugName, maxQty, value) => {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue) || numericValue <= 0) {
      setEditableQuantities((prev) => ({
        ...prev,
        [drugName]: ""
      }));
    } else if (numericValue > maxQty) {
      toast.error(`Quantity cannot exceed ${maxQty}`, { autoClose: 2000 });
      setEditableQuantities((prev) => ({
        ...prev,
        [drugName]: maxQty.toString()
      }));
    } else {
      setEditableQuantities((prev) => ({
        ...prev,
        [drugName]: value
      }));
    }
  };


  async function fetchData() {
    let tokenStr = localStorage.getItem("accessToken");
    setFormData((prev) => ({
      ...prev,
      patient_id: state.patientid + ""
    }));
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/orders/list-refill-prescriptions/" + state.patientid, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    let objData = data.prescriptions
    let results = [];
    objData.map((value) => {
      results.push({
        name: value.drug,
        rxNumber: value.pharmacy_order_id != null ? value.pharmacy_order_id : 0,
        qty: value.qty ? parseFloat(value.qty) : 0
      })
    })
    setDrugOptions(results)
  }

  const handleDrugSelection = (drug) => {
    if (!selectedDrugs.some((selected) => selected.name === drug.name)) {
      setSelectedDrugs([...selectedDrugs, drug]);
      setDrugOptions(drugOptions.filter((value) => value.rxNumber !== drug.rxNumber));

      setEditableQuantities((prev) => ({
        ...prev,
        [drug.rxNumber]: drug.qty.toString()
      }));
    }
  };

  const handleRemoveDrug = (drug) => {
    setSelectedDrugs(selectedDrugs.filter((selected) => selected.name !== drug.name));
    drugOptions.push(drug)
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFieldChange = (e) => {
    const value = e.target.value;
    setOrderTypeValue(value);
    setDocFlag(value === "3" || value === "4" || value === "5" || value === "6");

    setFormData((prev) => ({
      ...prev,
      order_type: value
    }));
    setErrors((prev) => ({
      ...prev,
      order_type: ""
    }));
  };

  const validateForm = () => {
    let newErrors = {};

    if (!orderTypeValue) {
      newErrors.order_type = "Order Type is required.";
    }

    if (selectedDate === "") {
      newErrors.delivery_date = "Delivery Date is required.";
    }

    if (![4].includes(parseInt(orderTypeValue))) {
      if (selectedDrugs.length === 0) {
        newErrors.selectedDrugs = "Please select at least one drug.";
      }
    } else {
      if (!newDrug.trim()) {
        newErrors.newDrug = "Please enter the new drug name.";
      }
    }

    // Check document requirement for order type 5 or 6
    if ([4,5, 6].includes(parseInt(orderTypeValue)) && !uploadedFile.length) {
      newErrors.uploadedFile = "Please upload a document.";
    }

    if (orderTypeValue && note.trim() === "") {
      newErrors.note = "Please enter a note.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    let payload = {
      patient_id: formData.patient_id,
      delivery_date: selectedDate,
      order_type: orderTypeValue,
      note: note,
    };

    if (![4].includes(parseInt(orderTypeValue))) {
      const obj = selectedDrugs.map(drug => ({
        rx_number: drug.rxNumber,
        quantity: editableQuantities[drug.rxNumber] || drug.qty
      }));
      payload.prescriptions = JSON.stringify(obj);
    } else {
      payload.new_drug = newDrug;
      // payload.document = uploadedFile;
    }

    let submissionData = new FormData();

    Object.keys(payload).forEach(key => {
      submissionData.append(key, payload[key]);
    });

    if ([4,5,6].includes(parseInt(orderTypeValue)) && uploadedFile.length > 0) {
      uploadedFile.forEach(file => {
        submissionData.append("documents", file, file.name);
      });
    }
    let tokenStr = localStorage.getItem("accessToken");
    let pelmedsPrescriptiontUrl = `${process.env.REACT_APP_SERVER_URL}/orders/create-prescription-order/`;

    try {
      const { data } = await axios.post(pelmedsPrescriptiontUrl, submissionData, {
        headers: { Authorization: `Bearer ${tokenStr}` }
      });

      if (data.status) {
        toast.success("Prescription Order added successfully");
        resetForm();
      } else {
        setLoading(false);
        toast.info("Error occurred while adding/updating Prescription Order.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const handleFileChange = (files) => {
    setUploadedFile(files);
  };

  const resetForm = () => {
    setDrugOptions([]);
    setSelectedDrugs([]);
    setNewDrug("");
    setNote("");
    setUploadedFile(null);
    setEditableQuantities({});
    setDocFlag(false);
    setFormData(initalData);
    setShow(false);
    setOrderTypeValue('')
    setLoading(false);
  };
  return (
    <Modal onHide={resetForm} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title as="h5">ADD Order for {state?.individual}/{state?.facilityname}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-body-tertiary p-4">
        <Form onSubmit={handleSubmit}>
          <Row className='mb-3'>
            <Col xs={6}>
              <Form.Label className="required">Delivery On</Form.Label>
              <Form.Select name="selectdeliverydate" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                {deliveryDates.map((date, index) => (
                  <option key={index} value={date.value}>{date.label}</option>
                ))}
              </Form.Select>
              {errors.delivery_date && <small className="text-danger">{errors.delivery_date}</small>}
            </Col>
            <Col>
              <Form.Label className="required">Order Type</Form.Label>
              <Form.Select name="ordertype" value={orderTypeValue} onChange={handleFieldChange}>
                {orderType.map((type) => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </Form.Select>
              {errors.order_type && <small className="text-danger">{errors.order_type}</small>}
            </Col>
          </Row>

          {orderTypeValue && ![4].includes(parseInt(orderTypeValue)) && (
            <>
              <Row className="mt-3">
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" className='w-50'>Select drug to order</Dropdown.Toggle>
                    <Dropdown.Menu className='w-50' style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {drugOptions.map((drug, index) => (
                        <Dropdown.Item key={index} onClick={() => handleDrugSelection(drug)}>{drug.name}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.selectedDrugs && <small className="text-danger">{errors.selectedDrugs}</small>}
                </Col>
              </Row>
              {selectedDrugs.length > 0 && (
                <>
                  <Row className="mt-4">
                    <Col xs={5} sm={6} md={6}>
                      <Form.Label>Drug Name</Form.Label>
                    </Col>
                    <Col xs={3} sm={2} md={2}>
                      <Form.Label>Rx Number</Form.Label>
                    </Col>
                    <Col xs={3} sm={3} md={3}>
                      <Form.Label>Qty</Form.Label>
                    </Col>
                    <Col>
                      {/* <Form.Label>Remove</Form.Label> */}
                    </Col>
                  </Row>

                  {selectedDrugs.map((drug, index) => (
                    <Row key={index} className="mb-3 align-items-center">
                      <Col xs={5} sm={6} md={6}>
                        <Form.Control type="text" value={drug.name} readOnly />
                      </Col>
                      <Col xs={3} sm={2} md={2}>
                        <Form.Control type="text" value={drug.rxNumber} readOnly />
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        <Form.Control
                          type="number"
                          min="1"
                          value={editableQuantities[drug.rxNumber] ?? drug.qty}
                          placeholder="Enter Qty"
                          onChange={(e) =>
                            handleQtyChange(drug.rxNumber, drug.qty, e.target.value)
                          }
                        />
                      </Col>
                      <Col className="mt-1">
                        <Col
                          className="badge bg-primary text-white cursor-pointer"
                          onClick={() => handleRemoveDrug(drug)}
                        >
                          ×
                        </Col>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </>
          )}

          {[4].includes(parseInt(orderTypeValue)) && (
            <>
              <Row>
                <Col>
                  <Form.Label>New Drug</Form.Label>
                  <Form.Control type="text" value={newDrug} onChange={(e) => setNewDrug(e.target.value)} />
                  {errors.newDrug && <small className="text-danger">{errors.newDrug}</small>}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Document Upload</Form.Label>
                    <Form.Control size="sm" type="file" onChange={(e) => setUploadedFile(e.target.files[0])} />
                    {uploadedFile && <div className="mt-2">Uploaded File: {uploadedFile.name}</div>} */}
                    <DemoFileUpload onFilesChange={handleFileChange} textOfDoc={'Document Upload'} errorFlag={!!errors.uploadedFile} />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {[5, 6].includes(parseInt(orderTypeValue)) && (
            <>
              <Row>
                <Col>
                  <Form.Label>Upload Document (Required)</Form.Label>
                  <DemoFileUpload onFilesChange={handleFileChange} textOfDoc={'Document Upload'} errorFlag={!!errors.uploadedFile} />
                  {errors.uploadedFile && <small className="text-danger">{errors.uploadedFile}</small>}
                </Col>
              </Row>
            </>
          )}
          {orderTypeValue && orderTypeValue !== "" && (
            <>
              <Row>
                <Col>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control as="textarea" rows={1} value={note} onChange={(e) => setNote(e.target.value)} />
                  {errors.note && <small className="text-danger">{errors.note}</small>}
                </Col>
              </Row>
            </>
          )}
          <Row className="mb-3">
            <Col md={12}>
              <Form.Label className="text-danger text-center fw-bold">
                *Please call pharmacy for urgent or after hours deliveries.
              </Form.Label>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Button type="submit"
              color="primary"
              name="addordermodalsubmit"
              className='w-25'
              disabled={loading}>
              {loading ? <Spinner size="sm" animation="border" /> : "Submit"}
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddOrderModal;
