import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as mapYourMedsConstant from '../../common/mapYourMedsConstant.js'
import FormValidations from 'components/FormValidations/FormValidations.js';
import MaskedInput from 'react-text-mask';
import FormatPhoneNumber from 'components/common/FormatPhoneNumber.js';

const MyProfile = (props) => {
  const [stateoptions, setStateOptions] = useState([]);
  const data = props.dataObj;
  const initialFormData = {
    id: '',
    fname: '',
    lname: '',
    uname: '',
    pname: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    mobile: '',
    email: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProfileData() {
      setFormData({
        id: data.id,
        fname: data.first_name || '',
        lname: data.last_name || '',
        uname: data.username || '',
        pname: '',
        address: data.address || '',
        address2: data.address2 || '',
        city: data.city || '',
        state: data.state || '',
        zip: data.zip || '',
        mobile: FormatPhoneNumber(data.phone) || '',
        email: data.email || ''
      });
    }

    setStateOptions([
      { key: 'Select a state', value: '' },
      ...mapYourMedsConstant.STATE_VALUES
    ])

    fetchProfileData();
  }, [data]);

  const validate = () => {
    const newErrors = {};
    if (!formData.fname) newErrors.fname = 'First Name is required.';
    if (!formData.lname) newErrors.lname = 'Last Name is required.';
    if (formData.city && !FormValidations(formData.city, 1)) {
      newErrors.city = 'City should be in letters';
    }
    if (!formData.zip) {
      newErrors.zip = 'Zip code is required.';
    } else if (!FormValidations(formData.zip, 3)) {
      newErrors.zip = 'Invalid ZIP code format.';
    }
    if (formData.mobile && !FormValidations(formData.mobile, 6, 10)) {
      newErrors.mobile = 'Invalid mobile number format.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    const userProfileData = {
      user_id: formData.id,
      username: formData.uname,
      email: formData.email,
      first_name: formData.fname,
      last_name: formData.lname,
      phone: formData.mobile,
      address: formData.address,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zip: formData.zip
    };

    const tokenStr = localStorage.getItem('accessToken');
    const userProfileUrl = `${process.env.REACT_APP_SERVER_URL}/users/update`;

    try {
      const { data } = await axios.post(userProfileUrl, userProfileData, {
        headers: { Authorization: `Bearer ${tokenStr}` }
      });
      if (data.status) {
        toast.success(data.message);
        navigate('/');
      } else {
        toast.info('Unable to update profile');
      }
    } catch (error) {
      toast.error('Error updating profile');
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Card>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">First Name</Form.Label>
                <Form.Control
                  placeholder="First Name"
                  value={formData.fname}
                  name="fname"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.fname}
                />
                <Form.Control.Feedback type="invalid">{errors.fname}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Last Name</Form.Label>
                <Form.Control
                  placeholder="Last Name"
                  value={formData.lname}
                  name="lname"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.lname}
                />
                <Form.Control.Feedback type="invalid">{errors.lname}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">User Name</Form.Label>
                <Form.Control
                  placeholder="User Name"
                  value={formData.uname}
                  name="uname"
                  onChange={handleFieldChange}
                  type="text"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Email</Form.Label>
                <Form.Control
                  placeholder="Email"
                  value={formData.email}
                  name="email"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.email}
                  disabled
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  placeholder="Address"
                  value={formData.address}
                  name="address"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Address2</Form.Label>
                <Form.Control
                  placeholder="Address2"
                  value={formData.address2}
                  name="address2"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.address2}
                />
                <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
              </Form.Group>
            </Col>

          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  placeholder="City"
                  value={formData.city}
                  name="city"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.city}
                />
                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label >State</Form.Label>
                <Form.Select
                  name="state"
                  value={formData.state}
                  onChange={handleFieldChange}
                  isInvalid={!!errors.state}
                >
                  {stateoptions.map((stateoption) => {
                    return (
                      <option key={stateoption.value} value={stateoption.value}>
                        {stateoption.key}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label className="required">Zip</Form.Label>
                <Form.Control
                  placeholder="Zip"
                  value={formData.zip}
                  name="zip"
                  onChange={handleFieldChange}
                  isInvalid={!!errors.zip}
                />
                <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <MaskedInput
                  mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className={`form-control ${errors.mobile ? 'is-invalid' : ''
                    }`}
                  guide={false}
                  placeholder="(XXX) XXX-XXXX"
                  onChange={handleFieldChange}
                 
                  name="mobile"
                  value={formData.mobile}
                />
                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col></Col>
            <Col md="3">
              <Button variant="primary" type="submit">
                Update Profile
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default MyProfile;
