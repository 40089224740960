import moment from 'moment';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';

const CycleDatesModal = ({ show, setShow, dateArray }) => {
    const [selectedIndex, setSelectedIndex] = useState(false);
    return (
        <Modal show={show} onHide={() => setShow(false)} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="fs-7 fw-semibold">Next Cycle Start Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-wrap gap-3 justify-content-center">
                    {dateArray.map((dateStr, index) => {
                        const dateObj = moment(dateStr);
                        return (
                            <div
                                key={index}
                                className="date-card d-flex flex-column align-items-center justify-content-center p-3 rounded-4 shadow-sm"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    cursor: 'pointer',
                                    backgroundColor: selectedIndex === index ? '#e6f2ff' : '#f9f9f9',
                                    border: selectedIndex === index ? '2px solid #0d6efd' : '1px solid #ddd',
                                    transition: 'all 0.2s ease-in-out',
                                }}
                                onClick={() => setSelectedIndex(index)}
                            >
                                <div className="fw-bold text-dark">
                                    {dateObj.format('MM/DD/YY')}
                                </div>
                                <div className="text-muted fs-12">
                                    {dateObj.format('dddd')}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default CycleDatesModal