import Flex from 'components/common/Flex';
import React, { useState } from 'react'
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import MyProfile from '../top/MyProfile';
import ResetPassword from '../top/ResetPassword';
import UserNotifcation from './UserNotifcation';

const NavItem = ({ item, classNameCustom }) => {
    return (
        console.log(classNameCustom),
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-3 px-1 cursor-pointer`}
                eventKey={item.toLowerCase().replaceAll(" ", "-")}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const UserProfileBody = (props) => {
    const [navItems, setNavItems] = useState(['Edit-Profile', 'Reset-Password',"User-Notification"]);
    const data = props.data;

    return (
        <Card className="mt-6">
            <Tab.Container id="audience-tab" defaultActiveKey="edit-profile">
                <Card.Header
                    as={Flex}
                    justifyContent="between"
                    alignItems="center"
                    className="p-0 border-bottom patientInfoTabs"
                >
                    <Nav
                        as="ul"
                        className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                    >
                        {navItems.map(item => (
                            <NavItem classNameCustom="col text-center" item={item} key={item} />
                        ))}
                    </Nav>
                </Card.Header>
              
                    <Row className="g-1 mt-2">
                        <Col xxl={12}>
                            <Tab.Content>
                                <Tab.Pane unmountOnExit eventKey="edit-profile">
                                    <MyProfile dataObj={data}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="reset-password">
                                    <ResetPassword />
                                </Tab.Pane>
                                <Tab.Pane eventKey="user-notification">
                                    <UserNotifcation/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                
            </Tab.Container>
        </Card>
    )
}

export default UserProfileBody