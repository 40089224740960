import React, { useState, useEffect } from 'react';
import { Breadcrumb, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import IconItem from 'components/common/icon/IconItem';
import AddOrderModal from './AddOrderModal';
import Select from "react-select";
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefillDiscontinuedModal from 'components/prescription/RefillDiscontinuedModal';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import SetMastersDetails from 'components/common/SetMastersDetails';
import RemoveMasters from 'components/common/RemoveMasters';

const ViewPatient = () => {
    //const [facility] = useState(facilityStaffData);
    //const [facilityResults, setFacilityResults] = useState([]);
    const [patient, setPatient] = useState([]);
    const [options, setOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showRefillModal, setShowRefillModal] = useState(false);
    const initialFacility = JSON.parse(localStorage.getItem("SelectedFacility")) || ({ key: "All Facilities", value: '' }) ;
    const groupList = JSON.parse(localStorage.getItem("groupsData")) || [];
    let facilityResults = JSON.parse(localStorage.getItem("facilitiesData"));
    const defaultGroup = groupList.length > 0 ? groupList[0] : null;
    const facilitiesForGroup = defaultGroup
        ? facilityResults.filter(facility => facility.group_id === defaultGroup.value)
        : [];
    const defaultFacility = facilitiesForGroup.length > 0 ? facilitiesForGroup[0] : initialFacility;
    const [groupOptions, setGroupOptions] = useState([]);
    const [facilityobj, setFacilityObj] = useState({});
    const [getFacilitiesObj, setFacilitiesObj] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(defaultGroup);
    const [selectedFacility, setSelectedFacility] = useState(defaultFacility);
    const [isTable, setIsTable] = useState(true);
    const navigate = useNavigate();
    // const [propsData, setPropsData] = useState(null);
    const columns = [
        {
            accessorKey: 'individual',
            header: 'Individual',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                const patientDetails = paths.patientDetails;
                return <Link to={{ pathname: `${patientDetails}/${original.patientid}` }}>
                    {original.individual}</Link>
            }
        },
        {
            accessorKey: 'dob',
            header: 'Dob',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'gender',
            header: 'Gender',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'facilityname',
            header: 'Facility Name',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'addorder',
            header: 'Add Order',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' }
            },
            cell: ({ row: { original } }) => {
                return <Link state={{
                    triggeredBy: "someAction",
                    data: original
                }}>
                    <IconItem
                        tag="button"
                        icon={['far', 'edit']}
                        size="sm"
                        onClick={addOrderModal}
                        className="btn rounded-3 me-2 fs-11 align-items-center"
                    />
                </Link>
            }
        },
        {
            accessorKey: '',
            header: 'Refill',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                return <Link className="p-2" onClick={() => { setShowRefillModal(true) }}
                    state={{
                        triggeredBy: "someAction",
                        id: original.patientid,
                        dataObj : original
                    }}>
                    <FontAwesomeIcon icon="plus" className="me-1 fs-11" /></Link>
            }
        }
        /*{
            accessorKey: 'medsheet',
            header: 'MedSheet',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                const { email } = original;
                return <a href='#'>MedSheet</a>;
            }
        },
        {
            accessorKey: 'treatment',
            header: 'Treatment',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                const { email } = original;
                return <a href='#'>Treatment</a>;
            }
        },*/
        // {
        //     accessorKey: 'Action',
        //     header: '',
        //     meta: {
        //         headerProps: {
        //             className: 'text-900'
        //         },
        //         cellProps: {
        //             className: 'align-items-center'
        //         }
        //     },
        //     enableSorting: false,
        //     cell: ({ row: { original } }) => {
        //Todo Temp Setup Till we get single doctor API Call
        // return (<Link to={{ pathname: paths.editPatient }} state={{
        //     patient_id: original.patientid,
        //     facilityid: original.facilityid
        /*firstname: original.first_name,
        lastname: original.last_name,                
        dob: original.dob,
        gender: original.gender,
        admit_date: original.admit_date,
        physical_exam_date: original.physical_exam_date,
        ssno: original.ssno,
        allergies: original.allergies,
        medsheet: original.medsheet,
        efsno: original.efsno,
        insgrpno: original.insgrpno,
        insno: original.insno,
        inspriority: original.inspriority,
        is_program: original.is_program,
        is_medicate: original.is_medicate*/

        //     }} ><IconItem
        //             tag="button"
        //             icon={['far', 'edit']}
        //             size="sm"
        //             className="btn rounded-3 me-2 fs-11 align-items-center"
        //         /></Link>)
        // }
        // }
    ];

    const addOrderModal = () => {
        setShowModal(true);
    };

    useEffect(() => {

        /*let tempFacilityResults = JSON.parse(localStorage.getItem("facilitiesData"));

        setFacilityResults([
            { key: 'Select a facility', value: '' },
            ...tempFacilityResults
        ]);*/

        //fetchPatientData(tempFacilityResults[0].value);
        if (defaultFacility) {
            fetchPatientData(defaultFacility.value);
        } else {
            fetchPatientData('');
        }
        SetMastersDetails();
        return () => {
            RemoveMasters();
        }
    }, []);

    async function fetchPatientData(facilityid) {
        try {
            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-all-patients/" + facilityid, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = [];

            //console.log("Patient Data Status " + JSON.stringify(data));
            let objData = data.patients.data;
            let groupId = "";
            const groupList = JSON.parse(localStorage.getItem("groupsData"));
            const facilityResultsNew = JSON.parse(localStorage.getItem("facilitiesData"));
            setOptions([
                { key: 'All facility', value: '' },
                ...facilityResultsNew
            ])
            if (localStorage.getItem("roleId") < 4) {
                let temp = {};
                const groupResults = groupList.map((group) => {
                    const facilitiesForGroup = facilityResults.filter(
                        (facility) => facility.group_id === group.value
                    );

                    temp[group.value] = facilitiesForGroup.map((facility) => ({
                        name: facility.key,
                        id: facility.value,
                    }));

                    return { key: group.key, value: group.value };
                });

                setGroupOptions([{ key: "Select Group", value: "" }, ...groupResults]);
                setFacilityObj(temp);
            }


            //console.log("patientObjData" + JSON.stringify(objData));

            objData.forEach((value) => {

                groupId = value.group_id;
                let genderName = "Male";
                if (value.gender != 1) {
                    genderName = "Female"
                }

                let filteredFacilityName = getFacilityName(value.facility_id, facilityResults);

                results.push({
                    individual: value.last_name + ", " + value.first_name,
                    dob: value.dob != null ? moment(value.dob, 'YYYY-MM-DD').format('MM/DD/YY') : null,
                    gender: genderName,
                    neworder: 'New Order',
                    medsheet: '',
                    treatment: '',
                    view: '',
                    facilityid: value.facility_id,
                    facilityname: filteredFacilityName,
                    patientid: value.id,
                    first_name: value.first_name,
                    last_name: value.last_name,
                    gender: genderName,
                    admit_date: value.admit_date,
                    physical_exam_date: value.physical_exam_date,
                    ssno: value.social_security_number,
                    allergies: value.allergies,
                    medsheet: value.medsheet_note,
                    efsno: value.insurance_efs_information,
                    insgrpno: value.insurance_group_no,
                    insno: value.insurance_no,
                    inspriority: value.insurance_priority,
                    is_program: value.not_capable_selfmeditating,
                    is_medicate: value.medication_independent_status
                });
            });

            //localStorage.setItem("groupId", groupId);

            setPatient(results);
        } catch (error) {
            navigate('/error')
        }
    }


    const handleGroupChange = (selectedGroup) => {
        setSelectedGroup(selectedGroup);
        const groupFacilityResults = facilityobj[selectedGroup.value]?.map((facility) => ({
            key: facility.name,
            value: facility.id,
        })) || [];
        setOptions([{ key: "All Facilities", value: '' }, ...groupFacilityResults]);
        setSelectedFacility(null);
    };

    function getFacilityName(facilityId, facilityList) {
        let facilityName = "";
        //Todo a break is required
        facilityList.forEach(function (singleFacility) {
            if (singleFacility.value == facilityId)
                facilityName = singleFacility.key;
        });
        return facilityName;
    }

    async function handleOptionSelect(selectedOption) {
        setSelectedFacility(selectedOption);
        localStorage.setItem("SelectedFacility", JSON.stringify(selectedOption));
        await fetchFacilityAfterSelection(selectedOption.value);
        if (Object.keys(facilityobj).length > 0) {
            const groupFacilityResults = facilityobj[selectedGroup.value]?.map((facility) => ({
                key: facility.name,
                value: facility.id,
            })) || [];
            setOptions([{ key: "All Facilities", value: '' }, ...groupFacilityResults]);
        }
    };

    const fetchFacilityAfterSelection = async (facilityId) => {
        try {
            let tokenStr = localStorage.getItem("accessToken");
            if (Number(localStorage.getItem("roleId")) < 4) {
                if (facilityId == "") {
                    setIsTable(false);
                    return
                }
            }
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-all-patients/" + facilityId, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = [];
            let objData = data?.patients?.data;
            if(objData.length > 0){
                setIsTable(true);
            }else{
                setIsTable(false);
            }
            objData.forEach((value) => {
                let genderName = "Male";
                if (value.gender != 1) {
                    genderName = "Female"
                }
                let filteredFacilityName = getFacilityName(value.facility_id, facilityResults);
                results.push({
                    individual: value.last_name + ", " + value.first_name,
                    dob: value.dob != null ? moment(value.dob, 'YYYY-MM-DD').format('MM/DD/YY') : null,
                    gender: genderName,
                    neworder: 'New Order',
                    medsheet: '',
                    treatment: '',
                    view: '',
                    facilityid: value.facility_id,
                    facilityname: filteredFacilityName,
                    patientid: value.id,
                    first_name: value.first_name,
                    last_name: value.last_name,
                    gender: genderName,
                    admit_date: value.admit_date,
                    physical_exam_date: value.physical_exam_date,
                    ssno: value.social_security_number,
                    allergies: value.allergies,
                    medsheet: value.medsheet_note,
                    efsno: value.insurance_efs_information,
                    insgrpno: value.insurance_group_no,
                    insno: value.insurance_no,
                    inspriority: value.insurance_priority,
                    is_program: value.not_capable_selfmeditating,
                    is_medicate: value.medication_independent_status
                });
                setPatient(results);
            })
        } catch (error) {
            navigate('/error')
        }
    };

    const table = useAdvanceTable({
        data: patient,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Patient', href: paths.viewPatient, isActive: true }
    ];

    return (
        <>
            {patient.length > 0 ? <>
                <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
                <RefillDiscontinuedModal show={showRefillModal} setShow={setShowRefillModal}/>
                <AddOrderModal show={showModal} setShow={setShowModal} />
                <AdvanceTableProvider {...table}>
                    <Card className="mb-3">
                        <Card.Body className="p-3">
                            <Row className='align-items-end mb-3'>
                                <Col xs="4" className="mb-0 view-patient-search-holder">
                                    <AdvanceTableSearchBox placeholder="Search..." />
                                </Col>
                                <Col xs="4" className="mb-0">
                                    {Number(localStorage.getItem("roleId") < 4) ? <Form.Group>
                                        <Form.Label>Group</Form.Label>
                                        <Select
                                            name="group"
                                            closeMenuOnSelect={true}
                                            placeholder='Select Group'
                                            classNamePrefix="react-select"
                                            options={groupOptions}
                                            value={selectedGroup}
                                            getOptionLabel={option => option.key}
                                            getOptionValue={option => option.value}
                                            onChange={(e) => handleGroupChange(e)}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: "#ced4da",
                                                    boxShadow: "none",
                                                    "&:hover": { borderColor: "#80bdff" },
                                                }),
                                                option: (provided, { isFocused, isSelected }) => ({
                                                    ...provided,
                                                    backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                                    color: isSelected ? "#212529 !important" : "#212529 !important",
                                                    cursor: "pointer",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#212529",
                                                }),
                                            }}
                                        />
                                    </Form.Group> : []}
                                </Col>
                                <Col xs="4" className="mb-0">
                                    <Form.Group>
                                        <Form.Label>Facility</Form.Label>
                                        <Select
                                            name="facility"
                                            closeMenuOnSelect={true}
                                            placeholder='Select Facility'
                                            classNamePrefix="react-select"
                                            options={options}
                                            value={selectedFacility}
                                            getOptionLabel={option => option.key}
                                            getOptionValue={option => option.value}
                                            onChange={(e) => handleOptionSelect(e)}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: "#ced4da",
                                                    boxShadow: "none",
                                                    "&:hover": { borderColor: "#80bdff" },
                                                }),
                                                option: (provided, { isFocused, isSelected }) => ({
                                                    ...provided,
                                                    backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                                    color: isSelected ? "#212529 !important" : "#212529 !important",
                                                    cursor: "pointer",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#212529",
                                                }),
                                            }}  
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col></Col>
                            </Row>
                            {isTable ? <Row>
                                <Col xs="12" className="mb-1 mt-1">
                                    <AdvanceTable
                                        headerClassName="bg-200 text-nowrap align-middle text-center"
                                        rowClassName="text-nowrap align-middle text-center"
                                        tableProps={{
                                            size: 'sm',
                                            striped: true,
                                            className: 'fs-10 mb-0 overflow-hidden'
                                        }}
                                    />
                                </Col>
                            </Row> :
                                <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '50px' }}>
                                    <p className="text-danger fw-bold mb-0">There is no data available for this option, Please select another one</p>
                                </div>
                            }
                        </Card.Body>
                        <Card.Footer>
                            <div className="mt-3">
                                <AdvanceTableFooter
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </div>
                        </Card.Footer>
                    </Card>
                </AdvanceTableProvider>
            </> : <ShimmerUI />}
        </>
    );
};

export default ViewPatient;