import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Table, Pagination } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DeliveryStatus = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState('');

  const sampleData = [
    {
      route: 'BRCK',
      firstName: 'DL00005816',
      lastName: 'AIN',
      group: 'AIN - AIN GROUP HOME',
      phone: '7812922163',
      deliveryType: 'Daily',
      plannedDate: '01/23/25',
      driver: '',
      eta: '',
      status: 'New',
      deliveryDate: '',
    },
    {
      route: 'BRCK',
      firstName: 'DL00006019',
      lastName: 'WALK',
      group: 'WALKER SCHOOL - 1968 CENTRAL AVE - MEDPACKS',
      phone: '7812922115',
      deliveryType: 'Daily',
      plannedDate: '01/23/25',
      driver: 'Pina, Melinda',
      eta: '',
      status: 'Delivered',
      deliveryDate: '01/23/25 08:32 PM',
    },
  ];

  const handleSearch = () => {
    console.log('Search clicked:', { fromDate, toDate, searchQuery });
  };

  return (
    <Card className="p-4">
      <Row className="mb-3">
        <Col md={3}>
          <Form.Group>
            <Form.Label>From:</Form.Label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="MM/dd/yyyy"
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>To:</Form.Label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="MM/dd/yyyy"
              className="form-control"
            />
          </Form.Group>
        </Col>
        <Col md={3} className="d-flex align-items-end">
          <Button variant="primary" className="me-2" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="info" className="me-2">
            Export
          </Button>
          <Button variant="secondary">Print</Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="4" className="mb-0 view-patient-search-holder">
          <AdvanceTableSearchBox placeholder="Search..." />
        </Col>
      </Row>
    </Card>
  );
};

export default DeliveryStatus;
