import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import LogoImage from './../../assets/img/logos/LogoImage.png';

const Logo = ({ at, width, className, textClass, ...rest }) => {
  return (
    <Link
      to="/main"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' },
        { 'navbar-brand pt-4 ': at === 'login' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'align-items-center': at === 'login',
            'flex-center fw-bolder fs-4 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img
          src={LogoImage}
          alt="MapYourMeds Logo"
          width={width}
          // height="auto"
          // style={{ maxHeight: '40px', objectFit: 'contain' }}
          className={textClass}
        />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 500 };

export default Logo;
