import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import DrugsAdminModal from './DrugsAdminModal';
import DrugsNoteModal from './DrugsNoteModal';

const DrugsModal = ({ show, setShow, patientname, patientid, drugsdata }) => {
    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [drugs, setDrugsData] = useState([]);
    const [documentType, setDocumentType] = useState();
    const [cycleDetailId, setCycleDetailId] = useState();
    const [tableOpData, setTableOpData] = useState([]);
    const [showDrugsNoteModal, setShowDrugsNoteModal] = useState(false);


    const columns = React.useMemo(() =>
        [
            {
                accessorKey: 'rxnumber',
                header: 'Rx number',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start px-2' }
                }
            },
            {
                accessorKey: 'drug',
                header: 'Drug',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start px-2' }
                }
            },
            {
                accessorKey: 'refill',
                header: 'Refill',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start px-2' }
                }
            },
            // {
            //     accessorKey: 'delivered',
            //     header: 'Delivered',
            //     meta: {
            //         headerProps: { className: 'text-900 text-start ' },
            //         cellProps: { className: 'text-start' }
            //     }
            // },
            // {
            //     accessorKey: 'nxtrefill',
            //     header: 'Next Refill',
            //     meta: {
            //         headerProps: { className: 'text-900 text-start ' },
            //         cellProps: { className: 'text-start' }
            //     }
            // },
            {
                accessorKey: 'status',
                header: 'Status',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start w-25 px-2' }
                },
                cell: ({ row: { original } }) => {
                    const lastSelectedValue = useRef(String(original.status));
                    const [isDropdownOpen, setDropdownOpen] = useState(false);
                    const handleSelectChange = (value) => {
                        handleRowData(value, "status", original.counter);
                        lastSelectedValue.current = value; // Update the last selected value
                    };

                    return (
                        <Form.Select
                            name={"cyclestatus" + original.counter}
                            value={original.status}
                            onChange={(e) => handleSelectChange(e.target.value)}
                            onClick={(e) => {
                                setTimeout(() => setDropdownOpen(document.activeElement === e.target), 100);
                                if (isDropdownOpen && (e.target.value) === lastSelectedValue.current) {
                                    handleRowData(e.target.value, "status", original.counter);
                                }
                            }}
                            size="sm"
                        >
                            <option key="Select" value="">
                                Select
                            </option>
                            <option key="dc" value="1">
                                DC
                            </option>
                            <option key="labelchange" value="2">
                                Label Change
                            </option>
                            <option key="hold" value="3">
                                Hold
                            </option>
                        </Form.Select>
                    );
                }
            },
            {
                accessorKey: 'house',
                header: 'House',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25 px-2' },
                    cellProps: { className: 'text-start px-2' }
                },
                cell: ({ row: { original } }) => {
                    // console.log("house" , original);
                    return <>
                        <Form.Control
                            type='number'
                            size='sm'
                            name={"housetxt" + original.counter}
                            value={original.house}
                            onChange={(e) => { handleRowData(e.target.value, "house", original.counter) }}
                        />
                    </>
                }
            },
            {
                accessorKey: 'dayprogram',
                header: 'Program',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25 px-2' },
                    cellProps: { className: 'text-start  px-2' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Form.Control
                            type='number'
                            size='sm'
                            name={"daytxt" + original.counter}
                            value={original.dayprogram}
                            onChange={(e) => { handleRowData(e.target.value, "dayprogram", original.counter) }}
                        />
                    </>
                }
            },
            {
                accessorKey: 'loa',
                header: 'LOA',
                meta: {
                    headerProps: { className: 'text-900 text-start w-25 px-2' },
                    cellProps: { className: 'text-start  px-2' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Form.Control
                            type='number'
                            size='sm'
                            name={"loatxt" + original.counter}
                            value={original.loa}
                            onChange={(e) => { handleRowData(e.target.value, "loa", original.counter) }}
                        />
                    </>
                }
            }

        ], []
    );

    useEffect(() => {
        setPatientName(patientname);
        setPatientId(patientid)
        filterDrugsData(drugsdata);

        //console.log("patientid" + patientid + patientname);
        // console.log("drugsdata" , drugsdata);

    }, [patientname, patientid, drugsdata]);

    const handleRowData = (gridControlValue, gridControlHeader, gridControlIndex) => {
        setDrugsData((prevDrugs) => {
            let updatedDrugs = [...prevDrugs];
            updatedDrugs[gridControlIndex] = {
                ...updatedDrugs[gridControlIndex],
                [gridControlHeader]: gridControlValue,
            };

            if (gridControlHeader === "status") {
                if (gridControlValue !== "") {
                    setDocumentType(gridControlValue);
                    setCycleDetailId(updatedDrugs[gridControlIndex].cycledetailid);
                    setShowDrugsNoteModal(true);
                }
            }
            return updatedDrugs;
        });
    };



    async function filterDrugsData(drugsdata) {

        let results = [];

        // console.log("modaldrugsdata" , (drugsdata));

        let firstrefill = drugsdata[0];
        let index = 0;

        for (let singledrugs in firstrefill) {

            results.push({
                cycledetailid: firstrefill[singledrugs].id,
                rxnumber: firstrefill[singledrugs].pharmacy_order_id,
                drug: firstrefill[singledrugs].drug,
                refill: firstrefill[singledrugs].refill_remaining,
                delivered: '',
                nxtrefill: '',
                status: firstrefill[singledrugs].change_type,
                house: firstrefill[singledrugs].facility_qty,
                dayprogram: firstrefill[singledrugs].program_qty,
                loa: firstrefill[singledrugs].loa,
                change_type: firstrefill[singledrugs].change_type,
                counter: index++
            })

        }

        //console.log("presresults" + JSON.stringify(results));
        //const tempResults = results;
        //setTableOpData(tempResults);
        localStorage.setItem("drugmodalresults", JSON.stringify(results));
        setDrugsData(results);
    }

    const handleSave = async (e) => {
        e.preventDefault();

        let hasError = false;
        let updatedDrugs = drugs.map((item) => {
            if ((item.house !== "" && item.house !== null) ||
                (item.dayprogram !== "" && item.dayprogram !== null) ||
                (item.loa !== "" && item.loa !== null)) {

                // If any field has a value, ensure others are not empty
                if (item.house === "" || item.house === null) {
                    item.house = 0;
                    hasError = true;
                }
                if (item.dayprogram === "" || item.dayprogram === null) {
                    item.dayprogram = 0;
                    hasError = true;
                }
                if (item.loa === "" || item.loa === null) {
                    item.loa = 0;
                    hasError = true;
                }
            }
            return item;
        });

        if (hasError) {
            setDrugsData(updatedDrugs);
            toast.warn("Missing values in House, Program, or LOA. Enter 0 where necessary.");
            return;
        }

        let tokenStr = localStorage.getItem("accessToken");
        let drugsGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-prescriptions`;

        let drugsGridData = updatedDrugs.map((arrayItem) => ({
            "cycle_detail_id": arrayItem["cycledetailid"],
            "change_type": arrayItem["status"],
            "facility_qty": arrayItem["house"],
            "program_qty": arrayItem["dayprogram"],
            "loa": arrayItem["loa"],
        }));

        let finalDrugsData = {
            "cycle_patient_id": patientId,
            "prescriptions": drugsGridData
        };

        try {
            const { data } = await axios.post(drugsGridUrl, finalDrugsData, {
                headers: { "Authorization": `Bearer ${tokenStr}` }
            });

            if (Object.keys(data).length > 0) {
                toast.success("Drug Details added successfully");
                setShow(false);
            } else {
                toast.error("Error occurred while filling Drug Details");
            }
        } catch (error) {
            toast.error("Failed to save drug details.");
        }
    };

    const table = useAdvanceTable({
        data: drugs,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
        selectionColumnWidth: 52
    });

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    Census for {patientName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary">
                <Row>
                    <AdvanceTableProvider {...table}>
                        <Row>
                            <Col xs="12">
                                <AdvanceTable
                                    headerClassName="bg-200 text-nowrap align-middle text-center"
                                    rowClassName="text-nowrap align-middle text-center"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="p-4">
                            <Col>
                                <AdvanceTableFooter
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </Col>
                        </Row>
                    </AdvanceTableProvider>
                </Row>
                <br />
                <Row>
                    <Col xs="8"></Col>
                    <Col xs="4">
                        <Button color="primary" className="w-100" onClick={handleSave}>Save</Button>
                    </Col>
                    <DrugsNoteModal show={showDrugsNoteModal} setShow={setShowDrugsNoteModal} docType={documentType} patientId={patientId} cycleDetailId={cycleDetailId} />
                </Row>
            </Modal.Body>
        </Modal>
    );
};

DrugsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default DrugsModal;

