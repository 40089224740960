import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import MedCard from './MedCard'

const PatientCard = ({ patient }) => {
    const facilityList = JSON.parse(localStorage.getItem("facilitiesData"));
    let facilityObj = facilityList.reduce((obj, item) => {
        obj[item.value] = item.key;
        return obj;
    }, {})
    return (
        <Card className='cycleViewCard m-2' role="button" >
            <Card.Header className={"bg-orange" + ' px-3 py-1 ' + "text-white"}>
                {patient.patient_lastname},{patient?.patient_firstname}
            </Card.Header>

            <Card.Body className={"bg-orange" + '-body text-dark px-2 py-1'}  >
                <Row>
                    <Col xs="7">
                        <span>{facilityObj[patient.facility_id]}</span>
                    </Col>
                    <Col xs="5" className="text-end">
                        12/31
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" >
                        {
                            patient?.orders?.map((order, orderIndex) => {
                                return (
                                    <MedCard key={orderIndex} data={order} />
                                )
                            })
                        }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PatientCard