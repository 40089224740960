import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

const PrescriptionModal = ({ show, setShow, handlePrescriptionModalValue }) => {

    let formName = localStorage.getItem("prescriptionFormName");
    let formValues = localStorage.getItem("prescriptionModalFormValues");

    let currentModalFormValue = "";

    if (formName == "btnmedic")
        currentModalFormValue = "Medication Status"
    else if (formName == "btnsplins")
        currentModalFormValue = "Medical Special Instruction"
    else if (formName == "btnrsnpres")
        currentModalFormValue = "Reason Prescribed"
    else if (formName == "btnroute")
        currentModalFormValue = "Route"
    else if (formName == "btnfrequency")
        currentModalFormValue = "Frequency"
    else if (formName == "btnamt")
        currentModalFormValue = "Amount"

    const [prescriptionModalData, setPrescriptionModalData] = useState("");

    const handleCallbackSubmit = e => {
        e.preventDefault();
        //console.log("prescriptionModalData " + prescriptionModalData);
        if (prescriptionModalData.trim() != "") {
            setShow(false);
            handlePrescriptionModalValue(prescriptionModalData);
            setPrescriptionModalData("");
        }
        else {
            toast.error("Empty Spaces Not allowed");
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="h5" id="copyLinkModalLabel">
                    {formName == "btnmedic" && (
                        <p>Medication Inspection Status</p>
                    )}
                    {formName == "btnsplins" && (
                        <p>Medical Special Instruction</p>
                    )}
                    {formName == "btnrsnpres" && (
                        <p>Reason Prescribed</p>
                    )}
                    {formName == "btnroute" && (
                        <p>Route</p>
                    )}
                    {formName == "btnfrequency" && (
                        <p>Frequency</p>
                    )}
                    {formName == "btnamt" && (
                        <p>Amount</p>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="required">{currentModalFormValue}</Form.Label>
                                <Form.Control
                                    value={prescriptionModalData}
                                    name="prescriptiontextbox"
                                    onChange={e => setPrescriptionModalData(e.target.value)}
                                    type="text"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="button"
                                    color="primary"
                                    name="btnprescriptionmodalsubmit"
                                    className="mt-4 w-50"
                                    onClick={handleCallbackSubmit}
                                    disabled={!prescriptionModalData}>
                                    Add
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

PrescriptionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default PrescriptionModal;
