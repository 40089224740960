import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import axios from "axios";
import { toast } from 'react-toastify';

const ResetPassword = () => {

  let initialFormData = {
    oldpassword: '',
    newpassword: '',
    cnfpassword: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    let validationErrors = {};

    if (formData.oldpassword === formData.newpassword) {
      validationErrors.newpassword = "New password should not be the same as the old password";
    }

    if (formData.newpassword !== formData.cnfpassword) {
      validationErrors.cnfpassword = "Confirm password does not match new password";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    let resetPasswordData = {
      "password": formData.newpassword,
      "confirm_password": formData.cnfpassword
    };

    let tokenStr = localStorage.getItem("accessToken");
    let resetPasswordUrl = `${process.env.REACT_APP_SERVER_URL}/users/change-password`;

    try {
      const { data } = await axios.post(resetPasswordUrl, resetPasswordData, {
        headers: { 'Authorization': `Bearer ${tokenStr}` }
      });

      if (data.status) {
        toast.success("Password updated successfully");
        navigate("/");
      } else {
        toast.error("Error encountered while updating the password");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Form onSubmit={handleSubmit} name="passwordform">
      <Card className="p-2">
        <Row className="m-2">
          <Col md="12">
            <Form.Group>
              <Form.Label className="required">Old Password</Form.Label>
              <Form.Control
                placeholder='Old Password'
                value={formData.oldpassword}
                name="oldpassword"
                onChange={handleFieldChange}
                type="password"
                size="sm"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-2">
          <Col md="12">
            <Form.Group>
              <Form.Label className="required">New Password</Form.Label>
              <Form.Control
                placeholder='New Password'
                value={formData.newpassword}
                name="newpassword"
                onChange={handleFieldChange}
                type="password"
                size="sm"
                isInvalid={!!errors.newpassword}
              />
              <Form.Control.Feedback type="invalid">{errors.newpassword}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-2">
          <Col md="12">
            <Form.Group>
              <Form.Label className="required">Confirm Password</Form.Label>
              <Form.Control
                placeholder='Confirm Password'
                value={formData.cnfpassword}
                name="cnfpassword"
                onChange={handleFieldChange}
                type="password"
                size="sm"
                isInvalid={!!errors.cnfpassword}
              />
              <Form.Control.Feedback type="invalid">{errors.cnfpassword}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-2">
          <Col></Col>
          <Col md="3">
            <Form.Group>
              <Button variant="primary" type="submit">
                Reset Password
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default ResetPassword;
