import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as mapYourMedsConstant from '../../common/mapYourMedsConstant';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import IframeContainer from 'components/navbar/top/IframeContainer';

const CycleStatusGrid = ({ cyclestatusdata, selectedFacility, selectedCensus }) => {
    const columns = [
        // {
        //     accessorKey: 'group',
        //     header: 'Group',
        //     meta: {
        //         headerProps: { className: 'text-900 text-start' },
        //         cellProps: { className: 'text-start' }
        //     }
        // },
        {
            accessorKey: 'facility',
            header: 'Facility',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'cycle',
            header: 'Cycle',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'delivery',
            header: 'Delivery',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'cutoff',
            header: 'Cutoff',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'censusstatus',
            header: 'Census',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.censusstatus == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.censusstatus == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }
        },
        {
            accessorKey: 'billed',
            header: 'Billed',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.billed == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.billed == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }
        },
        {
            accessorKey: 'robot',
            header: 'Robot',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.robot == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.robot == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }
        },
        {
            accessorKey: 'manual',
            header: 'Manual',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.manual == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.manual == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }
        },
        {
            accessorKey: 'control',
            header: 'Control',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.control == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.control == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }
        },
        {
            accessorKey: 'pv2',
            header: 'PV2',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.pv2 == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.pv2 == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }

        },
        {
            accessorKey: 'manifest',
            header: 'Manifest',
            meta: {
                headerProps: { className: 'text-900 text-center w-10' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    {original.manifest == "Y" && (<FontAwesomeIcon
                        icon="check"
                        className="text-success"
                    />)}
                    {original.manifest == "N" && (<FontAwesomeIcon className="text-danger" icon="times-circle" />)}
                </>
            }
        },
        {
            accessorKey: 'print',
            header: 'Print',
            meta: {
              headerProps: { className: 'text-900' },
            },
            cell: ({ row: { original } }) => {
              return (
                <div>
                  <Button 
                    color="primary" 
                    className="w-100" 
                    onClick={() => handleIframeToggle(original.cycleid)}
                  >
                    Print
                  </Button>
                  {showIframe && selectedCycleId === original.cycleid && (
                    <IframeContainer key={iframeKey} id={original?.cycleid} />
                  )}
                </div>
              );
            }
          },
    ];
    const [cyclestatus, setCycleStatus] = useState([]);
    const [fullTableData, setFullTableData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showIframe, setShowIframe] = useState(false);
    const [iframeKey, setIframeKey] = useState(0);
    const [selectedCycleId, setSelectedCycleId] = useState(null);

    useEffect(() => {
        setShowIframe(false);
        async function fetchCycleGridDetails() {
            setLoading(true);
            try {
                let tokenStr = localStorage.getItem("accessToken");
                let cycleStatusIp = {
                    "start_date": cyclestatusdata.startdate,
                    "end_date": cyclestatusdata.enddate
                };
                const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/cycles/get-cycle-status`, cycleStatusIp, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
                const cycleStatusOp = data.cycleData;
                let results = [];
                for (const dateKey in cycleStatusOp) {
                    cycleStatusOp[dateKey].forEach((singleData) => {
                        results.push({
                            cycleid : singleData.id,
                            facilityid: singleData.facility_id,
                            facility: get_facility_name(singleData.facility_id),
                            cycle: moment(singleData.cycle_start_date).format('YY/MM/DD'),
                            delivery: `${moment(singleData.delivery_date).format('YY/MM/DD')} ${singleData.delivery_time}`,
                            cutoff: moment(singleData.census_before).format('YY/MM/DD'),
                            censusstatus: singleData.census_status === 0 ? 'N' : 'Y',
                            billed: singleData.billed_status == null ? 'N' : 'Y',
                            robot: singleData.tcgrx_fill == null ? 'N' : 'Y',
                            manual: singleData.manual_fill == null ? 'N' : 'Y',
                            control: singleData.dosis_Fill == null ? 'N' : 'Y',
                            pv2: singleData.pv2_Status == null ? 'N' : 'Y',
                            manifest: singleData.manifest === 0 ? 'N' : 'Y'
                        });
                    });
                }
                setFullTableData(results);
                setFilteredTableData(results);
            } catch (e) {
                console.error("Error fetching cycle status data:", e);
            } finally {
                setLoading(false);
            }
        }

        async function refreshCycleGridDetails() {
            fetchCycleGridDetails();
        }
        refreshCycleGridDetails();

    }, [cyclestatusdata.startdate, cyclestatusdata.enddate]);

    useEffect(() => {
        setShowIframe(false);
        let filtered = fullTableData;

        if (selectedFacility) {
            filtered = filtered.filter(row => row.facilityid === selectedFacility);
        }
        if (selectedCensus) {
            filtered = filtered.filter(row => row.censusstatus === selectedCensus);
        }
        setFilteredTableData(filtered);
    }, [selectedFacility, selectedCensus, fullTableData]);


    const handleIframeToggle = (cycleid) => {
        setIframeKey(iframeKey + 1);
        setShowIframe(true);
        setSelectedCycleId(cycleid);
      };
    //todo make it common
    function get_facility_name(facility_id) {
        let facilityname = "";
        let facilityresults = JSON.parse(localStorage.getItem("facilitiesData"));

        //console.log("facilityresults " + facilityresults + facility_id);

        facilityresults.forEach(function (facilityItem) {
            if (facilityItem.value == facility_id) {
                facilityname = facilityItem.key;
            }
        });

        return facilityname;
    }

    //todo make it common
    function get_group_name(group_id) {
        let grpname = "";
        let grpresults = JSON.parse(localStorage.getItem("groupData"));

        //console.log("facilityresults " + JSON.stringify(facilityresults) + facility_id);

        grpresults.forEach(function (grpItem) {
            if (grpItem.value == group_id) {
                grpname = grpItem.key;
            }
        });

        return grpname;
    }



    // async function fetchCycleStatus() {
    //     let results = [];
    //     results.push({
    //         group: 'Evergreen',
    //         facility: 'Evergreen Mendon 2',
    //         cycle: '10/01/24-11/06/24',
    //         delivery: '10/15/24 10:00-15:00PM',
    //         cutoff: '10/06/24',
    //         censusstatus: 'Y',
    //         billed: 'N',
    //         robot: 'N',
    //         manual: 'Y',
    //         control: 'Y',
    //         pv2: 'Y',
    //         manifest: 'Y'
    //     });
    //     setCycleStatus(results);
    // }

    const table = useAdvanceTable({
        data: filteredTableData,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <>
            {loading ? (
                <ShimmerUI />
            ) : filteredTableData.length > 0 ? (
                <AdvanceTableProvider {...table}>
                    <Row className='mt-3 justify-content-between'>
                        <Col xs="4" className="mb-3">
                            <AdvanceTableSearchBox placeholder="Search..." />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-1 mt-1">
                            <AdvanceTable
                                headerClassName="bg-200 text-nowrap align-middle text-center"
                                rowClassName="text-nowrap align-middle text-center"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs-10 mb-0 overflow-hidden'
                                }}
                            />
                        </Col>
                    </Row>
                    <Card.Footer>
                        <div className="mt-3">
                            <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
                        </div>
                    </Card.Footer>
                </AdvanceTableProvider>
            ) : (
                <div className="text-center py-4">
                    <h5 className="text-danger">There is No Data Available in the table</h5>
                </div>
            )}
        </>
    );
};

export default CycleStatusGrid;