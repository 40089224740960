import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Button, Table, Alert } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import RefillYesModal from "./RefillYesModal";
import RefillNoModal from "./RefillNoModal";
import moment from "moment";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import { FixedSizeList as List } from "react-window";
import PrescriptionDetailsModal from "./PrescriptionDetailsModal";
import ShimmerUI from "components/ShimmerUI/PatientShimmer";

const NeedfRefill = () => {
    const columns = [
        {
            accessorKey: 'patient',
            header: 'Patient',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'drug',
            header: 'Drug',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'rx',
            header: 'Rx',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'text-center' }
            }
        },
        {
            accessorKey: 'lastDelivered',
            header: 'Last Delivered',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'text-center' }
            }
        },
        {
            accessorKey: 'refills',
            header: 'Refills',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'text-center' }
            }
        },
        {
            accessorKey: 'dueBy',
            header: 'Due By',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'text-center' }
            }
        },
        {
            accessorKey: 'refill',
            header: 'Refill',
            meta: {
                headerProps: { className: 'text-900 text-center px-2' },
                cellProps: { className: 'text-center px-2' }
            },
            cell: ({ row: { original } }) => (
                <>
                    <span>Yes </span>
                    <input
                        type="checkbox"
                        onChange={() => {
                            setSelectedOrder(original);
                            setYesModal(true);
                        }}
                    />
                    <span className="ms-2">No </span>
                    <input
                        type="checkbox"
                        onChange={() => {
                            setSelectedOrder(original); // Store selected row data
                            setNoModal(true);
                        }}
                    />
                </>
            )
        },
        {
            accessorKey: 'moreDetails',
            header: 'More Details',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => (
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => {
                        setSelectedOrder(original);
                        setDetailModal(true);
                    }}
                >
                    🔍
                </Button>
            )
        }
    ];

    const today = moment().format("MM/DD/YYYY");
    const lastYear = moment().subtract(1, 'years').format("MM/DD/YYYY");

    const [filters, setFilters] = useState({
        startDate: lastYear,
        endDate: today,
        facility: "",
        patient: "",
        rxNumber: "",
    });
    const height = 35;
    const [isLoading, setIsLoading] = useState(false);
    const [showNoModal, setNoModal] = useState(false);
    const [showYesModal, setYesModal] = useState(false);
    const [showDetailModal, setDetailModal] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [patientOptions, setPatientOptions] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const facilityOptions = JSON.parse(localStorage.getItem("facilitiesData")) || [];

    const defaultFacility = facilityOptions.length > 0 ? facilityOptions[0].value : "";

    useEffect(() => {
        if (defaultFacility) {
            setFilters((prev) => ({ ...prev, facility: defaultFacility }));
            handleSearch({ facility: defaultFacility });
        }
    }, []);

    const handleSearch = async (overrideFilters = {}) => {
        const updatedFilters = { ...filters, ...overrideFilters };
        if (!updatedFilters.startDate || !updatedFilters.endDate || !updatedFilters.facility) {
            alert("Please select Start Date, End Date, and Facility");
            return;
        }
        setIsLoading(true);

        let tokenStr = localStorage.getItem("accessToken");
        const payload = {
            from_date: updatedFilters.startDate,
            to_date: updatedFilters.endDate,
            facility_id: updatedFilters.facility,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/orders/refill-reminders-search/`,
                payload, { headers: { 'Authorization': `Bearer ${tokenStr}` } }
            );

            const objData = response.data.reminders;
            let results = [];
            let uniquePatientIds = new Set();
            let patientOptions = [{ key: 'All Patients', value: '' }];
            objData.forEach((iterator) => {
                if (!uniquePatientIds.has(iterator.patient_id)) {
                    uniquePatientIds.add(iterator.patient_id);
                    patientOptions.push({
                      key: `${iterator.patient_lastname}, ${iterator.patient_firstname}`,
                      value: iterator.patient_id,
                    });
                  }
                results.push({
                    orderId: iterator.id,
                    patient: iterator.patient_lastname + ", " + iterator.patient_firstname,
                    patient_id: iterator.patient_id,
                    drug: iterator.drug,
                    rx: iterator.pharmacy_order_id,
                    lastDelivered: moment(iterator.last_delivered).format("MM/DD/YY"),
                    refills: iterator.remaining_refills,
                    dueBy: moment(iterator.next_refill).format("MM/DD/YY"),
                    prescribe_date: moment(iterator.prescribe_date).format("MM/DD/YY"),
                });
            });

            setPatientOptions(patientOptions);
            setTableData(results);
            setFilteredData(results);
        } catch (error) {
            setTableData([]);
            setFilteredData([]);
        } finally {
            setIsLoading(false);
        }
    };

    // Filter the fetched data
    useEffect(() => {
        let filtered = tableData;

        if (selectedPatient) {
            if (selectedPatient.value != '') {
                filtered = filtered.filter(item => Number(item.patient_id) === Number(selectedPatient.value));
            }

        }
        if (filters.rxNumber) {
            filtered = filtered.filter(item => Number(item.rx) === Number(filters.rxNumber));
        }

        setFilteredData(filtered);
    }, [selectedPatient, filters.rxNumber]);

    const table = useAdvanceTable({
        data: filteredData,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    const MenuList = (props) => {
        const { options, children, maxHeight, getValue } = props;
        const value = getValue();
        const initialOffset = options.findIndex((option) => option.value === value?.value);

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset * height}
                width="100%"
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    };

    const handleRefillSuccess = (orderId) => {
        setFilteredData((prevData) => prevData.filter(patient => patient.orderId !== orderId));
    };

    return (
        <>
            <PrescriptionDetailsModal show={showDetailModal} handleClose={() => setDetailModal(false)} prescription={selectedOrder} />
            <RefillNoModal show={showNoModal} handleClose={setNoModal} orderData={selectedOrder} onRefillSuccess={handleRefillSuccess} />
            <RefillYesModal show={showYesModal} setShow={setYesModal} orderData={selectedOrder} onRefillSuccess={handleRefillSuccess} />

            <Card className="p-3">
                <h4 className="mb-3">Weekly Refill Reminders</h4>
                <div className="border rounded p-3 pb-4 mb-4">
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Label>Start Date:</Form.Label>
                            <DatePicker
                                selected={moment(filters.startDate, "MM/DD/YYYY").toDate()}
                                onChange={(date) => setFilters(prev => ({ ...prev, startDate: moment(date).format("MM/DD/YYYY") }))}
                                className="form-control"
                                dateFormat="MM/dd/yy"
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Label>End Date:</Form.Label>
                            <DatePicker
                                selected={moment(filters.endDate, "MM/DD/YYYY").toDate()}
                                onChange={(date) => setFilters(prev => ({ ...prev, endDate: moment(date).format("MM/DD/YYYY") }))}
                                className="form-control"
                                dateFormat="MM/dd/yy"
                            />
                        </Col>
                        <Col md={4}>
                            <Form.Label>Facility:</Form.Label>
                            <Select
                                name="facility"
                                closeMenuOnSelect={true}
                                placeholder="Select Facility"
                                classNamePrefix="react-select"
                                options={facilityOptions}
                                value={facilityOptions.find(facility => facility.value === filters.facility)}
                                getOptionLabel={(option) => option.key}
                                getOptionValue={(option) => option.value}
                                onChange={(selectedOption) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        facility: selectedOption.value,
                                    }))
                                }
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: "#ced4da",
                                        backgroundColor: "white",
                                        minHeight: "38px",
                                        boxShadow: "none",
                                        "&:hover": { borderColor: "#80bdff" },
                                    }),
                                    option: (provided, { isFocused, isSelected }) => ({
                                        ...provided,
                                        backgroundColor: isSelected
                                            ? "#e9ecef !important"
                                            : isFocused
                                                ? "#f8f9fa !important"
                                                : "white !important",
                                        color: isSelected ? "#212529 !important" : "#212529 !important",
                                        cursor: "pointer",
                                    }),
                                }}
                            />
                        </Col>
                        <Col className="d-flex align-items-end"><Button variant="primary" onClick={() => handleSearch()}>Search</Button></Col>
                    </Row>
                </div>
                <div className="filters-section border rounded pt-4 p-3 position-relative mb-4">
                    <div className="filter-heading">Filters</div>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Patient:</Form.Label>
                                <Select
                                    options={patientOptions}
                                    value={selectedPatient}
                                    onChange={setSelectedPatient}
                                    getOptionLabel={(option) => option.key}
                                    getOptionValue={(option) => option.value}
                                    placeholder="Select Patient"
                                    components={{ MenuList }}
                                    closeMenuOnSelect={true}
                                    classNamePrefix="react-select"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: "#ced4da",
                                            backgroundColor: "white",
                                            minHeight: "38px",
                                            boxShadow: "none",
                                            "&:hover": { borderColor: "#80bdff" },
                                        }),
                                        option: (provided, { isFocused, isSelected }) => ({
                                            ...provided,
                                            backgroundColor: isSelected
                                                ? "#e9ecef !important"
                                                : isFocused
                                                    ? "#f8f9fa !important"
                                                    : "white !important",
                                            color: isSelected ? "#212529 !important" : "#212529 !important",
                                            cursor: "pointer",
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Rx Number:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="rxNumber"
                                    placeholder="Search By Rx Number"
                                    value={filters.rxNumber}
                                    onChange={(e) => setFilters(prev => ({ ...prev, rxNumber: e.target.value }))}
                                // onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>


                {isLoading ? (
                    <ShimmerUI />
                ) : filteredData.length === 0 ? (
                    <Alert variant="info" className="text-center fw-bold">
                        No order details found for the selected date range.
                    </Alert>
                ) : (
                    <AdvanceTableProvider {...table}>
                        <Row>
                            <Col xs="12">
                                <AdvanceTable
                                    headerClassName="bg-200 text-nowrap align-middle text-center"
                                    rowClassName="text-nowrap align-middle text-center"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Card.Footer>
                                <div className="mt-3">
                                    <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
                                </div>
                            </Card.Footer>
                        </Row>
                    </AdvanceTableProvider>
                )}
            </Card>
        </>
    );
};

export default NeedfRefill;
