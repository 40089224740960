import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/20.jpg';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const PasswordResetForm = ({ hasLabel }) => {
  const [error, setError] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  // Handler
  async function handleSubmit(e) {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setError('');

    let sendingObj = {
      "hash" : id,
      "password" : formData.password,
      "confirm_password" : formData.confirmPassword
    }

    let tokenStr = localStorage.getItem("accessToken");
    let forgotpasswordurl = `${process.env.REACT_APP_SERVER_URL}` + '/users/reset-password'
    const { data } = await axios.post(forgotpasswordurl, sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    if (data.status) {
      toast.success('Login with your new password', {
        theme: 'colored'
      });
      navigate("/")
    }
    else {
      toast.info("Error occured while Resetting Password");
    }

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (

    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <h3>Reset password</h3>
      </div>
      <Form
        className={classNames('mt-3', { 'text-left': hasLabel })}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>New Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'New Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Button
          type="submit"
          className="w-100"
          disabled={!formData.password || !formData.confirmPassword}
        >
          Set password
        </Button>
      </Form>
    </AuthSplitLayout>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
