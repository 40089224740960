import axios from 'axios';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import paths from 'routes/paths';

const EditFacilityLookUpForm = () => {
    const location = useLocation();
    const data = location.state.obj;
    const [formData, setFormData] = useState(data);
    const [leadDays, setLeadDays] = useState([
        { key: '1', value: '1' },
        { key: '2', value: '2' },
        { key: '3', value: '3' },
        { key: '4', value: '4' },
        { key: '5', value: '5' },
        { key: '6', value: '6' }
    ]);
    const [deliveryTime, setDeliveryTime] = useState([
        {
            "key": "03:00PM - 08:00PM",
            "value": 0
        },
        {
            "key": "10:00AM - 03:00PM",
            "value": 1
        },
    ]);

    const handleSubmit = async e => {
        e.preventDefault()
        let tokenStr = localStorage.getItem("accessToken");
        console.log("rendered")
        let objData = {
            facility_id: formData.id,
            is_liaison: formData.is_liaison,
            is_email: formData.is_email,
            email: formData.email,
            admin_email: formData.admin_email,
            is_fax: formData.is_fax,
            is_sms: formData.is_sms,
            fax: formData.fax,
            phone: formData.phone,
            cycle_leed_day: formData.cycle_leed_day,
            delivery_time: formData.delivery_time
        }
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/facilities/update-facility", objData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.status) {
            toast.success("Group Details Updated successfully");
        }
        else {
            toast.info("Error");
        }
    }

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Groups&Facility', href: paths.faciliityLookUp, isActive: false },
        { name: 'Group-Profile', href: `${paths.facilitylistlookUp}/${formData.group_id}`, isActive: false },
        { name: 'Edit-Facility', href: paths.facilitylistlookUp, isActive: true },
    ];

    return (
        <>
        <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />
        <Form onSubmit={handleSubmit} name="FacilityLookUpForm">
            <Card className="mb-3">
                <Card.Body >
                    <Row className="mb-2">
                        <Form.Group>
                            <Form.Label>Facility</Form.Label>
                            <Form.Control
                                name="facility_name"
                                onChange={handleFieldChange}
                                size="sm"
                                disabled="disabled"
                                value={formData.facility_name}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_email"
                                id='is_email'
                                checked={
                                    formData.is_email
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_email: e.target.checked ? 1 : 0
                                    })
                                }
                                label='Email'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_fax"
                                id='is_fax'
                                checked={
                                    formData.is_fax
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_fax: e.target.checked ? 1 : 0
                                    })
                                }
                                label='Fax'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_sms"
                                id='is_sms'
                                checked={
                                    formData.is_sms
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_sms: e.target.checked ? 1 : 0
                                    })
                                }
                                label='SMS'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_liaison"
                                id='is_liaison'
                                checked={
                                    formData.is_liaison
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_liaison: e.target.checked ? 1 : 0
                                    })
                                }
                                label='Liaison'
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type='switch'
                                name="is_medpass"
                                id='is_medpass'
                                checked={
                                    formData.is_medpass
                                }
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        is_medpass: e.target.checked ? 1 : 0
                                    })
                                }
                                label='Med Pass'
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    placeholder='Email'
                                    value={formData.email != "null" ? formData.email : ""}
                                    name="email"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Facility Admin Email</Form.Label>
                                <Form.Control
                                    placeholder='Email'
                                    value={formData.facilityadminemail != "null" ? formData.facilityadminemail : ""}
                                    name="admin_email"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Mobile Number</Form.Label>
                                <MaskedInput
                                    mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    guide={false}
                                    placeholder="(XXX) XXX-XXXX"
                                    onChange={handleFieldChange}
                                    size="sm"
                                    name="mobile"
                                    value={formData.mobile}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>FAX</Form.Label>
                                <Form.Control
                                    placeholder=''
                                    value={formData.fax}
                                    name="fax"
                                    onChange={handleFieldChange}
                                    type="text"
                                    size="sm"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Lead Number of Day for Cycle Delivery</Form.Label>
                                <Form.Select
                                    name="cycle_leed_day"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {leadDays.map((option) => {
                                        let isLeadSelected = false;
                                        if (option.value == data.cycle_leed_day) {
                                            isLeadSelected = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isLeadSelected} >
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Delivery Time</Form.Label>
                                <Form.Select
                                    name="delivery_time"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {deliveryTime.map((option) => {
                                        let isdeliveryTime = false;
                                        if (option.value == data.delivery_time) {
                                            isdeliveryTime = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isdeliveryTime} >
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Row className="mb-2">
                        <Col>
                            <Form.Group>
                                <Form.Label>Print Medsheet</Form.Label>
                                <Form.Select
                                    name="selectedmedsheet"
                                    onChange={handleFieldChange}
                                    size="sm"
                                >
                                    {medSheet.map((option) => {
                                        let isMedSheetSelected = false;
                                        if (option.value == data.leadId) {
                                            isMedSheetSelected = "selected";
                                        }
                                        return (
                                            <option key={option.value} value={option.value} selected={isMedSheetSelected} >
                                                {option.key}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col></Col>
                        <Col>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="w-100 mt-4">
                                    {"Save"}
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                </Card.Body>
            </Card>
        </Form>
        </>
    )
}

export default EditFacilityLookUpForm