import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Card } from 'react-bootstrap';
import Select from "react-select";

const OrderHistoryDetailModal = (props) => {
    const data = props.dataObj;
    let order_status_facility = JSON.parse(localStorage.getItem("order_status_facility"));
    const [formData, setFormData] = useState(data || {});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    return (
        <Card>
            <Card.Body>
                <Form>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Patient</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="patient"
                                    value={formData.patient || ''}
                                    onChange={handleInputChange}
                                    disabled
                                    size='sm'
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Order Status</Form.Label>
                                <Form.Control
                                    name="orderStatus"
                                    onChange={handleInputChange}
                                    value={order_status_facility[formData.orderstatus] || formData.orderstatus}
                                    disabled
                                    size='sm'
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Rx Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="rxnumber"
                                    value={formData.rxnumber || ''}
                                    onChange={handleInputChange}
                                    disabled
                                    size='sm'
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Drug</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="drug"
                                    value={formData.drug || ''}
                                    onChange={handleInputChange}
                                    size='sm'
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                    <Col md={6}>
                            <Form.Group>
                                <Form.Label>Qty</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="qty"
                                    value={formData.qty || ''}
                                    onChange={handleInputChange}
                                    size='sm'
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Prescribed Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="deliverBy"
                                    value={formData.deliverBy || ''}
                                    onChange={handleInputChange}
                                    size='sm'
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Created</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="tech"
                                    value={formData.createddate || 'Tech'}
                                    disabled
                                    size='sm'
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Delivery Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="deliveryDate"
                                    value={formData.deliverydate || ''}
                                    onChange={handleInputChange}
                                    size='sm'
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Directions</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="direction"
                                    value={formData.direction}
                                    disabled
                                    size='sm'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <Row className='mt-2'>
                    <Col>
                        <p className='text-danger fw-bold'>NO FURTHER DETAILS AVAILABLE.</p>
                    </Col>
                </Row>
                {data?.documents && data.documents.length > 0 && (
                                <div className="mt-3">
                                    <h5>Documents:</h5>
                                    <div className="document-list">
                                        {data.documents.map((document, index) => (
                                            <div key={index} className="document-item mb-2">
                                                <a
                                                    href={document.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="d-flex align-items-center text-decoration-none"
                                                >
                                                    {document.document_name.toLowerCase().endsWith('.jpg') ||
                                                        document.document_name.toLowerCase().endsWith('.png') ||
                                                        document.document_name.toLowerCase().endsWith('.jpeg') ? (
                                                        <img
                                                            src={document.url}
                                                            alt={document.document_name}
                                                            className="document-thumbnail me-2"
                                                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon icon="file-alt" className="me-2" style={{ fontSize: '20px' }} />
                                                    )}
                                                    <span>{document.document_name}</span>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
            </Card.Body>
        </Card>
    );
};

export default OrderHistoryDetailModal;
