import React, { useState } from 'react';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import MedRecordCategoryModal from './MedRecordCategoryModal';

const MedRecordCategoryTable = (props) => {

    function handleEditInput(editValue, original) {
        //console.log("I m in Handle Edit " + editValue);
        window.scrollTo(0, 0);
        props.onCallEditParent(editValue, original);
    }

    const columns = [
        {
            accessorKey: 'code',
            header: 'Code',
            meta: {
                headerProps: { className: 'text-center text-900' },
                cellProps: { className: 'text-center align-middle' }
            }
        },
        {
            accessorKey: 'description',
            header: 'Description',
            meta: {
                headerProps: { className: 'text-center text-900' },
                cellProps: { className: 'text-center align-middle' }
            }
        },
        {
            accessorKey: 'mar_flag',
            header: 'Medsheet',
            meta: {
                headerProps: { className: 'text-center text-900' },
                cellProps: { className: 'text-center align-middle' }
            }
        },
        {
            accessorKey: 'tar_flag',
            header: 'Treatment',
            meta: {
                headerProps: { className: 'text-center text-900' },
                cellProps: { className: 'text-center align-middle' }
            }
        },
        {
            accessorKey: 'po_flag',
            header: 'Physician Order',
            meta: {
                headerProps: { className: 'text-center text-900' },
                cellProps: { className: 'text-center align-middle' }
            }
        },
        {
            accessorKey: 'Action',
            header: 'Action',
            meta: {
                headerProps: { className: 'text-center text-900' },
                cellProps: { className: 'text-center align-middle' }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div className="d-flex justify-content-center">
                    <IconItem
                        tag="button"
                        icon={['far', 'edit']}
                        size="sm"
                        onClick={() => handleEditInput(original.mc_id, original)}
                        className="btn rounded-3 me-2 fs-11 align-items-center"
                    />
                </div>
            )
        }
    ];

    const [medcategory, setMedCategory] = useState([]);
    const [error, setError] = useState(true);
    // const [showModal, setShowModal] = useState(false);

    // const handleEditMedCatModal = (mcId) => {
    //     localStorage.setItem("mcId", mcId);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchMedCategoryRec() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/patients/list-medical-record-category", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const medicalRecordCategoryData = data.data

            if (data.status) {
                medicalRecordCategoryData.forEach((value) => {
                    let active_sts = value.is_active == '1' ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            code: value.code,
                            description: value.description,
                            mar_flag: Number(value.mar_flag) ? 'Y' : 'N',
                            tar_flag: Number(value.tar_flag) ? 'Y' : 'N',
                            po_flag: Number(value.po_flag) ? 'Y' : 'N',
                            mc_id: value.id,
                            is_active: value.is_active// == 1 ? 0 : 1
                        });
                    }
                });

                if (results.length > 0) {
                    setMedCategory(results);
                    setError(true)
                } else {
                    setError(false)
                }
            }
            else {
                toast.info("No Medical Category Record found in this list");
            }
        }

        fetchMedCategoryRec();

        async function refreshMedRecDetails() {
            fetchMedCategoryRec();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshMedRecDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: medcategory,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
                {error ? <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle "//text-center
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                /> : <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '50px' }}>
                    <p className="text-danger fw-bold mb-0">There is no data available in the table</p>
                </div>}
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <MedRecordCategoryModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default MedRecordCategoryTable;
