import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate, useRouteError } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ServerError = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Card className="shadow-lg text-center p-4" style={{ maxWidth: "500px" }}>
        <Card.Body>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="3x"
            className="text-danger mb-3"
          />
          <h2 className="text-danger">Oops! Something went wrong</h2>
          <p className="text-muted">
            {error?.message || "We encountered an unexpected error. Please try again later."}
          </p>
          <Button variant="primary" className="mt-3" onClick={() => navigate("/main")}>
            Go Back Home
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ServerError;
