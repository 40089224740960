import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, ListGroup, Button, Form, Alert } from 'react-bootstrap';
import { BsCloudUpload } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';

const MAX_FILES = 5;

const DemoFileUpload = ({ onFilesChange, textOfDoc, errorFlag }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimitReached, setFileLimitReached] = useState(false);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (uploadedFiles.length + acceptedFiles.length > MAX_FILES) {
        setFileLimitReached(true);
        return;
      }
      setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      setFileLimitReached(false);
    },
  });

  useEffect(() => {
    if (onFilesChange) {
      onFilesChange(uploadedFiles);
    }
  }, [uploadedFiles, onFilesChange]);

  const handleRemoveFile = (fileName) => {
    const updatedFiles = uploadedFiles.filter((file) => file.name !== fileName);
    setUploadedFiles(updatedFiles);
    setFileLimitReached(false);
  };

  return (
    <Card className="shadow-sm"
      style={{
        transition: 'height 0.3s ease-in-out',
        height: uploadedFiles.length > 0 ? 'auto' : '250px'
      }}
    >
      <Card.Header className="bg-light text-center">
        <Form.Label className="mb-0 fs-9">{textOfDoc}</Form.Label>
      </Card.Header>
      <Card.Body 
        style={{ padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <div
          {...getRootProps({
            className: `dropzone-area border border-dashed border-2 rounded p-3 d-flex flex-column align-items-center justify-content-center text-center ${errorFlag ? 'bg-danger-subtle border-danger' : 'bg-light'}`,
          })}
          style={{
            minHeight: '120px',
            width: '100%',
            cursor: uploadedFiles.length >= MAX_FILES ? 'not-allowed' : 'pointer',
            opacity: uploadedFiles.length >= MAX_FILES ? 0.6 : 1,
          }}
        >
          <input {...getInputProps()} disabled={uploadedFiles.length >= MAX_FILES} />
          {isDragActive ? (
            <p className="fs-9 text-primary mb-0">Please drop your file here...</p>
          ) : (
            <>
              <BsCloudUpload size={16} className="text-primary mb-2" />
              <p className="fs-9 text-muted">Drag & drop your file here or</p>
              <Button 
                variant="outline-primary" 
                onClick={open} 
                size="sm"
                disabled={uploadedFiles.length >= MAX_FILES}
              >
                Browse Files
              </Button>
            </>
          )}
        </div>
      </Card.Body>

      {fileLimitReached && (
        <Alert variant="warning" className="text-center mx-3 py-2">
          Maximum of {MAX_FILES} files allowed!
        </Alert>
      )}

      {uploadedFiles.length > 0 && (
        <Card.Footer style={{ padding: '1rem' }}>
          <h6 className="mb-2">Uploaded Files ({uploadedFiles.length}/{MAX_FILES})</h6>
          <ListGroup variant="flush">
            {uploadedFiles.map((file) => (
              <ListGroup.Item
                key={file.name}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <span>{file.name}</span>
                  <span className="text-muted ms-3">({(file.size / 1024).toFixed(2)} KB)</span>
                </div>
                <Button
                  variant="link"
                  className="text-danger p-0"
                  onClick={() => handleRemoveFile(file.name)}
                >
                  <FaTimes size={16} />
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Footer>
      )}
    </Card>
  );
};

export default DemoFileUpload;
