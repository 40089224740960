import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CycleRefillGrid from './CycleRefillGrid';
import CycleNeedRefillAdminGrid from './CycleNeedRefillAdminGrid';
import CycleWillRefillAdminGrid from './CycleWillRefillAdminGrid';
import CensusAdminGrid from './CensusAdminGrid';
import CensusGrid from './CensusGrid';
import CycleOnHoldGrid from './CycleOnHoldGrid';
import Select from "react-select";
import { Link } from 'react-router-dom';

const NavItem = ({ item, classNameCustom, count }) => {
    return (
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-3 px-1 cursor-pointer`}
                eventKey={item.toLowerCase()}>
                {item}
                {count > 0 && item === 'Need-Refill' && (
                    <span className="badge bg-danger ms-2">
                        {count}
                    </span>
                )}
            </Nav.Link>
        </Nav.Item>
    );
};

const CycleTabDetails = (props) => { //'On-Hold'
    const [activeKey, setActiveKey] = useState("census");
    const [needRefillCount, setNeedRefillCount] = useState(0);
    const [navItems] = localStorage.getItem("roleId") < 4 ? useState(['Census', 'Need-Refill', 'Will-be-Refilled']) :
        useState(['Census', 'Need-Refill', 'Will-be-Refilled']);
        useEffect(() => {
            let results = []
            for (let patient in props.patientdetails){
                let patientlistObj = props.patientdetails[patient];
                let refillObj = patientlistObj["prescriptions"].need_refill
                results.push(refillObj)
            }
            const count = results.reduce((acc, arr) => acc + arr.length, 0);
            setNeedRefillCount(count)
        },[props])

    return (
        <Card>
            <Tab.Container id="audience-tab" defaultActiveKey="census">
                <Card.Header
                    as={Flex}
                    justifyContent="between"
                    alignItems="center"
                    className="p-0 border-bottom patientInfoTabs"
                >
                    <Nav
                        as="ul"
                        className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                    >
                        {navItems.map(item => (
                            <NavItem classNameCustom="col text-center" item={item} key={item} count={needRefillCount}/>
                        ))}
                    </Nav>
                </Card.Header>

                <Card.Body>
                    <Row className="g-1">
                        <Col xxl={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="census">
                                    {localStorage.getItem("roleId") < 4 && (
                                        <CensusAdminGrid patientdetails_obj={props.patientdetails} cycleid={props.cycleid}/>
                                    )}
                                    {localStorage.getItem("roleId") >= 4 && (
                                        <CensusGrid patientdetails_obj={props.patientdetails} flag = {props?.saveFlag} cycleid={props.cycleid}/>
                                    )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="need-refill">
                                    {localStorage.getItem("roleId") < 4 && (
                                        <CycleNeedRefillAdminGrid patientdetails_arr={props.patientdetails} cycleid={props.cycleid} status="needrefill" />
                                    )}
                                    {localStorage.getItem("roleId") >= 4 && (
                                        <CycleRefillGrid patientdetails_arr={props.patientdetails} cycleid={props.cycleid} status="needrefill"/>
                                    )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="will-be-refilled">
                                    {localStorage.getItem("roleId") < 4 && (
                                        <CycleWillRefillAdminGrid patientdetails_arr={props.patientdetails} cycleid={props.cycleid} status="willrefill" />
                                    )}
                                    {localStorage.getItem("roleId") >= 4 && (
                                        <CycleRefillGrid patientdetails_arr={props.patientdetails} cycleid={props.cycleid} status="willrefill" />
                                    )}
                                </Tab.Pane>
                                {/* <Tab.Pane unmountOnExit eventKey="on-hold">
                                    <CycleOnHoldGrid/>
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Card.Body>
            </Tab.Container>
        </Card>
    );
};

NavItem.propTypes = {
    item: PropTypes.string.isRequired
};

export default CycleTabDetails;