import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row, Button, Modal  } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconItem from 'components/common/icon/IconItem';
import DatePicker from "react-datepicker";

const ConfirmationBox = ({ show, handleClose, handleConfirm, title, message, hid, boxType }) => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Confirm'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message || 'Are you sure you want to proceed?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={() => handleConfirm(hid, boxType)}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
};



const HoldRxGrid = (props) => {
    
    const [formData, setFormData] = useState({"rx_number": ''});
    const [rxData, setRxData] = useState([]);
    
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmHoldId, setConfirmHoldId] = useState('');
    const [confirmBoxType, setConfirmBoxType] = useState('');
    const [reasonListRxOptions, setRxReasonsOptions] = useState([]);
    
    useEffect(() => {  
        setRxReasonsOptions([
            { key: 'Select Reason', value: '' },
            ...props.holdreasonsdata
        ]);
        
    }, []);

    
    
    const columns = [
        {
            accessorKey: 'rxcode',
            header: 'RX',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'rxpatient',
            header: 'Patient',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },  
        {
            accessorKey: 'rxdrug',
            header: 'Drug',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'rxreason',
            header: 'Reason',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    
                <Form.Select
                    name={"hold_rx_id_" + original.rx_hold_id}
                    id={"hold_rx_id_" + original.rx_hold_id}
                    onChange={(e) => {handleRxReasonChange(e,original.rx_hold_id)}}
                >
                    {reasonListRxOptions.map((option) => {
                        return (
                            <option key={option.value} value={option.value} >
                                {option.key}
                            </option>
                        );
                    })}
                </Form.Select>
                
                </>
            }
        },
        {
            accessorKey: 'rxholdtype',
            header: 'Hold Type',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                return <>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                type='radio'
                                name={"holdtype_" + original.rx_hold_id}
                                id={"holdtype_cycle" + original.rx_hold_id}
                                label='Cycle'
                                defaultChecked= {original.rxholdtype == 1 ? 'true' : '' }
                                disabled= {original.rxholdtype == 1 ? 'true' : '' }
                            />
                        </Col>
                        <Col xs="12">
                            <Form.Check
                                type='radio'
                                name={"holdtype_" + original.rx_hold_id}
                                id={"holdtype_daily" + original.rx_hold_id}
                                label='Daily'
                                defaultChecked= {original.rxholdtype == 0 ? 'true' : '' }
                                disabled= {original.rxholdtype == 1 ? 'true' : '' }
                            />
                        </Col>
                    </Row>
                </>
            }
        },
        {
            accessorKey: 'due_delivery_date',
            header: 'Due/Delivery Date',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                return <DatePicker 
                    name="duedate" 
                    selected={original.due_delivery_date} 
                    onChange={(date) => setDueDate(date)}
                    disabled= {original.rxholdtype == 1 ? 'true' : '' }
                    className='form-control form-control-sm' placeholderText="Select Due Date" 
                />
                
            }      
        },
        {
            accessorKey: 'rxaction',
            header: 'Delete',
            meta: {
                headerProps: { className: 'text-900 text-start w-10' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleDeleteHoldRow(original.rx_hold_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];


    const handleRxReasonChange = (e,hid) => {
       // console.log("e", e)
        rxData.forEach((ele)=>{
            if(ele.rx_hold_id == hid){
                ele.select_rxreason = e.target.value

            }
        })
        setRxData(rxData);
    };

    const handleDeleteHoldRow = (hid) => {   
           
        var temp = rxData.filter(function(item){
            return item.rx_hold_id != hid;
        })
        //console.log(rxData);
        setRxData(temp)
    };
    
    const handleSave  = async () => {

       // console.log("rxData", rxData);
       
        if (rxData.length > 0 ){
            console.log(rxData);
            const rxUpdateParams=[];
            rxData.forEach((val) => {
                rxUpdateParams.push({
                    "pharmacy_order_id" : val.rx_hold_id,
                    "is_hold": val.rxholdtype,
                    "holdreason_id": val.select_rxreason,
                    "due_date": moment(val.due_delivery_date, 'MM/DD/YYYY').format('YYYY-MM-DD')
                });
               
            });

            console.log("rxUpdateParams", rxUpdateParams)

            let tokenStr = localStorage.getItem("accessToken");
            let rxStatusUrl = `${process.env.REACT_APP_SERVER_URL}/drugs/add-hold-drug/`;

            const { data } = await axios.post(rxStatusUrl, Object.values(rxUpdateParams), { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            console.log(data.status);

            if (data.status) {
                toast.success("Drug hold's updates successfully");
                var temp = rxData.filter(function(item){
                    return item = null;
                })
                setRxData(temp);
                
                window.location.reload();
            }
            else {
                toast.error("Getting some server error, please try again");
            }
        }        
    };

    const handleShow = async (title, message, hid, boxType) => {
        setConfirmTitle(title);
        setConfirmMessage(message);
        setConfirmHoldId(hid);
        setConfirmBoxType(boxType);
        setShowConfirmation(true);
    };

    const handleClose = () => setShowConfirmation(false);
    const handleConfirm = async (hid, boxType) => {

        handleHoldStatusUpdate(hid);

        handleClose();
        window.location.reload();
    };
    const handleHoldStatusUpdate = async (hid) => {
        let tokenStr = localStorage.getItem("accessToken");
        let holdStatusUrl = `${process.env.REACT_APP_SERVER_URL}/drugs/update-hold-status/`;

        let holdStatusParams = {
            "hold_id": hid,
            "is_hold" : 0          
        };

       // console.log("Hold Status Data " + JSON.stringify(holdStatusParams));

        const { data } = await axios.post(holdStatusUrl, holdStatusParams, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

      //  console.log(data.status);

        if (data.status) {
          
        }
        else {
            toast.error("Getting some server error, please try again");
        }
    }
    
    var rxGridResults = {};
    const handleSubmit = async e => {
        e.preventDefault();
        let tokenStr = localStorage.getItem("accessToken");
        
        if (formData['rx_number'] != '') {
            var temp = rxData.filter(function(item){
                return item.rx_hold_id == formData['rx_number']
            })

            if(temp.length > 0 ){
                return 1;
            }            
       
            const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/drugs/hold-search-rx`, formData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            
            const holdRxData = data.data;
            if (holdRxData.length > 0) {   
                if (holdRxData[0].is_hold == 1){
                    handleShow('Confirm', 'This Rx is on hold, do you want to unhold it?', holdRxData[0].id, 'holdStatusUpdate');

                    
                }
                else {
                    rxGridResults={
                        rx_hold_id: holdRxData[0].pharmacy_order_id,
                        rxcode: holdRxData[0].pharmacy_order_id,
                        rxpatient: holdRxData[0].patient_lastname+', '+holdRxData[0].patient_firstname,
                        rxdrug: holdRxData[0].drug,
                        rxholdtype: holdRxData[0].type,
                        due_delivery_date: moment(holdRxData[0].due_date, 'YYYY-MM-DD').format('MM/DD/YY')                                        
                    };

                    setRxData([
                        ...rxData,
                        rxGridResults
                    ]);
                }
            }
            else {
                toast.info("Rx Details not found.");
            }
        }
        else {
            toast.info("Please enter the Rx number.");
        }
    }
    
    const handleFieldChange = e => {
        setFormData({
            "rx_number": e.target.value
        });
    };

    const tableRx = useAdvanceTable({
        data: rxData,
        columns,
        selection: false,
        sortable: true,
        pagination: true 
    });


    return (
        <>
        <Card className="pb-4 mb-4">
            <Card.Body
                className={classNames('h-100')}
                as={Flex}
                direction="column"
                alignItems="between"
            >
                <Form onSubmit={handleSubmit} name="rxsearchform" className="mb-3">        
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className="d-inline pe-2">Rx</Form.Label>
                                <Form.Control
                                    name="rx_number"
                                    type="text"
                                    placeholder="Search..."
                                    size="sm"
                                    className="rounded search-input d-inline w-25"
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <AdvanceTableProvider {...tableRx}>                 
                    <Row>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-1 mt-1">
                            <AdvanceTable
                                headerClassName="bg-200 text-nowrap align-middle text-center"
                                rowClassName="text-nowrap align-middle text-center"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs-10 mb-0 overflow-hidden'
                                }}
                            />
                        </Col>
                    </Row>
                </AdvanceTableProvider>
                { rxData.length > 0 ?  
                    <Row>
                        <Col className=' text-end mt-3'>
                            <Button color="primary" className="w-20" 
                            onClick={handleSave}
                            >Save</Button>
                        </Col>
                    </Row>
                    : ''
                }
                
            </Card.Body>
        </Card>


        <ConfirmationBox
            show={showConfirmation}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            title={confirmTitle}
            message={confirmMessage}
            hid={confirmHoldId}
            boxType={confirmBoxType}
        />

        </>
    );

    

};

export default HoldRxGrid;