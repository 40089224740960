import React, { useState, useEffect, useCallback } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import moment from 'moment';
import IconItem from 'components/common/icon/IconItem';
import AddNotesModal from './AddNotesModal';
import DrugsAdminModal from './DrugsAdminModal';
import { toast } from 'react-toastify';
import IframeContainer from 'components/navbar/top/IframeContainer';

const CensusAdminGrid = (props) => {

    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [drugsData, setDrugsData] = useState([]);
    const [medChecked, setMedCheck] = useState(false);
    const [tableGridData, setTableGridData] = useState([]);
    const [patientDetails, setPatientDetails] = useState({});
    const [showIframe, setShowIframe] = useState(false);
    const [iframeKey, setIframeKey] = useState(0);
    const updatePatientDetails = (updatedPatientDetails) => {
        setPatientDetails(updatedPatientDetails);
    };

    const columns = [
            {
                accessorKey: 'patient',
                header: 'Patient',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start px-2' }
                }
            },
            {
                accessorKey: 'pack',
                header: 'D/C Label Change or Split Packs',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start px-2' }
                },
                cell: ({ row: { original } }) => {
                    return <>
                        <Link to="#!" className="text-600 fw-semibold" onClick={() => handleEditDrugsData(original.patientid, original.patient, original.refilldrugsdata)}>{original.pack}</Link>
                    </>
                }
            },
            {
                accessorKey: 'facility_notes',
                header: 'Facility Note',
                meta: {
                    headerProps: { className: 'text-900 text-start w-10 px-2' },
                    cellProps: { className: 'text-start px-2' }
                },
                cell: ({ row: { original } }) => truncateText(original.facility_notes)
            },
            {
                accessorKey: 'pharmacy_notes',
                header: 'Pharmacy Note',
                meta: {
                    headerProps: { className: 'text-900 text-start w-10 px-2' },
                    cellProps: { className: 'text-start px-2' }
                },
                cell: ({ row: { original } }) => truncateText(original.pharmacy_notes)
            },
            {
                accessorKey: 'status',
                header: 'Status',
                meta: {
                    headerProps: { className: 'text-900 text-start px-2' },
                    cellProps: { className: 'text-start px-2' }
                },
                cell: ({ row: { original } }) => {
                    var isActiveSelected = original.census_status == 1 ? 'selected' : false;
                    var isDischargedSelected = original.census_status == 2 ? 'selected' : false;
                    var isDeceasedSelected = original.census_status == 3 ? 'selected' : false;
                    var isMloaSelected = original.census_status == 4 ? 'selected' : false;
                    return <>
                        <Form.Group>
                            <Form.Select
                                name={"census_status" + original.index}
                                onChange={(e) => { handleRowData(e.target.value, "census_status", original.index) }}
                                size="sm">
                                <option key="pending" value="0">
                                    Pending
                                </option>
                                <option key="active" value="1" selected={isActiveSelected}>
                                    Active
                                </option>
                                <option key="discharged" value="2" selected={isDischargedSelected} >
                                    Discharged
                                </option>
                                <option key="deceased" value="3" selected={isDeceasedSelected}>
                                    Deceased
                                </option>
                                <option key="mloa" value="4" selected={isMloaSelected}>
                                    MLOA
                                </option>
                            </Form.Select>
                        </Form.Group >
                    </>
                }
            },
            {
                accessorKey: 'action',
                header: 'More Detail',
                meta: {
                    headerProps: { className: 'text-900 text-start w-10 px-2' },
                    cellProps: { className: 'text-start px-2' }
                },
                cell: ({ row: { original } }) => {
                    return <IconItem
                        tag="button"
                        icon={['far', 'edit']}
                        size="sm"
                        onClick={() => handleAddNotes(original.patient, original.patientid)}
                        className="btn rounded-3 me-2 fs-11 align-items-center"
                    />
                }
            }
        ]

    const [refill, setRefill] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDrugsAdminModal, setShowDrugsAdminModal] = useState(false);

    const handleAddNotes = (pname, pid) => {
        setPatientName(pname);
        setPatientId(pid);
        setShowModal(true);
    };

    function handleEditDrugsData(pid, pname, drugsdata) {
        setPatientName(pname);
        setPatientId(pid);
        setDrugsData(drugsdata);
        setShowDrugsAdminModal(true);
    }

    const handleRowData = (gridControlValue, gridControlHeader, gridControlIndex) => {

        let tempArrData = JSON.parse(localStorage.getItem("censusadmingridresults"));//tableGridData;
        //console.log("tableOpData ip" + JSON.stringify(tempArrData));

        tempArrData.forEach(function (arrayItem, index) {
            if (index == gridControlIndex) {
                //console.log("index" + JSON.stringify(arrayItem) + gridControlValue);
                arrayItem[gridControlHeader] = gridControlValue;
            }
        });

        //setTableGridData(tempArrData);
        localStorage.setItem("censusadmingridresults", JSON.stringify(tempArrData));

        //console.log("tableOpData Op" + JSON.stringify(tempArrData));
    }

    const truncateText = (text, length = 30) => {
        return text && text.length > length ? text.substring(0, length) + "..." : text;
    };

    useEffect(() => {
        if (props.patientdetails_obj && Object.keys(props.patientdetails_obj).length > 0) {
          setPatientDetails(props.patientdetails_obj);
        }
      }, [props.patientdetails_obj]);
    
    useEffect(() => {
        if (Object.keys(patientDetails).length > 0) {
          fetchPatientData();
        }
      }, [patientDetails]);

    const fetchPatientData = () => {

        let results = [];
        let ctr = 0;

        for (let patient in patientDetails) {

            let patientlist_obj = patientDetails[patient];
            let refill_obj = [];
            let cycle_patientid = "";

            if (patientlist_obj["total_changed_prescriptions"] > 0) {
                //calculate split packs
                let will_be_filled = patientlist_obj["prescriptions"].will_be_filled;
                let need_refill = patientlist_obj["prescriptions"].need_refill;

                will_be_filled.forEach(function (single_array_obj) {

                    if (single_array_obj.change_type) {

                        cycle_patientid = single_array_obj.cycle_patient_id;

                        let facilityqty = single_array_obj.facility_qty == null ? 0 : single_array_obj.facility_qty;
                        let programqty = single_array_obj.program_qty == null ? 0 : single_array_obj.program_qty;
                        let loaqty = single_array_obj.loa == null ? 0 : single_array_obj.loa;

                        let single_drug_item = {
                            drugid: single_array_obj.id,
                            patientname: patientlist_obj["patient_first"] + ' ' + patientlist_obj["patient_last"],
                            drug: single_array_obj.drug,
                            rxnumber: single_array_obj.pharmacy_order_id,
                            type: 'split pack',
                            document: '',
                            change_type : single_array_obj?.change_type,
                            details: 'Facility:' + facilityqty + ',Program:' + programqty + ',Loa:' + loaqty,
                            done_in_ips: single_array_obj.updated_in_ips
                        };

                        refill_obj.push(single_drug_item);
                    }
                });

                need_refill.forEach(function (single_array_obj) {

                    if (single_array_obj.change_type) {

                        cycle_patientid = single_array_obj.cycle_patient_id;

                        let facilityqty = single_array_obj.facility_qty == null ? 0 : single_array_obj.facility_qty;
                        let programqty = single_array_obj.program_qty == null ? 0 : single_array_obj.program_qty;
                        let loaqty = single_array_obj.loa == null ? 0 : single_array_obj.loa;

                        let single_drug_item = {
                            drugid: single_array_obj.id,
                            patientname: patientlist_obj["patient_first"] + ' ' + patientlist_obj["patient_last"],
                            drug: single_array_obj.drug,
                            rxnumber: single_array_obj.pharmacy_order_id,
                            type: 'split pack',
                            document: '',
                            change_type : single_array_obj?.change_type,
                            details: 'Facility:' + facilityqty + ',Program:' + programqty + ',Loa:' + loaqty,
                            done_in_ips: single_array_obj.updated_in_ips
                        };

                        refill_obj.push(single_drug_item);
                    }
                });
            }

            //pending_ips_done + " Out Of " + (pending_ips_done + completed_ips_done)

            results.push({
                index: ctr++,
                // patient: patientlist_obj["patient_first"] + " " + patientlist_obj["patient_last"],
                patient: patientlist_obj["patient_last"] + ", " + patientlist_obj["patient_first"],
                pack: patientlist_obj["total_changed_prescriptions"] == 0 ? '' : patientlist_obj["updated_changed_prescriptions"] + ' Out Of ' + patientlist_obj["total_changed_prescriptions"],
                refilldrugsdata: refill_obj,
                patientid: cycle_patientid,
                sign_off: patientlist_obj["sign_off"],
                // patient: patientlist_obj["patient_last"] + ", " + patientlist_obj["patient_first"],
                // refilldrugsdata: refillObj,
                patientid: patientlist_obj['patient_id'],
                // sign_off: patientlist_obj["sign_off"],
                census_status: patientlist_obj['census_status'],
                facility_notes: patientlist_obj["facility_notes"],
                pharmacy_notes: patientlist_obj["pharmacy_notes"]
            })

        }

        //console.log("results " + results);
        setRefill(results);
        //setTableGridData(results);
        localStorage.setItem("censusadmingridresults", JSON.stringify(results));
    }

    const handleSave = async (e) => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let censusGridUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-patients`;

        let censusGridData = []

        let censusTableGridData = JSON.parse(localStorage.getItem("censusadmingridresults"));

        censusTableGridData.forEach(function (arrayItem, index) {
            let singlerowitem =
            {
                "cycle_patient_id": arrayItem["patientid"],
                "census_status": arrayItem["census_status"]
            }
            censusGridData.push(singlerowitem);
        });

        const { data } = await axios.post(censusGridUrl, censusGridData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success(data.message);
        }
        else {
            toast.error("Error occured while filling Census Details ");
        }
    }

    const handleMarkAllActive = () => {
        // Get current data from localStorage
        let censusTableGridData = JSON.parse(localStorage.getItem("censusadmingridresults")) || [];
    
        // Update census_status to "4" (Active) for all rows
        let updatedData = censusTableGridData.map(row => ({
            ...row,
            census_status: 1
        }));
    
        // Save updated data back to localStorage
        localStorage.setItem("censusadmingridresults", JSON.stringify(updatedData));
    
        // Update state to trigger re-render
        setRefill(updatedData);
    };

    const handleIframeToggle = () => {
        setIframeKey(iframeKey + 1);
        setShowIframe(true);
      };

    const table = useAdvanceTable({
        data: refill,
        columns,
        selection: false,
        sortable: false,
        pagination: true
    });

    return (
        <AdvanceTableProvider {...table}>
            <Row className='mt-3 justify-content-between'>
                <Col xs="6" className="mb-3">
                    <AdvanceTableSearchBox placeholder="Search..." />
                </Col>
                <Col xs="2" >
                    <Button color="primary" className="w-100" onClick={handleMarkAllActive}>All Active</Button>
                </Col>
                <Col xs="2" >
                    <Button color="primary" className="w-100" onClick={handleSave}>Save</Button>
                </Col>
                <Col xs="2">
                    <div>
                        <Button color="primary" className="w-100" onClick={handleIframeToggle}>
                            Print
                        </Button>
                        {showIframe && <IframeContainer key={iframeKey} id={props?.cycleid} />}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12" className="mb-1 mt-1">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle text-center"
                        rowClassName="text-nowrap align-middle text-center"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Col>
            </Row>
            <Card.Footer>
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </Card.Footer>
            {showModal && <AddNotesModal show={showModal} setShow={setShowModal} patientname={patientName} patientid={patientId} notesby="Pharmacy" dataObj={patientDetails} updateDataObj={updatePatientDetails} />}
            {showDrugsAdminModal && <DrugsAdminModal show={showDrugsAdminModal} setShow={setShowDrugsAdminModal} patientname={patientName} patientid={patientId} drugsdata={drugsData} />}
        </AdvanceTableProvider>
    );
};

export default CensusAdminGrid;
