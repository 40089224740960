import React, { useState } from "react";
import { Row, Col, Form, Button, InputGroup, Modal } from "react-bootstrap";

const NeedHelpForm = ({ show, handleClose }) => {
  const initialFormState = {
    name: "",
    subject: "",
    query: "",
    email: "",
    phone: "",
    file: null,
  };
  const [formData, setFormData] = useState(initialFormState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, file: e.target.files[0] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    handleClose();
  };

  const handleCloseAndReset = () => {
    setFormData(initialFormState);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseAndReset} centered>
      <Modal.Header closeButton>
        <Modal.Title>Need Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  disabled
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="subject">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  name="subject"
                  
                  value={formData.subject}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="query">
                <Form.Label>Query / Suggestion</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="query"
                  value={formData.query}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  disabled
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={12}>
              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  disabled
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={12}>
              <Form.Group controlId="phone">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  disabled
                  value={window.location.href}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="file">
                <Form.Label>Choose File</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-start">
              <Button variant="secondary" onClick={handleCloseAndReset}>
                Cancel
              </Button>
            </Col>
            <Col className="text-end">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NeedHelpForm;
