import avatar from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import React, { useEffect, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import * as accessControlObj from '../../authentication/accessControlMap'


const PatientDetailsHeader = (props) => {

    //console.log("patientDetailsName" + props.username + props.facilityid + props.facility_name + props.patientid);
    const [mymActive, setMymActive] = useState(false);
    const objData = props.paramsObj

    useEffect(() => {
        var localStorageAccessControl = localStorage.getItem("accessControl");
        if(localStorageAccessControl.includes(accessControlObj.ACCESS_CONTROL["mym"])) {
            setMymActive(true);
        }
    });

    return (
        <Card>
            <Card.Header className="pb-0 pt-4 mb-2">
                <h4 className="mb-1">
                    {props.patient_name}
                </h4>
            </Card.Header>
            <Card.Body className="py-0">
                <Row>
                    <Col lg={7}>
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Allergies: <span className='text-danger'>{objData?.allergies_text}</span></h6>
                            </div>
                        </Flex>
                    </Col>
                    <Col lg={4} className="ps-2 ps-lg-3 me-0 ms-auto">
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Facility Name: {objData.facility_name} </h6>
                            </div>
                        </Flex>
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">Group Name: {objData.group_name}</h6>
                            </div>
                        </Flex>
                    </Col>

                    <Row className="col-12 mx-0 w-100 avatar-profile-sec">
                        <Avatar
                            size='5xl'
                            className="avatar-profile col-1 mx-0 px-0 cursor-pointer"
                            src={objData.profile_image ? objData.profile_image : avatar }
                            mediaClass="img-thumbnail shadow-sm"
                            flag = {true}
                        />
                        <Col lg={10} className="border-top px-0 py-2 mt-3 ms-auto me-0 text-end">
                            <div className='d-inline mx-2 h6'>
                                {mymActive &&
                                 <Link to={{ pathname: paths.addPrescription }} state={{
                                    facilityname: objData.facility_name,
                                    patientid: objData.id,
                                    orderid: null,
                                    patientname : props.patient_name
                                }} className='text-primary'>Add Prescription</Link>}
                            </div>
                            <div className='d-inline mx-2 h6'>
                                {mymActive && <Link to={{ pathname: paths.addMedicalRecord }} state={{
                                    facilityid: objData.facility_id,
                                    patientid: objData.id,
                                    medrecord_id: null
                                }} className='text-success' >Add Medical Record</Link>}
                            </div>
                        </Col>
                    </Row>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PatientDetailsHeader;
