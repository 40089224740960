import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Nav, Row, Tab } from 'react-bootstrap';
import OrderDetailModal from './OrderDetailModal';
import { useLocation } from 'react-router-dom';
import OrderNotes from './OrderNotes';
import OrderDetailLogs from './OrderDetailLogs';
import AddPatientModal from './AddPatientModal';
import OrderInternalNotes from './OrderInternalNotes';

const NavItem = ({ item, classNameCustom }) => {
    return (
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-1 px-1 cursor-pointer`}
                eventKey={item.toLowerCase().replaceAll(" ", "-")}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const OrderDetailModalParent = ({ show, filteredData, setRefresh, handleClose, setOrder }) => {
    const [navItems,setNavItems] = useState([]);
    const location = useLocation();
    const state = location?.state?.original

    useEffect(() =>{
        let results = ['Order-Details', 'Messaging', 'Logs','Internal-Notes']
        // if(Number(localStorage.getItem('roleId')) < 4){
        //     results.push('Internal-Notes')
        // }
        setNavItems(results)
    },[])
    return (
        <Modal show={show} onHide={() => { handleClose(false) }} centered size={state?.ordertype === 9 ? "xl" : "lg"} className="main-wrapper">
            <Modal.Header closeButton>
                <Modal.Title className='fs-8 fw-bold'>Order Detail for {state?.patient}, Task ID: {state?.orderid} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Tab.Container id="audience-tab" defaultActiveKey="order-details">
                    <Card.Header
                        as={Flex}
                        justifyContent="between"
                        alignItems="center"
                        className="p-0 border-bottom patientInfoTabs"
                    >
                        <Nav
                            as="ul"
                            className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                        >
                            {navItems.map(item => (
                                <NavItem classNameCustom="col text-center" item={item} key={item} />
                            ))}
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        <div className="tab-content-wrapper">
                            <Row className="g-1">
                                <Col xxl={12}>
                                    <Tab.Pane unmountOnExit eventKey="order-details">
                                        {state?.ordertype === 9 ? <AddPatientModal dataObj={state} handleClose={handleClose} setRefreshOrder={setOrder} /> : <OrderDetailModal dataObj={state} setRefreshOrder={setOrder} handleClose={handleClose} />}
                                    </Tab.Pane>
                                    <Tab.Pane unmountOnExit eventKey="messaging">
                                        <OrderNotes data={state} filteredData={filteredData} setRefresh={setRefresh} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="logs">
                                        {/* <OrderDetailLogs /> */}
                                    </Tab.Pane>
                                    <Tab.Pane unmountOnExit eventKey="internal-notes">
                                        <OrderInternalNotes data={state} filteredData={filteredData} setRefresh={setRefresh} />
                                    </Tab.Pane>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Tab.Container>
            </Modal.Body>
        </Modal>
    )
}

export default OrderDetailModalParent