import React, { useEffect } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

const MedSheetForm = (props) => {

    const [medsheet, setMedSheet] = useState("");
    const [checked, setChecked] = React.useState(true);
    const [spinner, showSpinner] = useState(false);

    const [formData, setFormData] = useState({
        reportdate: '',
        reportformat: '',
        is_drug_hcp: 'drug',
        //is_medgroup: true,
       // is_highalert: true,
        is_prn: true,
        is_regular: true
    });

    const [reportdate, setReportDate] = useState(null);
    const [showResults, setShowResults] = React.useState(false)

    const navigate = useNavigate();


    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

      useEffect(() =>{
        if(spinner){
            setShowResults(false);
        }
      },[spinner])

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();

        if ((localStorage.getItem("patientId") != null || localStorage.getItem("reportFacilityId") != null) && formData.reportformat != "" && reportdate != null) {

            showSpinner(true);

            let tokenStr = localStorage.getItem("accessToken");
            let pelmedsMedSheetUrl = `${process.env.REACT_APP_SERVER_URL}` + "/reports/medsheet";

            //console.log("is_drug_hcp " + localStorage.getItem("patientId"));

            let pelmedsMedSheetData = localStorage.getItem("patientId") != null ?
                {
                    date: reportdate != null ? moment(reportdate).format('MMM YYYY') : null,//"01-10-2024",            
                    patient_id: localStorage.getItem("patientId"),
                    medsheet_format: formData.reportformat,
                    is_hcp: formData.is_drug_hcp == "hcp" ? 1 : 0,
                    is_drug: formData.is_drug_hcp == "drug" ? 1 : 0,
                   // is_medgroup: formData.is_medgroup ? 1 : 0,
                   // is_highalert: formData.is_highalert ? 1 : 0,
                    is_prn: formData.is_prn ? 1 : 0,
                    is_regular: formData.is_regular ? 1 : 0
                } :
                {
                    date: reportdate != null ? moment(reportdate).format('MMM YYYY') : null,//"01-10-2024",            
                    facility_id: localStorage.getItem("reportFacilityId"),
                    medsheet_format: formData.reportformat,
                    is_hcp: formData.is_drug_hcp == "hcp" ? 1 : 0,
                    is_drug: formData.is_drug_hcp == "drug" ? 1 : 0,
                 //   is_medgroup: formData.is_medgroup ? 1 : 0,
                 //   is_highalert: formData.is_highalert ? 1 : 0,
                    is_prn: formData.is_prn ? 1 : 0,
                    is_regular: formData.is_regular ? 1 : 0
                };

            //console.log("pelmedsMedSheetData " + JSON.stringify(pelmedsMedSheetData));

            const { data } = await axios.post(pelmedsMedSheetUrl, pelmedsMedSheetData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            if (data.status) {
                if(props?.formname){
                    setMedSheet(data.medsheet_url);
                    setShowResults(true);
                }
                //console.log(JSON.stringify(medsheet));

                if (localStorage.getItem("reportFacilityId") == null){
                    props.onCallParent(); //only required for reports at patient level not at facility level
                }else{
                    toast.success("MedSheet Generated Successfully");
                    localStorage.removeItem("reportFacilityId");
                }   
            }
            else {
                // toast.error("Error while generating the medsheet report");
                toast.error(data.message);
            }
            showSpinner(false);
        }
        else {
            toast.error("Required Fields are empty");
        }

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleRadioChange = e => {
        setChecked((state) => !state)
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">Report Date</Form.Label>
                            <DatePicker name="reportdate" selected={reportdate} onChange={(selectedDate) => {
                                setReportDate(selectedDate);
                            }}
                                dateFormat="MMM yyyy" showMonthYearPicker className='form-control form-control-sm' placeholderText="Select Report Date" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label className="required">Report Format</Form.Label>
                            <Form.Select name="reportformat"
                                onChange={handleFieldChange}
                                size="sm">
                                <option value=''>Select your Format</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                                <option value='4'>4</option>
                                <option value='7'>7</option>
                                <option value='8'>8</option>
                                <option value='9'>9</option>
                                <option value='10'>10</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Sort By</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='radio'
                                        name="is_drug_hcp"
                                        id='is_drug'
                                        value="drug"
                                        onChange={handleRadioChange}
                                        label='Drug'
                                        defaultChecked={checked}
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type='radio'
                                        name="is_drug_hcp"
                                        id='is_hcp'
                                        value="hcp"
                                        onChange={handleRadioChange}
                                        label='HCP'
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>MedSheet For</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_regular"
                                        id='is_regular'
                                        checked={
                                            formData.is_regular
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_regular: e.target.checked
                                            })
                                        }
                                        label='Regular'
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_prn"
                                        id='is_prn'
                                        checked={
                                            formData.is_prn
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_prn: e.target.checked
                                            })
                                        }
                                        label='PRN'
                                    />
                                </Col>
                                {/* <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_highalert"
                                        id='is_highalert'
                                        checked={
                                            formData.is_highalert
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_highalert: e.target.checked
                                            })
                                        }
                                        label='High Alert'
                                    />
                                </Col>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        name="is_medgroup"
                                        id='is_medgroup'
                                        checked={
                                            formData.is_medgroup
                                        }
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                is_medgroup: e.target.checked
                                            })
                                        }
                                        label='MedGroup'
                                    />
                                </Col> */}
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Form.Group>
                            {spinner && (
                                <Button variant="primary" disabled className="mt-3 w-100">
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="p-2" style={{ "verticalAlign": "3px" }}>Fetching...</span>
                                </Button>
                            )}
                            {!spinner && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mt-3 w-100">
                                    Generate
                                </Button>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                    </Col>
                </Row>
            </Form>
            <br />
            {showResults && (
                <a href={medsheet} target="_blank" rel="noreferrer">
                    <button className="btn btn-primary">
                        📄 Download Report
                    </button>
                </a>
            )}
        </div>
    );
};

MedSheetForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

MedSheetForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default MedSheetForm;
