import React, { useState } from "react";
import { Card, Form, Row, Col, Button, Nav, Tab, Container } from "react-bootstrap";

const DefaultSetting = () => {
    const [formData, setFormData] = useState({
        reasonPrescribed: "Group",
        specialInstSetting: "Group",
        pageSize: "10",
        medsheetFormat: "Format 1",
        autoVerifyBy: "DemoUser",
        specialInstLimit: 0,
        separateMedsheet: false,
        printXOnPastDays: true,
        autoVerify: true,
        isSplitMeds: true,
        medsheetVerification: false,
        autoCarryForward: true,
        isHcpOrder: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    return (
        // <Container fluid className="mt-4">
            // <Card className="">
            //     <Card.Body>
                    <Form className="p-3">
                        <Row className="mb-4">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label className="fw-semibold">Reason Prescribed Setting:</Form.Label>
                                    <Form.Select name="reasonPrescribed" value={formData.reasonPrescribed} onChange={handleChange}>
                                        <option>Group</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label className="fw-semibold">Special Inst. Setting:</Form.Label>
                                    <Form.Select name="specialInstSetting" value={formData.specialInstSetting} onChange={handleChange}>
                                        <option>Group</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={6}>
                                <h6 className="text-primary">Medsheet Settings</h6>
                                <Form.Check
                                    type="checkbox"
                                    label="Separate medsheet for high alert drugs"
                                    name="separateMedsheet"
                                    checked={formData.separateMedsheet}
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Print X on past days in medsheet?"
                                    name="printXOnPastDays"
                                    checked={formData.printXOnPastDays}
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Auto Verify (Continued order)"
                                    name="autoVerify"
                                    checked={formData.autoVerify}
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Is Split Meds"
                                    name="isSplitMeds"
                                    checked={formData.isSplitMeds}
                                    onChange={handleChange}
                                />
                            </Col>

                            <Col md={6}>
                                <h6 className="text-primary">General Settings</h6>
                                <Form.Group>
                                    <Form.Label className="fw-semibold">Page Size:</Form.Label>
                                    <Form.Select name="pageSize" value={formData.pageSize} onChange={handleChange}>
                                        <option>10</option>
                                        <option>20</option>
                                        <option>30</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-2">
                                    <Form.Label className="fw-semibold">Medsheet & TreatmentSheet:</Form.Label>
                                    <Form.Select name="medsheetFormat" value={formData.medsheetFormat} onChange={handleChange}>
                                        <option>Format 1</option>
                                        <option>Format 2</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={6}>
                                <h6 className="text-primary">Verification & Limits</h6>
                                <Form.Check
                                    type="checkbox"
                                    label="Medsheet verification?"
                                    name="medsheetVerification"
                                    checked={formData.medsheetVerification}
                                    onChange={handleChange}
                                />
                                <Form.Group className="mt-2">
                                    <Form.Label className="fw-semibold">Special Instruction Limit:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="specialInstLimit"
                                        value={formData.specialInstLimit}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <h6 className="text-primary">Auto Settings</h6>
                                <Form.Check
                                    type="checkbox"
                                    label="Auto carry forward"
                                    name="autoCarryForward"
                                    checked={formData.autoCarryForward}
                                    onChange={handleChange}
                                />
                                <Form.Group className="mt-2">
                                    <Form.Label className="fw-semibold">Auto verify By:</Form.Label>
                                    <Form.Select name="autoVerifyBy" value={formData.autoVerifyBy} onChange={handleChange}>
                                        <option>DemoUser</option>
                                        <option>Admin</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Is HCP Order"
                                    name="isHcpOrder"
                                    checked={formData.isHcpOrder}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-end">
                            <Button variant="primary" size="lg">Save</Button>
                        </div>
                    </Form>
            //     </Card.Body>
            // </Card>
        // </Container>
    );
};

export default DefaultSetting;
