import axios from 'axios';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const UserNotifcationTableBody = (props) => {
    const dataObj = props.data || []; 
    const { data: initialData = [], selectedFacilityId } = props;
    const [tableData, setTableData] = useState([]);
    const originalDataRef = useRef([]);

    const deepCopy = (data) => JSON.parse(JSON.stringify(data));

    useEffect(() => {
        const dataCopy = deepCopy(initialData);
        setTableData(dataCopy); 
        originalDataRef.current = dataCopy;
    }, [initialData]);

    const columns = [
        {
            accessorKey: 'type',
            header: 'Type',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'status',
            header: 'Status',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            },
            cell: ({ row }) => {
                const isChecked = tableData[row.index]?.status === 1;
                return (
                    <Form.Check
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(row.index, e.target.checked)}
                    />
                );
            }
        },
    ]

    const handleCheckboxChange = (index, isChecked) => {
        const updatedTableData = deepCopy(tableData);;
        updatedTableData[index].status = isChecked ? 1 : 0;
        setTableData(updatedTableData);
    };

    async function handleSave(e) {
        if (!selectedFacilityId) {
            console.error("Facility ID is missing.");
            return;
        }

    const changedNotifications = tableData.filter((item, index) => {
        return item.status !== originalDataRef.current[index]?.status;
    });

    const notifications = changedNotifications.map(item => ({
        facility_id: selectedFacilityId,
        notification_id: item.id,
        is_active: item.status
    }));

    const sendingObj = { notifications };

        let tokenStr = localStorage.getItem("accessToken");
        let optinNotifications = `${process.env.REACT_APP_SERVER_URL}` + '/users/optins-notifications'
        const { data } = await axios.post(optinNotifications, sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.status) {
            toast.success("Notification Settings Updated Successfully", {
                theme: 'colored'
            })

            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            toast.error("Failed to save notification settings.", {
                theme: 'colored'
            });
        }
    }

    const table = useAdvanceTable({
        data: dataObj,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <AdvanceTableProvider {...table}>
            <Row>
                <Col xs="12" className="mb-1 mt-1">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle text-center"
                        rowClassName="text-nowrap align-middle text-center"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Button color="primary"
                    size="md"
                    className="mt-1 w-25" onClick={handleSave}>
                    Save
                </Button>
            </Row>
        </AdvanceTableProvider>
    )
}

export default UserNotifcationTableBody