import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';
import FormValidations from 'components/FormValidations/FormValidations.js';

const PharmacyForm = (props) => {

    const initialFormData = {
        pharmacy: '',
        pharmacynumber: '',
        pharmacyemail: '',
        pharmacycontact: '',
        pharmacyfax: '',
        is_active: false,
        is_default: false
    };

    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState(false);
    const formRef = useRef(null);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    //const navigate = useNavigate();

    useEffect(() => {
        //populateData();

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
            populateData();
        }

    }, [props.edittrigger]);

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");

        //if (buttonStatus == "Edit") {
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/drugs/get-pharmacy-store/` + props.pharmacyid,
            { headers: { 'Authorization': `Bearer ${tokenStr}` } })
            .then((res) => {
                let objPharmacyData = res.data.data;

                objPharmacyData.forEach((value) => {
                    setFormData({
                        pharmacy: value.pharmacy_store_name,
                        pharmacynumber: value.pharmacy_store_number,
                        pharmacyemail: value.email,
                        pharmacyfax: value.fax,
                        pharmacycontact: value.phone,
                        is_active: Number(value.is_active) == 1 ? true : false,
                        is_default: Number(value.is_default) == 1 ? true : false
                    })

                });

            });
        //}
    }

    const validations = () => {
        let newErrors = {};
        if (formData.pharmacyemail && !FormValidations(formData.pharmacyemail, 5)) {
            newErrors.email = "Please Enter Correct Email Address"
        }
        if (formData.pharmacyfax && !FormValidations(formData.pharmacyfax, 4)) {
            newErrors.fax = "Please Enter Correct Fax Number"
        }
        if (formData.pharmacycontact && !FormValidations(formData.pharmacycontact, 4)) {
            newErrors.mobile = "Please Enter Correct Phone Number"
        }
        setError(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validations()) {
            return;
        }
        let tokenStr = localStorage.getItem("accessToken");

        let pelmedsPharmacyUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/drugs/add-pharmacy-store" :
            `${process.env.REACT_APP_SERVER_URL}` + "/drugs/update-pharmacy-store";

        let pelmedsPharmacyData = buttonStatus == "Add" ?
            {
                "pharmacy_store_name": formData.pharmacy,
                "pharmacy_store_number": formData.pharmacynumber,
                "email": formData.pharmacyemail,
                "group_id" : localStorage.getItem("groupId") || '',
                "fax": formData.pharmacyfax,
                "phone": formData.pharmacycontact,
                "is_active": formData.is_active ? 1 : 0,
                "is_default": formData.is_default ? 1 : 0
            } :
            {
                "id": props.pharmacyid,
                "group_id" : localStorage.getItem("groupId") || '',
                "pharmacy_store_name": formData.pharmacy,
                "pharmacy_store_number": formData.pharmacynumber,
                "email": formData.pharmacyemail,
                "fax": formData.pharmacyfax,
                "phone": formData.pharmacycontact,
                "is_active": formData.is_active ? 1 : 0,
                "is_default": formData.is_default ? 1 : 0
            }

        console.log("pelmedsPharmacyData" + JSON.stringify(pelmedsPharmacyData));

        const { data } = await axios.post(pelmedsPharmacyUrl, pelmedsPharmacyData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Pharmacy added/updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Pharmacy");
        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label className="required">Pharmacy Name</Form.Label>
                        <Form.Control
                            placeholder='pharmacy name'
                            value={formData.pharmacy}
                            name="pharmacy"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                            required
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Label className="required">Pharmacy Store Number</Form.Label>
                        <Form.Control
                            placeholder='Pharmacy Store Number'
                            value={formData.pharmacynumber}
                            name="pharmacynumber"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            placeholder='Email'
                            value={formData.pharmacyemail}
                            name="pharmacyemail"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                            isInvalid={!!error.email}
                        />
                        {error.email && <div className="text-danger small">{error.email}</div>}
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group>
                        <Form.Label>Pharmacy Contact Number</Form.Label>
                        <Form.Control
                            placeholder='Pharmacy Contact Number'
                            value={formData.pharmacycontact}
                            name="pharmacycontact"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                            isInvalid={!!error.mobile}
                        />
                        {error.mobile && <div className="text-danger small">{error.mobile}</div>}
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Fax</Form.Label>
                        <Form.Control
                            placeholder='Fax'
                            value={formData.pharmacyfax}
                            name="pharmacyfax"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                            isInvalid={!!error.fax}
                        />
                        {error.fax && <div className="text-danger small">{error.fax}</div>}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_active"
                        id='is_active'
                        checked={
                            formData.is_active
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_active: e.target.checked
                            })
                        }
                        label='Active'
                    />
                </Col>
                <Col>
                    <Form.Check
                        type='switch'
                        name="is_default"
                        id='is_default'
                        checked={
                            formData.is_default
                        }
                        onChange={e =>
                            setFormData({
                                ...formData,
                                is_default: e.target.checked
                            })
                        }
                        label='Is Default Pharmacy'
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-1 w-50"
                            size="sm"
                            disabled={!formData.pharmacy || !formData.pharmacynumber}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
            </Row>
        </Form>
    );
};
export default PharmacyForm;
