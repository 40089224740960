import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/20.jpg';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePasswordForm = ({ hasLabel }) => {
  const [error, setError] = useState('');
    const [spinner, showSpinner] = useState(false);
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  // Handler
  async function handleSubmit(e) {
    e.preventDefault();
    showSpinner(true);
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      showSpinner(false);
      return;
    }

    setError('');

    let sendingObj = {
      "password" : formData.password,
      "confirm_password" : formData.confirmPassword
    }

    let tokenStr = localStorage.getItem("hash");
    let forgotpasswordurl = `${process.env.REACT_APP_SERVER_URL}` + '/users/change-password'
    const { data } = await axios.post(forgotpasswordurl, sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    if (data.status) {
        localStorage.setItem("accessToken", tokenStr);
        localStorage.setItem("username", localStorage.getItem('usernametemp'));

        let sendingObj = {
          "masters": ["order_types", "order_status", "delivery_types","delivery_locations"]
        }
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/orders/fetch-masters`, sendingObj , { headers: { 'Authorization': `Bearer ${tokenStr}` } })
          .then((res) => { 
            localStorage.setItem("masters", JSON.stringify(res.data.masters));
          });

        //set roleid
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/profile`, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
          .then((res) => {
            localStorage.setItem("roleId", res.data.role_id);
            localStorage.setItem("groupId", res.data.group_id);
            localStorage.setItem("accessControl", res.data.access_control);
            toast.success('Password Changed Succesfully', {
                theme: 'colored'
              });
            localStorage.removeItem('usernametemp');
            localStorage.removeItem('hash');
            navigate("/main");
          });
    }
    else {
      toast.info("Error occured while Changing Password");
      showSpinner(false);
    }

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (

    <AuthSplitLayout bgProps={{ image: bgImg }}>
      <div className="text-center">
        <h3>Change password</h3>
      </div>
      <Form
        className={classNames('mt-3', { 'text-left': hasLabel })}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>New Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'New Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        {spinner && (
          <Button variant="primary" disabled className="mt-3 w-100">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="p-2" style={{ "verticalAlign": "3px" }}>Loading...</span>
          </Button>
        )}
        {!spinner && <Button
          type="submit"
          className="w-100"
          disabled={!formData.password || !formData.confirmPassword}
        >
          Set password
        </Button>}
      </Form>
    </AuthSplitLayout>
  );
};

ChangePasswordForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default ChangePasswordForm;
