import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customerDetailsData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import IconItem from 'components/common/icon/IconItem';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import EditMedHourModal from './EditMedHourModal';
import { toast } from 'react-toastify';

const MedHourTable = (props) => {

    function handleEditInput(editValue) {
        //console.log("I m in Handle Edit " + editValue);
        props.onCallEditParent(editValue);
    }

    const columns = [
        {
            accessorKey: 'hour',
            header: 'Time',
            meta: {
                headerProps: { className: 'pe-1 col-lg-4 text-start text-900' }
            }
        },
        // {
        //     accessorKey: 'updated_by',
        //     header: 'Updated By',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        // {
        //     accessorKey: 'updatedate',
        //     header: 'Updated Date',
        //     meta: {
        //         headerProps: { className: 'text-900' }
        //     }
        // },
        {
            accessorKey: 'Action',
            header: '',
            meta: {
                headerProps: {
                    className: 'text-900'
                },
                cellProps: {
                    className: 'align-items-center'
                }
            },
            enableSorting: false,
            cell: ({ row: { original } }) => {
                return <IconItem
                    tag="button"
                    icon={['far', 'edit']}
                    size="sm"
                    onClick={() => handleEditInput(original.hour + "," + original.is_active + "," + original.hour_id)}
                    className="btn rounded-3 me-2 fs-11 align-items-center"
                />
            }
        }
    ];

    const [preshour, setPresHour] = useState([]);
    const [error, setError] = useState(true);
    // const [showModal, setShowModal] = useState(false);

    // const handleEditMedHourModal = (hourValue, isActive, hourId) => {
    //     localStorage.setItem("hourValue", hourValue);
    //     localStorage.setItem("hourId", hourId);
    //     localStorage.setItem("isActive", isActive);
    //     setShowModal(true);
    // };

    useEffect(() => {

        async function fetchPresHour() {

            let tokenStr = localStorage.getItem("accessToken");

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/prescriptions/list-prescription-hours", { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []
            const presHourData = data.data

            if (data.status) {
                presHourData.forEach((value) => {
                    let active_sts = value.is_active ? "active" : "inactive";
                    if (props.active_status == active_sts) {
                        results.push({
                            hour: value.time,
                            hour_id: value.id,
                            is_active: value.is_active// == 1 ? 0 : 1
                            // updated_by: value.updated_by,
                            // updatedate: value.updated != null ? moment(value.updated, 'YYYY-MM-DD').format('MM/DD/YY') : null
                        });
                    }
                });

                if(results.length > 0){
                    setPresHour(results);
                    setError(true)
                }else{
                    setError(false)
                }
            }
            else {
                toast.info("No Prescription Hour found in this list");
            }
        }

        fetchPresHour();

        async function refreshMedHrDetails() {
            fetchPresHour();
        }

        if (props.trigger > 0) {
            //console.log(props.trigger);
            refreshMedHrDetails();
        }

    }, [props.trigger]);

    const table = useAdvanceTable({
        data: preshour,
        columns,
        selection: false,
        sortable: true,
        pagination: true,
    });

    return (
        <div>
            <AdvanceTableProvider {...table}>
            {error ? <AdvanceTable
                    headerClassName="bg-200 text-nowrap align-middle text-center"
                    rowClassName="text-nowrap align-middle "//text-center
                    tableProps={{
                        size: 'lg',
                        striped: true,
                        className: 'fs-10 mb-0'
                    }}
                /> : <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '50px' }}>
                <p className="text-danger fw-bold mb-0">There is no data available in the table</p>
            </div>}
                <div className="mt-3">
                    <AdvanceTableFooter
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </div>
            </AdvanceTableProvider>
            {/* <EditMedHourModal show={showModal} setShow={setShowModal} /> */}
        </div>
    );
};

export default MedHourTable;
