import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

const RefillYesModal = ({ show, setShow, orderData, onRefillSuccess }) => {
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [note, setNote] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (show) {
            const dates = getNextWorkingDays();
            setDeliveryDates(dates);
            if (dates.length > 0) {
                setSelectedDate(dates[0].value);
            }
        }
    }, [show]);

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
        });
    };

    const getNextWorkingDays = () => {
        const workingDays = [];
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        while (workingDays.length < 5) {
            const day = currentDate.getDay();
            if (day !== 0 && day !== 6) {
                let label = "";
                if (workingDays.length === 0) label = `Tomorrow (${formatDate(currentDate)})`;
                else label = `${currentDate.toLocaleDateString('en-US', { weekday: 'long' })} (${formatDate(currentDate)})`;

                workingDays.push({
                    label,
                    value: `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`
                });
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return workingDays;
    };

    const resetForm = () => {
        setNote('');
        setSelectedDate(deliveryDates.length > 0 ? deliveryDates[0].value : '');
        setErrors({});
        setShow(false);
    };

    const validateForm = () => {
        let validationErrors = {};
        if (!selectedDate) {
            validationErrors.deliveryDate = "Delivery date is required.";
        }
        if (!note.trim()) {
            validationErrors.note = "Note is required.";
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsSubmitting(true);
        let tokenStr = localStorage.getItem("accessToken");

        const payload = {
            delivery_date: selectedDate,
            notes: note,
            id: orderData?.orderId,
            reminder_status : 1, ///yes 
        };

        try {
            const {data} = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/orders/update-refill-reminder/`,
                payload,
                { headers: { 'Authorization': `Bearer ${tokenStr}` } }
            );

            if (data.status) {
                toast.success("Refill request submitted successfully!");
                if (onRefillSuccess) {
                    onRefillSuccess(orderData?.orderid);
                }
                resetForm();
            } else {
                toast.error("Failed to submit refill request.");
            }
        } catch (error) {
            toast.error("Error submitting request. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <Modal onHide={resetForm} show={show} centered>
            <Modal.Header closeButton>
                <Modal.Title className='fs-8 fw-bold'>Refill Selected Drug </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form onSubmit={handleSubmit}>
                    <Row className='mb-3'>
                        <Col>
                            <Form.Label className="required">Delivery On</Form.Label>
                            <Form.Select name="selectdeliverydate" isInvalid={!!errors.deliveryDate} value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                                {deliveryDates.map((date, index) => (
                                    <option key={index} value={date.value}>{date.label}</option>
                                ))}
                            </Form.Select>
                            {errors.deliveryDate && <div className="text-danger mt-1">{errors.deliveryDate}</div>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control isInvalid={!!errors.note} required as="textarea" rows={1} value={note} onChange={(e) => setNote(e.target.value)} />
                            {errors.note && <div className="text-danger mt-1">{errors.note}</div>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-danger mt-3 text-center fw-bold">
                            *Please call pharmacy for urgent or after-hours deliveries.
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    className="px-4"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Processing..." : "Refill"}
                </Button>
                <Button variant="secondary" className="px-4" onClick={resetForm}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RefillYesModal