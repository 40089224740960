export const prescriptionType = [
    {
        key: 'Select a Filter',
        value: '', 
    },
    {
        key: 'Current',
        value: 'current',
    },
    {
        key: 'Discontinued',
        value: 'discontinued',
    },
    {
        key: 'Unverified',
        value: 'unverified',
    }
];