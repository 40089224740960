import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';

const forgotPasswordPaths = {
  simple: paths.simpleForgotPassword,
  split: paths.splitForgotPassword,
  card: paths.cardForgotPassword
};

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    remember: false
  });

  const [spinner, showSpinner] = useState(false);

  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    showSpinner(true);

    fetch(`${process.env.REACT_APP_SERVER_URL}` + "/users/login",
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'username': formData.username, 'password': formData.password })
      })
      .then(response => response.json())
      .then(async function (data) {
        const items = data;
        //console.log("Loginform " + items.status);

        if (items.status) {
          localStorage.setItem("accessToken", items.token);
          localStorage.setItem("username", formData.username);

          let sendingObj = {
            "masters": ["order_types", "order_status", "delivery_types","delivery_locations"]
          }
          await axios.post(`${process.env.REACT_APP_SERVER_URL}/orders/fetch-masters`, sendingObj , { headers: { 'Authorization': `Bearer ${items.token}` } })
            .then((res) => { 
              localStorage.setItem("masters", JSON.stringify(res.data.masters));
            });

          //set roleid
          await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/profile`, { headers: { 'Authorization': `Bearer ${items.token}` } })
            .then((res) => {
              //console.log("Roleid of user is ", res.data.group_id);
              localStorage.setItem("roleId", res.data.role_id);
              localStorage.setItem("groupId", res.data.group_id);
              localStorage.setItem("accessControl", res.data.access_control);
              //localStorage.setItem("groupId", 56);
              toast.success(`Logged in as ${formData.username}`, {
                theme: 'colored'
              });

              // reload and navigate to main
              // window.location.href = "/main";
              navigate("/main");
            });


        }
        else {
          if([1421].includes(items.errorCode)){
            localStorage.setItem("usernametemp", formData.username);
            localStorage.setItem("hash", items.token);
            toast.error(`${items.message}, Please Change the Password`, {
              theme: 'colored'
            });
            navigate("/change-password")
          }
          else if ([1417, 1418, 1416].includes(items.errorCode)) {
            localStorage.setItem("hash", items.hash);
            toast.warning(`Email Needs to be verified ${formData.username}, Please Check Inbox For OTP`, {
              theme: 'colored'
            });
            navigate("/otp");
          }
          else {
            showSpinner(false);
            toast.error('Unable to sign in, please Enter Correct Username/Password not correct');
          }
        }
      })

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Username</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Username' : ''}
          value={formData.username}
          name="username"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group>
        {spinner && (
          <Button variant="primary" disabled className="mt-3 w-100">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="p-2" style={{ "verticalAlign": "3px" }}>Loading...</span>
          </Button>
        )}
        {!spinner && (
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.username || !formData.password}>
            Log in
          </Button>
        )}
      </Form.Group>
      <Form.Group>
        <Link className="fs-10 text-600" to = {{pathname : forgotPasswordPaths[layout]}}>Forgot Password</Link>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
