import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import OrderHistoryDetailModal from './OrderHistoryDetailModal';
import OrderHistoryNotes from './OrderHistoryNotes';

const NavItem = ({ item, classNameCustom }) => {
    return (
        <Nav.Item as="li" className={`${classNameCustom}`}>
            <Nav.Link
                className={`mb-0 py-1 px-1 cursor-pointer`}
                eventKey={item.toLowerCase().replaceAll(" ", "-")}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};

const OrderHistoryModalParent = ({ show, handleClose }) => {
    const [navItems, setNavItems] = useState([]);
    const location = useLocation();
    const state = location?.state?.original;

    useEffect(() => {
        let tabs = ['Order-Details']
        if (state?.order_from == 1) {
            // tabs.push('Notes');
            tabs.push('Messaging');
            tabs.push('Documents');
        }
        setNavItems(tabs);
    }, [show])
    return (
        <Modal show={show} onHide={() => { handleClose(false) }} centered size="md">
            <Modal.Header closeButton>
                <Modal.Title className='fs-8 fw-bold'>Order Detail for {state?.patient}</Modal.Title>
            </Modal.Header>
            <Modal.Body className=" tab-content-wrapper">
                <Tab.Container defaultActiveKey="order-details">
                    <Card.Header
                        as={Flex}
                        justifyContent="between"
                        alignItems="center"
                        className="p-0 border-bottom patientInfoTabs"
                    >
                        <Nav
                            as="ul"
                            className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
                        >
                            {navItems.map(item => (
                                <NavItem classNameCustom="col text-center" item={item} key={item} />
                            ))}
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        <div className="tab-content-wrapper">
                            <Row className="g-1">
                                <Col xxl={12}>
                                    <Tab.Pane unmountOnExit eventKey="order-details">
                                        <OrderHistoryDetailModal dataObj={state} />
                                    </Tab.Pane>
                                    <Tab.Pane unmountOnExit eventKey="messaging">
                                        <OrderHistoryNotes data={state}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="logs">
                                    </Tab.Pane>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Tab.Container>
            </Modal.Body>
        </Modal>
    )
}

export default OrderHistoryModalParent