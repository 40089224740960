export const rootPaths = {
  root: '/',
  adminRoot: 'admin',
  mainRoot: 'main',
  hcpRoot: 'hcp',
  patientRoot: 'patient',
  prescriptionRoot: 'prescription',
  dashboardRoot: 'dashboard',
  pagesRoot: 'pages',
  authRoot: 'authentication',
  authSimpleRoot: 'simple',
  authCardRoot: 'card',
  authSplitRoot: 'split',
  reportRoot: 'reports',
  pelTaskRoot: 'peltask',
  facilityLookUpRoot : 'groups',
  userProfileRoot:'users',
  orderHistoryRoot : 'order-history',
  route4MeRoot : 'route4me'
};

// export const defaultRoleId = {
//   roleid: localStorage.getItem("roleId")
// };

export default {
  changelog: '/changelog',
  widgets: '/widgets',
  landing: '/landing',
  starter: `/${rootPaths.pagesRoot}/starter`,
  error404: `/${rootPaths.errorsRoot}/404`,
  error500: `/${rootPaths.errorsRoot}/500`,
  simpleLogin: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/login`,
  simpleRegister: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/register`,
  simpleLogout: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/logout`,
  simpleForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/forgot-password`,
  simpleResetPassword: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/reset-password`,
  simpleConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/confirm-mail`,
  simpleLockScreen: `/${rootPaths.authRoot}/${rootPaths.authSimpleRoot}/lock-screen`,
  cardLogin: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/login`,
  cardRegister: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/register`,
  cardLogout: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/logout`,
  cardForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/forgot-password`,
  cardResetPassword: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/reset-password`,
  cardConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/confirm-mail`,
  cardLockScreen: `/${rootPaths.authRoot}/${rootPaths.authCardRoot}/lock-screen`,
  splitLogin: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/login`,
  splitRegister: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/register`,
  splitLogout: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/logout`,
  splitForgotPassword: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/forgot-password`,
  splitVerifyEmail : `/verify-email`,
  splitVerifyEmailId : `/verify-email/:id`,
  splitResetPassword: `/reset-password`,
  splitResetPasswordWithId: `/reset-password/:id`,
  splitConfirmMail: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/confirm-mail`,
  splitLockScreen: `/${rootPaths.authRoot}/${rootPaths.authSplitRoot}/lock-screen`,
  authWizard: `/${rootPaths.authRoot}/wizard`,
  analytics: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/analytics`,
  crm: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/crm`,
  saas: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/saas`,
  ecommerce: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/e-commerce`,
  lms: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/lms`,
  projectManagement: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/project-management`,
  supportDesk: `${rootPaths.mainRoot}/${rootPaths.dashboardRoot}/support-desk`,
  pelMedsLogin: `/`,
  UserProfile:`/${rootPaths.mainRoot}/${rootPaths.userProfileRoot}/view-profile`,
  defaultSetting:`/${rootPaths.mainRoot}/defaultsetting`,
  viewPatient: `/${rootPaths.mainRoot}/${rootPaths.patientRoot}/view-patient`,
  addPatient: `/${rootPaths.mainRoot}/${rootPaths.patientRoot}/add-patient`,
  editPatient: `/${rootPaths.mainRoot}/${rootPaths.patientRoot}/edit-patient`,
  addPrescription: `/${rootPaths.mainRoot}/${rootPaths.prescriptionRoot}/add-prescription`,
  editPrescription: `/${rootPaths.mainRoot}/${rootPaths.prescriptionRoot}/edit-prescription`,
  editPrescriptionWithId: `/${rootPaths.mainRoot}/${rootPaths.prescriptionRoot}/edit-prescription/:id`,
  addMedicalRecord: `/${rootPaths.mainRoot}/${rootPaths.prescriptionRoot}/add-medicalrecord`,
  editMedicalRecord: `/${rootPaths.mainRoot}/${rootPaths.prescriptionRoot}/edit-medicalrecord`,
  addEditHcp: `/${rootPaths.mainRoot}/${rootPaths.hcpRoot}/add-hcp`,
  viewHcp: `/${rootPaths.mainRoot}/${rootPaths.hcpRoot}/view-hcp`,
  hcpSpeciality: `/${rootPaths.mainRoot}/${rootPaths.hcpRoot}/hcp-speciality`,
  patientDetails: `/${rootPaths.mainRoot}/${rootPaths.patientRoot}/patient-details`,
  patientDetailsWithId: `/${rootPaths.mainRoot}/${rootPaths.patientRoot}/patient-details/:id`,
  addFacility: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/add-facility-staff`,
  viewfacilitystaffprofile: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/view-facility-staff`,
  viewfacilitystaffprofileWithid : `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/view-facility-staff/:id`,
  viewFacility: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/view-facility-staff-list`,
  medRoute: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-route`,
  medAmount: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-amount`,
  medFrequency: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-frequency`,
  medGroup: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-group`,
  medSplInstr: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-spl-instr`,
  medReasonPresc: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-reason-pres`,
  medHour: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-hour`,
  pharmacy: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/pharmacy`,
  allergy: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/allergy`,
  
  //holdReason: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/${rootPaths.pelTaskRoot}/hold-reason`,

  holdReason: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/hold-reason`,

  drugList: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/druglist`,
  insurance: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/insurance`,
  insurancePriority: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/insurance-priority`,
  medRecordCategory: `/${rootPaths.mainRoot}/${rootPaths.adminRoot}/med-record-category`,
  genMedSheet: `/${rootPaths.mainRoot}/${rootPaths.reportRoot}/medsheet`,
  genLoa: `/${rootPaths.mainRoot}/${rootPaths.reportRoot}/loa`,
  hcpOrder: `/${rootPaths.mainRoot}/${rootPaths.reportRoot}/hcoorder`,
  genTreatment: `/${rootPaths.mainRoot}/${rootPaths.reportRoot}/treatment`,
  genCurrentMed: `/${rootPaths.mainRoot}/${rootPaths.reportRoot}/currentmed`,
  //cycleDelivery: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/cycledelivery`,
  cycleDelivery: paramsFacilityId => `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/cycledetails${paramsFacilityId ? `/${paramsFacilityId}` : ''}`,
  cycleStatus: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/cyclestatus`,
  cycleView: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/cycleview/${1}`,
  cycleViewWithId: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/cycleview/:id`,
  indexHold: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/hold`,
  orderDetail: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/order-detail`,
  orderHistoryFacility: `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/orderhistory`,
  faciliityLookUp: `/${rootPaths.mainRoot}/${rootPaths.facilityLookUpRoot}/group-list`,
  editFaciliityLookUp: `/${rootPaths.mainRoot}/${rootPaths.facilityLookUpRoot}/editfaciliitylookUp`,
  facilitylistlookUp : `/${rootPaths.mainRoot}/${rootPaths.facilityLookUpRoot}/group-profile`,
  facilitylistlookUpwithid : `/${rootPaths.mainRoot}/${rootPaths.facilityLookUpRoot}/group-profile/:id`,
  editfaciliitylookupform : `/${rootPaths.mainRoot}/${rootPaths.facilityLookUpRoot}/edit-facility`,
  orderHistory :  `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/patient-dashboard`,
  route4medeliverydriver :  `/${rootPaths.mainRoot}/${rootPaths.route4MeRoot}/deliverydrivermaster`,
  route4medeliverystatus :  `/${rootPaths.mainRoot}/${rootPaths.route4MeRoot}/route4mehistory`,
  route4medatatransfer :  `/${rootPaths.mainRoot}/${rootPaths.route4MeRoot}/index`,
  demofile : `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/Demo-File`,
  needrefill : `/${rootPaths.mainRoot}/${rootPaths.pelTaskRoot}/FacilityNonCycleRefill`,
};
