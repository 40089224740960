import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

const OrderHistoryNotes = ({data}) => {
    const dataObj = data
    const [messages, setMessages] = useState([]);
    const fetchMessages = async () => {
        let resultMessages = [];
        dataObj.notesObj?.map((item) => {
            resultMessages.push({
                sender: item.created_by_username,
                text: item.note,
                timestamp: moment(item.created).local().format('MM/DD/YY, hh:mm:ss A'),
                from : item.note_from
            })
            setMessages(resultMessages);
        })
    }


    useEffect(() => {
        fetchMessages()
    }, [])


    return (
        <>
            <div
                className='mb-2 notes-wrapper'
                style={{
                    height: '300px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#f9f9f9',
                }}
            >
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        style={{
                            marginBottom: '10px',
                            textAlign: msg.from === 1 ? 'right' : 'left',
                        }}
                    >
                        <div style={{ marginBottom: '5px' }}>
                            <strong style={{ color: msg.sender === 'You' ? '#007bff' : '#555' }}>
                                {msg.sender}
                            </strong>{' '}
                            <span style={{ fontSize: '0.8em', color: '#888' }}>
                                {msg.timestamp}
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'inline-block',
                                backgroundColor: msg.sender === 'You' ? '#d1ecf1' : '#e9ecef',
                                padding: '10px',
                                borderRadius: '10px',
                                maxWidth: '70%',
                                textAlign: 'left',
                            }}
                        >
                            {msg.text}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default OrderHistoryNotes;
