import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import avatar from 'assets/img/team/avatar.png';
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const UserProfileHeader = (props) => {
const data = props.data
const name = data.last_name + "," + data.first_name
    return (
        <Card>
            <Card.Header className="pb-0 pt-4 mb-2">
                <h4 className="mb-1">
                    {name}
                </h4>
            </Card.Header>
            <Card.Body className="py-0 mb-4">
                <Row>
                    <Col lg={4}>
                        {/* <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                
                            </div>
                        </Flex> */}
                    </Col>
                    <Col lg={4} className="ps-2 ps-lg-3 me-0 ms-auto">
                        <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">User Name: {data.username} </h6>
                            </div>
                        </Flex>
                        {data.city ? <Flex alignItems="center" className="mb-2">
                            <div className="flex-1">
                                <h6 className="mb-0">City: {data.city}</h6>
                            </div>
                        </Flex> : []}
                    </Col>

                    <Row className="col-12 mx-0 w-100 avatar-profile-sec">
                        <Avatar
                            size="auto"
                            className="avatar-profile col-1 mx-0 px-0"
                            src={avatar}
                            mediaClass="img-thumbnail shadow-sm"
                        />
                    </Row>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default UserProfileHeader