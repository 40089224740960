import React, { useEffect, useState } from "react";
import { Card, Table, Form, Row, Col, Badge, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const DevicesList = (props) => {
  const data = props.dataObj
  const [spinner, showSpinner] = useState(false);
  const [devices, setDevices] = useState(data?.devices);
  const tokenStr = localStorage.getItem("accessToken");

  const handleStatusChange = (id, newStatus) => {
    const updatedDevices = devices.map((device) =>
      device.id === id ? { ...device, approval_status: parseInt(newStatus) } : device
    );
    setDevices(updatedDevices);
  };

  const handleUpdate = async (id, approvalStatus) => {
    showSpinner(true);
    const payload = {
      device_id: id,
      approval_status: approvalStatus || '',
    };

    const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/users/approve-device", payload, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
    if (data.status) {
      showSpinner(false);
      toast.success(data.message);
    } else {
      showSpinner(false);
      toast.error(data.message);
    }
  };

  const sortedDevices = [...devices].sort((a, b) => {
    if (a.approval_status === 0 && b.approval_status !== 0) return -1;
    if (a.approval_status !== 0 && b.approval_status === 0) return 1;
    return a.id - b.id;
  });

  return (
    <Card className="p-3">
      <Table responsive bordered hover>
        <thead className="bg-200 text-center">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Model</th>
            <th>Brand</th>
            <th>Approval Status</th>
            <th>Approved By</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedDevices.map((device) => (
            <tr
              key={device.id}
              className={
                device.approval_status === 0 ? "bg-success text-dark" : ""
              }
            >
              <td className="text-center">{device.id}</td>
              <td>{device.name}</td>
              <td>{device.model}</td>
              <td>{device.brand}</td>
              <td className="text-center">
                <Form.Select
                  value={device.approval_status}
                  onChange={(e) =>
                    handleStatusChange(device.id, e.target.value)
                  }
                  className="form-control-sm"
                >
                  <option value={0}>Select</option>
                  <option value={1}>Accept</option>
                  <option value={2}>Reject</option>
                </Form.Select>
              </td>
              <td>
                {device.approved_by
                  ? `${device.approved_first_name} ${device.approved_last_name}`
                  : "-"}
              </td>
              <td className="text-center">
                {spinner ? (
                  <Button variant="primary" size="sm" className="d-flex align-items-center justify-content-center" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    size="sm"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => handleUpdate(device.id, device.approval_status)}
                  >
                    Update
                  </Button>
                )}

              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="mt-3">
        <Col>
          <Badge bg="success" text="dark" className="me-2">
            New Device
          </Badge>{" "}
          indicates unapproved devices.
        </Col>
      </Row>
    </Card>
  );
};

export default DevicesList;
