import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";

const AddNotesModal = ({ show, setShow, patientname, patientid, notesby, dataObj, updateDataObj }) => {
    const [facilityNotes, setFacilityNotes] = useState("");
    const [pharmacyNotes, setPharmacyNotes] = useState("");
    const [errors, setErrors] = useState({}); // Store validation errors
    let defaultFacilitytvalue = '';
    let defaultPharmacytvalue = '';
    if(dataObj){
        defaultFacilitytvalue = dataObj[patientid]?.facility_notes;
        defaultPharmacytvalue = dataObj[patientid]?.pharmacy_notes;
    }

    useEffect(() => {
        setFacilityNotes("");
        setPharmacyNotes("");
    }, [patientname, notesby]);

    const validateFields = () => {
        let newErrors = {};

        if (notesby === "Facility" && !facilityNotes.trim()) {
            newErrors.facilityNotes = "Facility Notes are required.";
        }
        if (notesby === "Pharmacy" && !pharmacyNotes.trim()) {
            newErrors.pharmacyNotes = "Pharmacy Notes are required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        }

        let tokenStr = localStorage.getItem("accessToken");
        let notesUrl = `${process.env.REACT_APP_SERVER_URL}/cycles/update-cycle-patients`;

        let notesData = notesby === "Facility"
            ? [{ "cycle_patient_id": patientid, "facility_notes": facilityNotes }]
            : [{ "cycle_patient_id": patientid, "pharmacy_notes": pharmacyNotes }];

        try {
            const { data } = await axios.post(notesUrl, notesData, {
                headers: { 'Authorization': `Bearer ${tokenStr}` }
            });

            if (data.status) {
                toast.success(`${notesby} Notes added successfully`);
                const updatedDataObj = { ...dataObj };
                if (updatedDataObj[patientid]) {
                    updatedDataObj[patientid] = {
                        ...updatedDataObj[patientid],
                        facility_notes: notesby === "Facility" ? facilityNotes : updatedDataObj[patientid].facility_notes,
                        pharmacy_notes : notesby === "Pharmacy" ? pharmacyNotes : updatedDataObj[patientid].pharmacy_notes,
                    };
                }
                // Update parent state to trigger re-render
                if (typeof updateDataObj === "function") {
                    updateDataObj(updatedDataObj);
                }
                setShow(false);
            } else {
                toast.error("Error occurred while adding Notes");
            }
        } catch (error) {
            toast.error("Failed to save notes. Please try again.");
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="h5">
                    Notes for {patientname}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary p-4">
                <Form>
                    {notesby === "Facility" && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label className="required">Facility Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Enter Facility Notes"
                                    defaultValue={defaultFacilitytvalue}
                                    onChange={(e) => setFacilityNotes(e.target.value)}
                                    isInvalid={!!errors.facilityNotes} // Add validation
                                    rows="3"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.facilityNotes}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Pharmacy Notes</Form.Label>
                                <Form.Control as="textarea" rows="3" disabled defaultValue={defaultPharmacytvalue || 'No Notes'}></Form.Control>
                            </Form.Group>
                        </>
                    )}
                    {notesby === "Pharmacy" && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label className="required">Pharmacy Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Enter Pharmacy Notes"
                                    defaultValue={defaultPharmacytvalue}
                                    onChange={(e) => setPharmacyNotes(e.target.value)}
                                    isInvalid={!!errors.pharmacyNotes} // Add validation
                                    rows="3"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.pharmacyNotes}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Facility Notes</Form.Label>
                                <Form.Control as="textarea" rows="3" disabled defaultValue={defaultFacilitytvalue || 'No Notes'}></Form.Control>
                            </Form.Group>
                        </>
                    )}
                    <Row>
                        <Col xs={6}>
                            <Form.Group>
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mt-4 w-50"
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

AddNotesModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    patientname: PropTypes.string.isRequired,
    patientid: PropTypes.string.isRequired,
    notesby: PropTypes.string.isRequired,
};

export default AddNotesModal;
