import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Card, Col, Row, Button, InputGroup, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import moment from "moment";
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link, useLocation } from 'react-router-dom';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconItem from 'components/common/icon/IconItem';
import PrescriptionInfoModal from './PrescriptionInfoModal';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const RefillDiscontinuedModal = ({ show, setShow }) => {
    const [tableData, setTableData] = useState([]);
    const [showInfoModel, setShowInfoModel] = useState(false);
    const [editableQuantities, setEditableQuantities] = useState({});
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(1);
    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const location = useLocation();
    const data = location?.state?.dataObj;

    const columns = [
        {
            accessorKey: 'drug',
            header: 'Drug',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'type',
            header: 'Type',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'remainsrefill',
            header: 'Remains Refills',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'staticQuantity',
            header: 'Quantity',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'delivered',
            header: 'Delivered',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'nextrefill',
            header: 'Next Refill',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'createdby',
            header: 'Created By',
            meta: {
                headerProps: { className: 'text-900 text-start' },
                cellProps: { className: 'text-start' }
            }
        },
        {
            accessorKey: 'moredetails',
            header: 'More Details',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'text-center' }
            },
            cell: ({ row: { original } }) => {
                return <Link state={original}>
                    <IconItem
                        tag="button"
                        icon={['fas', 'search']}
                        size="sm"
                        onClick={(e) => setShowInfoModel(true)}
                        className="btn rounded-3 me-2 fs-11 align-items-center"
                    />
                </Link>
            }
        },
        {
            accessorKey: 'quantity',
            header: 'Edit Qty',
            meta: {
                headerProps: { className: 'text-900 text-center' },
                cellProps: { className: 'd-flex justify-content-center' }
            },
            cell: ({ row: { original } }) => {
                const initialQty = original.quantity;
                const hasLimit = initialQty > 0;
                const maxQty = hasLimit ? initialQty : Infinity;
                const [localValue, setLocalValue] = useState(
                    editableQuantities[original.rxid] ?? ""
                );

                const handleInputChange = (e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                        setLocalValue(value);
                    }
                };

                const handleBlur = () => {
                    const numericValue = parseFloat(localValue);
                    if (localValue === "") {
                        setEditableQuantities((prev) => ({
                            ...prev,
                            [original.rxid]: "",
                        }));
                        return;
                    }
                    if (hasLimit && (isNaN(numericValue) || numericValue > maxQty)) {
                        toast.error(`Quantity cannot exceed ${maxQty}`, { autoClose: 2000 });
                        setLocalValue("");
                    } else {
                        setEditableQuantities((prev) => ({
                            ...prev,
                            [original.rxid]: localValue,
                        }));
                    }
                };
                return (
                    <Form.Control
                        type="text"
                        value={localValue}
                        placeholder="Enter Qty"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onFocus={(e) => e.target.select()}
                        style={{
                            maxWidth: '75%',
                            textAlign: 'center',
                            borderRadius: '4px',
                            boxShadow: 'none',
                            appearance: 'none',
                        }}
                    />
                );
            },
        }
    ]
    useEffect(() => {
        if (show && location.state?.triggeredBy === "someAction") {
            fetchData()
            if (show) {
                const dates = getNextWorkingDays();
                setDeliveryDates(dates);
                if (dates.length > 0) {
                    setSelectedDate(dates[0].value);
                }
            }
        }
    }, [show, location.state])


    async function fetchData() {
        let tokenStr = localStorage.getItem("accessToken");
        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/orders/list-refill-prescriptions/" + location.state.id, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let objData = data.prescriptions.sort((a, b) => a.drug.localeCompare(b.drug));
        let results = [];
        objData.map((value) => {
            results.push({
                drug: value.drug,
                type: value.med_type,
                remainsrefill: value.remainsrefill,
                delivered: value.delivery_date != "Invalid date" ? value.delivery_date : "",
                nextrefill: value.next_refill_date != "Invalid date" ? value.next_refill_date : "",
                patientName: `${value.patient_lastname},${value.patient_firstname}`,
                rxid: value.pharmacy_order_id,
                doctorName: `${value.doctor_lastname},${value.doctor_firstname}`,
                prescribe_date: value.prescribe_date,
                direction: value.sig_english,
                staticQuantity: parseFloat(value.qty) || 0,
                quantity: parseFloat(value.qty) || 0
            })
        })
        setTableData(results)
    }

    const table = useAdvanceTable({
        data: tableData,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    // const getNextWorkingDay = () => {
    //     let nextDay = moment();
    //     while (nextDay.day() === 0 || nextDay.day() === 6) {
    //         nextDay.add(1, 'days');
    //     }
    //     return nextDay.format("MM/DD/YY");
    // };

    const handleRefillSubmit = async () => {
        if (!validateForm()) return; // Prevent submission if validation fails

        setLoading(true);

        const payload = Object.entries(editableQuantities).map(([rx_number, quantity]) => ({
            rx_number,
            quantity,
        }));
        let sendingObj = {
            patient_id: location.state.id,
            delivery_date: selectedDate,
            order_type: 1,
            note: note,
            order_document: {},
            prescriptions: payload
        }
        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsPrescriptiontUrl = `${process.env.REACT_APP_SERVER_URL}` + '/orders/create-prescription-order/'
        try {
            const { data } = await axios.post(pelmedsPrescriptiontUrl, sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            if (data.status) {
                toast.success("Prescription Order added successfully");
                handleModalClose();
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error("Failed to submit order. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        setTableData([]);
        setEditableQuantities({});
        setSelectedDate('');
        setNote('');
        setErrors({});
        setShow(false);
        setLoading(false);
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
        });
    };

    const getNextWorkingDays = () => {
        const workingDays = [];
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        while (workingDays.length < 5) {
            const day = currentDate.getDay();
            if (day !== 0 && day !== 6) {
                let label = "";
                if (workingDays.length === 0) label = `Tomorrow (${formatDate(currentDate)})`;
                else label = `${currentDate.toLocaleDateString('en-US', { weekday: 'long' })} (${formatDate(currentDate)})`;

                workingDays.push({
                    label,
                    value: `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`
                });
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return workingDays;
    };

    const validateForm = () => {
        let newErrors = {};
        if (!selectedDate) newErrors.selectedDate = "Please select a delivery date.";
        if (!note.trim()) newErrors.note = "Notes are required.";
        if (Object.keys(editableQuantities).length === 0) {
            newErrors.refill = "At least one refill quantity must be entered.";
            toast.error("At least one refill quantity must be entered.")
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <>
            <PrescriptionInfoModal show={showInfoModel} setShow={setShowInfoModel} />
            <Modal show={show} onHide={handleModalClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title as="h5" className="w-100" id="copyLinkModalLabel">
                        <div>Refill Order For {data?.individual}/{data?.facilityname}</div>
                        <div className="text-danger mt-3 me-3 h6 fw-bold text-end">
                            *Please call pharmacy for urgent or after hours deliveries.
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-body-tertiary">
                    <AdvanceTableProvider {...table}>
                        <Row className="align-items-start mb-3">
                            <Col xs="4" className="mb-0 view-patient-search-holder"><AdvanceTableSearchBox placeholder="Search..." /></Col>
                            <Col xs="4">
                                <Form.Label className="required">Delivery On</Form.Label>
                                <Form.Select name="selectdeliverydate" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                                    {deliveryDates.map((date, index) => (
                                        <option key={index} value={date.value}>{date.label}</option>
                                    ))}
                                </Form.Select>
                                {errors.selectedDate && <small className="text-danger">{errors.selectedDate}</small>}
                            </Col>
                            <Col xs="4">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" rows={1} value={note} onChange={(e) => setNote(e.target.value)} />
                                {errors.note && <small className="text-danger">{errors.note}</small>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" className="mb-1 mt-1">
                                <AdvanceTable
                                    headerClassName="bg-200 text-nowrap align-middle text-center"
                                    rowClassName="text-nowrap align-middle text-center"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs-10 mb-0 overflow-hidden'
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className='mt-3 mb-2'>
                                <AdvanceTableFooter
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </div>
                        </Row>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleRefillSubmit} disabled={loading}>
                                {loading ? <Spinner size="sm" animation="border" /> : "Refill"}
                            </Button>
                        </Modal.Footer>
                    </AdvanceTableProvider>
                </Modal.Body>
            </Modal>
        </>
    );
};

RefillDiscontinuedModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired
};

export default RefillDiscontinuedModal;
