import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customerDetailsData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import axios from "axios";
import { useEffect } from 'react'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import paths from 'routes/paths';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const columns = [
    {
        accessorKey: 'orderid',
        header: 'Document Name',
        meta: {
            headerProps: { className: 'pe-1 text-900' }
        }
    },
    {
        accessorKey: 'hcp',
        header: 'Description',
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'drug',
        header: 'FileSize',
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'generic',
        header: 'Download',
        meta: {
            headerProps: { className: 'text-900' }
        }
    }
];

const FileManagerGrid = () => {
    //const [facility] = useState(facilityStaffData);
    const [currentOrder, setCurrentOrder] = useState([]);

    /*useEffect(() => {

        async function fetchPrescriptionData() {

            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}`+"/prescriptions/list-all-prescriptions/" + props.patientid, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            let results = []

            console.log("Prescription Data Status " + data.prescriptions.status);
            let objData = data.prescriptions.data;
            let groupId = "";

            objData.forEach((value) => {

                results.push({
                    orderid: value.id,
                    hcp: '',
                    drug: value.drug,
                    generic: '',
                    amount: value.qty,
                    dose: value.days_supply,
                    frequency: value.no_of_refill,
                    route: '',
                    start: value.start_date,
                    stop: value.discontinue_date,
                    refill_due: '',
                    refill: '',
                    vieworder: '',
                    medexpirationdate: '',
                    status: '',
                    reason: '',
                    addexpirydate: ''
                });
            });

            setCurrentOrder(results);
        }

        // Trigger the fetch
        fetchPrescriptionData();
    }, []);*/

    const table = useAdvanceTable({
        data: currentOrder,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    return (
        <>
        {currentOrder.length > 0 ?<AdvanceTableProvider {...table}>
            <AdvanceTable
                headerClassName="bg-200 text-nowrap align-middle text-center"
                rowClassName="text-nowrap align-middle text-center"
                tableProps={{
                    size: 'lg',
                    striped: true,
                    className: 'fs-10 mb-0'
                }}
            />
            <div className="mt-3">
                <AdvanceTableFooter
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
            </div>
        </AdvanceTableProvider> : []}
        </>
    );
};

export default FileManagerGrid;
