import React, { useEffect } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import axios from "axios";
import Flex from 'components/common/Flex';
import moment from "moment";
import CycleChecklist from './CycleChecklist';
import CycleTabDetails from './CycleTabDetails';
import classNames from 'classnames';
import DeliveryModal from './DeliveryModal';
import { toast } from 'react-toastify';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import paths from 'routes/paths';
import Select from "react-select";
import CycleDatesModal from './CycleDatesModal';

const CycleDetails = () => {
    let { paramsFacilityId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const locationData = location.state;
    const [options, setOptions] = useState([{ key: 'Select Facility', value: '' }]);
    // const [grpoptions, setGrpOptions] = useState([]);
    const [cycledateoptions, setCycleDateOptions] = useState([{ key: 'Select CycleDate', value: '' }]);
    // const [cyclestartdateoptions, setCycleStartDateOptions] = useState([{ key: 'Select Cycle to get Cycle Start Date', value: '' }]);
    const [cycledateflag, setCycleDateFlag] = useState(false);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [facilitydata, setFacilityData] = useState();
    const [cycleinfoitems, setCycleInfoItems] = useState([]);
    const [cyclesummaryitems, setCycleSummaryItems] = useState([]);
    const [patientdetails, SetPatientDetails] = useState();
    // const [initialFacilityId, setInitialFacilityId] = useState();
    const [initialCycleDate, setInitialCycleDate] = useState();
    const [initialGroupId, setInitialGroupId] = useState();
    const [censusGrid, showCensusGrid] = useState(false);
    const [groupdata, setGroupData] = useState();
    const [facilityobj, setFacilityObj] = useState([]);
    const [censuscompleted, setCensusCompleted] = useState(false);
    const [censuscompleteddet, setCensusCompletedDet] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [localdeliverydate, setLocalDeliveryDate] = useState([]);
    const [deliverydate, setDeliveryDate] = useState([]);
    const [cycleId, setCycleId] = useState("");
    const [modalDate, setModalDate] = useState('')
    const [disableDelivery, setDisableDelivery] = useState(false);
    const [deliveryWarning, setDeliveryWarning] = useState("");
    const [showDatesModal, setShowDatesModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState([]);

    useEffect(() => {
        populateData();
    }, []);

    async function populateData() {

        let tokenStr = localStorage.getItem("accessToken");
        //console.log("facilitiesData " + localStorage.getItem("facilitiesData"));

        //set group details
        // if (localStorage.getItem("roleId") < 4) {

        //     //set groupid
        //     let groupResults = JSON.parse(localStorage.getItem("groupData"));

        //     const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/get-all-facility`, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        //     setGroupData(data.data);

        //     setGrpOptions([
        //         { key: 'Select a group', value: '' },
        //         ...groupResults
        //     ]);

        //     // setInitialGroupId(groupResults[0].value);
        // }
        // else {
        let results = JSON.parse(localStorage.getItem("facilitiesData"));
        setOptions([
            { key: 'Select a program', value: '' },
            ...results
        ]);

        //console.log("FacilityResults " + JSON.stringify(results));
        console.log("paramsFacilityId " + paramsFacilityId);

        // if (paramsFacilityId !== undefined) {
        //     setInitialFacilityId(paramsFacilityId);
        //     // setInitialCycleStartDate(paramsFacilityId);
        //     setSelectedFacility(results.find(option => option.value === paramsFacilityId));
        // }
        // else {
        //     setInitialFacilityId(results[0].value);
        //     // setInitialCycleStartDate(results[0].value);
        //     setSelectedFacility(results[0]);
        // }

        let defaultFacility = results.find(option => option.value === paramsFacilityId) || results[0];

        // setInitialFacilityId(defaultFacility?.value);
        if (!selectedFacility) {
            setSelectedFacility(defaultFacility);
        }
        // }

        //let initialcyclestartdate = setInitialCycleStartDate(results[0].value);
        //console.log("i m getting called" + JSON.stringify(initialcyclestartdate));
        //setCycleDate(initialcyclestartdate);

    }

    const handleGroupFieldChange = e => {

        let groupdetails = groupdata[e.target.value];

        let facilityresults = [];

        groupdetails["facilities"].map((option) => {
            facilityresults.push({
                key: option.facility_name,
                value: option.id
            });
        });

        setOptions([
            { key: 'Select a program', value: '' },
            ...facilityresults
        ]);

        setFacilityObj(facilityresults);

        // setFormData({
        //     ...formData,
        //     [e.target.name]: e.target.value
        // });
    };

    const handleFacilityFieldChange = (selectedOption) => {
        if (selectedFacility?.value !== selectedOption.value) {
            setSelectedFacility(selectedOption);
            setInitialCycleStartDate(selectedOption.value);
        }
    };

    const handleCycleDate = async e => {
        setCycleDate(e.target.value, facilitydata)
    };

    const setInitialCycleStartDate = async (facilityId) => {
        try {
            let tokenStr = localStorage.getItem("accessToken");

            console.log("facilityId " + facilityId);

            const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/cycles/list/` + facilityId, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

            let cycleData = data.cycleData;

            if (Object.keys(cycleData).length === 0) {
                showCensusGrid(false);
            }
            //console.log("cycleData" + JSON.stringify(cycleData));

            if (!cycleData || Object.keys(cycleData).length === 0) {
                showCensusGrid(false);
                return; // ? Prevents unnecessary processing
            }

            setFacilityData(cycleData);

            let cycleResults = [];

            for (let cyclekey in cycleData) {
                //console.log("cycleri " + cyclekey);
                cycleResults.push({
                    key: moment(cyclekey, 'YYYY-MM-DD').format('MM/DD/YY'),
                    value: cyclekey
                })
            }

            setCycleDateOptions([
                { key: 'Select Cycledate', value: '' },
                ...cycleResults
            ]);

            setInitialCycleDate(cycleResults[0].value);
            setCycleDate(cycleResults[0].value, cycleData);
        } catch (error) {
        }

    }

    const setCycleDate = (cycleDate, facilitydata) => {
        if (!facilitydata || !facilitydata[cycleDate]) return;
        //console.log("facilitydata " + JSON.stringify(facilitydata));

        let singleCycleData = facilitydata[cycleDate]
        let cycleInfoItems = [];
        let cycleSummaryItems = [];
        let deliverydateItems = [];
        let patientlist;
        let isDeliveryDisabled = false;
        let deliveryWarningText = "";

        //console.log(JSON.stringify(singleCycleData));

        singleCycleData.forEach((item) => {
            let deliverydate = item.delivery_date != null ? moment(item.delivery_date, 'YYYY-MM-DD').format('MM/DD/YY') : "";
            setModalDate(deliverydate);
            let deliverytime = item.delivery_time != null ? item.delivery_time : "";
            let updatedby = item.delivery_date_facility_updated_by != null ? item.delivery_date_facility_updated_by : "";
            let updatedon = item.delivery_date_facility_updated_on != null ? item.delivery_date_facility_updated_on : "";
            setSelectedIndex(item?.upcoming_cycles);
            let deliveryChangeBefore = item.delivery_date_change_before;
            let today = moment().format('YYYY-MM-DD');

            if (deliveryChangeBefore && today > deliveryChangeBefore) {
                isDeliveryDisabled = true;
                deliveryWarningText = "Please contact pharmacy to change delivery date.";
            }

            cycleInfoItems.push(
                {
                    key: "Delivery Date and Time:",
                    value: deliverydate.concat(" " + deliverytime)
                },
                {
                    key: "Reason:",
                    value: item.delivery_date_pharmacy_update_reason != null ? item.delivery_date_pharmacy_update_reason : "-"
                },
                {
                    key: "Changed:",
                    value : item.delivery_date_pharmacy_updated_by ? (item.delivery_date_pharmacy_updated_by + "on"+ moment(item.delivery_date_pharmacy_updated_on)) : "-"
                },
                {
                    key: "Cycle starts in:",
                    value: item.days_left != null ? item.days_left + " Days" : "-"
                }
            )

            if (item.census_completed_by == null)
                setCensusCompleted(false);
            else {
                setCensusCompletedDet(item.census_completed_by + " " + moment(item.census_completed_on, 'YYYY-MM-DD hh:mm:ss').format('MM/DD/YY hh:mm A'))
                setCensusCompleted(true);
            }

            deliverydateItems = item.delivery_date_change_options;
            patientlist = item.patients;

            let patientcount = 0;

            for (let patient in patientlist) {
                patientcount = patientcount + 1;
            }

            if (localStorage.getItem("roleId") < 4) {

                cycleSummaryItems.push(
                    {
                        key: "Cycle: ",
                        value: item.cycle_start_date
                    },
                    {
                        key: "Robot: ",
                        value: "Dosis"
                    },
                    {
                        key: "Patients: ",
                        value: patientcount
                    },
                    {
                        key: "Total rxs: ",
                        value: ""
                    },
                    {
                        key: "Filled rxs: ",
                        value: ""
                    },
                    {
                        key: "Fill: ",
                        value: ""
                    },
                    {
                        key: "Check: ",
                        value: ""
                    },
                    {
                        key: "Not Filled Rxs: ",
                        value: ""
                    },
                    {
                        key: "On Hold Rxs: ",
                        value: ""
                    }
                )
            }

            setLocalDeliveryDate(item.delivery_date_change_options);
            setCycleId(item.id);
        });

        setCycleSummaryItems(cycleSummaryItems)
        setCycleInfoItems(cycleInfoItems);
        setCycleDateFlag(true);
        SetPatientDetails(patientlist);
        showCensusGrid(true);

        if (localStorage.getItem('roleId') > 3) {
            setDisableDelivery(isDeliveryDisabled);
            setDeliveryWarning(deliveryWarningText);
        }
    }

    const handleDelivery = (e) => {

        setDeliveryDate(localdeliverydate);
        if (localStorage.getItem("roleId") < 4) {
            setShowModal(true);
        }
        else {
            if (!censuscompleted)
                toast.error("Please complete census before setting Delivery Date");
            else
                setShowModal(true);
        }

    }

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Cycle-Details', href: paths.cycleDelivery(), isActive: true }
    ];

    return (
        <>
            <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
            {showDatesModal && <CycleDatesModal show = {showDatesModal} setShow = {setShowDatesModal} dateArray = {selectedIndex} />}
            <Row className="g-3">
                <Col xs="9" className="card">
                    <Card className='shadow-none'>
                        <FalconCardHeader
                            title="Cycle Details"
                            titleTag="h6"
                            className="py-2"
                            light
                        />
                        <Card.Body
                            className="h-100"
                            as={Flex}
                            direction="column"
                            alignItems="between"
                        >
                            <Row className="align-items-end">
                                <Col md={6} >
                                    <Form.Group>
                                        <Form.Label className="required">Facility</Form.Label>
                                        <Select
                                            name="facility"
                                            closeMenuOnSelect={true}
                                            placeholder='Select Facility'
                                            className="form-control-sm"
                                            classNamePrefix="react-select"
                                            options={options}
                                            value={selectedFacility}
                                            getOptionLabel={option => option.key}
                                            getOptionValue={option => option.value}
                                            onChange={(selectedOption) => {
                                                setSelectedFacility(selectedOption);
                                                handleFacilityFieldChange(selectedOption);
                                            }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: "#ced4da",
                                                    backgroundColor: "white",
                                                    height: "calc(1.5em + 0.75rem + 2px)",
                                                    minHeight: "38px",
                                                    boxShadow: "none",
                                                    "&:hover": { borderColor: "#80bdff" }
                                                }),
                                                option: (provided, { isFocused, isSelected }) => ({
                                                    ...provided,
                                                    backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                                    color: isSelected ? "#212529 !important" : "#212529 !important",
                                                    cursor: "pointer",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#212529",
                                                }),
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                    <div className="d-flex justify-content-between align-items-center">
                                            <Form.Label className="required mb-0">Cycle</Form.Label>
                                            {cycledateflag && (
                                                <span
                                                    onClick={() => setShowDatesModal(true)}
                                                    style={{ fontSize: '0.8rem', color: '#0d6efd', cursor: 'pointer' }}
                                                >
                                                    Upcoming cycles
                                                </span>
                                            )}
                                        </div>
                                        <Form.Select
                                            size="sm"
                                            name="cycle"
                                            onChange={handleCycleDate}
                                        >
                                            {cycledateoptions.map((option) => {
                                                let isCycleSelected = false;
                                                if (option.value == initialCycleDate) {
                                                    isCycleSelected = "selected";
                                                }
                                                return (
                                                    <option key={option.value} value={option.value} selected={isCycleSelected}>
                                                        {option.key}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="my-3 mx-1">
                                {cycledateflag && (
                                    <Col className='px-0'>
                                        {/* <Card> */}
                                        {/* <Card.Body className="py-5 py-sm-3"> */}
                                        <Row className="g-5 g-sm-0">
                                            {cycleinfoitems.map((item, index) => (
                                                <Col sm={3} key={item.key}>
                                                    <div
                                                        className={classNames({
                                                            'border-sm-end border-500': index !== 3
                                                        })}
                                                    >
                                                        <div className="text-center">
                                                            <h6 className="text-700">{item.key}</h6>
                                                            <h5 className="fw-normal text-700">{item.value}</h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                        {/* </Card.Body> */}
                                        {/* </Card> */}
                                    </Col>
                                )}
                            </Row>
                            {cycledateflag && (
                                <Row>
                                    <Col className='col-5'>
                                        <Button variant="primary" disabled={disableDelivery} className='w-100' onClick={handleDelivery}>
                                            Set Delivery
                                        </Button>
                                    </Col>

                                    <Col className='text-center'>
                                        {!censuscompleted && (
                                            <h5>Census has not been completed</h5>
                                        )}
                                        {censuscompleted && (
                                            <h5>Census has been completed by {censuscompleteddet} </h5>
                                        )}
                                    </Col>
                                </Row>
                            )}
                            {disableDelivery && (
                                <Row className="mt-2">
                                    <Col className="text-center">
                                        <h5 className="text-danger">{deliveryWarning}</h5>
                                    </Col>
                                </Row>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3">
                    {(localStorage.getItem("roleId") >= 4) && (
                        <CycleChecklist />
                    )}
                    {(localStorage.getItem("roleId") < 4) && (
                        <>
                            {(cycledateflag) && (
                                <Card className="h-100">

                                    <FalconCardHeader
                                        title="Cycle Summary"
                                        titleTag="h6"
                                        className="py-2"
                                        light
                                    />
                                    <Card.Body
                                        className="h-100"
                                        as={Flex}
                                        direction="column"
                                        alignItems="between"
                                    >
                                        <ul className="fa-ul  m-0">
                                            {cyclesummaryitems.map(item => (
                                                <li key={item.key}>
                                                    <h6 className="text-700">
                                                        {item.key} {item.value}
                                                    </h6>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card.Body>

                                </Card>
                            )}
                        </>
                    )}
                </Col>
            </Row>
            <br />
            {censusGrid ? (
                <CycleTabDetails patientdetails={patientdetails} saveFlag = {disableDelivery} cycleid={cycleId}/>
            ) :
                <Card>
                    <p className='text-danger w-100 text-center p-4 mb-0 fw-bold'>There is no data available for this facility, Please select another one </p>
                </Card>}
            <DeliveryModal show={showModal} setShow={setShowModal} deliverydate={deliverydate} cycleid={cycleId} selectedDate={modalDate} />
        </>
    );
};

export default CycleDetails;

