import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  marketShare,
  totalOrder,
  weeklySalesData,
  weather
} from 'data/dashboard/default';

import axios from "axios";
import { useEffect } from 'react'
import WeeklySales from './WeeklySales';
import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import Weather from './Weather';

const Dashboard = () => {


  useEffect(() => {

    async function setInitialData() {

      //console.log("Setting Initial Data");

      let tokenStr = localStorage.getItem("accessToken");
      let drugResults = [];
      let groupId = localStorage.getItem("groupId") ? localStorage.getItem("groupId") : "";

      //set group details
      if (localStorage.getItem("roleId") < 4) {

        //prepopulate group data      
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/facilities/get-all-facility`, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
          .then((res) => {

            //console.log("res.data " + JSON.stringify(res.data.data));

            let objGrpData = res.data.data;
            let facilityResults = [];
            let groupResults = [];

            for (let index in objGrpData) {

              let grpData = objGrpData[index];
              let facilities = grpData.facilities;

              groupResults.push({
                key : grpData.name,
                value : grpData.id
              })

              facilities.forEach((value) => {
                facilityResults.push({
                  key: value.facility_name,
                  value: value.id,
                  group_id : value.group_id
                });
              });

              //console.log("GrpData " + JSON.stringify(grpData));
            };

            // sort group data by key
            facilityResults.sort((a, b) => a.key.localeCompare(b.key));
            groupResults.sort((a, b) => a.key.localeCompare(b.key));
            localStorage.setItem("facilitiesData", JSON.stringify(facilityResults));
            localStorage.setItem("groupsData", JSON.stringify(groupResults));

          });
      }
      else {
        //prepopulate facility data for a group id
        let results = [];

        const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}` + "/facilities/list-facility/" + groupId, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        //console.log("Facilties Data Set Data " + JSON.stringify(data.data) + groupId);

        let objData = data.data;

        objData.forEach((value) => {
          //groupId = value.group_id;
          results.push({
            key: value.facility_name,
            value: value.id
          });
        });

        // sort facility data by key
        results.sort((a, b) => a.key.localeCompare(b.key));

        //console.log("Facilities Data ", objData);
        //localStorage.setItem("groupId", groupId);

        localStorage.setItem("facilitiesData", JSON.stringify(results));

      }

      //prepopulate drugs data
      // await axios.get(`${process.env.REACT_APP_SERVER_URL}/drugs/list-drugs/`, { headers: { 'Authorization': `Bearer ${tokenStr}` } })
      //   .then((res) => {

      //     let objDrugData = res.data.drugs.data;

      //     //localStorage.setItem("ogdrugData", JSON.stringify(objDrugData));

      //     objDrugData.forEach((value) => {
      //       let drugBrand = value.brand_drug != null ? value.brand_drug : "";
      //       let drugNdc = value.ndc != null ? value.ndc : "";
      //       let drugForm = value.drug_form != null ? value.drug_form : "";
      //       let drugGeneric = value.generic != null ? value.generic : "";

      //       drugResults.push({
      //         key: value.drug,
      //         value: value.id + "|" + value.drug + "|" + drugBrand + "|" + drugNdc + "|" + drugForm + "|" + drugGeneric,
      //       });
      //     });

      //     //console.log("DrugResults " + drugResults);
      //     //localStorage.setItem("drugData", JSON.stringify(drugResults)); NEED TO OPTIMIZE FURTHER

      //   });

    }

    setInitialData();

  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <Weather data={weather} />
        </Col>
      </Row>

      {/*<Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects data={runningProjects} />
        </Col>
        <Col lg={6}>
          <TotalSales data={totalSales} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6} xl={7} xxl={8}>
          <StorageStatus className="h-lg-100" data={storageStatus} />
        </Col>
        <Col lg={6} xl={5} xxl={4}>
          <SpaceWarning />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <BestSellingProducts products={products} />
        </Col>
        <Col lg={5} xl={4}>
          <SharedFiles files={files} className="h-lg-100" />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6} xxl={3}>
          <ActiveUsers className="h-100" users={users} />
        </Col>
        <Col sm={6} xxl={3} className="order-xxl-1">
          <BandwidthSaved />
        </Col>
        <Col xxl={6}>
          <TopProducts data={topProducts} className="h-100" />
        </Col>
      </Row>*/}
    </>
  );
};

export default Dashboard;
