import React, { useState } from 'react';
import { Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import MedSplInstrForm from './MedSplInstrForm.js';
import MedSplInstrTable from './MedSplInstrTable.js';
import Flex from 'components/common/Flex';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb.js';
import paths from 'routes/paths.js';

const NavItem = ({ item }) => {
    return (
        <Nav.Item as="li">
            <Nav.Link
                className="mb-0 py-3 cursor-pointer"
                eventKey={item.toLowerCase()}>
                {item}
            </Nav.Link>
        </Nav.Item>
    );
};


const MedSplInstr = () => {

    const [navItems] = useState(['Active', 'Inactive']);

    const [trigger, setTrigger] = useState(0);
    const [edittrigger, setEditTrigger] = useState(0);
    const [medsplval, setMedSplVal] = useState('');
    //let allergy_val = "";

    const callAnotherChildMethod = () => {
        //console.log("Getting Trigger " + trigger);
        setTrigger((trigger) => trigger + 1);
    };

    const callAnotherEditChildMethod = (editValue) => {
        //console.log("Getting Edit Trigger " + edittrigger + editValue);
        window.scrollTo(0, 0);
        setEditTrigger((edittrigger) => edittrigger + 1);
        setMedSplVal(editValue);
    };

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'Drugs-Special-Instructions', href: paths.medGroup, isActive: true }
    ];

    return (
        <>
        <CommonBreadCrumb breadcrumbItems = {breadcrumbItems} />
        <Row className="g-3">
            <Col xs={12}>
                <Card className="mt-3">
                    <Card.Body>
                        <MedSplInstrForm edittrigger={edittrigger} onCallParent={callAnotherChildMethod} medsplvalue={medsplval} />
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={12}>
                <Card className="mt-3">
                    <Tab.Container id="audience-tab" defaultActiveKey="active">
                        <Card.Header
                            as={Flex}
                            justifyContent="between"
                            alignItems="center"
                            className="ps-0 py-0 border-bottom">
                            <Nav
                                as="ul"
                                className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret">
                                {navItems.map(item => (
                                    <NavItem item={item} key={item} />
                                ))}
                            </Nav>
                            <CardDropdown />
                        </Card.Header>

                        <Card.Body>
                            <Row className="g-1">
                                <Col xxl={12}>
                                    <Tab.Content>
                                        <Tab.Pane unmountOnExit eventKey="active">
                                            <MedSplInstrTable active_status="active" trigger={trigger} onCallEditParent={callAnotherEditChildMethod} />
                                        </Tab.Pane>
                                        <Tab.Pane unmountOnExit eventKey="inactive">
                                            <MedSplInstrTable active_status="inactive" trigger={trigger} onCallEditParent={callAnotherEditChildMethod} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </Col>
        </Row>
        </>
    );
};

export default MedSplInstr;
