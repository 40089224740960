import PropTypes from 'prop-types';
import React, { useState, useRef, createRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const OtpForm = ({ hasLabel, layout }) => {

    const OTPBOX = 6;

    const [formData, setFormData] = useState({
        otp0: '',
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otp5: '',
    });

    const [otp, setOtp] = useState(new Array(OTPBOX).fill(""));
    const elementsRef = useRef(otp.map(() => createRef()));

    const navigate = useNavigate();

    const [spinner, showSpinner] = useState(false);

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        showSpinner(true);

        let otpValue = formData.otp0 + formData.otp1 + formData.otp2 + formData.otp3 + formData.otp4 + formData.otp5;
        //console.log(otpValue);

        fetch(`${process.env.REACT_APP_SERVER_URL}` + "/users/verify-email",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'hash': localStorage.getItem("hash"), 'otp': otpValue })
            })
            .then(response => response.json())
            .then(function (data) {
                const items = data;
                //console.log("OtpForm " + items.status);
                if (items.status) {
                    toast.success('Email Verified Please login again', {
                        theme: 'colored'
                    });
                    navigate("/");
                }
                else {
                    showSpinner(false);
                    toast.error('Incorrect Otp', {
                        theme: 'colored'
                    });
                }
            })
    };

    const handleFieldChange = e => {
        if (e.target.value >= 0 && e.target.value <= 9) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
        else {
            e.target.value = "";
        }
    };

    const handleNextInput = e => {

        if (e.key >= 0 && e.key <= 9) {

            let targetName = e.target.name;
            let otpTextSeqNo = Number(targetName.substr(targetName.length - 1));
            if (otpTextSeqNo < OTPBOX - 1) {
                otpTextSeqNo = otpTextSeqNo + 1;
                elementsRef.current[otpTextSeqNo].current.focus();
                elementsRef.current[otpTextSeqNo].current.select();
            }
            else {
                elementsRef.current[0].current.focus();
                elementsRef.current[0].current.select();
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mb-1">
                {otp.map((digit, index) => (
                    <Col xs="2">
                        <Form.Control
                            name={"otp" + index}
                            type="text"
                            key={"otp" + index}
                            className="p-1 text-center"
                            onChange={handleFieldChange}
                            onKeyUp={(e) => handleNextInput(e)}
                            ref={elementsRef.current[index]}
                            maxLength="1"
                        />
                    </Col>
                ))}
            </Row>
            <Row className="mb-3">
                <Form.Group>
                    {spinner && (
                        <Button variant="primary" disabled className="mt-3 w-100">
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="p-2" style={{ "verticalAlign": "3px" }}>Loading...</span>
                        </Button>
                    )}
                    {!spinner && (
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-3 w-100"
                            disabled={!formData.otp0 || !formData.otp1 || !formData.otp2 || !formData.otp3 || !formData.otp4 || !formData.otp5}>
                            Verify Otp
                        </Button>
                    )}
                </Form.Group>
            </Row>
        </Form >
    );
};

OtpForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

OtpForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default OtpForm;
