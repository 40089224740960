import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import OrderDetailModal from './OrderHistoryDetailModal';
import Select from "react-select";
import OrderDetailModalParent from './OrderHistoryModalParent';
import moment from 'moment';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import { useAppContext } from 'providers/AppProvider';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import OrderHistoryModalParent from './OrderHistoryModalParent';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import DatePicker from 'react-datepicker';
import SetMastersDetails from 'components/common/SetMastersDetails';
import RemoveMasters from 'components/common/RemoveMasters';

const OrderHistory = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(true);
  const facilityList = JSON.parse(localStorage.getItem("facilitiesData"));
  const [options] = useState(facilityList);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(facilityList[0]);
  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').format('MM/DD/YYYY'),
    endDate: moment().endOf('day').format('MM/DD/YYYY'),
    facility: facilityList[0].value,
  });
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useAppContext();


  const columns = [
    {
      accessorKey: 'patient',
      header: 'Patient',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'ordertypetable',
      header: 'Order Type',
      meta: {
        headerProps: { className: 'text-900 text-center' },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'drug',
      header: 'Drug',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'qty',
      header: 'Qty',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'createdby',
      header: 'Created By',
      meta: {
        headerProps: { className: 'text-900 text-start' }
      }
    },
    {
      accessorKey: 'createddate',
      header: 'Created Date',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'deliverydate',
      header: 'Delivery Date',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'deliverynumber',
      header: 'Delivery No',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'orderstatusTable',
      header: 'Order Status',
      meta: {
        headerProps: { className: 'text-900 text-center' },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'signature',
      header: 'Signature',
      meta: {
        headerProps: { className: 'text-900 text-start' },
        cellProps: { className: 'text-start' }
      }
    },
    {
      accessorKey: 'prescription',
      header: 'Prescription',
      meta: {
        headerProps: { className: 'text-900 text-start' }
      }
    },
    {
      accessorKey: 'details',
      header: 'Details',
      meta: {
        headerProps: { className: 'text-900 text-center' },
        cellProps: { className: 'text-center fw-bold' }
      },
      cell: ({ row: { original } }) => {
        return (
          <Link onClick={() => { setShow(true) }} state={{ original }}>
            <FontAwesomeIcon icon="eye" className={original?.order_from == 2 ? "text-danger" : "text-primary"} />
          </Link>
        )
      }
    }
  ]

  const fetchTable = async () => {
    setIsLoading(true);
    setApiError(false);
    let sendingObj = {
      "facility_id": filters.facility,
      from_date: filters.startDate,
      to_date: filters.endDate
    }
    let tokenStr = localStorage.getItem("accessToken");

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/order-history/", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

      if (data.orders.length === 0) {
        setFilteredData([]);
        setIsLoading(false);
        return;
      }

      let orderType = JSON.parse(localStorage.getItem("order_types"));
      let orderStatus = JSON.parse(localStorage.getItem("order_status_facility"));
      let objData = data.orders
      let results = []
      objData.map((iterator) => {
        results.push({
          patient: iterator.patient_lastname && iterator.patient_firstname
            ? `${iterator.patient_lastname}, ${iterator.patient_firstname}`
            : "-",
          facilityid: iterator.facility_id || "-",
          ordertype: iterator.order_type || "-",
          ordertypetable: iterator.order_from == 1
            ? (orderType[iterator.order_type] || "New Patient")
            : (iterator.order_type || "-"),
          deliverydate: iterator.delivery_date
            ? moment(iterator.delivery_date).format('MM/DD/YY')
            : "-",
          reason: iterator.discarded_reason || "-",
          refillrequest: iterator.refill_request || "-",
          createdby: iterator.created_by_username || "-",
          createddate: iterator.created
            ? moment(iterator.created).format('MM/DD/YY')
            : "-",
          orderstatusTable: orderStatus[iterator.order_status] || iterator.order_status || "-",
          orderstatus: iterator.order_status || "-",
          rxnumber: iterator.pharmacy_order_id || "-",
          qty: parseFloat(iterator.drug_qty).toFixed(2) || "-",
          drug: iterator.drug || "-",
          notesObj: iterator.notes || "-",
          deliverynumber: iterator.delivery_no || "-",
          direction: iterator.directions || "-",
          order_from: iterator.order_from || "-",
          prescription: "-",
          createdDateRaw: iterator.created ? new Date(iterator.created) : null,
          documents: iterator.documents
        });

        results.sort((a, b) => (b.createdDateRaw || 0) - (a.createdDateRaw || 0));
        setData(results);
        setFilteredData(results);
      })
    } catch (error) {
      setApiError(true);
    } finally {
      setIsLoading(false);
    }
  }

  const searchByDate = async () => {
    try {
      fetchTable()
    } catch (error) {
      console.error('Error fetching data by date:', error);
      toast.error('Failed to fetch data by date');
    }
  };

  const table = useAdvanceTable({
    data: filteredData,
    columns,
    selection: false,
    sortable: true,
    pagination: true
  });

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);
    fetchData();
    SetMastersDetails();

    return () => {
      setConfig('isFluid', false);
      setConfig('isNavbarVerticalCollapsed', false);
      RemoveMasters();
    }
  }, []);

  const fetchData = async () => {
    try {
      // let sendingObj = {
      //   "masters": ["order_types", "order_status", "delivery_types"]
      // }
      // let tokenStr = localStorage.getItem("accessToken");
      // const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/orders/fetch-masters", sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
      // let objData = data.masters
      // let orderTypesResult = objData.order_types.reduce((obj, item) => {
      //   obj[item.id] = item.order_type;
      //   return obj;
      // }, {});

      // let orderStatusResult = objData.order_status.reduce((obj, item) => {
      //   obj[item.id] = item.order_status;
      //   return obj;
      // }, {});

      // let orderStatusResultInternal = objData.order_status.map(item => ({
      //   key: item.order_status,
      //   value: item.id
      // }));
      // localStorage.setItem("order_status_internal", JSON.stringify(orderStatusResultInternal))
      // localStorage.setItem("order_status", JSON.stringify(orderStatusResult))
      // localStorage.setItem("order_types", JSON.stringify(orderTypesResult))

      fetchTable();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters({
      ...filters,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleFieldChange = (e) => {
    setSelectedFacility(e)
    setFilters({
      ...filters,
      "facility": e.value
    });
  };

  async function handleSearch(e) {
    e.preventDefault();
    if (filters.startDate && filters.endDate) {
      await searchByDate();
    }
  };

  const validateExpiryDate = date => {
    if (moment(date) > moment(filters.startDate)) {
        handleInputChange({ target: { name: 'endDate', value: moment(date).format("MM/DD/YYYY") } })
    } else {
        toast.error("End date should be greater than Start Date");
    }
}

  const breadcrumbItems = [
    { name: 'Dashboard', href: '/main', isActive: false },
    { name: 'Order', href: '#', isActive: true },
    { name: 'Order History', href: "#", isActive: true }
  ];

  return (
    <>
      <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
      <OrderHistoryModalParent show={show} handleClose={() => setShow(false)} />
      <Card className="p-3">
        <h4 className="mb-3">Order History</h4>
        <div className="border rounded pb-4  p-3 mb-3">
          <Row className="mb-3">
            <Col md={3}>
              <Form.Group>
                <Form.Label>Facility</Form.Label>
                <Select
                  name="facility"
                  closeMenuOnSelect={true}
                  placeholder='Select Facility'
                  classNamePrefix="react-select"
                  options={options}
                  value={selectedFacility}
                  getOptionLabel={option => option.key}
                  getOptionValue={option => option.value}
                  onChange={handleFieldChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: "#ced4da",
                      boxShadow: "none",
                      "&:hover": { borderColor: "#80bdff" },
                    }),
                    option: (provided, { isFocused, isSelected }) => ({
                      ...provided,
                      backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                      color: isSelected ? "#212529 !important" : "#212529 !important",
                      cursor: "pointer",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#212529",
                    }),
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Start Date:</Form.Label>
                <DatePicker
                  placeholderText="mm/dd/yy"
                  className="form-control"

                  selected={filters.startDate ? moment(filters.startDate).format("MM/DD/YYYY") : null}
                  onChange={(date) =>
                    handleInputChange({ target: { name: 'startDate', value: moment(date).format("MM/DD/YYYY") } })
                  }
                  dateFormat="MM/dd/yy"
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>End Date:</Form.Label>
                <DatePicker
                  placeholderText="mm/dd/yy"
                  className="form-control"

                  selected={filters.endDate ? moment(filters.endDate).format("MM/DD/YYYY") : null}
                  onChange={(date) =>
                    validateExpiryDate(date)
                  }
                  dateFormat="MM/dd/yy"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="d-flex align-items-end">
              <Button variant="primary" onClick={handleSearch} className="me-2">
                Search
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
      {isLoading ? (
        <ShimmerUI />
      ) : apiError ? (
        <Alert variant="danger" className="text-center fw-bold">
          Failed to fetch order history. Please try again later.
        </Alert>
      ) : filteredData.length === 0 ? (
        <Alert variant="info" className="text-center fw-bold">
          No order history found for the selected facility and date range.
        </Alert>
      ) : (
        <Card className="p-3">
          <AdvanceTableProvider {...table}>
            <Row>
              <Col xs="4" className="mb-2 view-patient-search-holder">
                <AdvanceTableSearchBox placeholder="Search..." />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <AdvanceTable
                  headerClassName="bg-200 text-nowrap align-middle text-center"
                  rowClassName="text-nowrap align-middle text-center"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Card.Footer>
                <div className="mt-3">
                  <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
                </div>
              </Card.Footer>
            </Row>
          </AdvanceTableProvider>
        </Card>
      )}
    </>
  );
};

export default OrderHistory;
