import useAdvanceTable from 'hooks/useAdvanceTable';
import React, { useEffect, useState } from 'react'
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserNotifcationTableBody from './UserNotifcationTableBody';

const UserNotifcation = () => {
    const [data, setData] = useState([]);
    const [selectedBottomFacility, setSelectedBottomFacility] = useState(null);
    const [filteredFacilityResult, setFacilityResult] = useState([]);
    const [filteredNotificationResult, setNotificationResult] = useState([]);
    const [selectedType, setSelectedType] = useState(""); // Selected type
    const [selectedFacilities, setSelectedFacilities] = useState([]); // Selected facilities
    const [typeOptions, setTypeOptions] = useState([]);
    const [flag, setFlag] = useState(false)

    const facilityList = JSON.parse(localStorage.getItem("facilitiesData"));
    const handleTypeChange = (event) => {
        const selectedNotificationId = parseInt(event.target.value);
        setSelectedType(selectedNotificationId);
        const facilitiesForNotification = filteredNotificationResult[selectedNotificationId] || [];
        setSelectedFacilities(facilitiesForNotification);
    };

    useEffect(() => {
        fetchFacilityData()
        fetchUserFacilityData()
        if (flag) {
            const initialFacilityId = facilityList[0].value;
            handleBottomFacilityChange({ target: { value: initialFacilityId } });
        }
    }, [flag])


    async function fetchUserFacilityData() {
        let tokenStr = localStorage.getItem("accessToken");
        let listUserNotificationsurl = `${process.env.REACT_APP_SERVER_URL}` + '/users/list-user-notifications'
        const { data } = await axios.get(listUserNotificationsurl, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        const facilityResult = {};
        const notificationResult = {};
        const userObjData = data.data;
        userObjData.forEach(item => {
            const facilityId = item.facility_id;
            const notificationId = item.notification_id;
            if (!facilityResult[facilityId]) {
                facilityResult[facilityId] = [];
            }
            if (!notificationResult[notificationId]) {
                notificationResult[notificationId] = [];
            }

            if (!facilityResult[facilityId].includes(notificationId)) {
                facilityResult[facilityId].push(notificationId);
            }
            if (!notificationResult[notificationId].includes(facilityId)) {
                notificationResult[notificationId].push(facilityId);
            }
        });
        setNotificationResult(notificationResult)
        setFacilityResult(facilityResult)
        setFlag(true)

    }
    async function fetchFacilityData() {
        let tokenStr = localStorage.getItem("accessToken");
        let listNotificationsurl = `${process.env.REACT_APP_SERVER_URL}` + '/users/list-notifications'
        const { data } = await axios.get(listNotificationsurl, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        let dataObj = data.data;
        let listDropDown = [{ key: 'Select Type', value: '' }];
        dataObj.map((value) => {
            listDropDown.push({
                key: value.notification_name,
                value: value.id
            })
            setTypeOptions(listDropDown)
        })
    }

    const handleFacilityChange = (event) => {
        const options = event.target.options;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(options[i].value);
            }
        }
        setSelectedFacilities(selected);
    };

    const handleBottomFacilityChange = (event) => {
        const selectedFacilityId = parseInt(event.target.value);

        const associatedNotifications =
            filteredFacilityResult[selectedFacilityId] || [];

        const tableData = typeOptions
            .filter(notification => notification.value !== '')
            .map(notification => ({
                type: notification.key,
                status: associatedNotifications.includes(notification.value) ? 1 : 0,
                id: notification.value
            }));

        setData(tableData);
        setSelectedBottomFacility(selectedFacilityId)
    };

    const handleSave = async () => {
        if (!selectedType || selectedFacilities.length === 0) {
            toast.error("Please select a type and at least one facility.", {
                theme: 'colored'
            });
            return;
        }
        const notification = typeOptions.find(option => option.value === selectedType);
        const preselectedFacilities = (filteredNotificationResult[selectedType] || []).map(String);
        const currentSelectedFacilities = selectedFacilities.map(String);

        const activateFacilities = currentSelectedFacilities
            .filter(facilityId => !preselectedFacilities.includes(facilityId))
            .map(facilityId => ({
                facility_id: parseInt(facilityId),
                notification_id: notification.value,
                is_active: 1
            }));

        const deselectFacilities = preselectedFacilities
            .filter(facilityId => !currentSelectedFacilities.includes(facilityId))
            .map(facilityId => ({
                facility_id: parseInt(facilityId),
                notification_id: notification.value,
                is_active: 0
            }));

        const notifications = [...activateFacilities, ...deselectFacilities];
        let sendingObj = { notifications };
        let tokenStr = localStorage.getItem("accessToken");
        let optinNotifications = `${process.env.REACT_APP_SERVER_URL}` + '/users/optins-notifications'
        const { data } = await axios.post(optinNotifications, sendingObj, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
        if (data.status) {
            toast.success("Notification Settings Updated Successfully", {
                theme: 'colored'
            })

            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            toast.error("Failed to save notification settings.", {
                theme: 'colored'
            });
        }

    }
    return (
        <>
            {typeOptions?.length > 0 ? <Card className="mt-3">
                <Card.Body>
                    <h4>Notification Settings</h4>
                    <Row className="mb-3">
                        <Form.Group>
                            <Form.Label className='required'>Type</Form.Label>
                            <Form.Select value={selectedType} onChange={handleTypeChange}>
                                {typeOptions.map((type) => (
                                    <option key={type.value} value={type.value}>
                                        {type.key}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group>
                            <Form.Label className='required'>Facility</Form.Label>
                            <Form.Control
                                as="select"
                                multiple
                                value={selectedFacilities}
                                onChange={handleFacilityChange}
                                style={{ height: "200px" }}
                            >
                                {facilityList.map((facility) => (
                                    <option key={facility.value} value={facility.value}>
                                        {facility.key}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Label>Program (Press Ctrl to select multiple facilities)</Form.Label>
                        </Form.Group>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Button color="primary"
                            size="md"
                            className="mt-1 w-25" onClick={handleSave}>
                            Save
                        </Button>
                    </Row>
                </Card.Body>
                <br></br>
                <Card>
                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group>
                                <Form.Label className='required'>Facility</Form.Label>
                                <Form.Select onChange={handleBottomFacilityChange}>
                                    {facilityList.map((type) => (
                                        <option key={type.value} value={type.value}>
                                            {type.key}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row>
                            {selectedBottomFacility != null ? <UserNotifcationTableBody data={data} selectedFacilityId={selectedBottomFacility} /> : []}
                        </Row>
                    </Card.Body>
                </Card>
            </Card> : []}
        </>
    )
}

export default UserNotifcation