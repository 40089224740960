import React, { useState, useEffect } from 'react';
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import paths from 'routes/paths';
import ResetPasswordModal from '../add-facility-staff/ResetPasswordModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import ShimmerUI from 'components/ShimmerUI/PatientShimmer';
import CommonBreadCrumb from 'components/commonbreadcrumb/CommonBreadCrumb';
import { toast } from 'react-toastify';
import Select from "react-select";

const ViewFacilityStaff = () => {
    //const [facility] = useState(facilityStaffData);
    const [facilityStaff, setFacilityStaff] = useState([]);
    const [options, setOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [tableFlag, setTableFlag] = useState(true);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const navigate = useNavigate();
    const columns = [
        {
            accessorKey: 'name',
            header: 'Username',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            },
            cell: ({ row: { original } }) => {
                const viewfacilitystaffprofile = paths.viewfacilitystaffprofile
                return <Link to={{ pathname: `${viewfacilitystaffprofile}/${original.id}` }} state={{
                    userid: original.id
                }} >
                    {original.name}</Link>
            }
        },
        {
            accessorKey: 'fullname',
            header: 'Fullname',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'role',
            header: 'Role',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: 'mobile',
            header: 'Mobile',
            meta: {
                headerProps: { className: 'ps-5 text-900' }
            }
        },
        {
            accessorKey: 'email',
            header: 'Email',
            meta: {
                headerProps: { className: 'ps-5 text-900' }
            },
            cell: ({ row: { original } }) => {
                const isInvalidEmail = moment().diff(moment(original.email_last_confirm), 'days') >= 7;
                return (
                    <OverlayTrigger
                        placement="top"
                        overlay={isInvalidEmail ? <Tooltip style={{ position: 'fixed' }}>{"Email Address is/about expired"}</Tooltip> : <></>}
                    >
                        <span
                            style={{
                                color: isInvalidEmail ? 'red' : 'inherit',
                                cursor: isInvalidEmail ? 'pointer' : 'default',
                            }}
                        >
                            {original.email}
                        </span>
                    </OverlayTrigger>
                );
            }
        },
        {
            accessorKey: 'active',
            header: 'Active',
            meta: {
                headerProps: { className: 'text-900' }
            }
        }
        ,
        {
            accessorKey: 'lastlogin',
            header: 'Last Login',
            meta: {
                headerProps: { className: 'text-900' }
            }
        },
        {
            accessorKey: '',
            header: 'Action',
            meta: {
                headerProps: { className: 'pe-1 text-900' }
            },
            cell: ({ row: { original } }) => {
                return <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip style={{ position: 'fixed' }}>{"Reset Password"}</Tooltip>}>
                    <Link onClick={() => handleResetModal(original.id)}>
                        <FontAwesomeIcon icon="pencil-alt" className="fs-11 me-2" />
                    </Link>
                </OverlayTrigger>
            }
        },
    ];

    const handleResetModal = (userid) => {
        //console.log("PatientFormName " + event.target.id)
        localStorage.setItem("editUserId", userid);
        setShowModal(true);
    };

    useEffect(() => {

        fetchFacilityData("");
    }, []);

    async function fetchFacilityData(facilityid) {

        try {
            let tokenStr = localStorage.getItem("accessToken");
            const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}` + "/users/list", {}, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
            const results = []

            //console.log("Facilties Staff Status " + JSON.stringify(data));
            //const objData = data.data;
            //let groupId = "";
            const roleMapping = {
                5: "Facility Staff",
                4: "Group Admin",
                3: "PHARMACY Staff",
                2: "Pharmacy Admin",
                1: "System Admin"
            };

            data.forEach((value) => {
                if (facilityid != "") {
                    if (facilityid == value.facility_id) {
                        //groupId = value.group_id;
                        results.push({
                            id: value.id,
                            name: value.username,
                            fullname: value.first_name + ' ' + value.last_name,
                            role: roleMapping[value.role_id],
                            mobile: value.phone,
                            email: value.email,
                            active: value.is_active == 1 ? "Yes" : "No",
                            lastlogin: value.last_login_timestamp ? moment(value.last_login_timestamp).format("MM/DD/YY") : "-",
                            email_last_confirm: value.email_last_confirm,
                            facilities : value?.facilities
                        });
                    }
                }
                else {
                    //groupId = value.group_id;
                    results.push({
                        id: value.id,
                        name: value.username,
                        fullname: value.first_name + ' ' + value.last_name,
                        role: roleMapping[value.role_id],
                        mobile: value.phone,
                        email: value.email,
                        active: value.is_active == 1 ? "Yes" : "No",
                        lastlogin: value.last_login_timestamp ? moment(value.last_login_timestamp).format('MM/DD/YY HH:mm A') : "-",
                        email_last_confirm: value.email_last_confirm,
                        facilities : value?.facilities
                    });
                }
            });

            //localStorage.setItem("groupId", groupId);
            if (results.length > 0) {
                setFacilityStaff(results);
                setTableFlag(true);
            } else {
                setTableFlag(false);
                toast.error('Selected Facility is not Available')
            }

            let facilityResults = JSON.parse(localStorage.getItem("facilitiesData"));
            console.log("Facility Points " + JSON.stringify(facilityResults));

            setOptions([
                { key: 'Select a facility', value: '' },
                ...facilityResults
            ])
        } catch (error) {
            navigate('/error')
        }
    }

    const handleFieldChange = e => {
        //console.log(e.target.key + "," + e.target.value);
        fetchFacilityData(e.value);
    };

    const table = useAdvanceTable({
        data: facilityStaff,
        columns,
        selection: false,
        sortable: true,
        pagination: true
    });

    const breadcrumbItems = [
        { name: 'Dashboard', href: '/main', isActive: false },
        { name: 'User Management ', href: paths.viewFacility, isActive: true }
    ];

    return (
        <>
            {facilityStaff.length > 0 ?
                <>
                    <CommonBreadCrumb breadcrumbItems={breadcrumbItems} />
                    <AdvanceTableProvider {...table}>
                        <Card className="mb-3">
                            <Card.Body className="p-2">
                                <Row className='mt-3 justify-content-between'>
                                    <Col xs="4" className="mb-3 ml-3">
                                        <AdvanceTableSearchBox placeholder="Search..." />
                                    </Col>
                                    <Col xs="4">
                                        <Form.Group>
                                            <Select
                                            name="facilityname"
                                            closeMenuOnSelect={true}
                                            placeholder='Select Facility'
                                            className="form-control-sm"
                                            classNamePrefix="react-select"
                                            options={options}
                                            value={selectedFacility}
                                            getOptionLabel={option => option.key}
                                            getOptionValue={option => option.value}
                                            onChange={(selectedOption) => {
                                                setSelectedFacility(selectedOption);
                                                handleFieldChange(selectedOption);
                                            }}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: "#ced4da",
                                                    backgroundColor: "white",
                                                    height: "calc(1.5em + 0.75rem + 2px)",
                                                    minHeight: "38px",
                                                    boxShadow: "none",
                                                    "&:hover": { borderColor: "#80bdff" }
                                                }),
                                                option: (provided, { isFocused, isSelected }) => ({
                                                    ...provided,
                                                    backgroundColor: isSelected ? "#e9ecef !important" : isFocused ? "#f8f9fa !important" : "white !important",
                                                    color: isSelected ? "#212529 !important" : "#212529 !important",
                                                    cursor: "pointer",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "#212529",
                                                }),
                                            }}
                                        />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    {tableFlag ? <Col xs="12" className="mb-1 mt-1">
                                        <AdvanceTable
                                            headerClassName="bg-200 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                size: 'sm',
                                                striped: true,
                                                className: 'fs-10 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </Col> : <p className="text-danger text-center fw-bold">No data available in table</p>}
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <div className="mt-3">
                                    <AdvanceTableFooter
                                        rowInfo
                                        navButtons
                                        rowsPerPageSelection
                                    />
                                </div>
                            </Card.Footer>
                        </Card>
                        <ResetPasswordModal show={showModal} setShow={setShowModal} />
                    </AdvanceTableProvider>
                </>
                : <ShimmerUI />}
        </>
    );
};

export default ViewFacilityStaff;
