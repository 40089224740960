import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

const PrescriptionDetailsModal = ({ show, handleClose, prescription }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Patient Name:</Col>
          <Col xs={7}>{prescription?.patientName}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Drug:</Col>
          <Col xs={7}>{prescription?.drug}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Rx Number:</Col>
          <Col xs={7}>{prescription?.rx}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Doctor Name:</Col>
          <Col xs={7}>{prescription?.doctorName}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Last Delivered:</Col>
          <Col xs={7}>{prescription?.lastDelivered}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Refills:</Col>
          <Col xs={7}>{prescription?.refills}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Due By:</Col>
          <Col xs={7}>{prescription?.dueBy}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Prescribe Date:</Col>
          <Col xs={7}>{prescription?.prescribe_date}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Direction:</Col>
          <Col xs={7}>{prescription?.direction}</Col>
        </Row>

        <Row className="mb-2">
          <Col xs={5} className="fw-bold">Stop Date:</Col>
          <Col xs={7}>{prescription?.stopDate || "-"}</Col>
        </Row>

        <Row className="mt-3 text-center">
          <Col xs={12} className="text-danger fw-bold">
            *This is not an original prescription please do not copy
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrescriptionDetailsModal;
