import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

const MedicationInstructionsModal = ({ show, handleClose, dataObj, splitObj }) => {
  const [finalData, setData] = useState([]);

  useEffect(() => {
    if (splitObj?.length) {
      const initialData = splitObj.map((prescription) => ({
        id: prescription.id,
        sig: prescription.sig,
        amount: prescription.amount_id || '',
        dose: prescription.dose || '',
        frequency: prescription.frequency_id || '',
        hours: [
          prescription.time_1 || '',
          prescription.time_2 || '',
          prescription.time_3 || '',
          prescription.time_4 || '',
        ].filter(Boolean),
      }));
      setData(initialData);
    }
  }, [dataObj]);

  const [frequency] = useState(dataObj?.frequency || []);
  const [amount] = useState(dataObj?.amount || []);
  const [hours] = useState(dataObj?.prescription_hour || []);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...finalData];
    updatedData[index][field] = value;
    setData(updatedData);
  };

  const handleHourChange = (index, hourIndex, value) => {
    const updatedData = [...finalData];
    if (!updatedData[index].hours) {
      updatedData[index].hours = [];
    }
    updatedData[index].hours[hourIndex] = value;
    setData(updatedData);
  };

  const handleSave = async () => {
    for (let i = 0; i < finalData.length; i++) {
      const item = finalData[i];
      if (!item.amount) {
        toast.error(`Amount is required for row ${i + 1}`);
        return;
      }
      if (!item.dose || item.dose <= 0) {
        toast.error(`Dose must be greater than zero for row ${i + 1}`);
        return;
      }
      if (!item.frequency) {
        toast.error(`Frequency is required for row ${i + 1}`);
        return;
      }
      if (!item.hours || item.hours.every(hour => !hour)) {
        toast.error(`At least one hour must be selected for row ${i + 1}`);
        return;
      }
    }

    const payload = finalData.map((item) => ({
      id: item.id,
      dose: item.dose,
      amount_id: item.amount,
      frequency_id: item.frequency,
      time_1: item.hours?.[0] || null,
      time_2: item.hours?.[1] || null,
      time_3: item.hours?.[2] || null,
      time_4: item.hours?.[3] || null
    }));

    let tokenStr = localStorage.getItem("accessToken");
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/prescriptions/update-splitmends`,
      payload,
      { headers: { Authorization: `Bearer ${tokenStr}` } }
    );
    if (data.status) {
      toast.success("Splitmeds done Successfully");
      handleClose();
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Medication Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered>
          <thead>
            <tr>
              <th>Direction</th>
              <th>Amount</th>
              <th>Dose</th>
              <th>Frequency</th>
              <th>Hours</th>
            </tr>
          </thead>
          <tbody>
            {finalData.length > 0 ? finalData.map((item, index) => (
              <tr key={index}>
                <td>{item.sig}</td>
                <td>
                  <Form.Select
                    value={item.amount || ''}
                    onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                  >
                    <option value="">Select Amount</option>
                    {amount.map((amountOption) => (
                      <option key={amountOption.id} value={amountOption.id}>
                        {amountOption.amount}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <Form.Control
                    type="number"
                    min="1"
                    value={item.dose || ''}
                    onChange={(e) => handleInputChange(index, 'dose', e.target.value)}
                  />
                </td>
                <td>
                  <Form.Select
                    value={item.frequency || ''}
                    onChange={(e) => handleInputChange(index, 'frequency', e.target.value)}
                  >
                    <option value="">Select Frequency</option>
                    {frequency.map((freqOption) => (
                      <option key={freqOption.id} value={freqOption.id}>
                        {freqOption.name}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  {[0, 1, 2, 3].map((hourIndex) => (
                    <Form.Select
                      key={hourIndex}
                      value={(item.hours && item.hours[hourIndex]) || ''}
                      onChange={(e) =>
                        handleHourChange(index, hourIndex, e.target.value)
                      }
                      className="mb-2"
                    >
                      <option value="">Select Hour</option>
                      {hours.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.time}
                        </option>
                      ))}
                    </Form.Select>
                  ))}
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="5" className="text-center">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MedicationInstructionsModal;
