import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ColorStatusLegend = () => {
  const statuses = [
    { color: '#006400', label: 'Refill' }, // Dark Green
    { color: '#FF8C69', label: 'Billed' }, // Peach
    { color: '#0000FF', label: 'Robot' }, // Blue
    { color: '#800080', label: 'Manual' }, // Purple
    { color: '#008000', label: 'PV2' }, // Green
    { color: '#8B4513', label: 'Manifest' }, // Brown
    { color: '#FFA500', label: 'Pending' }, // Orange
    { color: '#FFFF00', label: 'Census complete' }, // Yellow
  ];

  return (
    <div className="d-flex align-items-center flex-wrap">
      {/* <strong className="me-3">Color status:</strong> */}
       <Row className="d-flex">{/* className="g-3 d-flex  align-items-center" */}
        {statuses.map((status, index) => (
          <Col key={index} className="d-flex align-items-center me-4 pb-2">
            <div
              style={{
                backgroundColor: status.color,
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                marginRight: '8px',
              }}
            ></div>
            <span>{status.label}</span>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ColorStatusLegend;
