import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import * as mapYourMedsConstant from '../../../common/mapYourMedsConstant.js';

const InsuranceForm = (props) => {

    const initialFormData = {
        insurance: "",
        isactive: false,
        id: ""
    };

    const [formData, setFormData] = useState(initialFormData);
    const [buttonStatus, setButtonStatus] = useState(mapYourMedsConstant.ADD_ACTION);
    const formRef = useRef(null);
    //const navigate = useNavigate();

    useEffect(() => {
        async function setInsuranceDetails() {
            console.log("set edit details " + props.insvalue);
            setFormData({
                insurance: props.insvalue.split(",")[0],
                isactive: props.insvalue.split(",")[1] == 1 ? true : false,
                id: props.insvalue.split(",")[2]
            });

            setButtonStatus(mapYourMedsConstant.UPDATE_ACTION);
        }

        if (props.edittrigger > 0) {
            //console.log(props.trigger);
            setInsuranceDetails();
        }
    }, [props.edittrigger]);

    const handleSubmit = async e => {
        e.preventDefault();

        let tokenStr = localStorage.getItem("accessToken");
        let pelmedsInsuranceUrl = buttonStatus == "Add" ? `${process.env.REACT_APP_SERVER_URL}` + "/patients/add-patient-insurance" :
            `${process.env.REACT_APP_SERVER_URL}` + "/patients/update-patient-insurance";

        let pelmedsInsuranceData = buttonStatus == "Add" ? { "name": formData.insurance,"group_id" : localStorage.getItem("groupId") || '', "is_active": formData.isactive ? 1 : 0 } :
            { "name": formData.insurance,"group_id" : localStorage.getItem("groupId") || '', "is_active": formData.isactive ? 1 : 0, "id": formData.id }

        //console.log("pelmedsInsuranceData" + JSON.stringify(pelmedsInsuranceData));

        const { data } = await axios.post(pelmedsInsuranceUrl, pelmedsInsuranceData, { headers: { 'Authorization': `Bearer ${tokenStr}` } });

        if (data.status) {
            toast.success("Insurance Info added/updated successfully");
            //navigate(0);
        }
        else {
            toast.info("Error while adding Insurance Info");
        }

        //if (props.status != "Edit") {
        setFormData(initialFormData);
        formRef.current.reset();
        setButtonStatus("Add");
        //}

        props.onCallParent();
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="required">Insurance Name</Form.Label>
                        <Form.Control
                            placeholder='Insurance Name'
                            value={formData.insurance}
                            name="insurance"
                            size="sm"
                            onChange={handleFieldChange}
                            type="text"
                        />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Button
                            type="submit"
                            color="primary"
                            className="mt-4 w-50"
                            size="sm"
                            disabled={!formData.insurance}>
                            {buttonStatus}
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Check
                            type='switch'
                            name="isactive"
                            id='isactive'
                            checked={
                                formData.isactive
                            }
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    isactive: e.target.checked
                                })
                            }
                            label='Active'
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label><span className="text-danger">*</span> indicates required field</Form.Label>
                </Col>
            </Row>
        </Form >
    );
};
export default InsuranceForm;
