import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [spinner, showSpinner] = useState(false);
  const navigate = useNavigate();
  // Handler
  async function handleSubmit(e) {
    showSpinner(true);
    e.preventDefault();
    if (email) {
      let tokenStr = localStorage.getItem("accessToken");
      let forgotpasswordurl = `${process.env.REACT_APP_SERVER_URL}` + '/users/forgot-password'
      const { data } = await axios.post(forgotpasswordurl, { "email": email  }, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
      if (data.status) {
        toast.success(`An email is sent to ${email} with password reset link`, {
          theme: 'colored'
        });
        navigate("/otp");
      }
      else {
        toast.info(data?.message || "Error occured while sending email");
        showSpinner(false);
      }
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
      {spinner && (
          <Button variant="primary" disabled className="mt-3 w-100">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="p-2" style={{ "verticalAlign": "3px" }}>Loading...</span>
          </Button>
        )}
        {!spinner && (<Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>)}
      </Form.Group>
      <Link className="fs-10 text-600 text-start d-block" to="/">
        <span className="d-inline-block ms-1"> &larr;</span>
        Go Back
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
