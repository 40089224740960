import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

const OrderInternalNotes = ({ data, filteredData, setRefresh }) => {
  const dataObj = data
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  async function handleSendMessage() {
    if (newMessage.trim()) {
      const messageToSend = {
        sender: localStorage.getItem("username"),
        text: newMessage,
        timestamp: new Date().toLocaleString(),
        from: 9
      };

      setMessages((prevMessages) => [...prevMessages, messageToSend]);
      let payload = {
        order_id: dataObj.orderid,
        note: newMessage
      }
      setNewMessage('');
      let tokenStr = localStorage.getItem("accessToken");
      let pelmedsAddNotestUrl = `${process.env.REACT_APP_SERVER_URL}` + '/orders/add-internal-note/'
      const { data } = await axios.post(pelmedsAddNotestUrl, payload, { headers: { 'Authorization': `Bearer ${tokenStr}` } });
      if (data.status) {
        toast.success("Notes Added");
        let obj = data.internal_notes;
        dataObj.internalNotesObj = obj
        if (dataObj?.flag) {
          filteredData?.map((item) => {
            if (item.orderid === dataObj.orderid) {
              item.internalNotesObj = obj
            }
          })
        }
        setRefresh(filteredData);
      }
    }
  }


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const fetchMessages = async () => {
    let resultMessages = [];
    dataObj.internalNotesObj?.map((item) => {
      if (item.note_from == 9) {
        resultMessages.push({
          sender: item.created_by_username,
          text: item.note,
          timestamp: moment(item.created).local().format('MM/DD/YY, hh:mm:ss A'),
          from: item.note_from
        })
        setMessages(resultMessages);
      }
    })
  }


  useEffect(() => {
    fetchMessages()
  }, [])


  return (
    <>
      <div
        className='mb-2 notes-wrapper'
        style={{
          height: '300px',
          overflowY: 'auto',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: '#f9f9f9',
        }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              marginBottom: '10px',
              textAlign: 'right',
            }}
          >
            <div style={{ marginBottom: '5px' }}>
              <strong style={{ color: msg.sender === 'You' ? '#007bff' : '#555' }}>
                {msg.sender}
              </strong>{' '}
              <span style={{ fontSize: '0.8em', color: '#888' }}>
                {msg.timestamp}
              </span>
            </div>
            <div
              style={{
                display: 'inline-block',
                backgroundColor: msg.sender === 'You' ? '#d1ecf1' : '#e9ecef',
                padding: '10px',
                borderRadius: '10px',
                maxWidth: '70%',
                textAlign: 'left',
              }}
            >
              {msg.text}
            </div>
          </div>
        ))}
      </div>
      <Row className="w-100 align-items-center">
        <Col xs={10}>
          <Form.Control
            type="text"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Col>
        <Col xs={2}>
          <Button
            variant="primary"
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{ width: '40px', height: '40px' }}
            onClick={handleSendMessage}
          >
            <FontAwesomeIcon
              icon="paper-plane"
              style={{ fontSize: '1.2rem', color: 'white' }}
            ></FontAwesomeIcon>
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default OrderInternalNotes