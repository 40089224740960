import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Accordion, Tab, Card, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { useNavigate } from "react-router-dom";
import MedSheetForm from './MedSheetForm';
import LoaForm from './LoaForm';
import HcpOrder from './HcpOrder';
import Treatment from './Treatment';
import CurrentMed from './CurrentMed';
import ViewReportTable from './ViewReportTable';
import Flex from 'components/common/Flex';
import ReportTypeIndex from './ReportTypeIndex';

// const NavItem = ({ item, classNameCustom }) => {
//     return (
//         console.log(classNameCustom),
//         <Nav.Item as="li" className={`${classNameCustom}`}>
//             <Nav.Link
//                 className={`mb-0 py-3 px-1 cursor-pointer`}
//                 eventKey={item.toLowerCase()}>
//                 {item}
//             </Nav.Link>
//         </Nav.Item>
//     );
// };

const GenerateReport = ({data}) => {

    // const [navItems] = useState(['Medsheet', 'Treatment', 'LoaForm', 'Hcporder', 'CurrentMed']);

    return (
        <ReportTypeIndex data = {data} />
        // <Tab.Container id="audience-tab" defaultActiveKey="medsheet">
        //     <Card.Header
        //         as={Flex}
        //         justifyContent="between"
        //         alignItems="center"
        //         className="p-0 border-bottom patientInfoTabs"
        //     >
        //         <Nav
        //             as="ul"
        //             className="nav-tabs border-0 d-flex w-100 flex-nowrap chart-tab tab-active-caret p-0"
        //         >
        //             {navItems.map(item => (
        //                 <NavItem classNameCustom="col text-center" item={item} key={item} />
        //             ))}
        //         </Nav>
        //     </Card.Header>

        //     <Card.Body>
        //         <Row className="g-1">
        //             <Col xxl={12}>
        //                 <Tab.Content>
        //                     <Tab.Pane unmountOnExit eventKey="medsheet">
        //                         <MedSheetForm />
        //                         <ViewReportTable report_type="medsheet" />
        //                     </Tab.Pane>
        //                     <Tab.Pane unmountOnExit eventKey="treatment">
        //                         <Treatment />
        //                         <ViewReportTable report_type="treatment" />
        //                     </Tab.Pane>
        //                     <Tab.Pane unmountOnExit eventKey="loaform">
        //                         <LoaForm />
        //                         <ViewReportTable report_type="loaform" />
        //                     </Tab.Pane>
        //                     <Tab.Pane unmountOnExit eventKey="hcporder">
        //                         <HcpOrder />
        //                         <ViewReportTable report_type="hcporder" />
        //                     </Tab.Pane>
        //                     <Tab.Pane unmountOnExit eventKey="currentmed">
        //                         <CurrentMed />
        //                         <ViewReportTable report_type="currentmeds" />
        //                     </Tab.Pane>
        //                 </Tab.Content>
        //             </Col>
        //         </Row>
        //     </Card.Body>
        // </Tab.Container>
    );
};

GenerateReport.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

GenerateReport.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default GenerateReport;
